import React from 'react';

import { Box, Text } from 'grommet';

import { GET_BILLING_PAY_PLAN_account_items_payplans_items } from '../../graphql/__generated__/GET_BILLING_PAY_PLAN';

import ResponsiveGrid from '../../../responsiveGrid';
import { PolicyPayPlans } from '../..';

interface Props {
  payPlans: GET_BILLING_PAY_PLAN_account_items_payplans_items[];
  onClick: Function;
  method: String;
  title: String;
  automaticPaymentStatus: string | null;
}

const CurrenctPoliciesListUI = ({
  payPlans,
  title,
  method,
  automaticPaymentStatus,
  onClick
}: Props) => (
  <ResponsiveGrid
    rows={['auto']}
    alignContent="start"
    columns={['1/2', '1/2']}
    areas={{
      xsmall: [
        { name: 'policies', start: [0, 0], end: [0, 0] },
        { name: 'method', start: [1, 0], end: [1, 0] }
      ],
      small: [
        { name: 'policies', start: [0, 0], end: [0, 0] },
        { name: 'method', start: [1, 0], end: [1, 0] }
      ],
      medium: [
        { name: 'policies', start: [0, 0], end: [0, 0] },
        { name: 'method', start: [1, 0], end: [1, 0] }
      ],
      large: [
        { name: 'policies', start: [0, 0], end: [0, 0] },
        { name: 'method', start: [1, 0], end: [1, 0] }
      ],
      xlarge: [
        { name: 'policies', start: [0, 0], end: [0, 0] },
        { name: 'method', start: [1, 0], end: [1, 0] }
      ]
    }}
    gap="24px"
  >
    <Box gridArea="policies" flex>
      <Text>Current Policies</Text>
      {payPlans.map(
        (payPlan: GET_BILLING_PAY_PLAN_account_items_payplans_items) => {
          if (payPlan?.policy && payPlan.policy.policy_number !== null) {
            return <PolicyPayPlans planData={payPlan} onClick={onClick} />;
          }
          return <></>;
        }
      )}
    </Box>
    <Box gridArea="method">
      <Text margin={{ bottom: '15px' }}>Payment Method</Text>
      <Box
        background="#F5F7FA"
        pad={{ top: '13px', left: '10px', bottom: '17px' }}
      >
        <Text color="#041E41" size="12px">
          {title}
        </Text>
        <Text color="#041E41" size="14px" weight="bold">
          {method}
        </Text>
        {automaticPaymentStatus && (
          <Box>
            <Text size="12px">Automatic Payments</Text>
            <Text size="14px" weight="bold">
              {automaticPaymentStatus}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  </ResponsiveGrid>
);

export default CurrenctPoliciesListUI;
