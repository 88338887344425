import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import LogRocket from 'logrocket';
import FeedbackCommentModal from './FeedbackCommentModal';
import FeedbackCommentModalButton from './FeedbackCommentModalButton';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useGlobalContext } from '../../util/globalContextProvider';

const provide_feedback_mutation = loader(
  './graphql/Put_Feedback.graphql'
);

interface FeedbackProps {
  rating: number | any;
  feedback: string | any;
}

interface FeedbackCommentModalDataContainerProps {
  userEmail: string | undefined;
  userName: string | undefined;
}

export default ({
  userEmail,
  userName
}: FeedbackCommentModalDataContainerProps) => {
  const [provideFeedback] = useMutation(provide_feedback_mutation);
  const [showModal, setShowModal] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const modalPopUpOnceIncrement = sessionStorage.getItem(
    'modalPopUpOnceIncrement'
  );
  const sessionIncrement = localStorage.getItem('sessionIncrement');
  const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();

  function timedModalPopOut() {
    setShowModal(!showModal);
  }

  if (sessionIncrement && modalPopUpOnceIncrement === '1') {
    if (parseInt(sessionIncrement, 10) % 5 === 0) {
      sessionStorage.setItem('modalPopUpOnceIncrement', '2');
      localStorage.setItem('sessionIncrement', '0');
      setTimeout(() => timedModalPopOut(), 30000);
    }
  }

  const toggleShowModal = () => {
    setShowModal(!showModal);
    setErrorState(false);
  };

  let policyNumber: null | string = null;
  let account_number: null | string = 'N/A';
  const urlSplitBySlash = window.location.pathname.split('/');

  if (urlSplitBySlash.includes('policy')) {
    policyNumber = urlSplitBySlash[4];
  }
  if (urlSplitBySlash.includes('account')) {
    account_number = urlSplitBySlash[2];
  }

  let logrocketurl: string = '';

  LogRocket.getSessionURL(sessionURL => {
    logrocketurl = sessionURL;
  });

  const submitFeedback = ({ rating, feedback }: FeedbackProps) => {
    const input = {
      account_number,
      userEmail,
      userName,
      rating,
      feedback,
      logrocketurl,
      policyNumber
    };
    const data = {
      event: 'sending-feedback-click',
      account_number,
      userEmail,
      userName,
      rating,
      feedback,
      logrocketurl,
      policyNumber
    };
    simpleGTMDataLayer(data);
    provideFeedback({ variables: input })
      .then(res => {
        if (res?.data?.provideFeedback?.id) {
          toggleShowModal();
        }
      })
      .catch((error: Error) => {
        setErrorState(true);
        console.error(error);
      });
  };

  if (showModal) {
    return (
      <FeedbackCommentModal
        submitFeedback={submitFeedback}
        toggleShowModal={toggleShowModal}
        errorState={errorState}
        readOnlyAccess={readOnlyAccess}
        readOnlyFeatureFlag={readOnlyFeatureFlag}
      />
    );
  }
  return (
    <FeedbackCommentModalButton
      toggleShowModal={toggleShowModal}
      readOnlyAccess={readOnlyAccess}
      readOnlyFeatureFlag={readOnlyFeatureFlag}
    />
  );
};
