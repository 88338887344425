import React from 'react';
import {
  DownloadDocumentBanner,
  BannerText,
  DownloadIcon
} from './cancelPolicyFormStyles';

import { AltInfo } from '../../../icons';

import DownloadIconSrc from '../../../assets/images/DownloadBlue.svg';

interface DocumentDownloadProps {
  systemCode: string;
}

export default ({ systemCode }: DocumentDownloadProps) => {
  const url = '\\Acord-35-Cancellation-Request-Form.pdf';
  const fileName = 'Acord-35-Cancellation-Request-Form.pdf';
  return (
    <DownloadDocumentBanner>
      <AltInfo />
      <BannerText>A Signed Cancellation Document is Required.</BannerText>
      <a href={url} download={fileName}>
        <DownloadIcon src={DownloadIconSrc} />
      </a>
    </DownloadDocumentBanner>
  );
};
