import React from 'react';
import { Warning } from '../../icons';
import {
  WarningBanner,
  WarningContainer,
  IconSizing,
  MainText,
  SecondaryText
} from './GlobalOutageBannertStyles';

export default ({
  mainText,
  secondaryText
}: {
  mainText?: string | null;
  secondaryText?: string | null;
}) => {
  return (
    <WarningBanner>
      <WarningContainer>
        <IconSizing>
          <Warning />
        </IconSizing>
        <div>
          <MainText>{mainText}</MainText>
          {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
        </div>
      </WarningContainer>
    </WarningBanner>
  );
};
