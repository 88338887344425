import styled from 'styled-components';

export const WarningBanner = styled.div`
  background: #fff6e9;
  width: 100%;
  height: 60px;
`;
export const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 23px;
  gap: 24px;
  align-items: center;
  padding-top: 11px;
`;

export const MainText = styled.div`
  color: #7a5212;
  font-weight: bold;
  font-size: 16px;
`;

export const SecondaryText = styled.div`
  color: #7a5212;
  font-size: 14px;
`;
export const IconSizing = styled.div`
  transform: scale(1.5);
`;
