import React, { useState } from 'react';
import {
  PrimaryContact,
  ChevronUp,
  ChevronDownUnbolded
} from '../../../icons';
import {
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
  Label,
  Value,
  RowContainer,
  LabelDisplay,
  IconContainer,
  Icon
} from '../ExpandedViewStyles';
interface InjuredPersonDetailsProps {
  name?:string;
}
const InjuredPersonDetails: React.FC<InjuredPersonDetailsProps> = ({
name
}) => {
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <DropdownContainer open={open}>
      <DropdownHeader onClick={toggleDropdown} open={open}>
        <span>Injured Person Details </span>
        <Icon position='right' open={open}>{open ? <ChevronUp /> : <ChevronDownUnbolded />}</Icon>
      </DropdownHeader>
      <DropdownContent open={open}>
        <RowContainer>
          <IconContainer>
            <PrimaryContact />
          </IconContainer>
          <LabelDisplay>
            <Label>Injured Name</Label>
            <Value>{name || 'N/A'}</Value>
          </LabelDisplay>
        </RowContainer>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default InjuredPersonDetails;
