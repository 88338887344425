import React from 'react';
import { navigate } from '@reach/router';
import { Button } from 'grommet';
import salogo from '../../assets/images/sa-logo-white.svg';

export default () => (
  <Button
    focusIndicator={false}
    onClick={() => {
      navigate(`/`);
    }}
  >
    <img src={salogo} alt="" />
  </Button>
);
