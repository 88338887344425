import React from 'react';
import {
  NavBarContainer,
  InsuredNameHolder,
  StyledInsuredName,
  StyledNav,
  StyledNavLink
} from './QuotePageNavBarStyles';

export interface QuotePageNavBarProps {
  insuredName: string | null;
}

export const QuotePageNavBar = ({ insuredName }: QuotePageNavBarProps) => {
  return (
    <NavBarContainer>
      <InsuredNameHolder>
        <StyledInsuredName test-attr="quote-insured-name">
          {insuredName ?? 'N/A'}
        </StyledInsuredName>
      </InsuredNameHolder>
      <StyledNav>
        <StyledNavLink>Overview</StyledNavLink>
      </StyledNav>
    </NavBarContainer>
  );
};
