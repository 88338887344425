import parseSearchHighlighting, {
  displayOption
} from '../../../util/parseSearchHighlighting';

interface processPhoneProps {
  primary_phone: string;
  cell_phone: string;
  home_phone: string;
  work_phone: string;
  cell_highlights: string[];
  home_highlights: string[];
  work_highlights: string[];
}

interface HLProps {
  plain: string;
  highlighted: displayOption[];
}

export const processPhone = ({
  primary_phone,
  cell_phone,
  home_phone,
  work_phone,
  cell_highlights,
  home_highlights,
  work_highlights
}: processPhoneProps) => {
  const phoneHighlighting: HLProps = {
    plain: '',
    highlighted: []
  };
  if (
    cell_highlights.length ||
    home_highlights.length ||
    work_highlights.length
  ) {
    if (cell_highlights.length && primary_phone === 'MOBILE') {
      phoneHighlighting.highlighted = parseSearchHighlighting(
        cell_highlights[0]
      );
      phoneHighlighting.plain = cell_phone;
    } else if (home_highlights.length && primary_phone === 'HOME') {
      phoneHighlighting.highlighted = parseSearchHighlighting(
        home_highlights[0]
      );
      phoneHighlighting.plain = home_phone;
    } else if (work_highlights.length && primary_phone === 'WORK') {
      phoneHighlighting.highlighted = parseSearchHighlighting(
        work_highlights[0]
      );
      phoneHighlighting.plain = work_phone;
    } else if (cell_highlights.length) {
      phoneHighlighting.highlighted = parseSearchHighlighting(
        cell_highlights[0]
      );
      phoneHighlighting.plain = cell_phone;
    } else if (home_highlights.length) {
      phoneHighlighting.highlighted = parseSearchHighlighting(
        home_highlights[0]
      );
      phoneHighlighting.plain = home_phone;
    } else {
      phoneHighlighting.highlighted = parseSearchHighlighting(
        work_highlights[0]
      );
      phoneHighlighting.plain = work_phone;
    }
  } else if (primary_phone === 'MOBILE' && cell_phone) {
    phoneHighlighting.plain = cell_phone;
  } else if (primary_phone === 'HOME' && home_phone) {
    phoneHighlighting.plain = home_phone;
  } else if (primary_phone === 'WORK' && work_phone) {
    phoneHighlighting.plain = work_phone;
  } else if (cell_phone) {
    phoneHighlighting.plain = cell_phone;
  } else if (home_phone) {
    phoneHighlighting.plain = home_phone;
  } else if (work_phone) {
    phoneHighlighting.plain = work_phone;
  }

  if (
    phoneHighlighting.highlighted.length >= 2 &&
    phoneHighlighting.highlighted[0].style === 'normal'
  ) {
    phoneHighlighting.highlighted[1].text = `(${phoneHighlighting.highlighted[1].text}`;
    phoneHighlighting.highlighted = phoneHighlighting.highlighted.slice(1);
  }

  return phoneHighlighting;
};

export const formatHighlightTitleCase = (
  baseText: string,
  highlightArray: displayOption[]
) => {
  let startingPosition = 0;
  const titleCaseHighlights = highlightArray.map(
    (partialText: displayOption) => {
      const partialLength = partialText.text.length;
      const transformedPartialText: displayOption = {
        style: partialText.style,
        text: baseText.slice(startingPosition, startingPosition + partialLength)
      };
      startingPosition += partialLength;
      return transformedPartialText;
    }
  );
  return titleCaseHighlights;
};
