import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

interface TextProps {
  color?: string;
}

export const Text = styled.span<TextProps>`
  font-size: 16px;
  line-height: 20px;
  ${props => (props.color ? `color: ${props.color};` : '')}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LabelText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
`;

export const SnapshotWrap = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
    background: #005c86;
  }
`;

export const SnapshotBackground = styled.div`
  display: flex;
  background-color: #f6f8fb;
  padding: 15px 30px;

  &.snapshot-invoice {
    cursor: pointer;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;

  &.snapshot-invoice {
    min-width: calc(50% - 7px);
  }
`;

export const LeftColumnRow = styled(Row)`
  gap: 19px;
`;

export const LeftColumnLabelText = styled(LabelText)`
  min-width: 92px;
`;

export const RightColumnLabelText = styled(LabelText)`
  min-width: 63px;
`;

export const RightColumnRow = styled(Row)`
  gap: 20px;
`;

export const LinkText = styled(Text)`
  color: #0173c6;
  cursor: pointer;
`;

export const Pagination = styled.div`
  margin-left: auto;
`;

export const WhatChangedLink = styled.p`
  cursor: pointer;
  display: inline-block;
  margin: 0;
  margin-left: 8px;
  font-size: 16px;
  color: #0173c6;
`;

export const WhatChangedModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(4, 30, 65, 0.75);
`;

export const WhatChangedModalBody = styled.div`
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
  border-radius: 3px;
  padding: 15px;
`;

export const ModalHeaderWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalHeaderTitle = styled.p`
  margin: 0;
  flex-grow: 1;
  font-weight: 700;
  font-size: 19px;
  color: #3b3b3b;
`;

export const ModalHeaderCloseBtn = styled.img`
  display: block;
  cursor: pointer;
  width: 16px;
  height: auto;
`;

export const WhatChangedModalDesc = styled.p`
  margin: 30px 0;
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
`;

export const ModalFooterBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalFooterCloseBtn = styled.p`
  cursor: pointer;
  margin: 0;
  line-height: 40px;
  padding: 0 25px;
  background: #4d840b;
  border-radius: 5px;
  font-weight: 700;
  font-size: 19px;
  color: #ffffff;
`;
export const IconDiv = styled.div`
  height: 20px;
  width: 20px;
`;
