import {Auth} from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
export default async(config: AxiosRequestConfig) => {
  const currentSession = await Auth.currentSession();
  const jwt = currentSession.getIdToken().getJwtToken();
  if(config.headers){
    config.headers.Authorization = jwt;
  } else {
    config.headers = {
      Authorization: jwt
    };
  }
  return await axios(config);
};