import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Text, Anchor } from 'grommet';
import { RouteComponentProps } from '@reach/router';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import CommunicationAccordion from '../../components/communicationAccordion';
import {
  PaymentInfo,
  OverviewAccountListTable,
  AccountInformationEdit,
  ResponsiveGrid,
  CustomerIntent,
  PrimaryButton,
  ChangeDueDate,
  OutageModal
} from '../../components';
import { CardLayout } from '../../components/cardLayout/CardLayout';
import { CardLayoutShell } from '../../components/cardLayoutShell/CardLayoutShell';
import { Info, SmallInfo } from '../../icons';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import NoPolicyorAccount from '../../assets/images/No_policies.png';
import Edit from '../../assets/images/Edit.svg';
import FeatureFlag from '../../components/featureFlags';
import EditIconDisabled from '../../icons/EditIconDisabled';
import getConfig, { Env } from '../../config';
import AltInfoIcon from '../../icons/AltInfoIcon';

import {
  AccordionStyledCard,
  BigBoldText,
  CenterModal,
  ChangeDate,
  Column1,
  Column2,
  EditButtonRow,
  EditHolder,
  InfoContainer,
  InfoPopUp,
  LayoutContainer,
  OuterBox,
  PaymentBox,
  SmallText,
  StyledBox,
  StyledBoxShell,
  EditTextWrap,
  StyledImg,
  VerticalDivider,
  AccountInfoHeaderBtnWrap,
  AccountInfoHeaderBtn,
  AccountInfoHeaderBtnDivider,
  AgencyBilledBanner
} from './AccountInformationPageStyles';

export interface AccountInformationPageProps {
  accountNumber: string;
  systemCode?: string | null;
  billingCenterOutage: boolean | undefined;
  policyCenterOutage: boolean | undefined;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
  displayOverViewPoliciesAndQuotes: boolean;
  isMigrated: boolean;
  isAgencyBilledOnly?: boolean;
  isAgencyAndDirect?: boolean;
}

export default ({
  accountNumber,
  systemCode,
  billingCenterOutage,
  policyCenterOutage,
  readOnlyAccess,
  isMigrated,
  readOnlyFeatureFlag,
  isAgencyBilledOnly,
  isAgencyAndDirect
}: RouteComponentProps<AccountInformationPageProps>) => {
  const [editable, setEditable] = useState(false);
  const [paymentShow, setPaymentShow] = useState(true);
  const [doSave, setDoSave] = useState(0);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [gtm, setGTM] = useState(false);
  const [showChangeDueDateModal, setShowChangeDueDateModal] = useState(false);
  const [
    showChangeDueDateOutageModal,
    setShowChangeDueDateOutageModal
  ] = useState<boolean>(false);
  const [showAccountEditOutageModal, setShowAccountEditOutageModal] = useState<
    boolean
  >(false);
  const accountType = systemCode === 'gwcl' ? 'COMMERCIAL' : 'PERSONAL';
  const navLinks = document.getElementById('nav-links');
  const searchForm = document.getElementById('searchForm');
  const config = getConfig(process.env.REACT_APP_ENV as Env);
  localStorage.setItem('lob', accountType);

  const { flagDetails: LastDayToPayFlag } = useFeatureFlag('LastDayToPay');

  if (!gtm) {
    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/account/overview',
        title: 'SA360: Account Overview'
      }
    });

    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Account Overview',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });

    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Account Overview',
      event_category: localStorage.getItem('lob')
        ? `${localStorage.getItem('lob')}/Snowplow`
        : '/Snowplow'
    });
    setGTM(true);
  }

  useEffect(() => {
    setShowChangeDueDateModal(false);
  }, [accountNumber]);

  const ErrorFallback = () => {
    simpleGTMDataLayer({
      event: 'invalidAccountOrPolicy',
      event_action: 'Account Overview',
      event_category: accountNumber
    });

    if (navLinks && searchForm) {
      navLinks.style.display = 'none';
      searchForm.style.display = 'none';
    }

    return (
      <OuterBox>
        <img
          height="250px"
          width="250px"
          src={NoPolicyorAccount}
          alt="No Account Exists"
        />
        <BigBoldText>
          No Active Policies Available for Account {accountNumber}
        </BigBoldText>
        <SmallText>If a policy was just bound, check back shortly!</SmallText>
        <SmallText>Close the tab to go back.</SmallText>
      </OuterBox>
    );
  };

  const editAccountInfo = () => {
    simpleGTMDataLayer({
      event: 'buttonClickEvents',
      event_category: window.location.pathname,
      event_label: 'Edit',
      event_action: 'button-click'
    });
    if (policyCenterOutage) {
      setShowAccountEditOutageModal(true);
    } else {
      setDoSave(0);
      setEditable(!editable);
    }
  };

  const changeDueDate = () => {
    simpleGTMDataLayer({
      event: 'click-overviewChangeDueDate',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_category_snowplow: `${window.location.pathname}/Snowplow`,
      event_label: 'Change Due Date'
    });
    if (billingCenterOutage) {
      setShowChangeDueDateOutageModal(true);
    } else {
      setShowChangeDueDateModal(true);
    }
  };

  const AccountPaymentBody = (
    <StyledBoxShell>
      <CardLayoutShell
        className={`${LastDayToPayFlag?.enabled ? 'AccountDetailsWrap' : ''} ${
          LastDayToPayFlag?.enabled && editable ? 'EditMode' : ''
        }`}
        title={`Account Number: ${accountNumber!}`}
        buttonComponent={
          systemCode === 'gwcl' || LastDayToPayFlag?.enabled ? (
            <div />
          ) : (
            <Box background-color="white">
              {!editable && (
                <Anchor
                  id="edit_account_info_anchor"
                  color={`${
                    readOnlyAccess && readOnlyFeatureFlag
                      ? '#7d7676'
                      : '#65A100'
                  }`}
                  onClick={() => editAccountInfo()}
                  label={
                    <EditTextWrap>
                      <>
                        {readOnlyAccess && readOnlyFeatureFlag ? (
                          <EditIconDisabled />
                        ) : (
                          <StyledImg src={Edit} alt="Edit Icon" />
                        )}
                      </>
                      {'  '}Edit
                    </EditTextWrap>
                  }
                  alignSelf="end"
                  disabled={readOnlyAccess && readOnlyFeatureFlag}
                />
              )}
            </Box>
          )
        }
        edit
      >
        <StyledBox alignContent="stretch" direction="column" flex="grow">
          <ResponsiveGrid
            align="start"
            columns={{
              medium: ['2 / 3', '1 / 3'],
              large: ['2 / 3', '1 / 3'],
              xlarge: ['2 / 3', '1 / 3']
            }}
            areas={[['accountInfo']]}
            pad={
              LastDayToPayFlag?.enabled
                ? {
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0'
                  }
                : {
                    left: '1.875rem',
                    right: '1.875rem',
                    top: '1.25rem',
                    bottom: '1.25rem'
                  }
            }
          >
            <EditHolder>
              <AccountInformationEdit
                lastDayToPayFlag={LastDayToPayFlag?.enabled}
                edit={editable}
                setEditable={setEditable}
                doSave={doSave}
                setServiceLoading={setServiceLoading}
              />
            </EditHolder>
          </ResponsiveGrid>
          {editable && (
            <EditButtonRow>
              <Anchor
                label="CANCEL"
                margin={{ right: 'large' }}
                disabled={serviceLoading}
                onClick={() => {
                  setEditable(false);
                  simpleGTMDataLayer({
                    event: 'buttonClickEvents',
                    event_category: window.location.pathname,
                    event_label: 'Cancel',
                    event_action: 'button-click'
                  });
                }}
              />
              <PrimaryButton
                id="save_account_info_button"
                label={serviceLoading ? 'SAVING...' : 'SAVE'}
                alignSelf="end"
                margin={{ right: 'small' }}
                disabled={serviceLoading}
                onClick={() => {
                  simpleGTMDataLayer({
                    event: 'buttonClickEvents',
                    event_category: window.location.pathname,
                    event_label: 'Save',
                    event_action: 'button-click'
                  });
                  setDoSave(doSave + 1);
                  setServiceLoading(true);
                }}
              />
            </EditButtonRow>
          )}
        </StyledBox>
      </CardLayoutShell>
      {!isAgencyBilledOnly &&
        ((!LastDayToPayFlag?.enabled && editable) || !editable) && (
          <VerticalDivider
            className={LastDayToPayFlag?.enabled ? 'PaymentSectionWrap' : ''}
          >
            {LastDayToPayFlag?.enabled ? (
              <PaymentInfo
                lastDayToPayFlag
                page="overview"
                paidInFull={() => {}}
                readOnlyAccess={readOnlyAccess}
                readOnlyFeatureFlag={readOnlyFeatureFlag}
              />
            ) : (
              <CardLayoutShell
                title="Next Payment"
                buttonComponent={
                  <FeatureFlag flagName="ChangeDueDate">
                    {!LastDayToPayFlag?.enabled && (
                      <ChangeDate onClick={() => changeDueDate()}>
                        CHANGE DUE DATE
                      </ChangeDate>
                    )}
                  </FeatureFlag>
                }
              >
                <PaymentBox>
                  <PaymentInfo
                    lastDayToPayFlag={LastDayToPayFlag?.enabled}
                    page="overview"
                    paidInFull={() => {}}
                    readOnlyAccess={readOnlyAccess}
                    readOnlyFeatureFlag={readOnlyFeatureFlag}
                  />
                </PaymentBox>
              </CardLayoutShell>
            )}
          </VerticalDivider>
        )}
    </StyledBoxShell>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CenterModal>
        {showChangeDueDateModal && (
          <ChangeDueDate setShowModal={setShowChangeDueDateModal} />
        )}
        {showChangeDueDateOutageModal && (
          <OutageModal
            title="Change Payment Due Date is Unavailable"
            onClose={() => setShowChangeDueDateOutageModal(false)}
          >
            Unfortunately, due to server maintenance, changing your payment due
            date is currently unavailable. Please check back in 24 to 48 hours.
          </OutageModal>
        )}
        {showAccountEditOutageModal && (
          <OutageModal
            title="Edit Account is Unavailable"
            onClose={() => setShowAccountEditOutageModal(false)}
          >
            Unfortunately, due to server maintenance, editing your account
            information is currently unavailable. Please check back in 24 to 48
            hours.
          </OutageModal>
        )}

        {(isAgencyBilledOnly || isAgencyAndDirect) && (
          <AgencyBilledBanner>
            <span style={{ display: 'flex' }}>
              <AltInfoIcon />
              {isAgencyBilledOnly
                ? `This account uses agency billing. `
                : `A policy on this account uses agency billing. `}
              Need to see billing details?&nbsp;
              <a href={config.agency_bill_url} target="_blank">
                View Agency Bill details.
              </a>
            </span>
          </AgencyBilledBanner>
        )}

        <LayoutContainer>
          <Column1>
            {LastDayToPayFlag?.enabled ? (
              <CardLayout
                className="UIRedesign"
                title="Account & Payment"
                buttonComponent={
                  <AccountInfoHeaderBtnWrap>
                    {systemCode !== 'gwcl' && !editable && !isMigrated && (
                      <>
                        <AccountInfoHeaderBtn
                          onClick={() => editAccountInfo()}
                          disabled={readOnlyAccess && readOnlyFeatureFlag}
                        >
                          EDIT ACCOUNT INFO
                        </AccountInfoHeaderBtn>
                        <FeatureFlag flagName="ChangeDueDate">
                          <AccountInfoHeaderBtnDivider />
                        </FeatureFlag>
                      </>
                    )}
                    <FeatureFlag flagName="ChangeDueDate">
                      {!isMigrated && (
                        <AccountInfoHeaderBtn onClick={() => changeDueDate()}>
                          CHANGE DUE DATE
                        </AccountInfoHeaderBtn>
                      )}
                    </FeatureFlag>
                  </AccountInfoHeaderBtnWrap>
                }
              >
                {AccountPaymentBody}
              </CardLayout>
            ) : (
              AccountPaymentBody
            )}

            <CardLayout title="Policy Information">
              <OverviewAccountListTable />
            </CardLayout>
          </Column1>

          <Column2>
            <CardLayout
              title="Possible Reasons For Call"
              buttonComponent={
                <InfoContainer>
                  <Info />
                  <InfoPopUp>
                    <Box>
                      <Box direction="row" margin={{ left: '5px' }}>
                        <SmallInfo />
                        <Text
                          weight="bold"
                          margin={{ left: 'xsmall' }}
                          size="12pt"
                        >
                          Possible Customer Issues
                        </Text>
                      </Box>

                      <Text size="12pt" margin={{ left: '28px' }}>
                        The rings predict how likely it is that a customer is
                        contacting you regarding a topic. Selecting a predicted
                        option will automatically direct you to the solution.
                      </Text>
                    </Box>
                  </InfoPopUp>
                </InfoContainer>
              }
            >
              <CustomerIntent
                readOnlyAccess={readOnlyAccess}
                readOnlyFeatureFlag={readOnlyFeatureFlag}
              />
            </CardLayout>

            <AccordionStyledCard title="Communications History">
              <CommunicationAccordion page="overview" />
            </AccordionStyledCard>
          </Column2>
        </LayoutContainer>
      </CenterModal>
    </ErrorBoundary>
  );
};
