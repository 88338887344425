//advanced filter
import styled from 'styled-components';
import PrimaryButton from '../PrimaryButton';
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Popup = styled.form`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 35vw;
  max-height: 100vh;
  overflow-y: auto;
`;
export const CalendarContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;
`;

export const CalendarOptions = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  right: 0;
  width: 100%;
  z-index: 9999;
`;
export const InputContainer = styled.form`
  position: relative;
  display: inline-block;
`;

interface CustomInputProps {
  invalid?: boolean;
}

export const Icon = styled.span`
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: 10px;  
`;

export const DateInput = styled.input<CustomInputProps>`
  padding: 8px 12px;
  border: 1px solid ${props => (props.invalid ? 'red' : '#0173C6')};
  outline: none;
  width: 10vw;
  height:6vh;
  &:focus,
`;

export const BorderLabel = styled.span`
  position: absolute;
  top: -10px;
  left: 5px;
  background-color: #fff;
  padding: 0 4px;
  font-size: 12px;
`;

export const AdvancedFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 5px;
  margin-bottom: 16px;
  padding: 16px;
`;

export const AdvancedFilterButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 10px 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2592f8;
  }
`;

export const Span = styled.span`
display: inline-flex,
justifyContent: center,
alignItems: center,
margin:5px,
`;
export const WarningMessage = styled.div`
  margin-top: 0px;
  position: absolute;
  transform: translateZ(1px);
  z-index: 10; 
  width:500px;
`;
export const Message = styled.div`
  margin-top: 1px;
`;
export const WarningText = styled.span`
  color: red;
  font-size: 16px;
  margin-left: 5px;
`;


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Icons = styled.div`
  margin-top: 20px;
`;
export const Heading = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const Value = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-right: 25px;
`;
export const Label =styled.div`
font-size: 16px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
`;
export const Button = styled.div`
flex-grow: 1;
display: flex; 
justify-content: flex-end;
`;
export const StyledPrimaryButton = styled(PrimaryButton)`
  cursor: pointer;
  font: inherit;
  padding: 5px;
  margin-left: 20px;
  margin-right:20px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  border: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
`;
interface  WcClaimsButtonProps{
  bool?:true;
}
export const WcClaimsButton =styled.div<WcClaimsButtonProps>`
  cursor: pointer;
  font: inherit;
  padding: 7px;
  margin-bottom: 20px;
  font-size: ${props => (props.bool ? '29px' : '19px')};
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #0173c6;
  color: #0173c6 !important;
  padding-left: 10px;
  padding-right: 10px;
`;
//injured name
interface Props {
  visible?: boolean;
  showWarning?: boolean;
}
export const InputDropdownOptions = styled.div<Props>`
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  width: 30vw;
  overflow-y: auto;
  z-index: 999;
  padding: 0px 20px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
export const InputDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const InputDropdownOption = styled.div`
  display: flex;
  align-items: center;
  &:hover,
  &.selected {
    background-color: #ccc;
  }
  height: 5vh;
`;
export const InputName = styled.input<Props>`
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30vw;
  height: 5vh;
  margin-top: 20px;
  margin-bottom: 5px;
`;
//location dropdown
export const OptionText = styled.span`
  margin-left: 5px;
`;
export const SelectedItem = styled.div`
  background-color: #ccc;
  font-weight: bold;
  margin: 5px;
  padding: 5px;
  height: 25px;
  display: inline-block;
   font-size: 14px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  &:hover,
  &.selected {
    background-color: #ccc;
  }
  height: 5vh;
`;
export const DropdownOptions = styled.div`
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  width: 30vw;
  overflow-y: auto;
  z-index: 999;
  padding: 5px;
`;

export const CustomInput = styled.div`
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30vw;
  height: auto;
  min-height: 6vh;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
`;
export const BorderLabelLocation = styled.span`
  position: absolute;
  top: -10px;
  left: 5px;
  background-color: #fff;
  padding: 0 4px;
  font-size: 12px;
  transform: translateY(140%);
`;