import React, { useState } from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { customBase } from '../../themes/default.theme';

export interface tabbedCardContent {
  tabTitle: string[];
  children?: JSX.Element[] | undefined;
  open: number;
  buttonComponent?: JSX.Element;
  tabOnClicks?: Function[];
}

interface TabTitle {
  currentlySelected: number | undefined;
  selectedMatch: number;
  title: string;
}

const StyledCard = styled(Box)`
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
  width: 100%;
`;

export const ChildContainer = styled.div`
  padding: 30px;
  border-radius: 0px 0px 4px 4px;
  background-color: #ffffff;
`;
const TabRow = styled(Box)`
  background-color: #ffffff;
  direction: row;
  border-bottom: solid ${customBase.global.colors.brand} 4px;
`;
export const TabBox = styled(Box)`
  padding: 15px 30px;
  border-radius: 4px 4px 1px 0px;
`;

const TabTitleDeselected = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: ${customBase.global.colors.brand};
`;

const TabTitleSelected = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
`;

const TabTitle = ({ currentlySelected, selectedMatch, title }: TabTitle) => {
  return currentlySelected === selectedMatch ? (
    <TabTitleSelected>{title}</TabTitleSelected>
  ) : (
    <TabTitleDeselected>{title.toUpperCase()}</TabTitleDeselected>
  );
};

export const TabbedCardLayout = ({
  tabTitle,
  children,
  open,
  buttonComponent = <Box />,
  tabOnClicks = []
}: tabbedCardContent) => {
  const [selected, setSelected] = useState<number>(open);
  let displayedChild;
  if (children && children.length > selected) {
    displayedChild = children[selected];
  } else displayedChild = <Box />;
  return (
    <StyledCard>
      <TabRow direction="row" justify="between">
        <Box direction="row">
          {tabTitle.map((title, index) => {
            return (
              <TabBox
                key={title}
                background={selected === index ? 'brand' : '#ffffff'}
                onClick={() => {
                  if (tabOnClicks[index]) {
                    tabOnClicks[index]();
                  }
                  setSelected(index);
                }}
              >
                <>
                  <TabTitle
                    currentlySelected={selected}
                    selectedMatch={index}
                    title={title}
                  />
                </>
              </TabBox>
            );
          })}
        </Box>
        <Box>{buttonComponent}</Box>
      </TabRow>
      <ChildContainer>{displayedChild}</ChildContainer>
    </StyledCard>
  );
};
