import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text, Select as GrommetSelect, Box } from 'grommet';
import { formatDate, formatMoney } from '../../util/formatters';
import { GET_POLICY_PAYASYOUGO_account_items_policies_items_payasyougo_items } from './graphql/__generated__/GET_POLICY_PAYASYOUGO';
import { ChevronDown } from '../../icons';

type PayAsYouGoDropdownOption = GET_POLICY_PAYASYOUGO_account_items_policies_items_payasyougo_items;

const SelectWrapper = styled.div`
  button {
    border: none;
  }
`;

const TitleText = styled(Text)`
  font-size: 12px;
  font-weight: 700;
`;
const ContentText = styled(Text)`
  font-size: 16px;
`;

export const Select = styled(GrommetSelect)`
  font-size: 14px;
  padding-right: 0px;
  width: 200px;
`;

export const StyledText = styled(Text)`
  color: #041e41;
  font-size: 14px;
`;

export interface PayAsYouGoDropdownProps {
  isLoading?: boolean;
  error?: Error;
  value?: string;
  onChange: (...args: any[]) => void;
  options?: PayAsYouGoDropdownOption[];
  className?: string;
}

export const PayAsYouGoDropdown = ({
  isLoading,
  error,
  onChange,
  options = [],
}: PayAsYouGoDropdownProps) => {
  const [optionAmount, setOptionAmount] = useState('');
  const [optionDue, setOptionDue] = useState('');
  const [optionValue, setOptionValue] = useState({});
  const filtered = options.filter(
    item =>
      item.audit_periodstartdate !== null && item.audit_periodenddate !== null
  );

  const formatOptions = useMemo(
    () =>
      filtered.map(
        ({
          audit_periodstartdate,
          audit_periodenddate,
          due_date,
          trans_premium
        }: PayAsYouGoDropdownOption) => ({
          label: `${formatDate(audit_periodstartdate)} to ${formatDate(
            audit_periodenddate
          )}`,
          value: `${formatDate(due_date)}`,
          amount: `${formatMoney(trans_premium)}`
        })
      ),
    [options]
  ).sort((a: any, b: any) => {
    const c = new Date(a.value);
    const d = new Date(b.value);
    return c > d ? 1 : -1;
  });

  useEffect(() => {
    if (
      filtered &&
      filtered.length > 0 &&
      Object.keys(optionValue).length === 0
    ) {
      setOptionValue(formatOptions[0]);
      setOptionDue(formatOptions[0].value);
      setOptionAmount(formatOptions[0].amount);
    }
  });

  return (
    <Box>
      <SelectWrapper>
        <StyledText>Reported Range: </StyledText>
        <Select
          multiple={false}
          value={optionValue}
          disabled={isLoading || Boolean(error)}
          onChange={({ option }) => {
            setOptionValue(option);
            setOptionAmount(option.amount);
            setOptionDue(option.value);
            onChange(option.value);
          }}
          options={formatOptions}
          labelKey="label"
          valueKey="value"
          icon={ChevronDown}
        />
      </SelectWrapper>
      <Box
        direction="row"
        align="center"
        background="#F5F7FA"
        justify-content="center"
        gap="50px"
        pad={{ top: '18px', bottom: '15px', left: '50px', right: '30px' }}
        max-width="190px"
      >
        <Box>
          <TitleText>Total Cost</TitleText>
          <ContentText>{optionAmount}</ContentText>
        </Box>
        <Box>
          <TitleText>Payroll Reporting due by</TitleText>
          <ContentText>{optionDue}</ContentText>
        </Box>
      </Box>
    </Box>
  );
};
