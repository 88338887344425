import styled from 'styled-components';

interface NavigateMonthProps {
  disabled: boolean;
}

interface CalendarDayProps {
  today: boolean;
  disabled: boolean;
  selected: boolean;
}

export const MonthSelect = styled.div`
  display: flex;
  align-items: center;
  margin: 14px 14px 0;
`;

export const NavigateMonth = styled.img<NavigateMonthProps>`
  display: block;
  width: 12px;
  height 12px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => (props.disabled ? 'opacity: 0.72;' : '')}
  padding: 12px;
  border-radius: 50%;
  ${props =>
    props.disabled
      ? ''
      : `:hover {
    background-color: #e3e9f1;
  }`}  
`;

export const MonthYear = styled.span`
  flex-grow: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-align: center;
`;

export const DaysRow = styled.div`
  display: flex;
  padding: 20px 24px 16px;
  justify-content: space-between;
`;

export const Days = styled.span`
  font-size: 12px;
  line-height: 14px;
  width: 14px;
  text-align: center;
  color: #000000;
  opacity: 0.48;
`;

export const Weeks = styled.div`
  display: flex;
  margin: 0 12px;
  padding: 4px 0;
  justify-content: space-between;
  :last-child {
    margin-bottom: 9px;
  }
`;

export const DayPlaceholder = styled.span`
  width: 36px;
  height: 36px;
  margin: 0 1px;
`;

export const CalendarDay = styled.span<CalendarDayProps>`
  width: ${props => (props.today ? 35 : 36)}px;
  height: ${props => (props.today ? 35 : 36)}px;
  line-height: ${props => (props.today ? 35 : 36)}px;
  font-size: 14px;
  ${props => (props.today ? 'border: 1px solid #0173c6;' : '')}
  text-align: center;
  border-radius: 50%;
  color: #000000;
  margin: 0 1px;
  ${props => (props.disabled ? 'opacity: .4;' : '')}
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: #${props => (props.selected ? '0173c6' : 'ffffff')};
  ${props => (props.selected ? 'color: #ffffff;' : '')}
  ${props =>
    props.disabled
      ? ''
      : `:hover {
    background-color: #${props.selected ? '0173c6' : 'e3e9f1'};
  }`}
`;
