import React from 'react';
import { Link } from '@reach/router';

import { BillingPayPlansData } from '../../BillingPayPlans';

import {
  SectionTitle,
  PaymentMethodPill,
  CurrentPoliciesTable,
  CurrentPoliciesTableTR,
  CurrentPoliciesTableTH,
  CurrentPoliciesTableTD,
  StyledHyperLink
} from './CurrentPoliciesTableUIStyles';

interface Props {
  payPlans: BillingPayPlansData[];
  method: string;
  onPolicyNumberClick: Function;
}

const formatCurrency = (amount: number) =>
  parseFloat(amount.toString()).toFixed(2);

const CurrentPoliciesTableUI = ({
  payPlans,
  method,
  onPolicyNumberClick
}: Props) => {
  return (
    <>
      <SectionTitle>Payment Method</SectionTitle>
      <PaymentMethodPill>{method}</PaymentMethodPill>
      <SectionTitle>Current Policies ({payPlans.length})</SectionTitle>
      <CurrentPoliciesTable>
        <CurrentPoliciesTableTR>
          <CurrentPoliciesTableTH>Policy Type</CurrentPoliciesTableTH>
          <CurrentPoliciesTableTH>Policy #</CurrentPoliciesTableTH>
          <CurrentPoliciesTableTH>Payment Plan</CurrentPoliciesTableTH>
          <CurrentPoliciesTableTH>Amount Paid</CurrentPoliciesTableTH>
          <CurrentPoliciesTableTH>Remaining</CurrentPoliciesTableTH>
        </CurrentPoliciesTableTR>
        {payPlans.map(
          ({ paid, remaining_balance, pay_plan, policy_number, policy }) => (
            <CurrentPoliciesTableTR>
              <CurrentPoliciesTableTD>
                {policy?.policy_type}
              </CurrentPoliciesTableTD>
              <CurrentPoliciesTableTD>
                <Link
                  onClick={() => {
                    return onPolicyNumberClick(policy_number);
                  }}
                  to={`../policy/${policy_number}`}
                  style={{ textDecoration: 'none' }}
                  test-attr="link-policy"
                >
                  <StyledHyperLink>{policy_number}</StyledHyperLink>
                </Link>
              </CurrentPoliciesTableTD>
              <CurrentPoliciesTableTD>{pay_plan}</CurrentPoliciesTableTD>
              <CurrentPoliciesTableTD>
                ${formatCurrency(paid || 0)}
              </CurrentPoliciesTableTD>
              <CurrentPoliciesTableTD>
                ${formatCurrency(remaining_balance || 0)}
              </CurrentPoliciesTableTD>
            </CurrentPoliciesTableTR>
          )
        )}
      </CurrentPoliciesTable>
    </>
  );
};

export default CurrentPoliciesTableUI;
