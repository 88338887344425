import styled from 'styled-components';
import { customBase } from '../../themes/default.theme';

const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;
const mediumBreakpoint = `${customBase.global.breakpoints.medium}px`;

export const StyledCancelAndSubmitButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const StyledQuestionHeader = styled.div`
  justify-content: center;
  margin-left: 25px;
  margin-top: 20px;
  font-family: 'PT Sans';
  color: #4c4c4c;
  font-weight: bold;
  font-size: 18px;
`;

export const StyledTextAboveInputBox = styled.div`
  margin-bottom: 5px;
  margin-left: 80px;
  margin-top: 12px;
  @media (max-width: ${xlargeBreakpoint}) {
    margin-left: 28px;
  }
`;

export const StyledInputBox = styled.textarea`
  margin-left: 71px;
  height: 74px;
  width: 372px;
  resize: none;
  @media (max-width: ${xlargeBreakpoint}) {
    margin-left: 28px;
    width: 340px;
  }
`;

export const StyledButtons = styled.button`
  &: hover {
    background-color: #65a100;
    color: #ffffff;
    cursor: pointer;
  }
  border: transparent;
  border-radius: 4px;
  background-color: transparent;
  color: hsl(205, 99%, 39%);
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-decoration: none;
  height: 45px;
  width: 163px;
  &:disabled {
    cursor: not-allowed;
    background: #e0e0e0;
    color: #ffffff;
  }
`;

export const StyledCloseButton = styled.button`
  border: none;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 4px 13px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  position: absolute;
  top: -5%;
  right: 5%;
  font-size: 30px;
  border-radius: 100%;
  color: #ffffff;
  background-color: #4c4c4c;
`;

export const StyledModal = styled.div`
  position: fixed;
  right: 0%;
  top: 20%;
  width: 502px;
  height: auto;
  background-color: white;
  box-shadow: 0px 2px 4px 0px hsla(205, 99%, 28%, 0.2);
  border-radius: 10px 10px 10px 10px;
  z-index: 100;
  @media (max-width: ${xlargeBreakpoint}) {
    width: 402px;
  }
`;

export const OpenFeedbackButton = styled.button`
  position: fixed;
  right: 0;
  top: 30%;
  height: 36px;
  width: 130px;
  background-color: #c74f32;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 2px 4px 0px hsla(205, 99%, 28%, 0.2);
  font-family: 'PT Sans', sans-serif;
  border-radius: 4px, 4px, 0px, 0px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
  @media (max-width: ${xlargeBreakpoint}) {
    top: 35%;
  }
  @media (max-width: ${mediumBreakpoint}) {
    top: 35%;
  }
  &:disabled {
    cursor: not-allowed;
    background: #e0e0e0;
    color: #ffffff;
  }
`;

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const StyledStarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
  @media (max-width: ${xlargeBreakpoint}) {
    margin-top: 10px;
  }
`;

export const DifficultOrEasyDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
`;

export const FeedbackButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FeedbackErrorMessage = styled.div`
  color: red;
  font-family: 'PT Sans', 'sans-serif';
  margin-left: 71px;
  margin-top: 15px;
  @media (max-width: ${xlargeBreakpoint}) {
    margin-left: 28px;
  }
`;

export const ErrorText = styled.div`
  font-weight: 700;
  color: #e02020;
  font-size: 14px;
  margin-left: 21%;
  @media (max-width: ${xlargeBreakpoint}) {
    margin-left: 20%;
  }
`;
