import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import { Link } from '@reach/router';
import { GET_POLICY_QUOTE_TABLE_INFO_account_items_overviewPoliciesAndQuotes } from '../overviewAccountListTable/graphql/__generated__/GET_POLICY_QUOTE_TABLE_INFO';
import { GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes } from '../quotePageTable/graphql/__generated__/GET_QUOTE_PAGE_TABLE_QUOTES';
import { formatDate, formatTitleCase } from '../../util/formatters';
import { StyledDataTable } from '..';
import usePagination from '../../util/usePagination';
import styled from 'styled-components';

export interface AccountListAndQuotesTableUIProps {
  isLoading?: boolean;
  error?: Error;
  policiesAndQuotes:
    | (
        | GET_POLICY_QUOTE_TABLE_INFO_account_items_overviewPoliciesAndQuotes
        | GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes
      )[]
    | undefined;
  onPolicyNumberClick?: Function;
  accountNumber?: string;
  quoteNumber?: string;
  redirectOnQuoteClickAction: Function;
}

const StyledHyperLink = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #0173C6;
  font-family: PT Sans, sans-serif;
`;

export default ({
  isLoading,
  error,
  policiesAndQuotes = [],
  onPolicyNumberClick,
  accountNumber,
  quoteNumber,
  redirectOnQuoteClickAction
}: AccountListAndQuotesTableUIProps) => {
  const modifiedPoliciesAndQuotesList:(
    | GET_POLICY_QUOTE_TABLE_INFO_account_items_overviewPoliciesAndQuotes
    | GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes
  )[]
| undefined = policiesAndQuotes?.map(contents => {
    const data = { ...contents };
    if (
      [
        contents?.policy_type?.toLocaleLowerCase(),
        contents?.product_code?.toLocaleLowerCase()
      ]?.includes('workers comp')
    ) {
      data.policy_type = "Workers' Compensation";
    }
    return data;
  });

  const [displayablePoliciesAndQuotes, pageSelector] = usePagination({
    accountNumber,
    quoteNumber,
    items: modifiedPoliciesAndQuotesList
  });

  if (error) {
    return <h3>An Error Occurred</h3>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Box align="start">
      {modifiedPoliciesAndQuotesList?.length ? (
        <Box fill="horizontal" overflow="auto">
          <StyledDataTable
            primaryKey="part_id"
            columns={[
              {
                property: 'policy_type',
                header: <Text weight="bold">Product</Text>,
                render: datum => (
                  <Text>
                    {datum?.quote_number
                      ? `${datum?.product_code}`
                      : `${datum?.policy_type}`}
                  </Text>
                )
              },
              {
                property: 'form',
                header: <Text weight="bold">Form</Text>,
                render: datum => (
                  <Text>{datum.form?.replace(/[NA]/, 'N/') ?? 'N/A'}</Text>
                )
              },
              {
                property: 'status',
                header: <Text weight="bold">Status</Text>,
                render: datum => (
                  <Text>
                    {datum.status.indexOf('InForce') === -1 ? (
                      <div>
                        {datum.status === 'Issuance In-Progress'
                          ? 'Binding'
                          : formatTitleCase(datum.status)}
                      </div>
                    ) : (
                      'In Force'
                    )}
                  </Text>
                )
              },
              {
                property: 'policy_number',
                header: <Text weight="bold">Policy / Quote #</Text>,
                render: datum => (
                  <>
                    {!datum?.quote_number ? (
                      <Link
                        onClick={() => {
                          return onPolicyNumberClick?.(datum.policy_number);
                        }}
                        to={`../policy/${datum.policy_number}`}
                        style={{ textDecoration: 'none' }}
                        test-attr="link-policy"
                      >
                        <StyledHyperLink>{datum.policy_number}</StyledHyperLink>
                      </Link>
                    ) : (
                      <>
                        {datum?.quote_number &&
                        [
                          'Draft',
                          'Quoted',
                          'Quote In-Progress',
                          'Issuance In-Progress'
                        ].includes(datum?.status) ? (
                          <Box
                            test-attr="link-quote"
                            onClick={() => {
                              redirectOnQuoteClickAction({
                                variables: {
                                  quote_number: datum.quote_number
                                }
                              });
                            }}
                          >
                            <Text color="brand">{datum?.quote_number}</Text>
                          </Box>
                        ) : (
                          <Text color="dark">{datum?.quote_number}</Text>
                        )}
                      </>
                    )}
                  </>
                )
              },
              {
                property: 'effective_date',
                header: <Text weight="bold">Effective Date</Text>,
                render: datum => <Text>{formatDate(datum.effective_date)}</Text>
              },
              {
                property: 'expiration_date',
                header: <Text weight="bold">Expiration Date</Text>,
                render: datum => (
                  <Text>{formatDate(datum.expiration_date)}</Text>
                )
              }
            ]}
            data={displayablePoliciesAndQuotes}
            a11yTitle="Account Policy Information"
          />
          <Box align="end">{pageSelector}</Box>
        </Box>
      ) : (
        <Text size="medium">
          There are no policies or quotes found for this account.
        </Text>
      )}
    </Box>
  );
};
