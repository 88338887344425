import React, { useState, useEffect } from 'react';

import {
  CancelPolicyFormWrap,
  FormButtonsWrap,
  ClearButton,
  SubmitButton,
  LoaderIconWrap,
  LoaderIcon,
  WarningText
} from './cancelPolicyFormStyles';

import { formStatusObj } from '../cancelPolicy';

import DownloadDocumentBanner from './downloadDocument';

import CancellationReason, {
  dropDownInitialState as cancellationReasonDropDownInitialState,
  textBoxInitialState as cancellationReasonTextBoxInitialState,
  isValid as isCancellationReasonValid,
  reasonOptions as cancellationReasonOptions
} from './formFields/cancellationReason';

import EffectiveDate, {
  initialState as effectiveDateInitialState,
  isValid as isEffectiveDateValid,
  isEffectiveDateInRange
} from './formFields/effectiveDate';

import CancellationDocument, {
  initialState as cancellationDocumentInitialState,
  isValid as isCancellationDocumentValid
} from './formFields/cancellationDocument';

import SpinnerSrc from '../../../assets/images/spinner-light.svg';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';
import { Warning } from '../../../icons';
import generateHolidays from '../../../util/holidays/generateHolidays';

const effectiveDateErrMsg = 'Cannot backdate by more than 2 days';

const initialState = (product: string, system: string) => ({
  cancellationReason:
    system === 'GWCL' &&
    product !== 'COMEMRCIAL PACKAGE' &&
    product !== 'WORKERS COMP'
      ? cancellationReasonTextBoxInitialState
      : cancellationReasonDropDownInitialState,
  effectiveDate: effectiveDateInitialState,
  cancellationDocument: cancellationDocumentInitialState
});

interface cancelFormProps {
  submit: Function;
  formStatus: string;
  systemCode: string;
  maxDate: string;
  minDate: string;
  cancellationSource: string;
  setCancellationSource: Function;
  product: string;
  initialSource: string;
  close: Function;
  openChanges: boolean;
  getAcceptance: Function;
  acceptable: boolean;
  acceptanceLoading: boolean;
  accountNumber: string;
  policyNumber: string;
}

export default ({
  submit,
  formStatus,
  systemCode,
  maxDate,
  minDate,
  cancellationSource,
  setCancellationSource,
  product,
  initialSource,
  close,
  openChanges,
  getAcceptance,
  acceptable,
  acceptanceLoading,
  accountNumber,
  policyNumber
}: cancelFormProps) => {
  const [formState, setFormState] = useState(initialState(product, systemCode));
  const [securityType, setSecurityType] = useState<string>('');
  const [reasonCode, setReasonCode] = useState<string>('');
  const [uploadStatus, setUploadStatus] = useState<string>('upload');
  const [carrierMovedTo, setCarrierMovedTo] = useState('');
  const [otherCarrier, setOtherCarrier] = useState<string>('');
  const [requestError, setRequestError] = useState({
    requestError: false,
    requestMsg: ''
  });
  const [carrierError, setCarrierError] = useState({
    carrierError: false,
    carrierMsg: ''
  });
  const [otherCarrierError, setOtherError] = useState({
    otherError: false,
    otherMsg: ''
  });

  const holidayList = generateHolidays();
  const clearForm = () => {
    setFormState(initialState(product, systemCode));
    setCancellationSource(initialSource);
    setSecurityType('');
    setCarrierMovedTo('');
    setOtherCarrier('');
    setRequestError({
      requestError: false,
      requestMsg: ''
    });
    setCarrierError({
      carrierError: false,
      carrierMsg: ''
    });
    setOtherError({
      otherError: false,
      otherMsg: ''
    });
    close();
  };

  const isFormValid = () => {
    let isValid = true;
    const {
      cancellationReason,
      effectiveDate,
      cancellationDocument
    } = formState;

    if (
      !isCancellationReasonValid(cancellationReason.value) ||
      reasonCode === ''
    ) {
      isValid = false;

      formState.cancellationReason = {
        ...cancellationReason,
        error: true,
        msg: cancellationReasonOptions[0]
      };
    }
    if (
      cancellationSource === 'Select Requested By' &&
      (product === 'WORKERS COMP' || product === 'COMMERCIAL PACKAGE')
    ) {
      isValid = false;
      setRequestError({
        requestError: true,
        requestMsg: 'Select Requested By'
      });
    }
    if (reasonCode === 'movedToOtherCarrier_SAI' && carrierMovedTo === '') {
      isValid = false;
      setCarrierError({
        carrierError: true,
        carrierMsg: 'Select Carrier Moved To'
      });
    }
    if (carrierMovedTo === 'Other' && otherCarrier === '') {
      isValid = false;
      setOtherError({
        otherError: true,
        otherMsg: 'Enter Other Carrier'
      });
    }

    if (!isEffectiveDateValid(effectiveDate.value)) {
      isValid = false;
      const changeObj = {
        error: true,
        msg: 'Invalid date'
      };

      if (
        effectiveDate.value !== '' &&
        !isEffectiveDateInRange(effectiveDate.value, minDate, maxDate)
      ) {
        changeObj.msg = effectiveDateErrMsg;
      }

      formState.effectiveDate = {
        ...effectiveDate,
        ...changeObj
      };
    }

    if (
      uploadStatus === 'upload' &&
      !isCancellationDocumentValid(
        uploadStatus,
        cancellationDocument.file,
        cancellationDocument.fileName
      )
    ) {
      isValid = false;
      formState.cancellationDocument = {
        ...cancellationDocument,
        error: true,
        msg:
          'You must upload a signed Cancellation Request/Policy Release (LPR)'
      };
    }

    if (uploadStatus === 'upload' && securityType === '') {
      isValid = false;
      formState.cancellationDocument = {
        ...cancellationDocument,
        error: true,
        msg: 'Select where to send the document'
      };
    }

    if (!isValid) {
      setFormState({ ...formState });
    }

    return isValid;
  };

  const uploadAvailable = uploadStatus === 'upload';
  const defaultCode =
    systemCode === 'GWCL' &&
    product !== 'COMMERCIAL PACKAGE' &&
    product !== 'WORKERS COMP';
  const [hasBeenCalled, setCalled] = useState<boolean>(false);
  const formSubmit = async () => {
    let modelDescription = "Insured's Request";
    if (formState.cancellationReason.value === 'Moved To Other Carrier') {
      modelDescription = 'Moved to Other Carrier';
    } else if (formState.cancellationReason.value === 'Policy Not Taken') {
      modelDescription = formState.cancellationReason.value;
    } else if (
      formState.cancellationReason.value === 'Non-Payment of Premium'
    ) {
      modelDescription = formState.cancellationReason.value;
    }
    const holidayIndicator = holidayList.includes(
      formState.effectiveDate.value
    );
    await getAcceptance({
      variables: {
        account_number: accountNumber,
        policy_number: policyNumber,
        refund_method: 'Pro Rata',
        description: modelDescription,
        holiday: holidayIndicator,
        request_source: cancellationSource,
        cancel_date: `${formState.effectiveDate.value}T00:00:00Z`
      }
    });
    setCalled(true);
  };
  useEffect(() => {
    if (hasBeenCalled) {
      if (
        formStatus === formStatusObj.notSubmitted &&
        isFormValid() &&
        !acceptanceLoading
      ) {
        submit({
          cancellationReason: formState.cancellationReason.value,
          effectiveDate: formState.effectiveDate.value,
          cancellationDocument: formState.cancellationDocument.file,
          securityType,
          reasonCode: defaultCode ? 'fincononpay' : reasonCode,
          uploadAvailable,
          carrierMovedTo,
          otherCarrier
        });
      }
    }
  }, [acceptable, acceptanceLoading, hasBeenCalled]);

  const handleChange = (
    field: string,
    value: string | File | null,
    fileName?: string
  ) => {
    const updateObj = {
      error: false,
      msg: ''
    };

    const {
      cancellationReason,
      effectiveDate,
      cancellationDocument
    } = formState;

    if (field === 'cancellationReason' && typeof value === 'string') {
      formState.cancellationReason = {
        ...cancellationReason,
        ...updateObj,
        value
      };
    } else if (field === 'effectiveDate' && typeof value === 'string') {
      formState.effectiveDate = {
        ...effectiveDate,
        ...updateObj,
        showDatePicker: false,
        value
      };
    } else if (
      field === 'cancellationDocument' &&
      typeof value !== 'string' &&
      fileName
    ) {
      formState.cancellationDocument = {
        ...cancellationDocument,
        ...updateObj,
        file: value,
        fileName
      };
    }

    setFormState({ ...formState });
  };

  const handleFocus = (field: string) => {
    const { cancellationReason, effectiveDate } = formState;

    switch (field) {
      case 'cancellationReason':
        formState.cancellationReason = {
          ...cancellationReason,
          active: true
        };
        break;
      case 'effectiveDate':
      default:
        formState.effectiveDate = {
          ...effectiveDate,
          active: true
        };
        break;
    }

    setFormState({ ...formState });
  };

  const handleBlur = (field: string) => {
    const { cancellationReason, effectiveDate } = formState;

    switch (field) {
      case 'cancellationReason':
        formState.cancellationReason = {
          ...cancellationReason,
          active: false
        };
        break;
      case 'effectiveDate':
      default:
        formState.effectiveDate = {
          ...effectiveDate,
          active: false
        };
        if (
          effectiveDate.value !== '' &&
          !isEffectiveDateInRange(effectiveDate.value, minDate, maxDate)
        ) {
          formState.effectiveDate = {
            ...effectiveDate,
            active: false,
            error: true,
            msg: effectiveDateErrMsg
          };
        }
        break;
    }

    setFormState({ ...formState });
  };

  const toggleCalendar = () => {
    const { effectiveDate } = formState;

    formState.effectiveDate = {
      ...effectiveDate,
      showDatePicker: !effectiveDate.showDatePicker
    };

    setFormState({ ...formState });
  };

  const disabled: boolean = formStatus !== formStatusObj.notSubmitted;

  return (
    <CancelPolicyFormWrap>
      {openChanges && (
        <WarningText>
          <Warning />
          Please review incomplete policy change transaction below before
          proceeding.
        </WarningText>
      )}
      <DownloadDocumentBanner systemCode={systemCode} />
      <CancellationReason
        state={formState.cancellationReason}
        onFocus={() => handleFocus('cancellationReason')}
        onBlur={() => handleBlur('cancellationReason')}
        onChange={(value: string) => handleChange('cancellationReason', value)}
        disabled={disabled}
        setReasonCode={setReasonCode}
        cancellationSource={cancellationSource}
        setCancellationSource={setCancellationSource}
        product={product}
        systemCode={systemCode}
        carrierMovedTo={carrierMovedTo}
        setCarrierMovedTo={setCarrierMovedTo}
        otherCarrier={otherCarrier}
        setOtherCarrier={setOtherCarrier}
        requestError={requestError}
        setRequestError={setRequestError}
        carrierError={carrierError}
        setCarrierError={setCarrierError}
        otherCarrierError={otherCarrierError}
        setOtherError={setOtherError}
      />

      <EffectiveDate
        state={formState.effectiveDate}
        toggleCalendar={() => toggleCalendar()}
        onFocus={() => handleFocus('effectiveDate')}
        onBlur={() => handleBlur('effectiveDate')}
        onChange={(value: string) => handleChange('effectiveDate', value)}
        disabled={disabled}
        maximumDate={maxDate}
        minimumDate={minDate}
      />

      <CancellationDocument
        state={formState.cancellationDocument}
        onChange={(file: File, fileName: string) =>
          handleChange('cancellationDocument', file, fileName)
        }
        disabled={disabled}
        setSecurityType={setSecurityType}
        setUploadStatus={setUploadStatus}
        requestedBy={cancellationSource}
        uploadStatus={uploadStatus}
        securityType={securityType}
      />

      <FormButtonsWrap>
        <ClearButton
          onClick={() => {
            simpleGTMDataLayer({
              event: 'CancelPolicy-ClearButton-click',
              event_action: 'CancelPolicy ClearButton Click',
              event_category: 'CancelPolicy',
              event_label: 'CancelPolicy ClearButton Button Click',
              event_category_snowplow: 'Snowplow CancelPolicy'
            });
            clearForm();
          }}
        >
          CLEAR
        </ClearButton>
        <SubmitButton onClick={() => formSubmit()}>
          {formStatus === formStatusObj.notSubmitted ? (
            'START CANCELLATION'
          ) : (
            <>
              <LoaderIconWrap>
                <LoaderIcon src={SpinnerSrc} />
              </LoaderIconWrap>
              SENDING REQUEST
            </>
          )}
        </SubmitButton>
      </FormButtonsWrap>
    </CancelPolicyFormWrap>
  );
};
