import React from 'react';
import EmptyCheck from '../assets/images/EmptyCheckBox.svg';
interface CheckedProps {
  width?: number;
  height?: number;
}
const EmptyCheckBox: React.FC<CheckedProps> = ({ width = 32, height = 32 }) => {
  return <img src={EmptyCheck} width={width} height={height} alt="" />;
};

export default EmptyCheckBox;
