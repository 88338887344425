import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import UserNav from './UserNavComponent';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import { GET_USERNAV_INFO } from './graphql/__generated__/GET_USERNAV_INFO';

const get_usernav_info = loader('./graphql/Get_UserNav_Info.graphql');
const get_migrated_status = loader('../../graphql/Has_Migrated_Policy.graphql');

export default (_: RouteComponentProps) => {
  const { accountNumber, policyNumber } = useParams();
  const queryResult = useQuery(get_usernav_info, {
    variables: {
      account_number: accountNumber
    }
  });
  // get migrated status
  const getMigratedStatusQueryResult = useQuery(get_migrated_status, {
    variables: {
      account_number: accountNumber
    }
  });
  const { flagDetails: claimsUnavailableFlagDetails } = useFeatureFlag(
    'ClaimsUnavailable'
  );

  const { flagDetails: generalInputErrorBanner } = useFeatureFlag(
    'GeneralInputErrorBanner'
  );

  const { loading: isLoading, error, data } = queryResult;
  const { data: migratedStatusData } = getMigratedStatusQueryResult;
  const accounts = data?.account?.items;
  const currentPage = window.location.pathname.split('/')[3];
  const onClick = (page: string) => {
    simpleGTMDataLayer({
      event: `click-${currentPage}NavTab`,
      page
    });
  };

  let state: string = '';
  let stateFlag = false;
  const featureFlagStates: string | undefined =
    generalInputErrorBanner?.value?.states;
  if (data?.account?.items) {
    state = data.account.items[0].state;
  }
  if (featureFlagStates) {
    const states = (generalInputErrorBanner?.value?.states).split(',');
    if (states.includes(state)) {
      stateFlag = true;
    }
  }

  let isDataMigrated: boolean = false;
  if (
    migratedStatusData &&
    migratedStatusData?.hasMigratedPolicy &&
    migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy
  ) {
    isDataMigrated = migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy;
  }

  const props = {
    userDisplayName: (accounts && accounts[0]?.display_name) || 'Valued Agent',
    policies: accounts && accounts[0]?.policies?.items,
    accountNumber,
    policyNumber: policyNumber || false,
    isLoading,
    error,
    onClick,
    featureFlagStatuses: {
      claimsUnavailable: claimsUnavailableFlagDetails?.enabled
    },
    showError: generalInputErrorBanner?.enabled && stateFlag,
    isMigrated: isDataMigrated
  };

  return <UserNav {...props} />;
};
