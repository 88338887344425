import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import { Box } from 'grommet';
import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import getConfig, { Env } from '../../config';
import {
  GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG,
  GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_payplans_items,
  GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_policies_items
} from './graphql/__generated__/GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG';
import {
  agencyAndDirectBilled,
  agencyBilledOnly,
  policyIsAgencyBilled
} from '../../util/agencyBilledUtils';
import formatTitleCase from '../../util/formatters/formatTitleCase';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import FeatureFlag from '../featureFlags';
import { DisclaimerModalPL } from '../makePaymentOptions/paymentOptionsModals';
import DisclaimerModal from '../makePaymentOptions/paymentOptionsModals/DisclaimerModal';
import MakePaymentOptions from '../makePaymentOptions/paymentOptionsModals/MakePaymentOptions';
import MakePaymentOptionsCL from '../makePaymentOptions/paymentOptionsModals/MakePaymentOptionsCL';
import {
  ModalButtonsWrap,
  CancelButton,
  DisclaimerInsideText,
  DisclaimerQuestionText,
  InsideText,
  NoButton,
  OkButton,
  OutsideBoxCL,
  OutsideBoxDisclaimer,
  QuestionText,
  YesButton,
  MakePaymentButtonContainer
} from '../makePaymentOptions/paymentOptionsStyles/makePaymentOptionsStyles';
import Modal from '../modal/Modal';

const config = getConfig(process.env.REACT_APP_ENV as Env);

const get_policy_payplan_and_newhomeflag = loader(
  './graphql/Get_Policy_PayPlan_And_NewHomeFlag.graphql'
);

const get_policy_payplan_billing_method = loader(
  './graphql/Get_Policy_PayPlan_Billing_Method.graphql'
);

const get_migrated_status = loader('../../graphql/Has_Migrated_Policy.graphql');

export const Backdrop = styled.div`
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
`;

export interface MakePaymentOptionsDataContainerProps
  extends RouteComponentProps {
  children: JSX.Element[] | undefined | any;
  noModalClickHandler: Function;
  page: string;
  policyNumber?: string | undefined;
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
}

export interface DisclaimerProps {
  displayDisclaimer: boolean;
  baseState: string;
}

export const policyNumberSort = (
  input:
    | (GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_payplans_items | null)[]
    | (GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_policies_items | null)[]
    | null
    | undefined,
  policyNums: (string | null | undefined)[]
) => {
  const sortedItems:
    | (GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_payplans_items | null)[]
    | (GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_policies_items | null)[]
    | null
    | undefined = [];
  for (let i = 0; i < policyNums.length; i += 1) {
    if (input) {
      for (let j = 0; j < input?.length; j += 1) {
        if (policyNums[i] === input[j]?.policy_number) {
          sortedItems[i] = input[j];
        }
      }
    }
  }
  return sortedItems;
};

export const checkMarket = (data: GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG) => {
  let commMarket: Boolean = false;
  const items = data?.account?.items?.[0]?.payplans?.items?.length ?? 0;
  for (let i = 0; i <= items - 1; i += 1) {
    const market = data?.account?.items?.[0]?.policies?.items?.[i]?.market;
    if (formatTitleCase(market) === 'Commercial') {
      commMarket = true;
    }
  }
  return commMarket;
};

export const checkMortgageeBilled = (
  data: GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG
) => {
  let isMortgageeBilled: boolean = false;
  if (data?.account?.items && data.account.items[0]) {
    const policyNums: (string | null | undefined)[] = [];
    const items = data?.account?.items?.[0]?.payplans?.items ?? [];
    for (let i = 0; i < items.length; i += 1) {
      if (items[i]) policyNums[i] = items[i]?.policy_number;
    }
    policyNums.sort();
    const payplansFiltered = policyNumberSort(
      data.account.items[0]?.payplans?.items,
      policyNums
    ) as GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_payplans_items[];
    const policiesFiltered = policyNumberSort(
      data.account.items[0]?.policies?.items,
      policyNums
    ) as GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG_account_items_policies_items[];
    for (let x = 0; x <= items.length - 1; x += 1) {
      if (payplansFiltered.length) {
        const payPlan = payplansFiltered[x]?.pay_plan;
        const status = policiesFiltered[x]?.status;
        if (
          formatTitleCase(payPlan) === 'Mortgagee Billed' &&
          formatTitleCase(status) !== 'Cancelled'
        ) {
          isMortgageeBilled = true;
        }
      }
    }
  }
  return isMortgageeBilled;
};

export const checkNewHomePurchase = (
  data: GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG
) => {
  let isNewHomePurchase: boolean = false;
  if (
    data?.account?.items &&
    data.account.items[0] &&
    data.account.items[0].policies &&
    data.account.items[0].policies.items
  ) {
    for (
      let i = 0;
      i <= data?.account?.items[0]?.policies?.items?.length - 1;
      i += 1
    ) {
      if (data?.account?.items[0]?.policies?.items[i]?.new_home_purchase_flag) {
        if (
          data.account.items[0]?.policies?.items[i]?.new_home_purchase_flag ===
          'Y'
        ) {
          isNewHomePurchase = true;
        }
      }
    }
  }
  return isNewHomePurchase;
};

export const showDisclaimer = (data: GET_POLICY_PAYPLAN_AND_NEWHOMEFLAG) => {
  let displayDisclaimer: boolean = false;
  let baseState: string = '';
  if (data?.account?.items && data.account.items[0]?.one_inc_disclaimer) {
    // const mock = {
    //   oneIncDisclaimer: `{
    //       "chargeFee": false,
    //       "baseState": "Connecticut"

    //   }`};
    // const disclaimerDetails = JSON.parse(mock.oneIncDisclaimer);
    const disclaimerDetails = JSON.parse(data?.account?.items && data.account.items[0]?.one_inc_disclaimer);
    displayDisclaimer = disclaimerDetails?.chargeFee;
    baseState = disclaimerDetails?.baseState;
  }
  return { displayDisclaimer, baseState };
};

export default ({
  noModalClickHandler,
  children,
  page,
  policyNumber,
  readOnlyAccess,
  readOnlyFeatureFlag
}: MakePaymentOptionsDataContainerProps) => {
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL'
  );
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const { accountNumber } = useParams();
  const variables = {
    account_number: accountNumber
  };
  const queryResult = useQuery(get_policy_payplan_and_newhomeflag, {
    variables
  });
  const { data } = queryResult;
  const userID = data?.me?.user_id;
  const [openOneIncURL] = generateOpenOneIncURL(
    accountNumber,
    userID,
    SSOneINCURL?.enabled,
    sessionStorage?.userLOB?.includes('Commercial') ? 'GWCL' : 'GWPL'
  );
  let useModalPL: Boolean = false;

  const billingMethodQueryResult = useQuery(get_policy_payplan_billing_method, {
    variables
  });

  const { data: billingMethodData } = billingMethodQueryResult;

  // get migrated status
  const getMigratedStatusQueryResult = useQuery(get_migrated_status, {
    variables
  });
  const { data: migratedStatusData } = getMigratedStatusQueryResult;
  let isDataMigrated: boolean = false;
  if (
    migratedStatusData &&
    migratedStatusData?.hasMigratedPolicy &&
    migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy
  ) {
    isDataMigrated = migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy;
  }

  const hasAgencyAndDirectBilled = agencyAndDirectBilled(billingMethodData);

  const shouldShowModal =
    checkMortgageeBilled(data) || checkNewHomePurchase(data);

  let shouldShowModalCL: Boolean = false;
  if (shouldShowModal && checkMarket(data)) {
    shouldShowModalCL = true;
  }

  const shouldShowDisclaimerAfterModal: DisclaimerProps = showDisclaimer(data);

  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showAltDisclaimerModal, setShowAltDisclaimerModal] = useState(false);
  const [showAgencyBillModal, setShowAgencyBillModal] = useState(true);
  const { flagDetails } = useFeatureFlag('PLCCFeeDisclaimer');
  const { flagDetails: showCCFeeForAllPLFlag } = useFeatureFlag(
    'ShowCCFeeForAllPL'
  );
  if (
    (showCCFeeForAllPLFlag?.enabled || shouldShowDisclaimerAfterModal?.displayDisclaimer) &&
    !checkMarket(data)
  ) {
    useModalPL = true;
  }

  // CLICK HANDLERS
  const onMakePaymentClick = () => {
    setShowModal(true);
    simpleGTMDataLayer({
      event: `click-${page}MakePayment`
    });
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const closeSecondModal = () => {
    setShowSecondModal(false);
    setShowAgencyBillModal(true);
  };
  const closeAltDisclaimerModal = () => {
    setShowAltDisclaimerModal(false);
    setShowAgencyBillModal(true);
  };
  const clickHandlerYes = () => {
    simpleGTMDataLayer({
      event: `click-${page}MakePaymentModalYesButton`
    });
    closeModal();
    setShowSecondModal(true);
  };
  const clickHandlerNo = () => {
    simpleGTMDataLayer({
      event: `click-${page}MakePaymentModalNoButton`
    });
    closeModal();
    setShowAltDisclaimerModal(true);
  };
  const clickHandlerYesDisclaimer = () => {
    simpleGTMDataLayer({
      event: `click-${page}DisclaimerModalOkButton`
    });
    closeModal();
    openOneIncURL();
  };
  const clickHandlerDisclaimer = () => {
    simpleGTMDataLayer({
      event: `click-${page}DisclaimerModalCancelButton`
    });
    closeModal();
    setShowAgencyBillModal(true);
  };
  const clickHandlerYesAgencyBill = () => {
    simpleGTMDataLayer({
      event: `click-${page}AgencyBillModalYesButton`
    });
    window.open(config.agency_bill_url, '_blank');
    closeModal();
  };
  const clickHandlerNoAgencyBill = () => {
    simpleGTMDataLayer({
      event: `click-${page}AgencyBillModalNoButton`
    });
    setShowAgencyBillModal(false);
  };

  // MODAL CHECKS
  const baseState: string = shouldShowDisclaimerAfterModal?.baseState;
  const displayOneIncDisclaimer: Boolean =
    checkMarket(data) &&
    !shouldShowModalCL &&
    !agencyBilledOnly(billingMethodData);
  const displayModalWithoutDisclaimer: Boolean =
    shouldShowModal &&
    !(shouldShowDisclaimerAfterModal.displayDisclaimer) &&
    !agencyBilledOnly(billingMethodData);
  const displayModalWithDisclaimer: Boolean =
    shouldShowModal &&
    (shouldShowDisclaimerAfterModal.displayDisclaimer) &&
    !agencyBilledOnly(billingMethodData);
  const displayAgencyBillModal: Boolean | undefined =
    hasAgencyAndDirectBilled && page !== 'policy' && showAgencyBillModal;

  // DISPLAY MODAL LOGIC
  if (page === 'policy') {
    if (policyIsAgencyBilled(billingMethodData, policyNumber)) {
      return (
        <div
          data-test-id="agencyBillPolicy-noModalClickHandler"
          className="agencyBillPolicy"
          onClick={() => noModalClickHandler()}
        >
          {children}
        </div>
      );
    }
  }

  if (displayAgencyBillModal) {
    return (
      <Box>
        <Modal
          showModal={showModal}
          onEsc={closeModal}
          onClickOutside={closeModal}
        >
          <OutsideBoxCL
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
            }}
            data-test-id="agency-direct-billed-modal-OutsideBoxCL"
          >
            <Box>
              <QuestionText data-test-id="agency-bill-make-payment-modal-header">
                Agency Billed Payment
              </QuestionText>
              <InsideText>Is this for an Agency Billed payment?</InsideText>
              <NoButton
                onClick={() => {
                  clickHandlerNoAgencyBill();
                }}
                data-test-id="agency-bill-make-payment-modal-no"
              >
                NO
              </NoButton>

              <YesButton
                onClick={() => {
                  clickHandlerYesAgencyBill();
                }}
                data-test-id="agency-bill-make-payment-modal-yes"
              >
                YES
              </YesButton>
            </Box>
          </OutsideBoxCL>
        </Modal>
        <MakePaymentButtonContainer
          onClick={() =>
            readOnlyAccess && readOnlyFeatureFlag ? false : onMakePaymentClick()
          }
          changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
        >
          {children}
        </MakePaymentButtonContainer>
      </Box>
    );
  }

  if (displayOneIncDisclaimer) {
    return (
      <Box>
        <Modal
          showModal={showModal}
          onEsc={closeModal}
          onClickOutside={closeModal}
        >
          <OutsideBoxDisclaimer>
            <DisclaimerQuestionText>Disclaimer</DisclaimerQuestionText>
            <DisclaimerInsideText>
              {baseState === 'Connecticut' ? `I understand that to pay by credit card/debit card I will be transferred to One Inc, a third-party payment processor, to collect my payment information and by clicking “OK” I will be transferred to One Inc. One Inc is a third-party payment solutions provider who will make a payment to State Auto on my behalf.` : `I understand that by clicking "OK", I will be
              transferred to One Inc to collect my payment information and a
              2.5% processing fee will apply to any payments made with my credit
              or debit card. One Inc is a third-party payment solutions provider
              who will make a payment to State Auto on behalf of the insured.
              Payments made by ACH/eCHECK are not assessed this fee.`}
            </DisclaimerInsideText>
            <ModalButtonsWrap>
              <CancelButton
                onClick={() => {
                  clickHandlerDisclaimer();
                }}
                data-test-id="one-inc-disclaimer-cancel"
              >
                CANCEL
              </CancelButton>

              <OkButton
                onClick={() => {
                  clickHandlerYesDisclaimer();
                }}
              >
                OK
              </OkButton>
            </ModalButtonsWrap>
          </OutsideBoxDisclaimer>
        </Modal>
        <MakePaymentButtonContainer
          onClick={() =>
            readOnlyAccess && readOnlyFeatureFlag ? false : onMakePaymentClick()
          }
          changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
        >
          {children}
        </MakePaymentButtonContainer>
      </Box>
    );
  }

  if (displayModalWithoutDisclaimer) {
    return (
      <Box>
        <Modal
          showModal={showModal}
          onEsc={closeModal}
          onClickOutside={closeModal}
        >
          {shouldShowModalCL ? (
            <MakePaymentOptionsCL
              page={page}
              openOneIncURL={openOneIncURL}
              closeModal={closeModal}
            />
          ) : (
            <MakePaymentOptions
              useDisclaimerModal={useModalPL}
              flagEnabled={flagDetails?.enabled}
              page={page}
              openOneIncURL={openOneIncURL}
              closeModal={closeModal}
              baseState={baseState}
            />
          )}
        </Modal>
        <MakePaymentButtonContainer
          onClick={() =>
            readOnlyAccess && readOnlyFeatureFlag ? false : onMakePaymentClick()
          }
          changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
        >
          {children}
        </MakePaymentButtonContainer>
      </Box>
    );
  }

  if (displayModalWithDisclaimer) {
    if (showSecondModal) {
      return (
        <DisclaimerModal
          page={page}
          openOneIncURL={openOneIncURL}
          showSecondModal={showSecondModal}
          closeSecondModal={closeSecondModal}
          baseState={baseState}
        >
          {children}
        </DisclaimerModal>
      );
    }
    if (showAltDisclaimerModal) {
      return (
        <DisclaimerModal
          alt
          page={page}
          openOneIncURL={openOneIncURL}
          showSecondModal={showAltDisclaimerModal}
          closeSecondModal={closeAltDisclaimerModal}
          baseState={baseState}
        >
          {children}
        </DisclaimerModal>
      );
    }
    return (
      <Box>
        <Modal
          showModal={showModal}
          onEsc={closeModal}
          onClickOutside={closeModal}
        >
          {shouldShowModalCL ? (
            <OutsideBoxCL
              onClick={(event: MouseEvent) => {
                event.stopPropagation();
              }}
            >
              <Box>
                <QuestionText>Mortgagee Payment</QuestionText>
                <InsideText>Is this payment for a Mortgagee Bill?</InsideText>
                <NoButton
                  onClick={() => {
                    clickHandlerNo();
                  }}
                  data-test-id="mortgagee-payment-modal-no"
                >
                  NO
                </NoButton>

                <YesButton
                  onClick={() => {
                    clickHandlerYes();
                  }}
                >
                  YES
                </YesButton>
              </Box>
            </OutsideBoxCL>
          ) : (
            <MakePaymentOptions
              useDisclaimerModal={useModalPL}
              flagEnabled={flagDetails?.enabled}
              closeModal={closeModal}
              page={page}
              openOneIncURL={openOneIncURL}
              baseState={baseState}
            />
          )}
        </Modal>
        <MakePaymentButtonContainer
          onClick={() =>
            readOnlyAccess && readOnlyFeatureFlag ? false : onMakePaymentClick()
          }
          changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
        >
          {children}
        </MakePaymentButtonContainer>
      </Box>
    );
  }

  if (useModalPL && flagDetails?.enabled) {
    return (
      <div>
        <MakePaymentButtonContainer
          onClick={() =>
            readOnlyAccess && readOnlyFeatureFlag ? false : onMakePaymentClick()
          }
          changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
        >
          {children}
        </MakePaymentButtonContainer>
        {showModal ? <Backdrop /> : <></>}
        <DisclaimerModalPL
          showModal={showModal}
          closeModal={closeModal}
          onContinue={clickHandlerYesDisclaimer}
          isMigrated={isDataMigrated}
          baseState={baseState}
        />
      </div>
    );
  }

  return <div onClick={() => noModalClickHandler()}>{children}</div>;
};
