import React from 'react';
import daysOfTheMonth from '../../util/generaters/daysOfTheMonth';
import {
  Calendar,
  NewDate,
  SingleDate,
  CurrentDate,
  UnavailableDateButton,
  DateButton,
  DoubleDigit
} from './changeDueDateStyles';

interface CalendarProps {
  newDate: string;
  current: string;
  setNewDate: Function;
}

export default ({ newDate, current, setNewDate }: CalendarProps) => {
  return (
    <Calendar>
      {daysOfTheMonth.map((day: string) => {
        if (newDate && newDate === day) {
          return (
            <NewDate id="new-date">
              {day.length === 1 ? (
                <SingleDate>{day}</SingleDate>
              ) : (
                <DoubleDigit>{day}</DoubleDigit>
              )}
            </NewDate>
          );
        }
        if (current && current === day) {
          return (
            <CurrentDate id="current-day">
              {day.length === 1 ? (
                <SingleDate>{day}</SingleDate>
              ) : (
                <DoubleDigit>{day}</DoubleDigit>
              )}
            </CurrentDate>
          );
        }
        if (day.includes('unavailable')) {
          return (
            <UnavailableDateButton>{day.split('-')[0]}</UnavailableDateButton>
          );
        }
        return (
          <DateButton
            id={`date-button-${day}`}
            onClick={() => {
              setNewDate(day);
            }}
          >
            {day}
          </DateButton>
        );
      })}
    </Calendar>
  );
};
