import React, { useState } from 'react';

import HamBurgerMenu from './HeaderMenuStyles';

import SideBarMenu from './sidebarMenu';

import HamBurgerSrc from '../../assets/images/Hamburger.svg';

const HeaderMenu = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <>
      <HamBurgerMenu onClick={() => setShowMenu(true)} src={HamBurgerSrc} />
      <SideBarMenu closeMenu={() => setShowMenu(false)} active={showMenu} />
    </>
  );
};

export default HeaderMenu;
