import React from 'react';
import ChevronUp from '../assets/images/chevron-up.svg';

interface ChevronIconProps {
  width?: number;
  height?: number;
}

const ChevronIcon: React.FC<ChevronIconProps> = ({ width = 14, height = 8 }) => {
  return (
    <div>
      <img src={ChevronUp} width={width} height={height} />
    </div>
  );
};

export default ChevronIcon;
