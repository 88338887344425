import React from 'react';
import { daySuffix } from './changeDueDateModal';
import {
  Modal,
  ChangeDueDateContainer,
  Unavailable,
  InfoIconSizing,
  CloseIconSizing
} from './changeDueDateStyles';
import { AltInfo, AltClose } from '../../icons';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

interface unavailableProps {
  current: string;
  setShowModal: Function;
  hasBeenCalled: boolean;
  noFutureInvoices: boolean;
}

export default ({
  current,
  setShowModal,
  hasBeenCalled,
  noFutureInvoices
}: unavailableProps) => {
  const header = noFutureInvoices
    ? 'This account has no planned invoices and cannot change the due date.'
    : 'You are only able to change your due date once every 365 days.';
  const body = noFutureInvoices
    ? `Your current due date is the ${current}${daySuffix(current)}.`
    : `You’ve already changed your due date to the ${current}${daySuffix(
        current
      )} of every month.`;
  return (
    <Modal>
      {hasBeenCalled && (
        <ChangeDueDateContainer>
          <Unavailable>
            <InfoIconSizing>
              <AltInfo />
            </InfoIconSizing>
            <div>
              <div>{header}</div>
              <div>{body}</div>
            </div>
            <CloseIconSizing
              onClick={() => {
                simpleGTMDataLayer({
                  event: 'click-closeDueDateHasAlreadyChanged',
                  event_action: 'Button Click',
                  event_category: window.location.pathname,
                  event_category_snowplow: `${window.location.pathname}/Snowplow`,
                  event_label: 'Close - Due Date Has Already Changed'
                });
                setShowModal(false);
              }}
            >
              <AltClose />
            </CloseIconSizing>
          </Unavailable>
        </ChangeDueDateContainer>
      )}
    </Modal>
  );
};
