import { navigate } from '@reach/router';
import { SEARCH_PANEL_GET_QUOTE_SEARCH_SELECTION_BEHAVIOR } from '../../components/searchPanel/graphql/__generated__/SEARCH_PANEL_GET_QUOTE_SEARCH_SELECTION_BEHAVIOR';

export default (data: SEARCH_PANEL_GET_QUOTE_SEARCH_SELECTION_BEHAVIOR | undefined) => {
  if (
    !data?.quoteSearchSelectionBehavior?.location ||
    !data?.quoteSearchSelectionBehavior?.type
  ) {
    console.error('Could not determine quote behavior');
    return null;
  }
  const {
    type: behaviorType,
    location: behaviorLocation
  }: {
    type: string | null;
    location: string | null;
  } = data?.quoteSearchSelectionBehavior;
  switch (behaviorType) {
    case 'navigate':
      navigate(behaviorLocation!);
      break;
    case 'redirect':
      window.open(behaviorLocation!, '_self');
      break;
    default:
      break;
  }
  return null;
};
