import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts } from '../../pages/ClaimsPage/graphql/__generated__/GET_CLAIM_INCIDENTS';
import { motifs } from '../../themes/default.theme';
import { Alert, Note, Payments, Completion } from '../../icons';

export const Row = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${motifs.lightBorder};
  }

  &:not(:first-child) {
    margin-top: 15px;
  }

  padding: 0px 0px 16px 0px;
`;
export interface ClaimsAlertProps {
  alerts?:
    | GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts[]
    | null | undefined;
}
function alertType(alertName: string) {
  let type = <Box />;
  if (
    [
      'enterprise reservation setup',
      'vehicle moved',
      'initial estimate prepared',
      'mandatory medical forms'
    ].includes(alertName)
  ) {
    type = <Alert />;
  } else if (
    [
      'estimate review is pending',
      'investigation is pending',
      'inspection process is underway'
    ].includes(alertName)
  ) {
    type = <Note />;
  } else if (
    ['investigation complete', 'initial estimate prepared'].includes(alertName)
  ) {
    type = <Completion />;
  } else if (['payment issued'].includes(alertName)) {
    type = <Payments />;
  }
  return type;
}

export default ({ alerts }: ClaimsAlertProps) => {
  return (
    <Box background="#FFFFFF" fill pad={{ top: '30px', left: '30px' }}>
      <Text size="18px" weight="bold" margin={{ bottom: '15px' }}>
        Latest Alerts & Updates
      </Text>

      {alerts && alerts.length ? (
        <Box overflow={{ vertical: 'auto' }} margin={{ top: '15px' }}>
          {alerts.map(alert => (
            <Row flex={false} key={alert.publicID}>
              <Box direction="row">
                <Box height="15px" margin={{ right: '15px' }}>
                  {alert.alertName && alertType(alert.alertName.toLowerCase())}
                </Box>
                <Box>
                  <Text size="14px" weight="bold">
                    {alert.createDate}
                  </Text>
                  <Text size="16px">{alert.alertName}</Text>
                </Box>
              </Box>
            </Row>
          ))}
        </Box>
      ) : (
        <p>No alerts found.</p>
      )}
    </Box>
  );
};
