import React, { useEffect } from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import NextPaymentSection from '../nextPaymentSection';
import PrimaryButton from '../PrimaryButton';
import { formatMoney } from '../../util/formatters';
import { customBase } from '../../themes/default.theme';
import { CancelPayment } from '..';
import alertIcon from '../../assets/images/AlertIcon.svg';
import { PAYMENT_INFO_GET_NEXT_PAYMENT_INFO_account_items_next_payment_no_mortgagee_billed } from './graphql/__generated__/PAYMENT_INFO_GET_NEXT_PAYMENT_INFO';
import { GET_MORTGAGE_INVOICES_INFO_invoices_items } from './graphql/__generated__/GET_MORTGAGE_INVOICES_INFO';

export interface nextPayment {
  lastDayToPayFlag: boolean;
  showPastDueDetails?: boolean;
  isLoading?: boolean;
  error?: Error;
  paymentInfo:
    | PAYMENT_INFO_GET_NEXT_PAYMENT_INFO_account_items_next_payment_no_mortgagee_billed
    | undefined;
  children: JSX.Element[] | undefined | any;
  allCancelled?: boolean;
  paidInFull: Function;
  formattedDate: string;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
  nextPaymentAutoDraftIndicator: boolean;
  mortgageeInvoice: GET_MORTGAGE_INVOICES_INFO_invoices_items | undefined;
  isAgencyAndDirectBilled: boolean;
}

const StyledPaymentInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const gridPadding = '1.25rem';
const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  text-align: center;
  width: 140px;
  padding: 4px;
  height: 31px;
  span {
    font-size: 14px;
    font-weight: bold;
  }
`;

const StyledAlert = styled(Text)`
  line-height: 1.375rem;
  height: 25px;
  color: #d0021b;
`;

export const TextPastDue = styled(Text)`
  color: #d0021b;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  vertical-align: top;
`;

const StyledValue = styled(Text)`
  line-height: 1.375rem;
`;

const StyledAmount = styled(StyledValue)`
  font-size: 28px;
`;

const StyledDate = styled(StyledValue)`
  height: 24px;
  font-size: 0.875rem;
`;

const StyledLabel = styled(Text)`
  line-height: 1.375rem;
  font-size: 14px;
  padding-bottom: 20px;
`;

export const StyledBox = styled(Box)`
  background-color: #f5f7fa;
  padding: 10px 10px 10px 10px;
`;

export const StyledPastDue = styled(Text)`
  line-height: 1.375rem;
  font-size: 14px;
  font-weight: bold;
`;

const StyledLineItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
`;

const BillingText = styled(Text)`
  font-size: 16px;
  line-height: 35px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 10px;
    background-color: #f4f6fa;
  }
`;

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-bottom: ${gridPadding};
  }
`;

const Column1 = styled(Column)`
  @media (min-width: ${xlargeBreakpoint}) {
    width: 50%;
  }
`;

const Column2 = styled(Column)`
  @media (min-width: ${xlargeBreakpoint}) {
    width: 50%;
  }
`;

export default ({
  lastDayToPayFlag,
  showPastDueDetails,
  isLoading,
  error,
  paymentInfo,
  children,
  allCancelled,
  paidInFull,
  formattedDate,
  readOnlyAccess,
  readOnlyFeatureFlag,
  nextPaymentAutoDraftIndicator,
  mortgageeInvoice,
  isAgencyAndDirectBilled
}: nextPayment) => {
  let showPastDueInfo = false;

  useEffect(() => {
    if (
      ['paid in full']?.includes(
        paymentInfo?.paymentAmount?.toLowerCase() as string
      )
    ) {
      paidInFull(true);
    }
  }, [paymentInfo]);

  if (error) {
    return <h3>Error</h3>;
  }

  if (isLoading || lastDayToPayFlag === undefined) {
    return <h3>Loading...</h3>;
  }

  if (paymentInfo?.pastDueAmount && +paymentInfo?.pastDueAmount) {
    showPastDueInfo = true;
  }
  if (lastDayToPayFlag) {
    return (
      <NextPaymentSection
        makePayment={children}
        nextPaymentInfo={paymentInfo}
        nextPaymentAutoDraftIndicator={nextPaymentAutoDraftIndicator}
        mortgageeInvoice={mortgageeInvoice}
        isAgencyAndDirectBilled={isAgencyAndDirectBilled}
      />
    );
  }

  return (
    <Box background="white">
      {showPastDueDetails ? (
        <Box>
          <Box fill="horizontal">
            <Row>
              <BillingText weight="bold">Payment Due Date</BillingText>
              <BillingText>{formattedDate}</BillingText>
            </Row>
            {['paid in full']?.includes(
              paymentInfo?.paymentAmount?.toLowerCase() as string
            ) && allCancelled ? (
              <div />
            ) : (
              <Box>
                <Row>
                  <BillingText weight="bold">Payment Amount Due</BillingText>
                  <BillingText>
                    {paymentInfo?.paymentAmount &&
                    paymentInfo?.paymentAmount !== '-'
                      ? formatMoney(paymentInfo?.paymentAmount)
                      : '$0'}
                  </BillingText>
                </Row>
                <Row>
                  <BillingText weight="bold" test-attr="PastDueAmount">
                    Past Due Amount
                  </BillingText>
                  <BillingText test-attr="PastDueAmountValue">
                    {paymentInfo?.pastDueAmount &&
                    paymentInfo?.pastDueAmount !== '-'
                      ? formatMoney(paymentInfo?.pastDueAmount)
                      : '$0'}
                  </BillingText>
                </Row>
              </Box>
            )}
            <Row>
              <BillingText weight="bold">Total Due</BillingText>
              <BillingText weight="bold" test-attr="totalDueAmount">
                {paymentInfo?.paymentAmount &&
                paymentInfo?.paymentAmount !== '-'
                  ? formatMoney(paymentInfo?.paymentAmount)
                  : '$0'}
              </BillingText>
            </Row>
          </Box>
        </Box>
      ) : (
        <StyledPaymentInfo>
          <Column1>
            <Box
              direction="column"
              pad={{ horizontal: 'small', vertical: 'small' }}
            >
              <StyledLineItem>
                <StyledAmount weight="bold" test-attr="paymentAmount">
                  {formatMoney(paymentInfo?.paymentAmount || '$0')}
                </StyledAmount>
              </StyledLineItem>
              <StyledLineItem>
                {!showPastDueInfo ? (
                  <StyledDate test-attr="dueDate">
                    on &nbsp;
                    {formattedDate || '-'}
                  </StyledDate>
                ) : (
                  <StyledDate />
                )}
              </StyledLineItem>
              <StyledLineItem>
                {showPastDueInfo ? (
                  <StyledAlert>
                    <img src={alertIcon} alt="Alert Past Due" />
                    <TextPastDue>Past Due</TextPastDue>
                  </StyledAlert>
                ) : (
                  <StyledAlert />
                )}
              </StyledLineItem>
            </Box>
            {children}
          </Column1>
          <Column2>
            <StyledBox>
              <StyledLabel>
                Amount is inclusive of all the policies.
              </StyledLabel>
              <StyledPastDue weight="bold">Past Due Amount:</StyledPastDue>
              {paymentInfo?.pastDueAmount
                ? formatMoney(paymentInfo?.pastDueAmount)
                : '$0'}
            </StyledBox>
            <CancelPayment
              readOnlyAccess={readOnlyAccess}
              readOnlyFeatureFlag={readOnlyFeatureFlag}
            />
          </Column2>
        </StyledPaymentInfo>
      )}
    </Box>
  );
};
