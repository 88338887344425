import React from 'react';
import { ResponsiveContext, Grid } from 'grommet';
import {
  ResponsiveGridProps,
  ResponsiveAreas,
  ResponsiveColumns
} from './ResponsiveGrid.d';

export default ({
  children,
  columns,
  areas,
  ...props
}: ResponsiveGridProps) => {
  const size: string = React.useContext(ResponsiveContext);
  return (
    <Grid
      fill
      areas={Array.isArray(areas) ? areas : (areas as ResponsiveAreas)[size]}
      columns={
        Array.isArray(columns)
          ? columns
          : (columns as ResponsiveColumns)[size] || ['fill']
      }
      {...props}
    >
      {children}
    </Grid>
  );
};
