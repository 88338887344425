import React, { useState } from 'react';
import {
  Modal,
  ChangeDueDate,
  Header,
  MessageTextBox,
  ButtonRow,
  CancelAnchor,
  ConfirmationButton,
  WarningIconSizing,
  ChangeDueDateContainer
} from './changeDueDateStyles';
import { UPDATE_INVOICE_DUE_DATE_updateInvoiceDueDate } from './graphql/__generated__/UPDATE_INVOICE_DUE_DATE';
import { Warning } from '../../icons';
import {
  UnavailableModal,
  SuccessModal,
  DescriptionContainer,
  DateDescription,
  SelectDateCalendar,
  ConfirmCalendar
} from '.';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

export interface ChangeDueDateProps {
  loading: boolean;
  error?: Error;
  current: string;
  available: boolean;
  updateDue: Function;
  updatedDate: UPDATE_INVOICE_DUE_DATE_updateInvoiceDueDate;
  setShowModal: Function;
  accountNumber: string;
  within10Days: boolean;
  pastDue: boolean;
  hasBeenCalled: boolean;
  stoppedLoading: boolean;
  user: string;
  noFutureInvoices: boolean;
}

export const daySuffix: Function = (currentDate: string): string => {
  if (currentDate === '1' || currentDate === '21' || currentDate === '31')
    return 'st';
  if (currentDate === '2' || currentDate === '22') return 'nd';
  if (currentDate === '3' || currentDate === '23') return 'rd';
  return 'th';
};

export default ({
  loading,
  error,
  current,
  available,
  updateDue,
  updatedDate,
  setShowModal,
  accountNumber,
  within10Days,
  pastDue,
  hasBeenCalled,
  stoppedLoading,
  user,
  noFutureInvoices
}: ChangeDueDateProps) => {
  const [newDate, setNewDate] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const updateBillingDue = async () => {
    await updateDue({
      variables: {
        account_number: accountNumber,
        day: newDate,
        sourceSystem: 'Agency360',
        userID: user
      }
    });
    if (!loading) {
      setConfirmation(false);
    }
  };

  if (!available && stoppedLoading) {
    return (
      <UnavailableModal
        current={current}
        setShowModal={setShowModal}
        hasBeenCalled={hasBeenCalled}
        noFutureInvoices={noFutureInvoices}
      />
    );
  }
  if (updatedDate && updatedDate.updated) {
    return <SuccessModal newDate={newDate} />;
  }
  return (
    <Modal>
      {stoppedLoading && (
        <ChangeDueDateContainer>
          <ChangeDueDate>
            <Header>Change Due Date</Header>
            <DescriptionContainer confirmation={confirmation} />
            <DateDescription
              newDate={newDate}
              current={current}
              confirmation={confirmation}
            />
            {!confirmation ? (
              <SelectDateCalendar
                current={current}
                newDate={newDate}
                setNewDate={setNewDate}
              />
            ) : (
              <ConfirmCalendar newDate={newDate} />
            )}
            {!confirmation && newDate !== '' && parseInt(newDate, 10) > 28 && (
              <MessageTextBox>
                <WarningIconSizing>
                  <Warning />
                </WarningIconSizing>
                <div>
                  The day selected does not exist in every month of the year. In
                  the months there are missing the selected day (i.e. February),
                  the scheduled withdrawal date will be the last day of the
                  month.
                </div>
              </MessageTextBox>
            )}
            {!confirmation && within10Days && pastDue && (
              <MessageTextBox>
                <WarningIconSizing>
                  <Warning />
                </WarningIconSizing>
                <div>
                  There is a past due and a scheduled payment on your account.
                  The day of the month you have selected will not apply until
                  your next planned payment date.
                </div>
              </MessageTextBox>
            )}
            {!confirmation && within10Days && (
              <MessageTextBox>
                <WarningIconSizing>
                  <Warning />
                </WarningIconSizing>
                <div>
                  Payment due dates are locked 10 days before an auto payment is
                  due. The day of the month you have selected will not apply
                  until your next planned payment date.
                </div>
              </MessageTextBox>
            )}
            {!confirmation && pastDue && (
              <MessageTextBox>
                <WarningIconSizing>
                  <Warning />
                </WarningIconSizing>
                <div>
                  There is a past due payment on your account. The day of the
                  month you have selected will not apply until your next planned
                  payment date.
                </div>
              </MessageTextBox>
            )}
            {error && (
              <MessageTextBox>
                <WarningIconSizing>
                  <Warning />
                </WarningIconSizing>
                <div>{updatedDate.reason}</div>
              </MessageTextBox>
            )}
            {confirmation ? (
              <ButtonRow>
                <CancelAnchor
                  id="return-to-change-date"
                  onClick={() => {
                    setConfirmation(false);
                  }}
                >
                  BACK
                </CancelAnchor>
                <ConfirmationButton
                  id="change-due-date"
                  onClick={() => {
                    simpleGTMDataLayer({
                      event: 'click-confirmDueDateChange',
                      event_action: 'Button Click',
                      event_category: window.location.pathname,
                      event_category_snowplow: `${window.location.pathname}/Snowplow`,
                      event_label: 'Confirm Change Due Date'
                    });
                    updateBillingDue();
                  }}
                >
                  CONFIRM
                </ConfirmationButton>
              </ButtonRow>
            ) : (
              <ButtonRow>
                <CancelAnchor
                  id="cancel-change-due-date"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  CANCEL
                </CancelAnchor>
                <ConfirmationButton
                  id="confirm-due-date"
                  onClick={() => {
                    setConfirmation(true);
                  }}
                >
                  NEXT
                </ConfirmationButton>
              </ButtonRow>
            )}
          </ChangeDueDate>
        </ChangeDueDateContainer>
      )}
    </Modal>
  );
};
