import React from 'react';
import { GET_QUOTE_ACCOUNT_DETAILS_quote } from './graphql/__generated__/GET_QUOTE_ACCOUNT_DETAILS';
import {
  AccountDetail,
  LabelText,
  HorizontalLine,
  DetailContainer,
  DetailColumn,
  DetailTitle,
  DetailText
} from './QuoteAccountDetailsStyles';
import {
  formatTitleCase,
  formatPhone,
  formatZipcode
} from '../../util/formatters';

export interface QuoteAccountProps {
  quoteInfo: GET_QUOTE_ACCOUNT_DETAILS_quote;
  quoteNumber: string;
}

export default ({ quoteInfo, quoteNumber }: QuoteAccountProps) => {
  return (
    <AccountDetail>
      {quoteInfo?.account_number ? (
        <LabelText>Account Number: {quoteInfo?.account_number}</LabelText>
      ) : (
        <LabelText>Quote Number: {quoteNumber}</LabelText>
      )}
      <HorizontalLine />
      <DetailContainer>
        <DetailColumn>
          {quoteInfo?.account ? (
            <div>
              <DetailTitle>Address</DetailTitle>
              <DetailText>
                {formatTitleCase(quoteInfo?.account?.address?.street_name)}
              </DetailText>
              <DetailText>
                {formatTitleCase(quoteInfo?.account?.address?.city)},{' '}
                {quoteInfo?.account?.address?.state}{' '}
                {formatZipcode(quoteInfo?.account?.address?.zip_code)}
              </DetailText>
            </div>
          ) : (
            <div>{formatTitleCase(quoteInfo?.address)}</div>
          )}
          {quoteInfo?.account?.email_address && (
            <div>
              <DetailTitle>Email</DetailTitle>
              <DetailText>{quoteInfo?.account?.email_address}</DetailText>
            </div>
          )}
        </DetailColumn>
        <DetailColumn>
          {quoteInfo?.account?.home_phone && (
            <div>
              <DetailTitle>
                Home{' '}
                {quoteInfo?.account?.primary_phone === 'HOME' && '(Primary)'}
              </DetailTitle>
              <DetailText>
                {formatPhone(quoteInfo?.account?.home_phone)}
              </DetailText>
            </div>
          )}
          {quoteInfo?.account?.cell_phone && (
            <div>
              <DetailTitle>
                Mobile{' '}
                {quoteInfo?.account?.primary_phone === 'MOBILE' && '(Primary)'}
              </DetailTitle>
              <DetailText>
                {formatPhone(quoteInfo?.account?.cell_phone)}
              </DetailText>
            </div>
          )}
          {quoteInfo?.account?.work_phone && (
            <div>
              <DetailTitle>
                Work{' '}
                {quoteInfo?.account?.primary_phone === 'WORK' && '(Primary)'}
              </DetailTitle>
              <DetailText>
                {formatPhone(quoteInfo?.account?.work_phone)}
              </DetailText>
            </div>
          )}
        </DetailColumn>
      </DetailContainer>
    </AccountDetail>
  );
};
