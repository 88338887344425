import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { PolicyInfo } from './PolicyInfo';
import { policyIsAgencyBilled } from '../../util/agencyBilledUtils';
import useDocumentDownload from '../../util/useDocumentDownload';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';

const get_policy_info_query = loader('./graphql/Get_Policy_Info.graphql');

const get_policy_payplan_billing_method = loader(
  './graphql/Policy_Info_Get_Policy_PayPlan_Billing_Method.graphql'
);

const get_next_payment_no_mortgagee_billed_details = loader(
  './graphql/Policy_Info_Get_Next_Payment_No_Mortgagee_Billed_Details.graphql'
);

export const transformData = (data: any) => {
  const information = data?.account?.items?.[0]?.policy ?? null;
  if (information?.status === 'InForce') information.status = 'In Force';
  return {
    information
  };
};

export default () => {
  const { policyNumber, accountNumber } = useParams();

  const { flagDetails: lastDayToPayFlagDetails } = useFeatureFlag(
    'LastDayToPay'
  );

  const { onDocumentClick, documentModal } = useDocumentDownload(
    accountNumber,
    'policy',
    'click-policyDeclarationDocument'
  );

  const variables = {
    account_number: accountNumber,
    policy_number: policyNumber
  };

  const accountLevelVariables = {
    account_number: accountNumber
  };

  const {
    data: getPolicyInfoQueryResult,
    loading: policyInfoLoading,
    error: policyInfoError
  } = useQuery(get_policy_info_query, {
    variables
  });

  const { data: billingMethodQueryResult } = useQuery(
    get_policy_payplan_billing_method,
    {
      variables: accountLevelVariables
    }
  );

  const { data: lastDayToPayResult } = useQuery(
    get_next_payment_no_mortgagee_billed_details,
    {
      variables: accountLevelVariables
    }
  );

  let transformedPolicyInfoData = getPolicyInfoQueryResult;

  if (getPolicyInfoQueryResult) {
    transformedPolicyInfoData = transformData(getPolicyInfoQueryResult);
  }

  const lastDayToPayDetails =
    lastDayToPayResult?.account?.items?.[0]?.next_payment_no_mortgagee_billed;

  return (
    <>
      {documentModal}
      <PolicyInfo
        isLoading={policyInfoLoading}
        error={policyInfoError}
        {...transformedPolicyInfoData}
        accountNumber={accountNumber}
        policyNumber={policyNumber}
        agencyBilled={policyIsAgencyBilled(
          billingMethodQueryResult,
          policyNumber
        )}
        onDocumentClick={onDocumentClick}
        lastDayToPayFlag={lastDayToPayFlagDetails?.enabled}
        lastDayToPayDetails={lastDayToPayDetails}
      />
    </>
  );
};
