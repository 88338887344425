import React, { MouseEvent } from 'react';
import { Box } from 'grommet';
import {
  OutsideBoxCL,
  QuestionText,
  InsideText,
  NoButton,
  YesButton
} from '../paymentOptionsStyles/makePaymentOptionsStyles';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

interface MakePaymentOptionsProps {
  openOneIncURL: Function;
  page: string;
  closeModal: () => void;
}

export default ({
  openOneIncURL,
  page,
  closeModal
}: MakePaymentOptionsProps) => {
  const clickHandlerYes = () => {
    simpleGTMDataLayer({
      event: `click-${page}MakePaymentModalYesButton`
    });
    openOneIncURL(true);
    closeModal();
  };
  const clickHandler = () => {
    simpleGTMDataLayer({
      event: `click-${page}MakePaymentModalNoButton`
    });
    openOneIncURL();
    closeModal();
  };

  return (
    <OutsideBoxCL
      onClick={(event: MouseEvent) => {
        event.stopPropagation();
      }}
    >
      <Box>
        <QuestionText>Mortgagee Payment</QuestionText>
        <InsideText>Is this payment for a Mortgagee Bill?</InsideText>
        <NoButton
          onClick={() => {
            clickHandler();
          }}
        >
          NO
        </NoButton>

        <YesButton
          onClick={() => {
            clickHandlerYes();
          }}
        >
          YES
        </YesButton>
      </Box>
    </OutsideBoxCL>
  );
};
