import React, { useState } from 'react';
import { Box } from 'grommet';
import { SearchPanel } from '../../components';
import { CardLayout } from '../../components/cardLayout/CardLayout';
import simpleGTMDataLayer from '../../util/GTMHelpers/simpleGTMDataLayer';

export default () => {
  const [gtm, setGTM] = useState(false);

  if (!gtm) {
    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Home Page',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });
    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Home Page',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob') + '/Snowplow'
        : '/Snowplow'
    });

    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/',
        title: 'SA360: Search'
      }
    });
    setGTM(true);
  }

  return (
    <Box align="center" pad={{ top: '80px' }}>
      <CardLayout title="Customer Lookup">
        <SearchPanel />
      </CardLayout>
    </Box>
  );
};
