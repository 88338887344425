import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import {
  QuotePageAlertAndButtonRow,
  QuoteAccountInfo,
  QuotePageTable,
  QuotePageNavBar
} from '../../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: 813px;
`;

export default (_: RouteComponentProps) => {
  return (
    <Container>
      <QuotePageNavBar />
      <QuotePageAlertAndButtonRow />
      <QuoteAccountInfo />
      <QuotePageTable />
    </Container>
  );
};
