import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import { PayAsYouGoDropdown } from './PayAsYouGo';

const get_payasyougo_by_account = loader(
  './graphql/Get_Policy_PayAsYouGo.graphql'
);

/**
 * We are just passing through the onChange handler here.
 * The reason we don't provide it from the data-container itself is because
 * the behavior depends on the context in which PolicyDropdown is used.
 */
export default ({
  onChange,
  className
}: {
  onChange: (...args: any[]) => void;
  className?: string;
}) => {
  const { accountNumber, policyNumber } = useParams();
  const variables = {
    account_number: accountNumber
  };
  const {
    loading: isLoading,
    error,
    data
  } = useQuery(get_payasyougo_by_account, { variables });

  const props = {
    isLoading,
    onChange,
    error,
    value: policyNumber,
    className
  };

  if (data?.account?.items?.[0]?.policies?.items?.[0]?.payasyougo?.items) {
    Object.assign(props, {
      options: data.account.items[0].policies.items[0].payasyougo.items
    });
  }

  return <PayAsYouGoDropdown {...props} />;
};
