import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { isFirefox, isSafari } from 'react-device-detect';

const get_payment_url_mutation = loader(
  './graphql/Open_One_Inc_Get_Payment_Url.graphql'
);

export default (
  accountNumber: string,
  userID: string | null | undefined,
  SSOneINCURL?: boolean | null,
  sourceSystemCode?: string | null | undefined
) => {
  const [getURL] = useMutation(get_payment_url_mutation);
  const openFunction = (mortgageeBillIndicator: boolean | null = null) => {
    const input = {
      account_number: accountNumber,
      role: 'SA360',
      pay_my_bill: true,
      mortgageeBillIndicator,
      requested_by: userID,
      lob: sourceSystemCode === 'GWPL' ? 'pl' : 'cl',
      awsURLEnabled: !SSOneINCURL
    };
    getURL({ variables: input })
      .then((res: any) => {
        if (res?.data?.getOneIncPaymentUrl?.payment_portal_redirect_url) {
          if (!isFirefox && !isSafari) {
            if (window.screen.width <= 750) {
              window.location.assign(
                res.data.getOneIncPaymentUrl.payment_portal_redirect_url
              );
            } else {
              window.open(
                res.data.getOneIncPaymentUrl.payment_portal_redirect_url,
                '_blank'
              );
            }
          } else {
            window.location.assign(
              res.data.getOneIncPaymentUrl.payment_portal_redirect_url
            );
          }
        } else {
          console.log('payment_portal_redirect_url not returned');
          alert(
            'We’re unable to reach our payment processor right now. We apologize for the inconvenience.'
          );
        }
      })
      .catch((error: Error) => {
        console.log(error.message);
        alert(
          'We’re unable to reach our payment processor right now. We apologize for the inconvenience.'
        );
      });
  };
  return [openFunction];
};
