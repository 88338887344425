import React, { useState, useEffect } from 'react';
import { Box, Button } from 'grommet';
import styled from 'styled-components';
import {
  PaginationFirst,
  PaginationFirstDisabled,
  PaginationPrevious,
  PaginationPreviousDisabled,
  PaginationNext,
  PaginationNextDisabled,
  PaginationLast,
  PaginationLastDisabled
} from '../../icons';
import { colors } from '../../themes/default.theme';
import { simpleGTMDataLayer } from '../GTMHelpers';

const PageBox = styled(Box)`
  margin-left: 30px;
  margin-right: 30px;
`;

const Text = styled.span`
  font-size: 21px;
  line-height: 25px;
`;

const WhiteText = styled.span`
  font-size: 24px;
  color: #ffffff;
  line-height: 31px;
`;

const GreenCircle = styled.div`
  background-color: ${colors.green1};
  padding: 7px 15.5px;
  border-radius: 22.5px;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  background-color: ${colors.lightGray2};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.lightGray1};
  width: 30px;
  height: 30px;

  & > * {
    height: 10px;
    padding: 0px;
    width: 12px;
    margin-left: 8px;
  }
`;

interface usePaginationProps {
  accountNumber?: string;
  policyNumber?: string;
  quoteNumber?: string;
  items: any[] | undefined;
  itemsPerPage?: number;
}

export default ({
  accountNumber,
  policyNumber,
  quoteNumber,
  items,
  itemsPerPage = 10
}: usePaginationProps): [any[], JSX.Element] => {
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfItems = items?.length;
  useEffect(() => {
    setCurrentPage(1);
  }, [accountNumber, policyNumber, quoteNumber]);
  if (!numberOfItems) {
    return [[], <div />];
  }
  const lastPage = Math.ceil(numberOfItems / itemsPerPage);
  const startingItem = (currentPage - 1) * itemsPerPage;
  const endingItem = startingItem + itemsPerPage;

  let isFirstPage = true;
  if (currentPage !== 1) {
    isFirstPage = false;
  }

  let isLastPage = true;
  if (currentPage !== lastPage) {
    isLastPage = false;
  }

  const pageSelector = (
    <Box direction="row" align="center" pad={{ top: '30px' }}>
      <Box direction="row" gap="15px">
        <StyledButton
          onClick={
            isFirstPage
              ? undefined
              : () => {
                setCurrentPage(1);
                simpleGTMDataLayer({
                  event: 'paginationClickEvents',
                  event_category: window.location.pathname,
                  event_label: 'Page: 1',
                  event_action: 'pagination-click'
                });
              }
          }
          icon={isFirstPage ? <PaginationFirstDisabled /> : <PaginationFirst />}
        />
        <StyledButton
          onClick={
            isFirstPage
              ? undefined
              : () => {
                setCurrentPage(currentPage - 1);
                simpleGTMDataLayer({
                  event: 'paginationClickEvents',
                  event_category: window.location.pathname,
                  event_label: `Page: ${currentPage - 1}`,
                  event_action: 'pagination-click'
                });
              }
          }
          icon={
            isFirstPage ? (
              <PaginationPreviousDisabled />
            ) : (
              <PaginationPrevious />
            )
          }
        />
      </Box>
      <PageBox direction="row" gap="5px" align="center">
        <GreenCircle>
          <WhiteText>{currentPage}</WhiteText>
        </GreenCircle>
        <Text>of</Text>
        <Text>{lastPage}</Text>
      </PageBox>
      <Box direction="row" gap="15px">
        <StyledButton
          onClick={
            isLastPage
              ? undefined
              : () => {
                setCurrentPage(currentPage + 1);
                simpleGTMDataLayer({
                  event: 'paginationClickEvents',
                  event_category: window.location.pathname,
                  event_label: `Page: ${currentPage + 1}`,
                  event_action: 'pagination-click'
                });
              }
          }
          icon={isLastPage ? <PaginationNextDisabled /> : <PaginationNext />}
        />
        <StyledButton
          onClick={
            isLastPage
              ? undefined
              : () => {
                setCurrentPage(lastPage);
                simpleGTMDataLayer({
                  event: 'paginationClickEvents',
                  event_category: window.location.pathname,
                  event_label: `Page: ${lastPage}`,
                  event_action: 'pagination-click'
                });
              }
          }
          icon={isLastPage ? <PaginationLastDisabled /> : <PaginationLast />}
        />
      </Box>
    </Box>
  );

  const displayedItems = items!.slice(startingItem, endingItem);
  return [displayedItems, pageSelector];
};
