import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import ClaimsSelectAPolicyDropdown  from './ClaimsSelectAPolicyDropdown';
import { ClaimsSelectAPolicyDropdownProps } from './ClaimsSelectAPolicyDropdown';

const get_claims_policies_by_account = loader(
  './graphql/Get_Claims_Policies_by_Account.graphql'
);
const get_source_system_code = loader(
  './graphql/Get_Account_System_Code.graphql'
);

export interface SelectPolicyDropdownClaims {
  showModal: boolean;
  setShowModal: Function;
  setWcClaim: Function;
}

export default ({
  showModal,
  setShowModal,
  setWcClaim
}: SelectPolicyDropdownClaims) => {
  const { accountNumber } = useParams();
  let LineOfBusinessArray = [];
  const variables = {
    account_number: accountNumber
  };

  const CloseModal = () => {
    setShowModal(false);
  };

  let modalInformation: ClaimsSelectAPolicyDropdownProps = {
    modalInformation: {},
    code: '',
    closeModal: CloseModal
  };
  let code: any;
  let { data, loading, error } = useQuery(get_claims_policies_by_account, {
    variables
  });

  if (data?.account?.items[0]) {
    modalInformation.modalInformation = data.account.items[0].policies.items;
  }

  data = useQuery(get_source_system_code, { variables });

  if (data?.data?.account?.items) {
    code = data.data.account.items[0].source_system_code;
  }

  for (let i = 0; i < modalInformation.modalInformation.length; i++) {
    if (code === 'GWCL') {
      if (
        modalInformation.modalInformation[i].policy_type === 'Commercial Auto'
      ) {
        LineOfBusinessArray[i] = '&LOB=CA';
      } else if (
        modalInformation.modalInformation[i].policy_type ===
        'Commercial Property'
      ) {
        LineOfBusinessArray[i] = '&LOB=CP';
      } else {
        LineOfBusinessArray[i] = '';
      }
    } else {
      if (modalInformation.modalInformation[i].isDwellingFire) {
        LineOfBusinessArray[i] = '&LOB=DF';
      } else {
        if (modalInformation.modalInformation[i].policy_type === 'Auto') {
          LineOfBusinessArray[i] = '&LOB=PA';
        } else if (
          modalInformation.modalInformation[i].policy_type === 'Home'
        ) {
          LineOfBusinessArray[i] = '&LOB=HO';
        } else {
          LineOfBusinessArray[i] = '';
        }
      }
    }
  }
  if (Array.isArray(modalInformation.modalInformation)) {
    const hasWorkersCompPolicy = modalInformation.modalInformation.some(
      (policy: any) => policy.policy_type === 'Workers Comp'
    );
    setWcClaim(hasWorkersCompPolicy);
  }
  if (showModal) {
    return (
      <ClaimsSelectAPolicyDropdown
        modalInformation={modalInformation.modalInformation}
        code={LineOfBusinessArray}
        closeModal={CloseModal}
      />
    );
  }
  return <div />;
};
