import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import RecentClaimsItem from '../recentClaimsItem/RecentClaimsItem';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const OuterBox = styled(Box)`
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
  padding: 0px 0px 15px 0px;
  background-color: #ffffff;
  border-radius: 3px;
  align-content: start;
`;

const RecentClaimsText = styled(Text)`
  font-weight: 700;
  font-size: 21px;
  color: #041e41;
  margin: 30px 163px 30px 30px;
`;

const Separator = styled(Box)`
  background-color: #d9dfeb;
  height: 1px;
  margin: 0px 30px 0px 30px;
`;

export interface RecentClaimData {
  claimNumber: string | null | undefined;
  lossLocation: string | null | undefined;
  dateOfLoss: string | null | undefined;
  status: string;
}

export interface RecentClaimsItemList {
  list: RecentClaimData[];
  changeSelected: Function;
  selected: string;
}

export default ({ list, changeSelected, selected }: RecentClaimsItemList) => {
  return (
    <OuterBox>
      <Box>
        <RecentClaimsText>Recent Claims</RecentClaimsText>
      </Box>

      {list.map(displayedItem => (
        <Box fill="horizontal">
          <Separator />
          <Box
            onClick={() => {
              simpleGTMDataLayer({
                event: 'click-claimsRecentClaim',
                claimNumber: displayedItem.claimNumber
              });
              changeSelected(displayedItem.claimNumber, displayedItem.status);
            }}
            className="recent-claims-item"
          >
            <RecentClaimsItem
              {...displayedItem}
              selected={displayedItem.claimNumber === selected}
            />
          </Box>
        </Box>
      ))}
    </OuterBox>
  );
};
