import React from 'react';
import { Box, Text } from 'grommet';
import ResponsiveGrid from '../responsiveGrid';
import { customBase } from '../../themes/default.theme';

const smallFont = '12px';
const infoFont = '16px';
const infoColor = customBase.global.colors.text.dark;

export interface PayeesProps {
  name: string | null;
}

export interface AmountProps {
  amount: number | null;
  currency: string | null;
}

export interface ClaimsLatestPaymentProps {
  payees: string[];
  paymentMethod: string;
  checkNumber: string;
  amount: string;
  issueDate: string;
}

export interface ClaimsLatestPayment {
  claimsSummaryPaymentsList?: ClaimsLatestPaymentProps[] | undefined;
}

export default ({ claimsSummaryPaymentsList }: ClaimsLatestPayment) => {
  return (
    <Box
      background="#FFFFFF"
      fill
      round="5px"
      pad={{ top: '30px', left: '30px' }}
    >
      <Text size="18px" weight="bold" margin={{ bottom: '15px' }}>
        Latest Payments
      </Text>
      {(claimsSummaryPaymentsList?.length) ? (
        <Box overflow={{ vertical: 'auto' }} margin={{ top: '15px' }}>
          {claimsSummaryPaymentsList?.map(
            (payment: ClaimsLatestPaymentProps | null) => {
              return (
                <Box flex={false}>
                  <Box
                    round="3px"
                    pad={{ vertical: '10px', left: '20px' }}
                    background="#F4F6FA"
                    direction="column"
                    margin={{ right: '17px' }}
                  >
                    <Box gap="5px" direction="row" align="start">
                      <Text weight="bold" size="14px">
                        Payee:
                      </Text>
                      <Box>
                        <Text size="14px">{payment?.payees[0]}</Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    background="#FFFFFF"
                    pad={{ left: '20px', top: '20px', bottom: '15px' }}
                  >
                    <ResponsiveGrid
                      fill
                      rows={['auto', 'auto', 'auto']}
                      columns={['1/3', '1/3', '1/3']}
                      areas={{
                        xsmall: [
                          { name: 'Grid1', start: [0, 0], end: [2, 0] },
                          { name: 'Grid2', start: [0, 1], end: [2, 1] },
                          { name: 'Grid3', start: [0, 2], end: [2, 2] }
                        ],
                        small: [
                          { name: 'Grid1', start: [0, 0], end: [2, 0] },
                          { name: 'Grid2', start: [0, 1], end: [2, 1] },
                          { name: 'Grid3', start: [0, 2], end: [2, 2] }
                        ],
                        medium: [
                          { name: 'Grid1', start: [0, 0], end: [2, 0] },
                          { name: 'Grid2', start: [0, 1], end: [2, 1] },
                          { name: 'Grid3', start: [0, 2], end: [2, 2] }
                        ],
                        large: [
                          { name: 'Grid1', start: [0, 0], end: [2, 0] },
                          { name: 'Grid2', start: [0, 1], end: [2, 1] },
                          { name: 'Grid3', start: [0, 2], end: [2, 2] }
                        ],
                        xlarge: [
                          { name: 'Grid1', start: [0, 0], end: [0, 0] },
                          { name: 'Grid2', start: [1, 0], end: [1, 0] },
                          { name: 'Grid3', start: [2, 0], end: [2, 0] }
                        ]
                      }}
                    >
                      <Box
                        gridArea="Grid1"
                        direction="column"
                        margin={{ bottom: '5px' }}
                      >
                        <Text
                          size={smallFont}
                          weight="bold"
                          margin={{ bottom: '5px' }}
                        >
                          Issued
                        </Text>
                        <Text color={infoColor} size={infoFont}>
                          {payment?.issueDate}
                        </Text>
                      </Box>
                      <Box gridArea="Grid2" margin={{ bottom: '5px' }}>
                        <Text
                          size={smallFont}
                          weight="bold"
                          margin={{ bottom: '5px' }}
                        >
                          {payment?.paymentMethod}
                        </Text>
                        <Text color={infoColor} size={infoFont}>
                          {payment?.checkNumber}
                        </Text>
                      </Box>
                      <Box gridArea="Grid3" margin={{ bottom: '5px' }}>
                        <Text
                          size={smallFont}
                          weight="bold"
                          margin={{ bottom: '5px' }}
                        >
                          Amount
                        </Text>
                        <Text color={infoColor} size={infoFont}>
                          {payment?.amount}
                        </Text>
                      </Box>
                    </ResponsiveGrid>
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <p>No payments found.</p>
      )}
    </Box>
  );
};
