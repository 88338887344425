import React, { useState, ReactElement } from 'react';
import moment, { Moment } from 'moment-timezone';

import { getMomentObj, today } from './DatePickerUtil';

import {
  Container,
  DatePickerHeader,
  Year,
  Date,
  DatePickerBody,
  DatePickerFooter,
  FooterButton
} from './DatePicker.styles';
import {
  ContainerFilter,
  DatePickerHeaderFilter,
  YearFilter,
  DateFilter,
  DatePickerBodyFilter,
  DatePickerFooterFilter,
  FooterButtonFilter
} from './DatePickerFilter.styles';

import DateView from './dateView/DateView';
import YearView from './yearView/YearView';

interface DatePickerProps {
  date?: string;
  setDate: Function;
  minDate?: string;
  maxDate?: string;
  onClickCancel?: Function | null;
  onClickOk?: Function | null;
  isFilter?: boolean | null;
}

const DatePicker = ({
  date = '',
  setDate,
  minDate,
  maxDate,
  onClickCancel,
  onClickOk,
  isFilter
}: DatePickerProps): ReactElement => {
  const passedDate = getMomentObj(date);
  const selectedDate: Moment = passedDate.isValid()
    ? passedDate
    : getMomentObj(today);

  const [currentMonthYear, setCurrentMonthYear] = useState<Moment>(
    moment(selectedDate).date(1)
  );

  const [dateSelectView, setDateSelectView] = useState<boolean>(true);

  const isCancelFunction: boolean = typeof onClickCancel === 'function';
  const isOkFunction: boolean = typeof onClickOk === 'function';

  let headerYearContent: number = 0;
  let headerMonthDateContent: string = '';

  if (passedDate.isValid()) {
    headerYearContent = selectedDate.year();
    headerMonthDateContent = selectedDate.format('ddd, MMM D');
  } else {
    headerYearContent = currentMonthYear.year();
    headerMonthDateContent = currentMonthYear.format('MMM');
  }

  const currentMonthView = () => {
    if (passedDate.isValid()) {
      setCurrentMonthYear(moment(selectedDate).date(1));
    }
    setDateSelectView(true);
  };

  const handleCancelClick = () => {
    if (typeof onClickCancel === 'function') {
      setDateSelectView(true);
      onClickCancel();
    }
  };

  const handleOkClick = () => {
    if (typeof onClickOk === 'function') {
      setDateSelectView(true);
      onClickOk();
    }
  };

  const handleYearChange = (year: number) => {
    setCurrentMonthYear(moment(currentMonthYear).year(year));
    setDateSelectView(true);
  };

  return (
    <>
      {isFilter ? (
        <ContainerFilter
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
          }}
        >
          <DatePickerHeaderFilter>
            <YearFilter onClick={() => setDateSelectView(false)}>
              {headerYearContent}
            </YearFilter>
            <DateFilter onClick={() => currentMonthView()}>
              {headerMonthDateContent}
            </DateFilter>
          </DatePickerHeaderFilter>
          <DatePickerBodyFilter>
            {dateSelectView ? (
              <DateView
                isPassedDateValid={passedDate.isValid()}
                selectedDate={selectedDate}
                setDate={(newDate: string) => setDate(newDate)}
                minDate={minDate}
                maxDate={maxDate}
                currentMonthYear={currentMonthYear}
                setCurrentMonthYear={setCurrentMonthYear}
                isFilter={isFilter}
              />
            ) : (
              <YearView
                selectedYear={currentMonthYear.year()}
                setCurrentYear={handleYearChange}
               isFilter={true}
              />
            )}
          </DatePickerBodyFilter>
          {(isCancelFunction || isOkFunction) && (
            <DatePickerFooterFilter>
              {isCancelFunction && (
                <FooterButtonFilter onClick={() => handleCancelClick()}>
                  CANCEL
                </FooterButtonFilter>
              )}
              {isOkFunction && (
                <FooterButtonFilter onClick={() => handleOkClick()}>OK</FooterButtonFilter>
              )}
            </DatePickerFooterFilter>
          )}
        </ContainerFilter>
      ) : (
        <Container
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
          }}
        >
          <DatePickerHeader>
            <Year onClick={() => setDateSelectView(false)}>
              {headerYearContent}
            </Year>
            <Date onClick={() => currentMonthView()}>
              {headerMonthDateContent}
            </Date>
          </DatePickerHeader>
          <DatePickerBody>
            {dateSelectView ? (
              <DateView
                isPassedDateValid={passedDate.isValid()}
                selectedDate={selectedDate}
                setDate={(newDate: string) => setDate(newDate)}
                minDate={minDate}
                maxDate={maxDate}
                currentMonthYear={currentMonthYear}
                setCurrentMonthYear={setCurrentMonthYear}
              />
            ) : (
              <YearView
                selectedYear={currentMonthYear.year()}
                setCurrentYear={handleYearChange}
              />
            )}
          </DatePickerBody>
          {(isCancelFunction || isOkFunction) && (
            <DatePickerFooter>
              {isCancelFunction && (
                <FooterButton onClick={() => handleCancelClick()}>
                  CANCEL
                </FooterButton>
              )}
              {isOkFunction && (
                <FooterButton onClick={() => handleOkClick()}>OK</FooterButton>
              )}
            </DatePickerFooter>
          )}
        </Container>
      )}
    </>
  );
};

DatePicker.defaultProps = {
  date: '',
  minDate: '',
  maxDate: '',
  onClickCancel: null,
  onClickOk: null,
  isFilter: null
};

export default DatePicker;
