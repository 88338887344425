import TagManager from 'react-gtm-module';

interface DataLayer {
  event_action: string;
  event_label: string;
  event_name_snowplow: string;
  event_category?: string;
  event_category_snowplow?: string;
  event_action_snowplow?: string;
  event_label_snowplow?: string;
}

export default (dataLayer: DataLayer) => {
  TagManager.dataLayer({
    dataLayer: {
      event_category: window.location.pathname,
      event_category_snowplow: `${window.location.pathname}/Snowplow`,
      event_action_snowplow: `Snowplow ${dataLayer.event_action}`,
      event_label_snowplow: `Snowplow ${dataLayer.event_label}`,
      ...dataLayer,
      event: 'generic_event'
    }
  });
};
