import { Box, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import { GET_PAYMENT_HISTORY_account_items_payment_history_items } from './graphql/__generated__/GET_PAYMENT_HISTORY';
import { SmallInfo } from '../../icons';
import { colors, customBase } from '../../themes/default.theme';
import { formatDate, formatMoney } from '../../util/formatters';

export interface PaymentHistoryRowsProps {
  payments?: (GET_PAYMENT_HISTORY_account_items_payment_history_items | null)[];
  responsive: any;
}

export const Data = styled.li`
  display: flex;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  background-color: #ffffff;
  word-break: break-word;
`;

const DataText = styled(Text)`
  font-size: 16px;
`;

export const HoverableRow = styled(Box)`
  border-left: 4px solid #ffffff;
  font-size: 16px;
  padding: 14px 0px 18px 30px;
  &:hover {
    border-left: 4px solid #9325b2;
  }
  background-color: #ffffff;
`;

const ToolTipWrapper = styled.div`
  height: 40px;
  position: absolute;
`;

const FailedPaymentToolTip = styled.span`
  display: none;
  background-color: #3e5572;
  width: 270px;
  height: 78px;
  color: white;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0px 4px 6px -2px rgb(0, 32, 56, 0.33);
  padding: 10px;
  bottom: 100px;
  right: 112px;
`;

const StatusRow = styled(Data)`
  flex-direction: column;

  &:hover ${FailedPaymentToolTip} {
    display: block;
  }
`;

interface StatusTextProps {
  status: string | null | undefined;
}

const StatusText = styled(DataText)<StatusTextProps>`
  color: ${props =>
    (props.status === 'Failed' || props.status === 'Refund Rejected') &&
    customBase.global.colors.brand};
`;

const ToolTipHeader = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
`;

const ToolTipBody = styled(Text)`
  font-size: 12px;
  padding: 0 10px 0 24px;
  line-height: 18px;
`;

const Rows = styled(Box)`
  responsive: true;
  overflow: auto;

  & ${HoverableRow}:not(:last-child) {
    border-bottom: 1px solid #d9dfeb;
  }

  & ${HoverableRow}:first-child {
    margin-top: 10px;
  }
`;

const AmountPaidCredit = styled(DataText)`
  color: ${colors.valueRed1};
`;

export default ({ payments, responsive }: PaymentHistoryRowsProps) => (
  <Rows
    fill={
      responsive === 'small' || responsive === 'medium' ? false : 'horizontal'
    }
  >
    {payments?.map(data => (
      <HoverableRow key={data?.id || undefined} direction="row">
        <Data>
          <DataText>{formatDate(data?.date)}</DataText>
        </Data>
        <Data>
          <DataText>{data?.method}</DataText>
        </Data>
        <StatusRow>
          {(data?.status === 'Failed' ||
            data?.status === 'Refund Rejected') && (
            <ToolTipWrapper>
              <FailedPaymentToolTip>
                <Box>
                  <Box direction="row">
                    <SmallInfo />
                    <ToolTipHeader>Reason for Payment Failure</ToolTipHeader>
                  </Box>
                  <ToolTipBody>{data?.failed_reason}</ToolTipBody>
                </Box>
              </FailedPaymentToolTip>
            </ToolTipWrapper>
          )}
          <StatusText status={data?.status}>{data?.status}</StatusText>
        </StatusRow>
        <Data>
          {data?.amount_paid?.toString()[0] === '-' ||
          data?.status === 'Refund Rejected' ? (
            <AmountPaidCredit>
              {formatMoney(data?.amount_paid)}
            </AmountPaidCredit>
          ) : (
            <DataText>{formatMoney(data?.amount_paid)}</DataText>
          )}
        </Data>
      </HoverableRow>
    ))}
  </Rows>
);
