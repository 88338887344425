import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import {
  GET_QUOTE_PAGE_TABLE_QUOTESVariables,
  GET_QUOTE_PAGE_TABLE_QUOTES,
  GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes
} from './graphql/__generated__/GET_QUOTE_PAGE_TABLE_QUOTES';
import AccountListAndQuotesTableUI from '../accountListAndQuotesTableUI';
import { CardLayout } from '../cardLayout/CardLayout';
import redirectOnQuotesClick from '../../util/redirectOnQuotesClick';

const get_table_quotes = loader(
  './graphql/Get_Quote_Page_Table_Quotes.graphql'
);

const get_quote_search_selection_behavior = loader(
  './graphql/Get_Quote_Page_Table_Search_Selection_Behavior.graphql'
);

export default () => {
  const { quoteNumber } = useParams();

  const variables: GET_QUOTE_PAGE_TABLE_QUOTESVariables = {
    quote_number: quoteNumber
  };

  const { data, loading, error } = useQuery<
    GET_QUOTE_PAGE_TABLE_QUOTES,
    GET_QUOTE_PAGE_TABLE_QUOTESVariables
  >(get_table_quotes, {
    variables
  });

  const [redirectOnQuoteClickAction] = useLazyQuery(
    get_quote_search_selection_behavior,
    {
      onCompleted: redirectOnQuotesClick
    }
  );

  const policiesAndQuotes: GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes[] =
    data?.quote?.account?.overviewPoliciesAndQuotes?.filter(
      (
        policyOrQuote: GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes | null
      ): policyOrQuote is GET_QUOTE_PAGE_TABLE_QUOTES_quote_account_overviewPoliciesAndQuotes =>
        !!policyOrQuote
    ) ?? [];

  if (!policiesAndQuotes.length) return <></>;

  return (
    <CardLayout title="Policy Information">
      <AccountListAndQuotesTableUI
        isLoading={loading}
        error={error}
        quoteNumber={quoteNumber}
        policiesAndQuotes={policiesAndQuotes}
        redirectOnQuoteClickAction={redirectOnQuoteClickAction}
      />
    </CardLayout>
  );
};
