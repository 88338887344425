import React, { useState, useEffect, useRef } from 'react';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import {
  EmptyCheckBox,
  CheckedCheckBox,
  BlackCross,
  ChevronDownUnbolded,
  ChevronUp
} from '../../icons';
import { formatAddress } from './AdvancedFilter';
import {
  InputContainer,
  Icon,
  OptionText,
  SelectedItem,
  DropdownContainer,
  DropdownOption,
  DropdownOptions,
  CustomInput,
  BorderLabelLocation
} from './AdvancedFilterStyles';

interface LocationDropDownProps {
  claimsList: ClaimsData[] | null;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

const LocationDropDown = ({
  claimsList,
  setSelectedOptions,
  selectedOptions
}: LocationDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOptions(prevSelectedOptions => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter(
          selectedOption => selectedOption !== option
        );
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };
  const handleClose = (option: string) => {
    setSelectedOptions(prevSelectedOptions => {
      return prevSelectedOptions.filter(
        selectedOption => selectedOption !== option
      );
    });
  };

  let options = null;
  if (claimsList) {
    const uniqueOptions = new Set<string>();

    options = Object.values(claimsList).map((claim, index) => {
      const {
        losslocationaddressline1,
        losslocationcity,
        losslocationstate,
        losslocationzip
      } = claim;
      const optionValue = formatAddress(
        losslocationaddressline1,
        losslocationcity,
        losslocationstate,
        losslocationzip
      );
      if (!uniqueOptions.has(optionValue)) {
        uniqueOptions.add(optionValue);
        const isChecked = selectedOptions.includes(optionValue);

        return (
          <DropdownOption
            key={index}
            className={isChecked ? 'selected' : ''}
            onClick={() => handleOptionClick(optionValue)}
          >
            {isChecked ? <CheckedCheckBox /> : <EmptyCheckBox />}
            <OptionText>{optionValue}</OptionText>
          </DropdownOption>
        );
      }

      return null;
    });
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <InputContainer>
        <CustomInput className={isOpen ? 'open' : ''} onClick={toggleDropdown}>
          {selectedOptions.map(option => (
            <SelectedItem>
              {option}
              <span onClick={() => handleClose(option)}>
                <BlackCross />
              </span>
            </SelectedItem>
          ))}
        </CustomInput>

        <Icon>{isOpen ? <ChevronUp /> : <ChevronDownUnbolded />}</Icon>
        <BorderLabelLocation>Select Loss Location</BorderLabelLocation>
      </InputContainer>
      {isOpen && <DropdownOptions>{options}</DropdownOptions>}
    </DropdownContainer>
  );
};

export default LocationDropDown;
