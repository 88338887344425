import React from 'react';
import {
  DescriptionBuffer,
  Description,
  BoldedDescription
} from './changeDueDateStyles';

interface DescriptionProps {
  confirmation: boolean;
}

export default ({ confirmation }: DescriptionProps) => {
  return (
    <div>
      {confirmation ? (
        <DescriptionBuffer>
          <Description>Please confirm this is correct as you are </Description>
          <BoldedDescription>
            only able to change your payment due date once every 365 days.
          </BoldedDescription>
        </DescriptionBuffer>
      ) : (
        <DescriptionBuffer>
          <Description>
            Select the day you would like as your new payment due date, please
            note you may only change your payment due date once a year.
          </Description>
        </DescriptionBuffer>
      )}
    </div>
  );
};
