import React from 'react';
import styled from 'styled-components';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { SmallInfo } from '../../icons';
import { colors } from '../../themes/default.theme';

interface styledButtonProps {
  disabled: boolean;
}

const ToolTip = styled.span`
  display: flex;
  flex-direction: row;
  gap: 5px;
  visibility: hidden;
  background-color: ${colors.darkGray1};
  color: #ffffff;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  border-radius: 3px;
  box-shadow: 0px 4px 6px -2px #002038;
  padding: 5px;
`;
const ToolTipContainer = styled.div`
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

const Button = styled.button<styledButtonProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: #ffffff;
  color: ${props => (props.disabled ? '#bdbdbd' : colors.darkBlue2)};
  border: 1px solid;
  padding: 7px 10px;
  border-radius: 3px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: bold;
`;

interface cancelPaymentModalButtonProps {
  allowCancelPayment: boolean;
  toggleShowModal: Function;
  toolTipText: string;
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
}

export default ({
  allowCancelPayment,
  toggleShowModal,
  toolTipText,
  readOnlyAccess,
  readOnlyFeatureFlag
}: cancelPaymentModalButtonProps) => (
  <ToolTipContainer>
    {toolTipText ? (
      <ToolTip>
        <SmallInfo />
        {toolTipText}
      </ToolTip>
    ) : (
      <div />
    )}
    <Button
      disabled={
        !allowCancelPayment ||
        ((readOnlyAccess && readOnlyFeatureFlag) as boolean)
      }
      type="button"
      onClick={() => {
        toggleShowModal();
        simpleGTMDataLayer({
          event: 'cancelPaymentClick',
          event_action: 'Button Click',
          event_category: window.location.pathname,
          event_label: 'Open Stop Payment Modal'
        });
      }}
      test-attr="cancel-payment-button"
    >
      STOP PAYMENT
    </Button>
  </ToolTipContainer>
);
