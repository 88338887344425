import styled, { keyframes } from 'styled-components';

export const Modal = styled.div`
  z-index: 2;
  display: inline;
  position: absolute;
  margin: auto;
  align-self: center;
`;

export const SuccessContainer = styled.div`
  display: flex;
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  justify-content: center;
`;

export const ChangeDueDateContainer = styled.div`
  display: inline;
  flex-direction: row;
  z-index: 1;
  left: 0;
  top: 0;
  margin: auto;
  background-color: #ffffff;
  position: relative;
`;

export const ChangeDueDate = styled.div`
  width: 460px;

  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 3px;
  align-content: center;
  justify-content: space-between;
  padding: 30px;
  position: relative;
  box-shadow: 0px 8px 32px -8px rgba(0, 32, 56, 0.33);
`;

export const DateTextBox = styled.div`
  border: 1px solid #189ecc;
  border-radius: 3px;
  background: #e2f5f9;
  display: flex;
  flex-direction: row;
  padding: 12px 28px 12px 20px;
  font-size: 16px;
`;
export const BoldedDateText = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

export const MessageTextBox = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff6e9;
  border: 1px solid #f5a524;
  border-radius: 3px;
  padding: 12px 16px 12px 16px;
  color: #772f1d;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const closing = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const persisting = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 1;
}
`;
export const Unavailable = styled.div`
  display: flex;
  flex-direction: row;
  width: 578px;
  height: 88px;
  border: 1px solid #b5e6f0;
  border-radius: 5px;
  background: #e2f5f9;
  border-left: 5px solid #189ecc;
  color: #005c86;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
`;
export const Success = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 495px;
  height: 74px;
  border: 1px solid #deedc0;
  border-radius: 5px;
  background: #f2f8e6;
  border-left: 5px solid #65a100;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  align-items: center;
  color: #256c00;
  padding-left: 26px;
  font-size: 14px;
`;

export const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  min-width: 300px;
  height: 225px;
  padding: 32px 0px 32px 0px;
`;

export const DateButton = styled.div`
  font-size: 16px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 7px;
  padding-top: 5px;
`;

export const CurrentDate = styled.div`
  background: #d9dfeb;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
`;

export const DoubleDigit = styled.div`
  padding-left: 1px;
  padding-right: 1px;
`;

export const SingleDate = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

export const NewDate = styled.div`
  background: #0173c6;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 5px;
`;

export const UnavailableDateButton = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3b3b;
  opacity: 0.5;
  margin-bottom: 7px;
  padding-top: 5px;
`;

export const Header = styled.div`
  color: #041e41;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 6px;
`;

export const Description = styled.span`
  color: #041e41;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
export const BoldedDescription = styled.span`
  color: #041e41;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
export const DescriptionBuffer = styled.div`
  padding-bottom: 16px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 30px;
  vertical-align: middle;
`;

export const CancelAnchor = styled.div`
  font-size: 14px;
  font-weight: 700;
  justify: end;
  align: end;
  text-align: right;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
  height: 45px;
  padding-top: 12px;
  width: 157px;
`;

export const ConfirmationButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  background-color: #65a100;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #65a100;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
  }
  height: 45px;
  width: 157px;
`;

export const InfoIconSizing = styled.div`
  width: 15px;
  padding-right: 15px;
`;
export const CloseIconSizing = styled.div`
  width: 15px;
  padding-right: 15px;
  &:hover {
    cursor: pointer;
  }
`;
export const WarningIconSizing = styled.div`
  width: 50px;
  padding-right: 9px;
`;
export const SuccessIconSizing = styled.div`
  width: 15px;
  padding-right: 22px;
`;
