import React from 'react';
import BlueCross from '../assets/images/BlueCross.svg';

interface BlueCrossProps {
  width: string;
  height: string;
}

const BlueCrossIcon: React.FC<BlueCrossProps> = ({ width, height }) => {
  return <img src={BlueCross} alt="" width={width} height={height} />;
};

export default BlueCrossIcon;
