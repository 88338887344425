import React from 'react';
import styled from 'styled-components';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import WcClaimsItem from '../wcClaimsItem/wcClaimsItem';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemsWrapper = styled.div`
  width: 100%;
  height: 470px;
  overflow: auto;
`;

export interface WcClaimsListProps {
  selectClaimHandler: Function;
  selectedClaim: string | boolean;
  claimsList: ClaimsData[];
}

export default ({
  selectClaimHandler,
  selectedClaim,
  claimsList
}: WcClaimsListProps) => {
  return (
    <MainWrapper>
      {claimsList && (
        <ItemsWrapper>
          {claimsList.map((item: ClaimsData) => {
            return (
              <WcClaimsItem
                key={item.claimnumber}
                claim={item}
                selectClaimHandler={selectClaimHandler}
                selectedClaim={selectedClaim}
              />
            );
          })}
        </ItemsWrapper>
      )}
    </MainWrapper>
  );
};
