import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import BillingPayPlans, {
  paymentPlanData,
  PayPlansCollection
} from './BillingPayPlans';
import { GET_BILLING_PAY_PLAN } from './graphql/__generated__/GET_BILLING_PAY_PLAN';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import determineAutoPayments from '../../util/determineAutomaticPayments';

const get_billing_payplan_info = loader(
  './graphql/Get_Billing_Pay_Plan.graphql'
);

const get_policies_by_account = loader(
  './graphql/Get_Policies_by_Account.graphql'
);
let processedPayPlans: any[] | paymentPlanData | null = [];

export function checkIfCurrentDated(combinedPayPlans: any[]) {
  const today: Number = new Date().getTime();
  for (let i = 0; i < combinedPayPlans.length; i += 1) {
    if (combinedPayPlans[i].effective_date) {
      if (new Date(combinedPayPlans[i].effective_date).getTime() < today) {
        return true;
      }
    }
  }
  return false;
}

const transformData = (data: GET_BILLING_PAY_PLAN) => {
  const payPlanInfoAccountItems = data?.account?.items;
  let policyNumber: string | null | undefined = '-';
  let defaultPaymentMethod1: string | null | undefined = '-';

  if (payPlanInfoAccountItems && payPlanInfoAccountItems.length) {
    const payPlanInfoArr = payPlanInfoAccountItems[0]?.payplans?.items;
    const billingSummaryArr =
      payPlanInfoAccountItems[0]?.billing_summary?.items;

    if (payPlanInfoArr && billingSummaryArr && payPlanInfoArr.length > 0) {
      let payPlansCollection:
        | PayPlansCollection[]
        | paymentPlanData
        | null
        | any = [];

      let currentTermPayplans: any[] = [];

      if (checkIfCurrentDated(billingSummaryArr)) {
        currentTermPayplans = billingSummaryArr.filter(summary => {
          return (
            summary?.expiration_date &&
            summary?.expiration_date > new Date().toISOString().slice(0, 10)
          );
        });
      }

      currentTermPayplans.forEach(summary => {
        const { paid, total_value } = summary;
        const matchedByPolicy = payPlanInfoArr.find(plan =>
          summary?.policy_number
            ?.substring(0, summary?.policy_number?.length - 2)
            .includes(plan?.policy?.policy_number as string)
        );
        if (
          matchedByPolicy?.policy?.status?.toLowerCase() !== 'cancelled' &&
          matchedByPolicy?.policy?.status?.toLowerCase() !== 'expired'
        ) {
          const matchedByID = payPlanInfoArr.find(
            plan =>
              summary?.policy_period_id === (plan?.policy_period_id as string)
          );

          defaultPaymentMethod1 = matchedByPolicy?.default_payment_method;
          policyNumber = summary?.policy_number?.substring(
            0,
            summary?.policy_number?.length - 2
          );

          let remaining_balance: number = 0;
          if (summary && summary.paid && summary.total_value) {
            remaining_balance =
              parseFloat(summary?.total_value) - parseFloat(summary?.paid);
          }

          payPlansCollection.push({
            ...matchedByID,
            paid,
            total_value,
            remaining_balance
          });
          payPlansCollection = payPlansCollection.flat();
        }
      });

      processedPayPlans = { payPlans: payPlansCollection };
    }
  }
};

const accountType = (data: paymentPlanData) => {
  let title = 'Bank Account';
  const uniqueAccountTypes = Array.from(
    new Set(data?.payPlans!.map(item => item?.payment_method_type))
  );
  let i = 0;
  let check = false;
  while (!check && i < uniqueAccountTypes.length) {
    if (uniqueAccountTypes[i] !== null && uniqueAccountTypes[i] !== undefined) {
      check = true;
      if (uniqueAccountTypes[i] === 'CREDITCARD') {
        title = 'Credit Card';
      }
    }
    i += 1;
  }
  return title;
};

const messageFormat = (input: string) => {
  let message = '';
  const index = input.indexOf('.');
  const type = input.slice(0, index);
  const numbers = input.slice(input.length - 4, input.length);
  message = `${type}...${numbers}`;
  return message;
};

const defaultPaymentMethod = (data: paymentPlanData) => {
  let message = 'No default payment method';
  const uniquePaymentMethods = Array.from(
    new Set(data?.payPlans?.map(item => item?.default_payment_method))
  );

  let i = 0;
  let check = false;
  while (!check && i < uniquePaymentMethods?.length) {
    if (
      uniquePaymentMethods[i] &&
      uniquePaymentMethods[i] !== 'RESPONSIVE' &&
      data?.payPlans &&
      data?.payPlans[i]?.policy
    ) {
      check = true;
      const temp = uniquePaymentMethods[i]!;
      message = messageFormat(temp);
    }
    i += 1;
  }
  return message;
};

const onPolicyNumberClick = (policyNumber: string | null) => {
  simpleGTMDataLayer({
    event: 'click-policyNumberLinkPayPlans',
    page: 'Policy',
    policyNumber
  });
};

export default ({ payPlanDetailsFlag }: { payPlanDetailsFlag: boolean }) => {
  const { accountNumber } = useParams();

  const variables = {
    account_number: accountNumber
  };
  const queryResult = useQuery(get_billing_payplan_info, {
    variables
  });
  const policyResult = useQuery(get_policies_by_account, { variables });
  const { data: policyData } = policyResult;
  let policyExist = false;
  if (
    policyData?.account?.items &&
    policyData?.account?.items[0]?.policies?.items?.length
  ) {
    policyExist = true;
  }

  const { loading, error } = queryResult;
  let { data } = queryResult;
  let automaticPaymentsStatus = null;

  if (data?.account?.items && policyExist) {
    automaticPaymentsStatus = determineAutoPayments(data);
    transformData(data);
  }

  let method = 'No default payment method';
  let title = '';

  if (processedPayPlans) {
    data = processedPayPlans;
  }

  if (data?.payPlans && policyExist) {
    method = defaultPaymentMethod(data);
  }

  if (method !== 'No default payment method') {
    title = accountType(data);
  }

  const onClick = (policyNumber: string) => {
    simpleGTMDataLayer({
      event: 'click-policyNumberLinkBilling',
      page: 'Policy',
      policyNumber
    });
  };

  return (
    <BillingPayPlans
      payPlanDetailsFlag={payPlanDetailsFlag}
      onClick={onClick}
      loading={loading}
      error={error}
      plansData={data}
      method={method}
      title={title}
      automaticPaymentStatus={automaticPaymentsStatus}
      policyPresent={policyExist}
      onPolicyNumberClick={onPolicyNumberClick}
    />
  );
};
