import React, { useState } from 'react';
import {
  OctoBannerContainer,
  Octobanner,
  ColorStrip,
  InfoWrap,
  BannerTextWrap,
  BannerTitle,
  BannerDescription,
  BannerLinksWrap,
  BannerLinkDivider,
  BannerLink,
  CloseWrap
} from './OctoBannerStyles';
import { AltInfo, AltClose as Close } from '../../icons';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { UPDATE_OCTO_BANNER_specialEventTracking } from './graphql/__generated__/UPDATE_OCTO_BANNER';

export interface OctoBannerProps {
  octoPresent: Boolean;
  hasBeenSeen?: Boolean;
  updateBannerStatus: Function;
  updateStatusResult?: UPDATE_OCTO_BANNER_specialEventTracking;
  accountNumber: String;
}

export default ({
  octoPresent,
  hasBeenSeen,
  updateBannerStatus,
  updateStatusResult,
  accountNumber
}: OctoBannerProps) => {
  const [modalClosed, setModalClosed] = useState<boolean>(false);
  const handleClose = () => {
    simpleGTMDataLayer({
      event: 'click-OCTOBannerClose',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_category_snowplow: `${window.location.pathname}/Snowplow`,
      event_label: 'OCTO Banner Close'
    });
    updateBannerStatus({
      variables: {
        account_number: accountNumber,
        field_name: 'agency360_octo_modal'
      }
    });
    setModalClosed(true);
  };

  const handleRegister = () => {
    simpleGTMDataLayer({
      event: 'click-OCTOBannerRegister',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_category_snowplow: `${window.location.pathname}/Snowplow`,
      event_label: 'OCTO Banner Register'
    });
    updateBannerStatus({
      variables: {
        account_number: accountNumber,
        field_name: 'agency360_octo_modal'
      }
    });
    setModalClosed(true);
  };

  const handleInfo = () => {
    simpleGTMDataLayer({
      event: 'click-OCTOBannerInfo',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_category_snowplow: `${window.location.pathname}/Snowplow`,
      event_label: 'OCTO Banner Info'
    });
  };

  if (
    !octoPresent ||
    hasBeenSeen ||
    updateStatusResult?.response === 'Success' ||
    modalClosed
  ) {
    return <div />;
  }
  return (
    <OctoBannerContainer>
      <Octobanner>
        <ColorStrip />
        <InfoWrap>
          <AltInfo />
        </InfoWrap>
        <BannerTextWrap>
          <BannerTitle>Switch to the Tag</BannerTitle>
          <BannerDescription>
            We&#39;ll discontinue support for the Dongle device beginning
            January 1, 2022.
          </BannerDescription>
        </BannerTextWrap>
        <BannerLinksWrap>
          <BannerLink
            target="_blank"
            href="http://stateauto.com/Safety360"
            onClick={() => handleInfo()}
          >
            LEARN MORE
          </BannerLink>
          <BannerLinkDivider>|</BannerLinkDivider>
          <BannerLink
            target="_blank"
            href="http://stateauto.com/switch"
            onClick={() => handleRegister()}
          >
            REGISTER NOW
          </BannerLink>
        </BannerLinksWrap>
        <CloseWrap onClick={() => handleClose()}>
          <Close />
        </CloseWrap>
      </Octobanner>
    </OctoBannerContainer>
  );
};
