import React, { useState } from 'react';
import {
  HeadingContainer,
  IconContainer,
  TitleContainer,
  DateContainerText,
  DateWrapper,
  DateContainer,
  ItemContainer,
  ItemWrapper,
  TableWrapper,
  StyledTable,
} from './PaymentsStyles';
import {
  PAYMENT_DETAILS_items
} from './graphql/Get_WC_CLAIM_PAYMENTS';
import {
    ChevronDownUnbolded,
    ChevronUp,
  } from '../../../icons';
export interface PayeeDetailsProps {
  pay: any;
}

const PayeeDetails: React.FC<PayeeDetailsProps> = ({
  pay
}: PayeeDetailsProps) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  const handleCollapseClick: React.MouseEventHandler = () => {
    setIsCollapse(!isCollapse);
  };
  return (
    <>
      <ItemContainer key={pay.name}>
        <TitleContainer>
          <ItemWrapper>
            <HeadingContainer>{pay.name}</HeadingContainer>
            <DateWrapper>
              <DateContainer>
                <DateContainerText>Charged To Date:</DateContainerText>
                <DateContainerText>$ {pay.chargedtodate}</DateContainerText>
              </DateContainer>
              <DateContainer>
                <DateContainerText>Paid To Date:</DateContainerText>
                <DateContainerText>$ {pay.paidtodate}</DateContainerText>
              </DateContainer>
            </DateWrapper>
            {isCollapse ? (
              <IconContainer onClick={handleCollapseClick}>
                <ChevronDownUnbolded />
              </IconContainer>
            ) : (
              <IconContainer onClick={handleCollapseClick}>
                <ChevronUp />
              </IconContainer>
            )}
          </ItemWrapper>
          {!isCollapse && (
            <TableWrapper>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>From Date</th>
                    <th>Thru Date</th>
                    <th>Check #</th>
                    <th>Check Date</th>
                    <th>Charged</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {pay.paymentDetails &&
                    pay.paymentDetails.map((pav: PAYMENT_DETAILS_items) => (
                      <tr key={pav.checknumber}>
                        <td>{pav.paymentstatus}</td>
                        <td>{pav.service_fromdate}</td>
                        <td>{pav.service_thrudate}</td>
                        <td>{pav.checknumber}</td>
                        <td>{pav.checkdate}</td>
                        <td>$ {pav.chargedamount}</td>
                        <td>$ {pav.paidamount}</td>
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            </TableWrapper>
          )}
        </TitleContainer>
      </ItemContainer>
    </>
  );
};

export default PayeeDetails;
