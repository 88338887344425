import React from 'react';
import TrashCan from '../assets/images/TrashCan.svg';
import TrashCanBlue from '../assets/images/TrashCanBlue.svg';

interface Props {
  isBlue: boolean;
}

const TrashCanComponent: React.FC<Props> = ({ isBlue }) => {
  return <img src={isBlue ? TrashCanBlue : TrashCan} alt="Trash Can" />;
};

export default TrashCanComponent;
