import React, { useState, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { loader } from 'graphql.macro';

import ClaimsPageLossRunView from './claimsPage';
import LossRunModal from './lossRunModal';
import { RequestButtonForPolicy } from './lossRunRequestButton';

const get_system_code = loader('./graphql/Get_Account_System_Code.graphql');

const generate_loss_run_report = loader(
  './graphql/Generate_Loss_Run_Report.graphql'
);

export default ({ screenPath }: { screenPath: string }) => {
  const [canRequest, setCanRequest] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

  const { accountNumber, policyNumber } = useParams();

  const variables = {
    account_number: accountNumber
  };
  const { data: accountData } = useQuery(get_system_code, { variables });

  const [generateLossRunReport] = useMutation(generate_loss_run_report);

  useEffect(() => {
    setCanRequest(
      accountData?.account?.items[0]?.source_system_code === 'GWCL'
    );
  }, [accountData]);

  const submit = (policy: string) => {
    setDisabled(true);
    const input = {
      accountNumber,
      policyNumber: policy
    };
    generateLossRunReport({ variables: input })
      .then(res => {
        if (res?.data?.lossRunReport?.reportGenerated) {
          setSubmitSuccess(true);
        } else {
          setSubmitError(true);
        }
      })
      .catch((error: Error) => {
        setSubmitError(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  if (!canRequest) {
    return <></>;
  }

  if (screenPath === 'claims') {
    return (
      <ClaimsPageLossRunView
        accountNumber={accountNumber}
        disabled={disabled}
        submit={submit}
        submitError={submitError}
        submitSuccess={submitSuccess}
        setSubmitError={setSubmitError}
        setSubmitSuccess={setSubmitSuccess}
      />
    );
  }

  if (screenPath === 'policy') {
    return (
      <>
        <RequestButtonForPolicy
          onClick={() => submit(policyNumber)}
          disabled={disabled}
        />
        {(submitError || submitSuccess) && (
          <LossRunModal
            disabled={false}
            options={[]}
            close={() => {
              if (submitError) setSubmitError(false);
              if (submitSuccess) setSubmitSuccess(false);
            }}
            submit={submit}
            submitError={submitError}
            submitSuccess={submitSuccess}
          />
        )}
      </>
    );
  }

  return <></>;
};
