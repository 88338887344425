import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Box, Text, Button } from 'grommet';
import { isIE } from 'react-device-detect';
import getConfig, { Env } from '../../config';
import { Modal } from '../../components';
import openHTML from '../openHTML';
import b64toBlobURL from '../createBase64BlobURL';
import { simpleGTMDataLayer } from '../GTMHelpers';
import Loading from '../../assets/images/Loading.svg';

export interface fetchDocumentProps extends Headers {
  document_key?: string;
  communication_id?: string;
  invoice_number?: string;
  policy_number?: string;
  snapshot_id?: string;
  gtm_data?: Object;
}

const StyledModal = styled(Box)`
  padding: 15px;
  width: 650px;
  height: 401px;
  text-align: center;
`;

const StyledBox = styled(Box)`
  padding: 15px;
`;

const StyledFooter = styled(Box)`
  align-items: flex-end;
  padding: 10px;
  margin-top: auto;
`;

const StyledCloseButton = styled(Button)`
  color: #000;
  background: #fff;
  font-size: 1.125rem;
  width: 110px;
`;

const StyledText = styled(Text)`
  font-size: 12pt;
  padding: 20px 20px;
  margin-right: 20px;
`;

const config = getConfig(process.env.REACT_APP_ENV as Env);

export default (
  account_number: string,
  document_info_type:
    | 'document'
    | 'communication'
    | 'invoice'
    | 'policy'
    | 'snapshot',
  gtm_event: string
) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoadingMsg, setLoadingMsg] = useState(true);
  const [documentError, setDocumentError] = useState<null | Error>(null);

  const closeModal = () => {
    setDocumentError(null);
    setLoadingMsg(true);
    setShowModal(false);
  };

  const handleError = (error: Error, newWindow: Window) => {
    newWindow.close();
    setDocumentError(error);
  };

  const download = (contents: string, newWindow: Window) => {
    setTimeout(() => {
      const newTab = newWindow.document.body.appendChild(
        newWindow.document.createElement('iframe')
      );
      newTab.width = '100%';
      newTab.height = '100%';
      newTab.setAttribute('style', 'border: 0');
      newTab.src = contents;
      closeModal();
    }, 0);
  };

  const fetchDocument = async (
    variables: fetchDocumentProps,
    newWindow: Window
  ) => {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const response = await fetch(
      `${config.api_gateway_url}/document/download`,
      {
        cache: 'no-cache',
        method: 'POST',
        headers: {
          Authorization: jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          document_info_type,
          account_number,
          ...variables
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch document');
    }
    const documentData = await response.json();
    let fileType = 'htm';
    fileType = documentData.filetype;
    if (fileType === 'error') {
      throw new Error('Invalid file type');
    } else {
      let source;
      switch (fileType) {
        case 'pdf':
          source = b64toBlobURL(documentData.contents, 'application/pdf');
          download(source, newWindow);
          break;
        case 'htm':
        case 'html':
          openHTML(documentData.contents, newWindow);
          closeModal();
          break;
        default:
          throw new Error(`Invalid file type: ${fileType}`);
      }
    }
  };

  const onDocumentClick = (fetchDocumentProps: fetchDocumentProps) => {
    if (!isIE) {
      const newWindow = window.open('about:blank');

      const { gtm_data } = fetchDocumentProps;
      let dataLayer;
      if (gtm_data) {
        dataLayer = {
          event: gtm_event,
          ...gtm_data
        };
      } else {
        dataLayer = {
          event: gtm_event
        };
      }
      simpleGTMDataLayer(dataLayer);
      setDocumentError(null);
      setShowModal(true);
      const fetchVariables = fetchDocumentProps;
      delete fetchVariables.gtm_data;
      if (newWindow) {
        fetchDocument(fetchVariables, newWindow).catch(fetchError => {
          handleError(fetchError, newWindow);
        });
      } else {
        setDocumentError(new Error('Failed to open window'));
      }
    } else {
      setShowModal(true);
    }
  };

  const documentModal = (
    <Modal showModal={showModal} onEsc={closeModal} onClickOutside={closeModal}>
      <StyledModal>
        {!documentError && !isIE && (
          <StyledBox>
            {showLoadingMsg && (
              <img alt="Loading" src={Loading} width="auto" height="100px" />
            )}
            <Box>{showLoadingMsg && 'Preparing your document...'}</Box>
          </StyledBox>
        )}

        {documentError && (
          <StyledBox>
            <Text>There was an error fetching the requested document.</Text>
            <Text>Close the modal and try downloading again.</Text>
          </StyledBox>
        )}

        {isIE && (
          <Box>
            <StyledText>
              Downloading documents is currently not supported in Internet
              Explorer.
              <br />
              Please use a more current browser such a Microsoft Edge, Google
              Chrome or Firefox.
            </StyledText>
          </Box>
        )}

        {(documentError || isIE) && (
          <StyledFooter>
            <StyledCloseButton label="CLOSE" onClick={closeModal} />
          </StyledFooter>
        )}
      </StyledModal>
    </Modal>
  );
  return {
    onDocumentClick,
    documentModal
  };
};
