import styled from 'styled-components';

const HamBurgerMenu = styled.img`
  display: block;
  cursor: pointer;
  padding: 12px;
  border-radius: 4px;
  background-color: #ffffff00;
  margin-left: 32px;
  width: 30px;
  height: auto;
  transition: all 0.2s;

  :hover {
    background-color: #ffffff2d;
  }
`;

export default HamBurgerMenu;
