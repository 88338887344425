import React from 'react';
import CheckBox from '../assets/images/CheckedCheckBox.svg';
interface CheckedProps {
  width?: number;
  height?: number;
}
const CheckedCheckBox: React.FC<CheckedProps> = ({
  width = 30,
  height = 30
}) => {
  return <img src={CheckBox} width={width} height={height} alt="" />;
};

export default CheckedCheckBox;
