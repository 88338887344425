import React, { useState } from 'react';
import {
  ItemWrapper,
  ItemLeftWrapper,
  ItemRightWrapper,
  ClaimNumberBox,
  ClaimNumberText,
  InsuredNameBox,
  InsuredNameTextLeft,
  InsuredNameTextRight,
  DateOfInjuryBox,
  DateOfInjuryTextLeft,
  DateOfInjuryTextRight,
  InjuryDetailsBox,
  InjuryDetailsTextLeft,
  InjuryDetailsTextRight,
  LossLocationBox,
  LossLocationTextLeft,
  LossLocationTextRight,
  ClaimStatusBox,
  ClaimStatusTextLeft,
  ClaimStatusCloseTextRight,
  ClaimStatusOpenTextRight,
  ItemRightIconWrapper,
  SelectedClaim,
  ItemRightWrapperSelected
} from './wcClaimsItemStyles';
import { ChevronDownUnbolded, ChevronUp } from '../../icons';
import { formatDate } from '../../util/formatters';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';

export interface WcClaimsListItemProps {
  key: string | undefined;
  claim: ClaimsData;
  selectClaimHandler: Function;
  selectedClaim: string | boolean;
  
}

export default ({
  claim,
  selectedClaim,
  selectClaimHandler,
  
}: WcClaimsListItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <ItemWrapper>
      <ItemLeftWrapper onClick={() =>
                selectClaimHandler(claim.claimnumber, claim.policynumber,claim?.hideClaimDetails)
              } >
        {isCollapsed ? (
          <>
            {selectedClaim === claim.claimnumber ? (
              <SelectedClaim  >
                <ClaimNumberBox
                 
                >
                  <ClaimNumberText>{claim.claimnumber}</ClaimNumberText>
                </ClaimNumberBox>
                <InsuredNameBox>
                  <InsuredNameTextLeft>Insured Name:</InsuredNameTextLeft>
                  <InsuredNameTextRight>{claim.name}</InsuredNameTextRight>
                </InsuredNameBox>
                <DateOfInjuryBox>
                  <DateOfInjuryTextLeft>Date of Injury:</DateOfInjuryTextLeft>
                  <DateOfInjuryTextRight>
                    {formatDate(claim.dateofloss)}
                  </DateOfInjuryTextRight>
                </DateOfInjuryBox>
              </SelectedClaim>
            ) : (
              <>
                <ClaimNumberBox
                  onClick={() =>
                    selectClaimHandler(claim.claimnumber, claim.policynumber,claim?.hideClaimDetails)
                  }
                >
                  <ClaimNumberText>{claim.claimnumber}</ClaimNumberText>
                </ClaimNumberBox>
                <InsuredNameBox>
                  <InsuredNameTextLeft>Insured Name:</InsuredNameTextLeft>
                  <InsuredNameTextRight>{claim.name}</InsuredNameTextRight>
                </InsuredNameBox>
                <DateOfInjuryBox>
                  <DateOfInjuryTextLeft>Date of Injury:</DateOfInjuryTextLeft>
                  <DateOfInjuryTextRight>
                    {formatDate(claim.dateofloss)}
                  </DateOfInjuryTextRight>
                </DateOfInjuryBox>
              </>
            )}
          </>
        ) : (
          <>
            {selectedClaim === claim.claimnumber ? (
              <SelectedClaim>
                <ClaimNumberBox>
                  <ClaimNumberText>{claim.claimnumber}</ClaimNumberText>
                </ClaimNumberBox>
                <InsuredNameBox>
                  <InsuredNameTextLeft>Insured Name:</InsuredNameTextLeft>
                  <InsuredNameTextRight>{claim.name}</InsuredNameTextRight>
                </InsuredNameBox>
                <DateOfInjuryBox>
                  <DateOfInjuryTextLeft>Date of Injury:</DateOfInjuryTextLeft>
                  <DateOfInjuryTextRight>
                    {formatDate(claim.dateofloss)}
                  </DateOfInjuryTextRight>
                </DateOfInjuryBox>
                <InjuryDetailsBox>
                  <InjuryDetailsTextLeft>Injury Details:</InjuryDetailsTextLeft>
                  <InjuryDetailsTextRight>
                    {claim.injurydescription}
                  </InjuryDetailsTextRight>
                </InjuryDetailsBox>
                <LossLocationBox>
                  <LossLocationTextLeft>Loss Location:</LossLocationTextLeft>
                  <LossLocationTextRight>
                    {claim.losslocationaddressline1} {claim.losslocationcity}{' '}
                    {claim.losslocationstate} {claim.losslocationzip}
                  </LossLocationTextRight>
                </LossLocationBox>
                <ClaimStatusBox>
                  <ClaimStatusTextLeft>Claim Status:</ClaimStatusTextLeft>
                  {claim.claim_status === 'Open' ? (
                    <ClaimStatusOpenTextRight>
                      {claim.claim_status}
                    </ClaimStatusOpenTextRight>
                  ) : (
                    <ClaimStatusCloseTextRight>
                      {claim.claim_status}
                    </ClaimStatusCloseTextRight>
                  )}
                </ClaimStatusBox>
              </SelectedClaim>
            ) : (
              <>
                <ClaimNumberBox>
                  <ClaimNumberText>{claim.claimnumber}</ClaimNumberText>
                </ClaimNumberBox>
                <InsuredNameBox>
                  <InsuredNameTextLeft>Insured Name:</InsuredNameTextLeft>
                  <InsuredNameTextRight>{claim.name}</InsuredNameTextRight>
                </InsuredNameBox>
                <DateOfInjuryBox>
                  <DateOfInjuryTextLeft>Date of Injury:</DateOfInjuryTextLeft>
                  <DateOfInjuryTextRight>
                    {formatDate(claim.dateofloss)}
                  </DateOfInjuryTextRight>
                </DateOfInjuryBox>
                <InjuryDetailsBox>
                  <InjuryDetailsTextLeft>Injury Details:</InjuryDetailsTextLeft>
                  <InjuryDetailsTextRight>
                    {claim.injurydescription}
                  </InjuryDetailsTextRight>
                </InjuryDetailsBox>
                <LossLocationBox>
                  <LossLocationTextLeft>Loss Location:</LossLocationTextLeft>
                  <LossLocationTextRight>
                    {claim.losslocationaddressline1} {claim.losslocationcity}{' '}
                    {claim.losslocationstate} {claim.losslocationzip}
                  </LossLocationTextRight>
                </LossLocationBox>
                <ClaimStatusBox>
                  <ClaimStatusTextLeft>Claim Status:</ClaimStatusTextLeft>
                  {claim.claim_status === 'Open' ? (
                    <ClaimStatusOpenTextRight>
                      {claim.claim_status}
                    </ClaimStatusOpenTextRight>
                  ) : (
                    <ClaimStatusCloseTextRight>
                      {claim.claim_status}
                    </ClaimStatusCloseTextRight>
                  )}
                </ClaimStatusBox>
              </>
            )}
          </>
        )}
      </ItemLeftWrapper>
      {selectedClaim === claim.claimnumber ? (
        <ItemRightWrapperSelected onClick={toggleCollapse}>
          <ItemRightIconWrapper>
            {isCollapsed ? <ChevronDownUnbolded /> : <ChevronUp />}
          </ItemRightIconWrapper>
        </ItemRightWrapperSelected>
      ) : (
        <ItemRightWrapper onClick={toggleCollapse}>
          <ItemRightIconWrapper>
            {isCollapsed ? <ChevronDownUnbolded /> : <ChevronUp />}
          </ItemRightIconWrapper>
        </ItemRightWrapper>
      )}
    </ItemWrapper>
  );
};
