import React from 'react';
import { Text } from 'grommet';
import styled from 'styled-components';
import { formatTitleCase } from '../../util/formatters';
import { motifs, colors, customBase } from '../../themes/default.theme';

export const StyledHR = styled.div`
  background-color: ${motifs.lightBorder};
  height: 1px;
  margin-bottom: 10px;
  margin-right: 2px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.darkBlue2};
  margin-bottom: 0.625rem;
`;

export const AssetWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddInfoItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AddInfoGroup = styled.div`
  float: left;
`;

export const ChangePageIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

export const MainWrapper = styled.div`
  width: 100%;
`;

export const TotalPagesText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #979797;
  margin-right: 20px;
  margin-left: 20px;
`;

export const IconSpacing = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
`;

export const TextWrapper = styled.div`
  flex: 0.5;
  margin-right: 1.375rem;
  margin-left: 1.375rem;
  margin-bottom: 1.375rem;
`;

export const TitleText = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;
export const ContentText = styled(Text)`
  font-size: 12px;
`;

export const ToolTipText = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: #0173c6;
`;

export const InfoPopUp = styled.span`
  visibility: hidden;
  background-color: #3e5572;
  width: 260px;
  color: white;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: -50px;
  border-radius: 3px;
  box-shadow: 0px 4px 6px -2px #002038;
  padding: 5px;
`;

export const InfoContainer = styled.div`
  position: relative;
  &:hover ${InfoPopUp} {
    visibility: visible;
  }
`;

export const HomeInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  // line-height: 1.375rem;
  line-height: 16px;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  // margin-left: 10px;
`;

export const StyledLabel = styled.span`
  font-weight: bold;
`;

export const HomeInfoLabel = styled(StyledLabel)`
  font-weight: bold;
  font-size: 0.75rem;
`;

export const OtherInsuredLabel = styled(StyledLabel)`
  font-weight: bold;
  font-size: 0.75rem;
  padding-bottom: 2px;
`;

export const VehicleSectionLabel = styled(StyledLabel)`
  font-weight: bold;
  font-size: 0.875rem;
`;

export const VehicleItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid ${motifs.lightBorder};
  }
`;

export const VehicleDescription = styled.div`
  font-size: 1.125rem;
`;

export const VehicleInfo = styled.div`
  display: flex;
  line-height: 1rem;
`;

export const VehicleInfoLabel = styled(StyledLabel)`
  font-weight: bold;
  font-size: 0.75rem;
  min-width: 3.5rem;
`;

export const VIN = styled.span`
  font-size: 0.75rem;
  letter-spacing: 0.075rem;
`;

export const Drivers = styled.span`
  font-size: 0.75rem;
`;

// Page Level Styles Below
const gridPadding = '1.25rem';
const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;

export const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &,
  & > div:first-child {
    margin-bottom: ${gridPadding};
  }
  @media (min-width: ${xlargeBreakpoint}) {
    min-height: 600px;
    width: 66%;
    width: calc(calc(2 / 3 * 100%) - calc(${gridPadding} / 2));
  }
`;

export const Column2 = styled.div`
  width: 100%;
  @media (min-width: ${xlargeBreakpoint}) {
    width: 33%;
    width: calc(calc(1 / 3 * 100%) - calc(${gridPadding} / 2));
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${xlargeBreakpoint}) {
    flex-direction: row;
  }
`;
export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FirstAddressLine = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`;

const SecondAddressLine = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const AttemptingStringAsAddress = ({
  children: address
}: {
  children: string;
}) => (
  <>
    <FirstAddressLine>
      {formatTitleCase(address.split(' - ')[0])}
    </FirstAddressLine>
    <SecondAddressLine>
      {address.split(' - ')[1] === undefined
        ? formatTitleCase(address.split(' - ')[1])
        : `${formatTitleCase(address.split(' - ')[1].split(',')[0])},${
            address.split(' - ')[1].split(',')[1]
          }`}
    </SecondAddressLine>
  </>
);
