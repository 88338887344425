import styled from 'styled-components';

interface textProps {
  active?: boolean;
  error?: boolean;
  defaultColor?: string;
}

export const CancelPolicyFormWrap = styled.div`
  padding: 30px;
`;

export const DownloadDocumentBanner = styled.div`
  display: flex;
  background: #e2f5f9;
  border: 1px solid #189ecc;
  border-radius: 3px;
  margin-bottom: 15px;
  align-items: center;
  padding: 6px 6px 6px 14px;
`;

export const BannerText = styled.p`
  margin: 0;
  flex-grow: 1;
  font-size: 14px;
  line-height: 16px;
  color: #005c86;
  padding-left: 14px;
`;

export const DownloadIcon = styled.img`
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 8px;
  width: 24px;
  height: auto;
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  :last-child {
    padding-bottom: 30px;
  }
`;

const statusColor = ({
  active = false,
  error = false,
  defaultColor = '#666666'
}: textProps) => {
  let color = defaultColor;
  if (active) {
    color = '#65A100';
  } else if (error) {
    color = '#B00020';
  }
  return color;
};

export const Label = styled.span<textProps>`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 6px;
  line-height: 18px;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  color: ${props => statusColor(props)};
`;

export const ElementWrap = styled.div<textProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-radius: 5px;
  flex-grow: 1;
  border-color: ${props => statusColor({ ...props, defaultColor: '#e7e7e7' })};
`;

export const ReasonSelect = styled.select`
  flex-grow: 1;
  height: 56px;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  padding: 0 16px;
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
`;

export const SelectChevron = styled.img`
  display: block;
  position: absolute;
  height: auto;
  width: 14px;
  right: 16px;
  top: calc(50% - 4px);
  z-index: 0;
`;

export const DatePicker = styled.input.attrs(props => ({
  ...props,
  type: 'date'
}))`
  flex-grow: 1;
  height: 56px;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  padding-left: 16px;
  border: none;
  outline: none;
  text-transform: uppercase;
  background: transparent;
  ::-webkit-calendar-picker-indicator {
    width: 0;
    height: 0;
    background: transparent;
  }
  z-index: 1;
`;

export const CalendarIcon = styled.img`
  cursor: pointer;
  display: block;
  position: absolute;
  height: calc(100% - 30px);
  width: 26px;
  padding: 15px;
  right: 0;
  top: 0;
  z-index: 2;
`;

export const CustomDatePickerModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.45);
`;

export const CustomDatePickerModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const CustomDatePickerWrap = styled.div`
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px #01538e33;
  width: 291px;
  z-index: 2;
`;

export const DocumentUpload = styled.input.attrs(props => ({
  ...props,
  type: 'file'
}))`
  cursor: pointer;
  width: 100%;
  height: 56px;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const DocumentUploadZWrap = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const FileName = styled.p`
  left: 0;
  top: 0;
  flex-grow: 1;
  height: 56px;
  line-height: 56px;
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  padding: 0 16px;
  overflow: hidden;
`;

export const BrowseButton = styled.div`
  display: block;
  height: 20px;
  padding: 15px 20px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0173c6;
  background: #f4f6fa;
  margin: 3px;
`;

export const FieldSubText = styled.span<textProps>`
  color: ${props => statusColor(props)};
  line-height: 18px;
  font-size: 12px;
  margin-left: 16px;
`;

export const FormButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
`;

export const ClearButton = styled.div`
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 5px;

  float: right;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #3b3b3b;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 5px 15px;
  background: #65a100;
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;

export const LoaderIconWrap = styled.div`
  padding-right: 6px;
`;

export const LoaderIcon = styled.img`
  display: block;
  width: 19px;
  height: auto;
`;

export const SecuritySelector = styled.input`
  border-radius: 50%;
  width: 15px;
  height: 15px;
`;

export const SecurityOption = styled.label`
  color: #3b3b3b;
  font-weight: 400;
  font-size: 16px;
`;

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 20px;
  &:first-child {
    padding-top: 18px;
  }
  &:last-child {
    padding-bottom: 40px;
  }
`;
export const ReasonText = styled.textarea`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: 'PT Sans', sans-serif;
  height: 69px;

  &:focus {
    outline-color: #65a100;
  }
  padding: 10px;
  display: flex;
  flex-direction: row;
`;
export const ReasonLength = styled.span`
  font-size: 14px;
  color: #979797;
  align-self: end;
  display: flex;
  flex-direction: row;
`;
export const CarrierText = styled.input`
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  height: 40px;

  &:focus {
    outline-color: #65a100;
  }
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-left: 18px;
`;

export const WarningText = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff6e9;
  border: 1px solid #f5a524;
  border-left: 5px solid #f5a524;
  border-radius: 4px;
  color: #772f1d;
  margin-bottom: 30px;
  padding: 16px;
  font-size: 14px;
  gap: 16px;
`;
