import React, { MouseEventHandler } from 'react';
import { formatDate } from '../../util/formatters';
import {
  Container,
  GrayBox,
  Header,
  MainText,
  Bold,
  CloseButton
} from './successMessageStyles';

interface SuccessMessageProps {
  effectiveDate: string;
  closeDropdown: Function;
}

export default ({ effectiveDate, closeDropdown }: SuccessMessageProps) => {
  return (
    <Container>
      <GrayBox>
        <Header>Change Request Sent</Header>
        <MainText test-attr="policy-change-success-main-text">
          We&apos;ve sent your request for the change effective{' '}
          <Bold>{formatDate(effectiveDate)}</Bold> and it will be reviewed by
          your Underwriter.
        </MainText>
      </GrayBox>
      <CloseButton
        test-attr="policy-change-success-close-button"
        onClick={closeDropdown as MouseEventHandler<HTMLButtonElement>}
      >
        CLOSE
      </CloseButton>
    </Container>
  );
};
