import styled from 'styled-components';
import { AltInfo } from '../../icons';

export const Modal = styled.div`
  z-index: 2;
  display: inline;
  position: fixed;
  margin: auto;
  align-self: center;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 650px;
`;

export const ModalTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 16px;
`;

export const ModalInfoBanner = styled.p`
  background: #e2f5f9;
  border: 1px solid #189ecc;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px;
`;

export const InfoIcon = styled(AltInfo)`
  display: inline;
`;

export const AddressBlockWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

export const AddressBlockLeft = styled.div`
  flex-grow: 1;
`;

export const AddressBlockRight = styled.div`
  flex-grow: 0;
  align-self: center;
`;

export const AddressTitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 22px;
  font-weight 700;
`;

export const AddressText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 22px;
`;

export const AddressCopy = styled.button`
  margin: 0;
  color: #0173c6;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
`;

export const ModalCloseButton = styled.button`
  cursor: pointer;
  margin-top: 8px;
  height: 45px;
  width: 158px;
  border-radius: 3px;
  background: #65a100;
  align-self: end;
  border: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
`;
