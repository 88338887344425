import React from 'react';
import { DisplayableResult } from './SearchDisplayInterfaces';
import {
  BluePillBox,
  StyledBubbleText,
  RedPillBox,
  GrayPillBox
} from './QuoteSearchStyled';
import { formatTitleCase } from '../../util/formatters';

interface QuotePillProps {
  result: DisplayableResult;
}

export default ({ result }: QuotePillProps) => {
  const status = result.status ?? '';
  const displayText = status === 'DECLINED' ? formatTitleCase(status) : status;

  if (['Draft', 'Quoted', 'Quote In-Progress'].includes(status))
    return (
      <BluePillBox>
        <StyledBubbleText>{displayText}</StyledBubbleText>
      </BluePillBox>
    );
  if (status === 'Issuance In-Progress') {
    return (
      <BluePillBox>
        <StyledBubbleText>Binding</StyledBubbleText>
      </BluePillBox>
    );
  }

  if (
    [
      'Quote Not-Taken',
      'Quote Declined',
      'Quote Withdrawn',
      'DECLINED'
    ].includes(status)
  )
    return (
      <RedPillBox>
        <StyledBubbleText>{displayText}</StyledBubbleText>
      </RedPillBox>
    );

  if (status)
    return (
      <GrayPillBox>
        <StyledBubbleText>{displayText}</StyledBubbleText>
      </GrayPillBox>
    );

  return <></>;
};
