import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import { PolicyDropdown, PolicyDropdownProps } from '.';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import passed90Days from '../../util/passed90Days';

const get_policies_by_account = loader(
  './graphql/Policy_Dropdown_Get_Policies_by_Account.graphql'
);

/**
 * We are just passing through the onChange handler here.
 * The reason we don't provide it from the data-container itself is because
 * the behavior depends on the context in which PolicyDropdown is used.
 */
export default ({
  onChange,
  className
}: {
  onChange: (...args: any[]) => void;
  className?: string;
}) => {
  const { accountNumber, policyNumber } = useParams();
  const variables = {
    account_number: accountNumber
  };
  const { loading: isLoading, error, data } = useQuery(
    get_policies_by_account,
    { variables }
  );

  const onChangeDecorator = (policy_number: string) => {
    simpleGTMDataLayer({
      event: 'click-policyDropdown',
      policyNumber: policy_number
    });
    onChange(policy_number);
  };

  const props = {
    isLoading,
    error,
    onChange: onChangeDecorator,
    value: policyNumber,
    className
  };

  const policies = [];
  if (data?.account?.items) {
    const policiesList = data.account.items[0].policies.items;
    let i = 0;
    for (i = 0; i < policiesList.length; i += 1) {
      if (
        policiesList[i].expiration_date &&
        passed90Days(policiesList[i].expiration_date)
      ) {
        policies.push(policiesList[i]);
      }
    }
    Object.assign(props, {
      options: policies
    });
  }

  return <PolicyDropdown {...props} />;
};
