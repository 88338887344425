import React, { ChangeEvent } from 'react';

import {
  InputGroup,
  Label,
  ElementWrap,
  ReasonSelect,
  SelectChevron,
  FieldSubText,
  ReasonText,
  ReasonLength,
  CarrierText
} from '../cancelPolicyFormStyles';

import ChevronIconSrc from '../../../../assets/images/chevron-down.svg';
import CarrierMovedTo from './carrierMovedTo';

interface initialState {
  value: string;
  active: boolean;
  error: boolean;
  msg: string;
}

interface CancellationReasonProps {
  state: initialState;
  onFocus: Function;
  onBlur: Function;
  onChange: Function;
  disabled: boolean;
  setReasonCode: Function;
  cancellationSource: string;
  setCancellationSource: Function;
  product: string;
  systemCode: string;
  carrierMovedTo: string;
  setCarrierMovedTo: Function;
  otherCarrier: string;
  setOtherCarrier: Function;
  requestError: {
    requestError: boolean;
    requestMsg: string;
  };
  setRequestError: Function;
  carrierError: {
    carrierError: boolean;
    carrierMsg: string;
  };
  setCarrierError: Function;
  otherCarrierError: {
    otherError: boolean;
    otherMsg: string;
  };
  setOtherError: Function;
}

export const reasonOptions: string[] = [
  'Select Reason for Cancellation',
  'Insured Request',
  'Insured Request / Moved out of State'
];

export const reasonCode: { [key: string]: string } = {
  'Insured Request': 'fincononpay',
  'Insured Request / Moved out of State': 'insuredRequestOrMovedOutState_Ext'
};

export const clReasonOptions: string[] = [
  'Select Reason for Cancellation',
  'Insured Request',
  'Moved To Other Carrier',
  'Policy Not Taken',
  'Non-Payment of Premium'
];

export const clReasonCode: { [key: string]: string } = {
  'Insured Request': 'fincononpay',
  'Moved To Other Carrier': 'movedToOtherCarrier_SAI',
  'Policy Not Taken': 'nottaken',
  'Non-Payment of Premium': 'nonpayment_Ext'
};

export const dropDownInitialState: initialState = {
  value: reasonOptions[0],
  active: false,
  error: false,
  msg: ''
};
export const textBoxInitialState: initialState = {
  value: '',
  active: false,
  error: false,
  msg: ''
};

export const isValid = (value: string) =>
  value !== reasonOptions[0] && value !== '';

export default ({
  state: { value, active, error, msg },
  onFocus,
  onBlur,
  onChange,
  disabled,
  setReasonCode,
  cancellationSource,
  setCancellationSource,
  product,
  systemCode,
  carrierMovedTo,
  setCarrierMovedTo,
  otherCarrier,
  setOtherCarrier,
  requestError: { requestError, requestMsg },
  setRequestError,
  carrierError,
  setCarrierError,
  otherCarrierError: { otherError, otherMsg },
  setOtherError
}: CancellationReasonProps) => (
  <div>
    {(product === 'WORKERS COMP' || product === 'COMMERCIAL PACKAGE') && (
      <InputGroup test-attr="cancel-policy-cancellation-source">
        <Label active={active} error={requestError}>
          Request By
        </Label>
        <ElementWrap active={active} error={requestError}>
          <ReasonSelect
            value={cancellationSource}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setCancellationSource(e.target.value);
              setRequestError({ requestError: false, requestMsg: '' });
            }}
            disabled={disabled}
          >
            <option key="default">Select Requested By</option>
            <option key="Agent">Agent</option>
            <option key="Insured">Insured</option>
          </ReasonSelect>
          <SelectChevron src={ChevronIconSrc} />
        </ElementWrap>
        <FieldSubText error={requestError}>{requestMsg}</FieldSubText>
      </InputGroup>
    )}
    {systemCode === 'GWPL' && (
      <InputGroup test-attr="cancel-policy-cancellation-reason">
        <Label active={active} error={error}>
          Reason
        </Label>
        <ElementWrap active={active} error={error}>
          <ReasonSelect
            onFocus={() => onFocus()}
            onBlur={() => onBlur()}
            value={value}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              onChange(e.target.value);
              if (reasonCode[e.target.value]) {
                setReasonCode(reasonCode[e.target.value]);
              }
            }}
            disabled={disabled}
          >
            {reasonOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </ReasonSelect>
          <SelectChevron src={ChevronIconSrc} />
        </ElementWrap>
        <FieldSubText error={error}>{msg}</FieldSubText>
      </InputGroup>
    )}
    {systemCode === 'GWCL' && (
      <div>
        {product === 'COMMERCIAL PACKAGE' || product === 'WORKERS COMP' ? (
          <div>
            <InputGroup test-attr="cancel-policy-cancellation-reason-cpp-wc">
              <Label active={active} error={error}>
                Reason
              </Label>
              <ElementWrap active={active} error={error}>
                <ReasonSelect
                  value={value}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChange(e.target.value);
                    if (clReasonCode[e.target.value]) {
                      setReasonCode(clReasonCode[e.target.value]);
                    }
                  }}
                  disabled={disabled}
                >
                  {clReasonOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </ReasonSelect>
                <SelectChevron src={ChevronIconSrc} />
              </ElementWrap>
              <FieldSubText error={error}>{msg}</FieldSubText>
            </InputGroup>
            {value === clReasonOptions[2] && (
              <CarrierMovedTo
                carrierMovedTo={carrierMovedTo}
                setCarrierMovedTo={setCarrierMovedTo}
                disabled={disabled}
                product={product}
                carrierError={carrierError}
                setCarrierError={setCarrierError}
              />
            )}
            {value === clReasonOptions[2] && carrierMovedTo === 'Other' && (
              <div>
                <InputGroup>
                  <Label active={active} error={otherError}>
                    Enter Other Carrier
                  </Label>
                  <CarrierText
                    type="text"
                    onFocus={() => onFocus()}
                    onBlur={() => onBlur()}
                    value={otherCarrier}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setOtherCarrier(e.target.value);
                      setOtherError({ otherError: false, otherMsg: '' });
                    }}
                    placeholder="Other Carrier"
                  />
                  <FieldSubText error={otherError}>{otherMsg}</FieldSubText>
                </InputGroup>
              </div>
            )}
          </div>
        ) : (
          <div>
            <InputGroup>
              <Label active={active} error={error}>
                Reason for Cancellation
              </Label>
              <ReasonText
                maxLength={250}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
                value={value}
                placeholder="Enter Reason for Cancellation"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  onChange(e.target.value);
                  setReasonCode('fincononpay');
                }}
              />
              <ReasonLength>{value.length}/250</ReasonLength>
            </InputGroup>
          </div>
        )}
      </div>
    )}
  </div>
);
