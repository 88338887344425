import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { FileContent } from '.';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';

const upload_policy_document_mutation = loader(
  './graphql/Upload_File_Content_Upload_Policy_Document.graphql'
);
const get_system_code = loader(
  './graphql/Upload_File_Content_Get_Account_System_Code.graphql'
);
const get_policy_state = loader('./graphql/Get_Policy_State.graphql');

interface uploadProps {
  data: any;
  dispatch: any;
  name?: string;
  policy: string;
  uploadState: number;
  file: File;
  uploadFlag: Function;
  spinnerOn: number;
  successCount: number;
  setSuccessCount: Function;
  dropOn: string;
  setDropOn: Function;
}
export default ({
  data,
  dispatch,
  name,
  policy,
  uploadState,
  file,
  uploadFlag,
  spinnerOn,
  successCount,
  setSuccessCount,
  dropOn,
  setDropOn
}: uploadProps) => {
  const { accountNumber } = useParams();
  const [uploadDoc, { loading, error, data: uploadData }] = useMutation(
    upload_policy_document_mutation
  );
  const variables = {
    account_number: accountNumber
  };
  const sourceQueryResult = useQuery(get_system_code, { variables });
  let code = '';
  const { data: accountData } = sourceQueryResult;

  if (accountData) {
    code = accountData?.account?.items[0]?.source_system_code;
  }

  const stateResult = useQuery(get_policy_state, {
    variables: { account_number: accountNumber, policy_number: policy }
  });
  let state = '';
  const { data: stateData } = stateResult;

  if (stateData) {
    state = stateData?.account?.items[0]?.policy?.jurisdiction_state;
  }
  const { flagDetails: fireSubscription } = useFeatureFlag(
    'Firebased Subscription Doc Type'
  );
  const { flagDetails: driverProof } = useFeatureFlag('Driver Discovery Proof');

  const AZEnabled = fireSubscription?.enabled || false;
  const DDPEnabled = driverProof?.enabled || false;

  return (
    <FileContent
      data={data}
      dispatch={dispatch}
      name={name}
      policy={policy}
      loading={loading}
      error={error}
      uploadDoc={uploadDoc}
      uploadState={uploadState}
      file={file}
      uploadData={uploadData}
      systemCode={code}
      uploadFlag={uploadFlag}
      spinnerOn={spinnerOn}
      successCount={successCount}
      setSuccessCount={setSuccessCount}
      dropOn={dropOn}
      setDropOn={setDropOn}
      state={state}
      AZEnabled={AZEnabled}
      DDPEnabled={DDPEnabled}
    />
  );
};
