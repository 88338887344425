import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/GrayCloseIcon.svg';

export const ModalBackground = styled.div`
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #041e41bf;
  z-index: 101;
`;

export const ModalContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 3px;
  max-width: 444px;
  gap: 30px;
`;

export const InfoText = styled.span`
  font-size: 16px;
  line-height: 21px;
`;

export const HeaderText = styled.span`
  font-size: 19px;
  line-height: 23px;
  font-weight: 700;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TopRow = styled(Row)`
  justify-content: space-between;
`;

export const BottomRow = styled(Row)`
  & > *:first-child {
    margin-left: auto;
  }
`;

export const CloseButton = styled.button`
  color: #ffffff;
  background-color: #4d840b;
  border: 0;
  border-radius: 5px;
  font-size: 19px;
  line-height: 23px;
  font-weight: 700;
  padding: 8px 25px 9px;
  cursor: pointer;
`;

export const PhoneLink = styled.a`
  text-decoration: none;
  &:link {
    color: #0173c6;
  }
  &:visited {
    color: #0173c6;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
