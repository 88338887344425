export const Button = {
  primary: {
    extend: {
      color: 'white'
    },
    color: '#65A100',
    background: '#65A100'
  },
  secondary: {
    'background-color': '#fff',
    color: '#000000'
  },
  disabled: {
    color: 'disabled-text',
    opacity: 0.2
  },
  border: {
    radius: '3px',
    width: '0',
    color: '#83D000'
  }
};
