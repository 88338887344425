import React from 'react';
import { Box, Text } from 'grommet';
import { ApolloError } from 'apollo-client';
import styled from 'styled-components';
import { StyledDataTable } from '..';
import { formatDate, formatMoney } from '../../util/formatters';
import { colors } from '../../themes/default.theme';
import { GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items } from './graphql/__generated__/GET_INVOICE_IN_ACCOUNT';

export interface invoiceSnapshotProps {
  isLoading?: boolean;
  error?: ApolloError;
  invoiceDetails: (GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items | null)[];
}

export const BorderedBox = styled(Box)`
  border-bottom: 4px solid #65a100;
`;

export const StyledInvoiceBox = styled(Box)`
  width: 100%;
`;

export default ({ isLoading, error, invoiceDetails }: invoiceSnapshotProps) => {
  if (isLoading) {
    return <p>Loading...</p>;
  }
  
  if (error) {
    return <p>Error while Loading...</p>;
  }

  const renderNumber = (value: number) => {
    if (value > 0) {
      return <Text>{formatMoney(value)}</Text>;
    }
    if (value === 0) {
      return <Text>-</Text>;
    }
    return <Text color={colors.valueRed1}>{formatMoney(value)}</Text>;
  };

  return (
    <Box background="white">
      <StyledInvoiceBox pad={{ top: '10px', bottom: '20px', left: '30px', right: '30px' }}>
        <Box border={{ side: 'bottom' }} margin={{ bottom: '20px' }}>
          <BorderedBox width="small">
            <Text weight="bold">Invoice Breakdown</Text>
          </BorderedBox>
        </Box>

        <StyledDataTable
          primaryKey="id"
          columns={[
            {
              property: 'item_date',
              header: <Text weight="bold">Date</Text>,
              render: datum => <Text>{formatDate(datum.item_date)}</Text>
            },
            {
              property: 'category',
              header: <Text weight="bold">Category</Text>
            },
            {
              property: 'description',
              header: <Text weight="bold">Description</Text>,
              render: datum => (
                <Text>
                  {datum !== null && datum.description !== null
                    ? datum.description
                    : '-'}
                </Text>
              )
            },
            {
              property: 'invoice_item_type',
              header: <Text weight="bold">Type</Text>,
              footer: 'Total'
            },
            {
              property: 'invoice_item_amount',
              header: <Text weight="bold">Invoice Amount</Text>,
              render: datum => renderNumber(datum.invoice_item_amount),
              aggregate: 'sum',
              footer: { aggregate: true }
            },
            {
              property: 'paid_amount',
              header: <Text weight="bold">Amount Paid</Text>,
              render: datum => renderNumber(datum.paid_amount),
              aggregate: 'sum',
              footer: { aggregate: true }
            },
            {
              property: 'amount_due',
              header: <Text weight="bold">Due</Text>,
              render: datum => renderNumber(datum.amount_due),
              aggregate: 'sum',
              footer: { aggregate: true }
            }
          ]}
          data={invoiceDetails}
          a11yTitle="Invoice Breakdown"
          footerSize={{
            side: 'right',
            numCells: 4,
            gap: true
          }}
        />
      </StyledInvoiceBox>
    </Box>
  );
};
