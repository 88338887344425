import React, { useState, useEffect } from 'react';
import { PolicyChangeForm, ErrorModal } from '.';
import {
  PolicyChange,
  ChevronUp,
  ChevronDownUnbolded,
  PolicyChangeDisabled,
  ChevronDownDisabled
} from '../../icons';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  BoundingBox,
  Dropdown,
  IconOuterBox,
  IconInnerBox,
  WhiteBox,
  BoldText,
  ChevronBox
} from './changePolicyStyles';
import { GET_CHANGE_POLICY_INFO_account_items_policy_valid_policy_change_messages } from './graphql/__generated__/GET_CHANGE_POLICY_INFO';
import { OutageModal } from '..';

export interface dropdownUIProps {
  isOpen?: boolean;
  disabled?: boolean;
}

export interface textProps {
  active?: boolean;
}

export interface changePolicyDropdownProps {
  policyNumber: string;
  accountNumber: string;
  sourceSysCode: string;
  makeAPolicyChange: Function;
  maxEffectiveDate?: string | undefined;
  minEffectiveDate?: string | undefined;
  policyType?: string;
  clChangeWithDocument: Function;
  renewalDate: string | null;
  errorMessage: string | null | undefined;
  status: string | undefined | null;
  returnedMessage?:
    | (GET_CHANGE_POLICY_INFO_account_items_policy_valid_policy_change_messages | null)[]
    | null;
  pcOutage: boolean;
  isLoading: boolean;
  setIsLoading: Function;
  isOpen: boolean;
  setIsOpen: Function;
  multipleChangesIndicator: boolean | undefined;
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
  isFarmPolicy: boolean;
}

export default ({
  policyNumber,
  accountNumber,
  makeAPolicyChange,
  maxEffectiveDate,
  minEffectiveDate,
  policyType,
  clChangeWithDocument,
  renewalDate,
  errorMessage,
  status,
  returnedMessage,
  pcOutage,
  isLoading,
  setIsLoading,
  isOpen,
  setIsOpen,
  multipleChangesIndicator,
  readOnlyAccess,
  readOnlyFeatureFlag,
  isFarmPolicy
}: changePolicyDropdownProps) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [outageModalOpen, setOutageModalOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [policyNumber]);

  useEffect(() => {
    if (errorMessage) {
      setShowErrorModal(true);
    }
  }, [errorMessage]);

  return (
    <div>
      {showErrorModal ? (
        <ErrorModal
          setShowModal={setShowErrorModal}
          errorMessage={errorMessage}
        />
      ) : (
        <></>
      )}
      <BoundingBox>
        {outageModalOpen && (
          <OutageModal
            title="Change Policy is Unavailable"
            onClose={() => setOutageModalOpen(false)}
          >
            Unfortunately, due to server maintenance, changing your policy is
            currently unavailable. Please check back in 24 to 48 hours.
          </OutageModal>
        )}
        <Dropdown
          test-attr="policy-change-dropdown-form-wrapper"
          isOpen={isOpen}
          onClick={() => {
            if (pcOutage) {
              setOutageModalOpen(!outageModalOpen);
            } else {
              setIsOpen(!isOpen);
              simpleGTMDataLayer({
                event: 'openPolicyChangeDropdownClick',
                event_action: 'Button Click',
                event_category: window.location.pathname,
                event_label: 'openChangePolicyDropdown'
              });
              simpleGTMDataLayer({
                event: 'openPolicyChangeDropdownClickSnowplow',
                event_action: 'Button Click',
                event_category: `${window.location.pathname}/Snowplow`,
                event_label: 'openChangePolicyDropdown'
              });
            }
          }}
          disabled={readOnlyAccess && readOnlyFeatureFlag}
        >
          <IconOuterBox>
            <IconInnerBox>
              <>
                {readOnlyAccess && readOnlyFeatureFlag ? (
                  <PolicyChangeDisabled />
                ) : (
                  <PolicyChange />
                )}
              </>
            </IconInnerBox>
          </IconOuterBox>
          <WhiteBox>
            <BoldText>CHANGE THIS POLICY</BoldText>
            <ChevronBox>
              {isOpen ? (
                <ChevronUp />
              ) : (
                <>
                  {readOnlyAccess && readOnlyFeatureFlag ? (
                    <ChevronDownDisabled />
                  ) : (
                    <ChevronDownUnbolded />
                  )}
                </>
              )}
            </ChevronBox>
          </WhiteBox>
        </Dropdown>
        {isOpen && (
          <PolicyChangeForm
            changePolicy={makeAPolicyChange}
            isOpen={isOpen}
            policyNumber={policyNumber}
            accountNumber={accountNumber}
            maxEffectiveDate={maxEffectiveDate}
            minEffectiveDate={minEffectiveDate}
            policyType={policyType}
            clChangeWithDocument={clChangeWithDocument}
            setIsOpen={setIsOpen}
            renewalDate={renewalDate}
            status={status}
            returnedMessage={returnedMessage}
            test-attr="policy-change-form"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            multipleChangesIndicator={multipleChangesIndicator}
            isFarmPolicy={isFarmPolicy}
          />
        )}
      </BoundingBox>
    </div>
  );
};