import React from 'react';
import { DocumentsTable } from '../../components';
import { GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items } from './graphql/__generated__/GET_DOCUMENTS_BY_ACCOUNT';
import { GET_POLICIES_BY_ACCOUNT_account_items_policies_items } from '../../components/billingPayPlans/graphql/__generated__/GET_POLICIES_BY_ACCOUNT';

export interface DocumentsPageProps {
  isLoading?: boolean;
  error?: Error;
  information: GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items[];
  onDocumentClick: Function;
  accountNumber: string;
  policies: GET_POLICIES_BY_ACCOUNT_account_items_policies_items[];
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
  nextClickHandler: Function;
}

export const DocumentsPage = ({
  onDocumentClick,
  information,
  isLoading,
  error,
  accountNumber,
  policies,
  readOnlyAccess,
  nextClickHandler,
  readOnlyFeatureFlag
}: DocumentsPageProps) => {
  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error while loading...</p>;
  }
  const props = {
    documents: information,
    onDocumentClick,
    accountNumber,
    policies,
    readOnlyAccess,
    readOnlyFeatureFlag,
    nextClickHandler
  };

  return <DocumentsTable {...props} />;
};
