import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import vehicleIcon from '../../assets/images/VehicleIcon.svg';
import { formatTitleCase } from '../../util/formatters';
import { PolicyInfoProps } from './PolicyInfo';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  VehicleItem,
  VehicleDescription,
  VehicleInfo,
  VehicleInfoLabel,
  VIN,
  Subtitle,
  StyledHR
} from './PolicyInfoStyles';
import usePagination from '../../util/usePagination';
import { Driver } from '../../icons';
import { ContentsTag } from '../billingHistory';
import { GET_POLICY_INFO_account_items_policy_insuredparties_items } from './graphql/__generated__/GET_POLICY_INFO';

export const Column1 = styled(Box)`
  margin-left: 0;
  margin-right: 33%;
  display: flex;
  flex-direction: column;
  line-height: 1.375rem;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Column2 = styled(Box)`
  display: flex;
  flex-direction: column;
  line-height: 1.375rem;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const RelationSpacer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ExcludedSpacing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  width: 280px;
  gap: 20px;
`;

const ContentSize = styled.div`
  height: 20px;
  width: 80px;
  margin-bottom: 10px;
`;

export const sortDrivers = (
  driverList: GET_POLICY_INFO_account_items_policy_insuredparties_items[]
) => {
  const returnedList: GET_POLICY_INFO_account_items_policy_insuredparties_items[] = [];
  let namesList = [];
  let i;
  for (i = 0; i < driverList.length; i++) {
    namesList[i] = driverList[i].display_name;
  }
  namesList = namesList.sort();
  for (i = 0; i < namesList.length; i++) {
    for (let j = 0; j < driverList.length; j++) {
      if (driverList[j].display_name === namesList[i]) {
        returnedList.push(driverList[j]);
        break;
      }
    }
  }
  return returnedList;
};

/*
Types of Commercial Products
- Auto
- COMMERCIAL AUTO
- FARMOWNERS AUTO
*/
export default ({
  information,
  accountNumber,
  policyNumber
}: PolicyInfoProps) => {
  const insuredAssets = information!.insuredassets?.items ?? [];

  const [displayableAssets, pageSelector] = usePagination({
    accountNumber,
    policyNumber,
    items: insuredAssets,
    itemsPerPage: 5
  });

  let uniqueDrivers = [];
  const uniqueNames: (string | null)[] = [];
  let contact: string | null = '';
  let i = 0;
  const items: (GET_POLICY_INFO_account_items_policy_insuredparties_items | null)[] =
    information?.insuredparties?.items ?? [];
  for (i = 0; i < items?.length; i += 1) {
    const item = items?.[i];
    if (item?.role_type === 'POLICYPRINAMEDINSURED' && contact === '') {
      contact = item?.contact_id;
    }
    if (
      (item?.retired && item?.retired === 'N') ||
      item?.retired_date === null
    ) {
      if (
        !uniqueNames.includes(item?.display_name as string) &&
        item?.role_type === 'POLICYDRIVER'
      ) {
        uniqueNames.push(item?.display_name);
        uniqueDrivers.push(item);
      } else if (
        !uniqueNames.includes(item?.display_name as string) &&
        item?.role_type === 'CADRVR'
      ) {
        uniqueNames.push(item?.display_name);
        uniqueDrivers.push(item);
      }
    }
  }
  uniqueDrivers = sortDrivers(uniqueDrivers);

  const [displayableDrivers, driverPages] = usePagination({
    accountNumber,
    policyNumber,
    items: uniqueDrivers,
    itemsPerPage: 8
  });

  simpleGTMDataLayer({
    event: 'goal-tracking',
    event_action: 'PolicyInfoAuto',
    event_category: information.market,
    event_label: information.policy_type
  });
  simpleGTMDataLayer({
    event: 'goal-tracking-snowplow',
    event_action: 'PolicyInfoAuto',
    event_category: `${information.market}/Snowplow`,
    event_label: information.policy_type
  });

  return (
    <Box>
      <Subtitle>{insuredAssets?.length > 1 ? 'Vehicles' : 'Vehicle'}</Subtitle>
      <StyledHR />
      <Box>
        {displayableAssets.map(item => (
          <VehicleItem key={item.asset}>
            <Box pad="medium">
              <img src={vehicleIcon} alt="Vehicle Icon" />
            </Box>
            <Box>
              <VehicleDescription>
                {formatTitleCase(item.asset_name)}
              </VehicleDescription>
              <VehicleInfo>
                <VehicleInfoLabel>VIN</VehicleInfoLabel>
                <VIN>{item.asset}</VIN>
              </VehicleInfo>
            </Box>
            <StyledHR />
          </VehicleItem>
        ))}
      </Box>
      {insuredAssets.length > 1 ? (
        <Box align="end">{pageSelector}</Box>
      ) : (
        <div />
      )}
      {displayableDrivers.length !== 0 && (
        <div>
          <Subtitle>Listed Drivers</Subtitle>
          <StyledHR />
        </div>
      )}
      <Box>
        {displayableDrivers.map(item => (
          <VehicleItem>
            <Box pad="medium" margin={{ left: 'small', right: 'small' }}>
              <Driver />
            </Box>
            <Box>
              <ExcludedSpacing>
                {formatTitleCase(item.display_name)}
              </ExcludedSpacing>
              {(item.relation_to_primary_insured ||
                item.contact_id === contact) && (
                <RelationSpacer>
                  <VehicleInfoLabel>Relationship</VehicleInfoLabel>
                  <VIN>
                    {item.contact_id === contact ? (
                      <div>Primary Insured</div>
                    ) : (
                      <div>
                        {item.relation_to_primary_insured === 'NOTRELATED'
                          ? 'Not Related'
                          : formatTitleCase(item.relation_to_primary_insured)}
                      </div>
                    )}
                  </VIN>
                </RelationSpacer>
              )}
            </Box>
            {item.driver_exclusion === '1' && (
              <ContentSize>
                <ContentsTag color="gray" testId="">
                  Excluded
                </ContentsTag>
              </ContentSize>
            )}
            <StyledHR />
          </VehicleItem>
        ))}
      </Box>
      {uniqueDrivers.length > 8 ? (
        <Box align="end">{driverPages}</Box>
      ) : (
        <div />
      )}
    </Box>
  );
};
