import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import { Text } from 'grommet';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { MakePaymentOptionsWrapper } from '..';
import getConfig, { Env } from '../../config';
import {
  agencyAndDirectBilled,
  agencyBilledOnly,
  policyIsAgencyBilled
} from '../../util/agencyBilledUtils';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import PaymentInfo, { StyledPrimaryButton } from './PaymentInfo';
import { formatDate } from '../../util/formatters';
import { PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items } from './graphql/__generated__/PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import OutageModal from '../outageModal';
import { PAYMENT_INFO_GET_NEXT_PAYMENT_INFO } from './graphql/__generated__/PAYMENT_INFO_GET_NEXT_PAYMENT_INFO';

const get_policy_payplan_billing_method = loader(
  './graphql/Payment_Info_Get_Policy_PayPlan_Billing_Method.graphql'
);

const get_billing_date = loader(
  './graphql/Payment_Info_Get_Billing_Date.graphql'
);

const get_cancel_payment = loader(
  './graphql/Payment_Info_Get_CancelPayment_Invoices.graphql'
);

const get_Next_Payment_Info = loader(
  './graphql/Payment_Info_Get_Next_Payment_Info.graphql'
);

const get_mortgage_invoices_info = loader(
  './graphql/Get_Mortgage_Invoices_Info.graphql'
);

const config = getConfig(process.env.REACT_APP_ENV as Env);

export const transformData = (data: PAYMENT_INFO_GET_NEXT_PAYMENT_INFO) => {
  const paymentInfo =
    data?.account?.items?.[0]?.next_payment_no_mortgagee_billed ?? null;

  return {
    paymentInfo
  };
};

export const agencyBillClickHandler: Function = () => {
  simpleGTMDataLayer({
    event: 'click-billingAgencyBillButton'
  });
  window.open(config.agency_bill_url, '_blank');
};

export const correctUpdatedDate = (
  currentDate: string,
  dayOfTheMonth: string,
  within10Days: boolean
) => {
  let paymentDueDate: string | number | Date | null | undefined = '';
  if (
    currentDate &&
    currentDate !== '-' &&
    dayOfTheMonth !== '' &&
    !within10Days
  ) {
    const currentDateDue = moment.tz(
      currentDate.substring(0, 10),
      'America/New_York'
    );
    if (`${parseInt(currentDateDue.format('DD'), 10)}` !== dayOfTheMonth) {
      currentDateDue.set(
        'date',
        Math.min(parseInt(dayOfTheMonth, 10), currentDateDue.daysInMonth())
      );
      paymentDueDate = formatDate(currentDateDue.toDate());
    } else paymentDueDate = formatDate(currentDate); // days of month match
  } else paymentDueDate = formatDate(currentDate); // within10days or no future date
  return paymentDueDate;
};

export const filterNonCancelablePayments = (
  invoices: (PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items | null)[]
) => {
  const filteredInvoices: PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items[] = invoices.filter(
    (
      invoice: PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items | null
    ): invoice is PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items => {
      if (!invoice?.pmt_draft_date) {
        return false;
      }
      const draft_date = moment.tz(
        invoice.pmt_draft_date,
        'YYYY-MM-DD',
        'America/New_York'
      );
      const currentDate = moment.tz('America/New_York');
      const isBeforeDraftDate =
        currentDate.isBefore(draft_date) ||
        (currentDate.isSameOrBefore(draft_date, 'days') &&
          invoice.pmt_status === 'Canceled');
      if (
        isBeforeDraftDate &&
        currentDate.isSameOrAfter(draft_date.clone().subtract(10, 'days'))
      ) {
        return true;
      }
      return false;
    }
  );
  return filteredInvoices;
};

export const containsDesiredPaymentStatus = (
  invoices: PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items[],
  desiredStatus: string
) => {
  let containsDesiredStatus: boolean = false;
  let firstInvoiceWithStatus: PAYMENT_INFO_GET_CANCELPAYMENT_INVOICES_account_items_invoices_items | null = null;
  for (let i = 0; i < invoices.length; i += 1) {
    if (invoices[i].pmt_status === desiredStatus) {
      containsDesiredStatus = true;
      firstInvoiceWithStatus = invoices[i];
      break;
    }
  }
  return {
    containsDesiredStatus,
    firstInvoiceWithStatus
  };
};

interface PaymentInfoDataContainerProps {
  lastDayToPayFlag: boolean;
  page: string;
  policyNumber?: string | undefined;
  allCancelled?: boolean;
  paidInFull: Function;
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
}

export default ({
  lastDayToPayFlag,
  page,
  policyNumber,
  allCancelled,
  paidInFull,
  readOnlyAccess,
  readOnlyFeatureFlag
}: PaymentInfoDataContainerProps) => {
  const { accountNumber } = useParams();
  const { flagDetails: billingCenterOutageFlagDetails } = useFeatureFlag(
    'BillingCenter-Outage'
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL'
  );
  const [displayMakePaymentOutage, setDisplayMakePaymentOutage] = useState<
    boolean
  >(false);
  const variables = {
    account_number: accountNumber
  };
  const nextPaymentQueryResult = useQuery(get_Next_Payment_Info, {
    variables
  });

  const billingMethodQueryResult = useQuery(get_policy_payplan_billing_method, {
    variables
  });
  const billingDateResult = useQuery(get_billing_date, { variables });

  const mortgageInvoicesQueryResult = useQuery(get_mortgage_invoices_info, {
    variables
  });
  const {
    loading: paymentInfoLoading,
    error: paymentInfoError
  } = nextPaymentQueryResult;
  let { data: paymentInfoQueryResult } = nextPaymentQueryResult;
  const userID = paymentInfoQueryResult?.me?.user_id;
  const [openOneIncURL] = generateOpenOneIncURL(
    accountNumber,
    userID,
    SSOneINCURL?.enabled,
    sessionStorage?.userLOB?.includes('Commercial') ? 'GWCL' : 'GWPL'
  );
  const { data: billingDate } = billingDateResult;
  const { data: mortgageInvoicesInfo } = mortgageInvoicesQueryResult;
  const {
    data: billingMethodData,
    loading: billingMethodLoading
  } = billingMethodQueryResult;
  const currentPolicyIsAgencyBilled = policyIsAgencyBilled(
    billingMethodData,
    policyNumber
  );
  const isAgencyAndDirectBilled = agencyAndDirectBilled(billingMethodData);
  let noModalClickHandler: Function;

  if (paymentInfoQueryResult) {
    paymentInfoQueryResult = transformData(paymentInfoQueryResult);
  }

  if (agencyBilledOnly(billingMethodData) || currentPolicyIsAgencyBilled) {
    noModalClickHandler = agencyBillClickHandler;
  } else if (page) {
    noModalClickHandler = (isAltPaymentOptions = null) => {
      simpleGTMDataLayer({
        event: `click-${page}MakePayment`
      });
      openOneIncURL(isAltPaymentOptions);
    };
  } else {
    noModalClickHandler = openOneIncURL;
  }

  let date = '';
  if (billingDate) {
    date = billingDate.account.items[0].payment.fixed_bill_due_date;
  }

  const timeToPaymentResult = useQuery(get_cancel_payment, { variables });
  const { data: paymentResult } = timeToPaymentResult;
  let filteredPayments;
  let within10Days = false;
  if (paymentResult) {
    filteredPayments = filterNonCancelablePayments(
      paymentResult.account.items[0].invoices.items
    );
    within10Days = containsDesiredPaymentStatus(filteredPayments, 'Requested')
      .containsDesiredStatus;
  }

  let paymentDueDate: string | number | Date | null | undefined = '';
  if (paymentInfoQueryResult?.paymentInfo?.paymentDueDate && date) {
    paymentDueDate = correctUpdatedDate(
      paymentInfoQueryResult?.paymentInfo?.paymentDueDate,
      date,
      within10Days
    );
  }

  const { flagDetails: NextPaymentAutoDraftIndicator } = useFeatureFlag(
    'NextPaymentAutoDraftIndicator'
  );
  return (
    <PaymentInfo
      lastDayToPayFlag={lastDayToPayFlag}
      isLoading={paymentInfoLoading || billingMethodLoading}
      error={paymentInfoError}
      {...paymentInfoQueryResult}
      allCancelled={allCancelled}
      paidInFull={paidInFull}
      formattedDate={paymentDueDate}
      readOnlyAccess={readOnlyAccess}
      readOnlyFeatureFlag={readOnlyFeatureFlag}
      nextPaymentAutoDraftIndicator={
        NextPaymentAutoDraftIndicator?.enabled ?? false
      }
      showPastDueDetails={
        paymentInfoQueryResult?.paymentInfo?.pastDueAmount !== '$0.00' &&
        paymentInfoQueryResult?.paymentInfo?.pastDueAmount !== '0'
      }
      mortgageeInvoice={mortgageInvoicesInfo?.invoices?.items[0] ?? null}
      isAgencyAndDirectBilled={isAgencyAndDirectBilled}
    >
      {paymentInfoQueryResult?.paymentAmount === 'PAID IN FULL' &&
      allCancelled ? (
        <div />
      ) : (
        <MakePaymentOptionsWrapper
          page={page}
          noModalClickHandler={noModalClickHandler}
          policyNumber={policyNumber}
          readOnlyAccess={readOnlyAccess}
          readOnlyFeatureFlag={readOnlyFeatureFlag}
        >
          <StyledPrimaryButton
            onClick={(event: MouseEvent) => {
              if (billingCenterOutageFlagDetails?.enabled) {
                event.stopPropagation();
                setDisplayMakePaymentOutage(true);
              }
            }}
            round={{ size: 'xsmall', corner: 'bottom' }}
            disabled={readOnlyAccess && readOnlyFeatureFlag}
          >
            <Text textAlign="center" data-test-id="make-payment-button">
              MAKE A PAYMENT
            </Text>
          </StyledPrimaryButton>
        </MakePaymentOptionsWrapper>
      )}
      {displayMakePaymentOutage && (
        <OutageModal
          title="Making a Payment is Unavailable"
          onClose={() => setDisplayMakePaymentOutage(false)}
        >
          Unfortunately, due to server maintenance, making your payment is
          currently unavailable. Please check back in 24 to 48 hours.
        </OutageModal>
      )}
    </PaymentInfo>
  );
};
