import {
  GET_BILLING_PAY_PLAN,
  GET_BILLING_PAY_PLAN_account_items_payplans_items
} from '../components/billingPayPlans/graphql/__generated__/GET_BILLING_PAY_PLAN';

export default (data: GET_BILLING_PAY_PLAN) => {
  const determinedStatus = data?.account?.items?.reduce(
    (result: string | null, item: any) => {
      if (
        item?.source_system_code?.toLowerCase() === 'gwpl' ||
        item?.source_system_code == null
      )
        return null;
      return item?.payplans?.items?.reduce(
        (
          status: string | null,
          payplan: GET_BILLING_PAY_PLAN_account_items_payplans_items | null
        ) => {
          if (payplan?.response_bill_indicator) {
            return payplan?.response_bill_indicator;
          }

          return status;
        },
        null
      );
    },
    null
  );
  if (determinedStatus === 'Yes') return 'Disabled';
  if (determinedStatus === 'No') return 'Enabled';
  return null;
};
