import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { PolicyInfoProps } from './PolicyInfo';
import { HomeInfoLabel, AttemptingStringAsAddress } from './PolicyInfoStyles';
import { formatTitleCase } from '../../util/formatters';
import getUniqueParties from '../../util/uniqueParties/uniqueParties';

export const OtherInsuredItem = styled(Text)`
  word-break: keep-all;
  font-size: 16px;
  padding-bottom: 10px;
`;

export const Column1 = styled(Box)`
  line-height: 1.375rem;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Column2 = styled(Box)`
  line-height: 1.375rem;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

/*
Types of Commercial Products
- Home
- BUSINESSOWNERS
- FARM & RANCH
*/
export default ({ information }: PolicyInfoProps) => {
  const insuredAssets = information?.insuredassets?.items;
  const effectiveStartDate = information?.effective_date;
  const expirationDate = information?.expiration_date;
  const uniqueParties =
    getUniqueParties(information?.insuredparties?.items) ?? [];
  return (
    <>
      <Box direction="row" gap="20px">
        <Column1 direction="column">
          <HomeInfoLabel>Address</HomeInfoLabel>
          {insuredAssets?.[0]?.asset && (
            <AttemptingStringAsAddress>
              {insuredAssets?.[0].asset}
            </AttemptingStringAsAddress>
          )}
        </Column1>
        <Column2 direction="column">
          {uniqueParties?.length !== 0 ? (
            <HomeInfoLabel>Other Insured</HomeInfoLabel>
          ) : (
            ''
          )}
          <OtherInsuredItem>
            {uniqueParties?.length !== 0
              ? uniqueParties?.map(item => formatTitleCase(item))
              : ''}
          </OtherInsuredItem>
        </Column2>
      </Box>
    </>
  );
};
