import React, { useState } from 'react';

import moment from 'moment-timezone';
import { CancelPayment } from '..';

import {
  LastPaymentWrap,
  LastPaymentAmtText,
  LastPaymentDateWrap,
  LastPaymentSuccessIcon,
  LastPaymentDate,
  NextPaymentWrap,
  NextPaymentSectionTitle,
  NextPaymentAmount,
  NextPaymentDate,
  NextPaymentAmtDisclaimer,
  PastDuePill,
  PastDuePillIcon,
  PastDuePillText,
  LastDayToPayText,
  FooterButtonsWrap
} from './NextPaymentSectionStyles';

import SuccessSrc from '../../assets/images/Success.svg';
import WarningSrc from '../../assets/images/AlertIcon.svg';
import { PAYMENT_INFO_GET_NEXT_PAYMENT_INFO_account_items_next_payment_no_mortgagee_billed } from '../paymentInfo/graphql/__generated__/PAYMENT_INFO_GET_NEXT_PAYMENT_INFO';
import { formatDate, formatMoney } from '../../util/formatters';
import { GET_MORTGAGE_INVOICES_INFO_invoices_items } from '../paymentInfo/graphql/__generated__/GET_MORTGAGE_INVOICES_INFO';
interface LastPaymentProps {
  lastPaymentAmt: string | JSX.Element;
  lastPaymentDate: string | number | Date | null | undefined;
}

export const LastPaymentSection = ({
  lastPaymentAmt,
  lastPaymentDate
}: LastPaymentProps) => {
  return (
    <LastPaymentWrap>
      <LastPaymentAmtText>Last Payment {lastPaymentAmt}</LastPaymentAmtText>
      <LastPaymentDateWrap>
        {lastPaymentDate && lastPaymentDate !== '-' && (
          <LastPaymentSuccessIcon src={SuccessSrc} />
        )}
        <LastPaymentDate>Received on {lastPaymentDate}</LastPaymentDate>
      </LastPaymentDateWrap>
    </LastPaymentWrap>
  );
};

interface NextPaymentInfoProps {
  paymentInfo:
    | PAYMENT_INFO_GET_NEXT_PAYMENT_INFO_account_items_next_payment_no_mortgagee_billed
    | undefined;
  isPastDue: boolean;
  nextPaymentAutoDraftIndicator: boolean;
  hasStoppedPayment: boolean;
  mortgageeInvoice: GET_MORTGAGE_INVOICES_INFO_invoices_items | undefined;
}

export const NextPaymentInfo = ({
  paymentInfo,
  isPastDue,
  nextPaymentAutoDraftIndicator,
  hasStoppedPayment,
  mortgageeInvoice
}: NextPaymentInfoProps) => {
  const responsiveIndicator =
    nextPaymentAutoDraftIndicator && paymentInfo?.responsiveBillIndicator
      ? paymentInfo.responsiveBillIndicator
      : 'yes';
  const formattedAmt = paymentInfo?.paymentAmount
    ? formatMoney(paymentInfo.paymentAmount)
    : '$0.00';
  const formattedPaymentDueDate = paymentInfo?.paymentDueDate
    ? formatDate(paymentInfo.paymentDueDate)
    : '-';

  if (
    paymentInfo?.paymentAmount?.toUpperCase() === 'PAID IN FULL' &&
    !paymentInfo?.mortgageeBilledOnly
  ) {
    return (
      <>
        <NextPaymentAmount>Paid In Full</NextPaymentAmount>
      </>
    );
  }

  if (paymentInfo?.mortgageeBilledOnly && !isPastDue) {
    if (!parseFloat(mortgageeInvoice?.amount_due?.toString() ?? '0')) {
      return (
        <>
          <NextPaymentAmount>Paid In Full</NextPaymentAmount>
        </>
      );
    }

    return (
      <>
        <NextPaymentAmount>{`${formatMoney(
          mortgageeInvoice?.amount_due ?? '-'
        )}\u00A0`}</NextPaymentAmount>
        <NextPaymentDate>
          <b>
            {`${
              mortgageeInvoice?.due_date &&
              new Date(mortgageeInvoice?.due_date).getTime() >
                new Date().getTime()
                ? `will be billed to the mortgage company and`
                : `has been billed to the mortgage company and was`
            } due`}
          </b>
          {` on ${formatDate(mortgageeInvoice?.due_date ?? '-')}`}
        </NextPaymentDate>
      </>
    );
  }

  if (
    responsiveIndicator?.toLowerCase() === 'no' &&
    hasStoppedPayment === false &&
    paymentInfo?.defaultBillingMethod?.toUpperCase() !== 'RESPONSIVE' &&
    !isPastDue &&
    formattedPaymentDueDate !== '-'
  ) {
    return (
      <>
        <NextPaymentAmount>{formattedAmt}</NextPaymentAmount>
        <NextPaymentDate>
          <b>Scheduled for automatic draft</b> on {formattedPaymentDueDate}
        </NextPaymentDate>
      </>
    );
  }

  if (
    formattedPaymentDueDate !== '-' &&
    responsiveIndicator?.toLowerCase() === 'no' &&
    paymentInfo?.defaultBillingMethod?.toUpperCase() !== 'RESPONSIVE' &&
    hasStoppedPayment === true &&
    !isPastDue
  ) {
    return (
      <>
        <NextPaymentAmount>{formattedAmt}</NextPaymentAmount>
        <NextPaymentDate>by {formattedPaymentDueDate}</NextPaymentDate>
      </>
    );
  }

  if (
    (responsiveIndicator?.toLowerCase() === 'yes' ||
      paymentInfo?.defaultBillingMethod?.toUpperCase() === 'RESPONSIVE') &&
    formattedPaymentDueDate !== '-' &&
    !isPastDue
  ) {
    return (
      <>
        <NextPaymentAmount>{formattedAmt}</NextPaymentAmount>
        <NextPaymentDate>by {formattedPaymentDueDate}</NextPaymentDate>
      </>
    );
  }

  return (
    <>
      <NextPaymentAmount>{formattedAmt}</NextPaymentAmount>
    </>
  );
};

interface NextPaymentSectionProps {
  makePayment: JSX.Element[] | undefined | any;
  nextPaymentInfo:
    | PAYMENT_INFO_GET_NEXT_PAYMENT_INFO_account_items_next_payment_no_mortgagee_billed
    | undefined;
  nextPaymentAutoDraftIndicator: boolean;
  mortgageeInvoice: GET_MORTGAGE_INVOICES_INFO_invoices_items | undefined;
  isAgencyAndDirectBilled: boolean;
}

const NextPaymentSection = ({
  makePayment,
  nextPaymentInfo,
  nextPaymentAutoDraftIndicator,
  mortgageeInvoice,
  isAgencyAndDirectBilled
}: NextPaymentSectionProps) => {
  const [hasStoppedPayment, setHasStoppedPayment] = useState<boolean>(false);
  const dueAmt = nextPaymentInfo?.pastDueAmount
    ? formatMoney(nextPaymentInfo.pastDueAmount)
    : '0';
  const isNotPastDue =
    !nextPaymentInfo?.hasPastDue ||
    !dueAmt ||
    (dueAmt && ['0', '-', '0.0', '$0.0'].includes(dueAmt));

  const transEffectiveDate =
    nextPaymentInfo?.policy?.trans_effective_date || '';
  const currentDate = moment.tz('America/New_York').format('YYYY-MM-DD');
  const diff =
    transEffectiveDate &&
    moment.tz(transEffectiveDate, 'America/New_York').diff(currentDate, 'days');
  const showPastDueDate =
    !isNotPastDue &&
    diff &&
    diff > 0 &&
    nextPaymentInfo?.policy?.status?.toLowerCase().includes('canceling');

  const lastDueDate = new Date(transEffectiveDate).setDate(
    new Date(transEffectiveDate).getDate() - 1
  );
  return (
    <NextPaymentWrap>
      <NextPaymentSectionTitle>Next Payment</NextPaymentSectionTitle>
      <NextPaymentInfo
        paymentInfo={nextPaymentInfo}
        isPastDue={!isNotPastDue}
        nextPaymentAutoDraftIndicator={nextPaymentAutoDraftIndicator}
        hasStoppedPayment={hasStoppedPayment}
        mortgageeInvoice={mortgageeInvoice}
      />
      <NextPaymentAmtDisclaimer>
        {isAgencyAndDirectBilled
          ? `Amount is inclusive of only direct bill policy(s)`
          : `Amount is inclusive of all the policies.`}
      </NextPaymentAmtDisclaimer>
      {!isNotPastDue && (
        <div>
          <PastDuePill>
            <PastDuePillIcon src={WarningSrc} />
            <PastDuePillText>Past Due</PastDuePillText>
          </PastDuePill>
          <LastDayToPayText>
            There is a past due amount of <b>{dueAmt}</b> on the account.{' '}
            {showPastDueDate && (
              <span>
                The policy will cancel if the payment is not received on or
                before <b>{formatDate(lastDueDate)}</b>.
              </span>
            )}
          </LastDayToPayText>
        </div>
      )}

      <FooterButtonsWrap>
        {makePayment}
        <CancelPayment setHasStoppedPayment={setHasStoppedPayment} />
      </FooterButtonsWrap>
    </NextPaymentWrap>
  );
};

export default NextPaymentSection;
