import styled from 'styled-components';
import { Box } from 'grommet';
import { customBase } from '../../themes/default.theme';
import { CardLayout } from '../../components/cardLayout/CardLayout';

const gridPadding = '1.25rem';
const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;
export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  & > div {
    margin-bottom: ${gridPadding};
  }
`;

export const OuterBox = styled.div`
  text-align: center;
`;

export const AnchorBox = styled(Box)`
  width: 30%;
`;

export const Column1 = styled(Column)`
  display: block;
  @media (min-width: ${xlargeBreakpoint}) {
    width: 65%;
    width: calc(calc(2 / 3 * 100%) - calc(${gridPadding} / 2));
  }

  div.UIRedesign > div:last-child > div {
    padding: 0;
  }
`;

export const Column2 = styled(Column)`
  & > div:last-child {
    margin-bottom: 0;
    width: 100%;
  }
  @media (min-width: ${xlargeBreakpoint}) {
    width: 33%;
    width: calc(calc(1 / 3 * 100%) - calc(${gridPadding} / 2));
  }
`;

export const StyledBox = styled(Box)`
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledBoxShell = styled(Box)`
  justify-content: space-between;
  flex-direction: row;
  height: fit-content;
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);

  div.AccountDetailsWrap {
      width: 42%;
      &.EditMode {
        width: 100%;
      }
      & > div > div:first-child {
        padding: 30px;

        & > div {
          padding: 0;
          border: none;

          & > div {
            grid-template-columns: 100% 0%;

            & span[class*='StyledText'] {
              font-weight: 700;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
  div.PaymentSectionWrap {
    width: 58%;
  }
`;

export const InfoPopUp = styled.span`
  visibility: hidden;
  background-color: #3e5572;
  width: 260px;
  color: white;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -210px;
  border-radius: 3px;
  box-shadow: 0px 4px 6px -2px #002038;
  padding: 5px;
`;
export const InfoContainer = styled.div`
  position: relative;
  &:hover ${InfoPopUp} {
    visibility: visible;
  }
`;

export const EditButtonRow = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  background-color: #f5f7fa;
  justify-content: flex-end !important;
  align-items: center;
  padding: 10px 20px;
  radius: 0px 0px 3px 3px;
`;
export const EditHolder = styled.div`
  z-index: 0;
`;

export const AccordionStyledCard = styled(CardLayout)`
  display: flex;
  & .cardBody {
    padding: 0;
  }
  & .cardShellBody {
    flex-direction: row;
    padding: 0;
    background-color: #ffffff;
    border-bottom: 2px solid blue;
  }
`;

export const PaymentBox = styled(Box)`
  background-color: #ffffff;
  justify-self: flex-end;
`;

export const BigBoldText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin-top: 45px;
`;

export const SmallText = styled.p`
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const VerticalDivider = styled.div`
  border-left: 2px dashed #ccc;

  &.UIRedesign {
    border-left: 1px dashed #d9dfeb;
  }
`;

export const EditTextWrap = styled.span`
  display: flex;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
`;

export const StyledImg = styled.img`
  margin-right: 3px;
`;

export const ChangeDate = styled.div`
  color: #65a100;
  font-weight: 700;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const CenterModal = styled.div`
  align-items: start;
  justify-content: start;
  display: flex;
  flex-direction: column;
`;

export const AccountInfoHeaderBtnWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const AccountInfoHeaderBtn = styled.p.attrs(
  (props: { disabled: boolean }) => props
)`
  cursor: pointer;
  white-space: nowrap;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  padding: 4px;
  opacity: ${props => props?.disabled && '0.4'};
  pointer-events: ${props => props?.disabled && 'none'};
  cursor: ${props => props?.disabled && 'not-allowed'};
`;

export const AccountInfoHeaderBtnDivider = styled.span`
  display: block;
  width: 2px;
  height: 16px;
  background-color: #ffffff;
  margin: 0 4px;
`;
export const AgencyBilledBanner = styled(Box)`
  padding: 10px;
  margin-bottom: 10px;
  /* background-color: rgb(231, 245, 250); */
  /* border-color: rgb(185, 226, 240) rgb(185, 226, 240) rgb(185, 226, 240) rgb(24, 160, 205); */
  display: flex;
  background: rgb(231, 245, 250);
  /* color: rgb(12, 80, 102); */
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: rgb(185, 226, 240) rgb(185, 226, 240) rgb(185, 226, 240)
  rgb(24, 160, 205);
  border-image: initial;
  border-radius: 4px;
  box-shadow: rgba(1, 83, 142, 0.2) 0px 2px 4px 0px;
`;
