import React from 'react';
import { loader } from 'graphql.macro';
import { useParams } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { QuoteAccountDetails } from '.';

const get_quote_account_details = loader(
  './graphql/Get_Quote_Account_Details.graphql'
);

export default () => {
  const { quoteNumber } = useParams();
  const { data } = useQuery(get_quote_account_details, {
    variables: { quote_number: quoteNumber }
  });
  let quoteInfo = null;
  console.log(data);
  if (data) {
    quoteInfo = data?.quote;
  }
  return (
    <QuoteAccountDetails quoteInfo={quoteInfo} quoteNumber={quoteNumber} />
  );
};
