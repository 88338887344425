import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useCallback
} from 'react';
import { Auth, Hub } from 'aws-amplify';
import TagManager from 'react-gtm-module';
import { Grommet, Grid, Box, Text } from 'grommet';
import {
  Router,
  RouteComponentProps,
  Redirect,
  useLocation
} from '@reach/router';
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid';
import setupLogRocketReact from 'logrocket-react';
import styled from 'styled-components';
import Default, { customBase } from './themes/default.theme';
import { SAHeader, FooterComponent, UserNav, OctoBanner } from './components';
import {
  AccountInformationPage,
  PolicyInformationPage,
  BillingPage,
  DocumentsPage,
  Search,
  LookupToolsPage,
  CommunicationsPage,
  ClaimsPage,
  QuotePage
} from './pages';
import SearchPageBackground from './assets/images/search-background.jpg';
import { FreshChat } from './util/freshchat';
import FeedbackCommentModal from './components/feedbackCommentModal';
import getConfig, { Env } from './config';
import FeatureFlag from './components/featureFlags';
import DatePickerTest from './components/datePicker/testing/DatePickerTest';
import GlobalContextProvider from './util/globalContextProvider';
import { CPRAFooter } from './util/CPRAFooter/CpraFooter';

declare global {
  interface Window {
    snowplow: Function;
    utag: any;
  }
}

const config = getConfig(process.env.REACT_APP_ENV as Env);
const version = `SA360-${process.env.REACT_APP_ENV}@${process.env.REACT_APP_VERSION}`;
const redirectSignInURL: URL = new URL(config.oauth.redirectSignIn);
const SearchPage = (_: RouteComponentProps) => <Search />;

// https://stackoverflow.com/questions/53058110/stop-reach-router-scrolling-down-the-page-after-navigating-to-new-page
const ScrollToTop = ({
  children
}: RouteComponentProps<{ children: React.ReactNode | React.ReactNode[] }>) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return <>{children}</>;
};

const xlargeBreakpoint = `${customBase?.global?.breakpoints?.xlarge?.value}px`;

const OctoBannerContainer = styled.div`
  position: relative;
`;

const StyledContentWrapper = styled.div`
  padding: 0 3.75rem 3.75rem;
  margin: 2.5rem auto 0;
  max-width: 1320px;
  @media (min-width: ${xlargeBreakpoint}) {
    width: 90%;
  }
`;

const StyledInnerContentWrapper = styled.div`
  padding-top: 2.125rem;
  min-height: 80vh;
`;
interface AppProps {
  pathLocation: {
    pathname: string;
  };
  skipSignIn?: boolean;
}

const gtmId = 'GTM-M27F45Z';

// TODO: Need unit tests for this component which test route resolution
const App = ({ skipSignIn = false, pathLocation }: AppProps) => {
  const [signedIn, setSignedIn] = useState(false);
  const [isInvalidUser, setIsInvalidUser] = useState(false);
  const [signInErrorOccurred, setSignInErrorOccurred] = useState(false);

  const [visible, setVisible] = useState(true);
  const [email, setEmail] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [userType, setUserType] = useState<string>();

  const logRocketAppId = config.logrocket.id;
  const lrSdkServer = config.logrocket.url;
  const lrIngestServer = config.logrocket.url;

  const sentryOrigin =
    process.env.REACT_APP_ENV === 'DEV'
      ? 'localhost'
      : 'agency360.msd001.stateauto.com';

  const doSignIn = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setSignedIn(true);
        setEmail(user.attributes.email);
        setUsername(user.username.split('_').pop());
        setUserType(user.attributes['custom:userType']);
        sessionStorage.setItem('modalPopUpOnceIncrement', '1');
        sessionStorage.setItem(
          'EIDuserName',
          user?.signInUserSession?.idToken?.payload?.identities[0]?.userId?.toLowerCase() ??
            ''
        );
        if (localStorage.getItem('sessionIncrement')) {
          let sessionIncrement = localStorage.getItem('sessionIncrement');
          let sessionNumber;
          if (sessionIncrement) {
            sessionNumber = parseInt(sessionIncrement, 10) + 1;
            sessionIncrement = sessionNumber.toString();
            localStorage.setItem('sessionIncrement', sessionIncrement);
          }
        } else {
          localStorage.setItem('sessionIncrement', '1');
        }

        if (config.environment === 'dev') window.snowplow = () => {};
        TagManager.initialize({
          gtmId,
          dataLayer: {
            csrId: user.username
          }
        });

        LogRocket.init(logRocketAppId, {
          sdkServer: lrSdkServer,
          ingestServer: lrIngestServer
        });

        LogRocket.identify(uuidv4(), {
          name: `${user.attributes.name} ${user.attributes.given_name}`,
          email: user.attributes.email,
          userID: user.username.replace('EmpowerId_', '')
        });

        FreshChat.initialize(user);
        CPRAFooter.initialize();
        setupLogRocketReact(LogRocket);
        
      })
      .catch(err => {
        if (
          err === 'not authenticated' ||
          err === 'The user is not authenticated'
        ) {
          if (window.location.search.indexOf('?code=') === -1) {
            sessionStorage.setItem('redirectLocation', window.location.href);
            Auth.federatedSignIn({
              customProvider: 'EmpowerId'
            }).catch(_err => {
              console.error('error signing in', _err);
            });
          }
        }
      });
  }, []);

  useEffect(() => {
    if (pathLocation.pathname.includes('/claims')) {
      setVisible(false);
      FreshChat.hide();
    } else if (!visible) {
      setVisible(true);
      FreshChat.show();
    }
  }, [pathLocation.pathname, visible]);

  const doRedirectToRequestedPage = useCallback(() => {
    const redirectLocation = sessionStorage.getItem('redirectLocation');
    if (userType === 'I') {
      const redirectPath = redirectLocation
        ? new URL(redirectLocation).pathname
        : window.location.pathname;
      sessionStorage.removeItem('redirectLocation');
      window.location.assign(`${config.customer_360_url}${redirectPath}`);
    }
    sessionStorage.removeItem('redirectLocation');
    if (redirectLocation && redirectLocation !== window.location.href) {
      window.location.assign(redirectLocation);
    }
  }, [userType]);

  useEffect(() => {
    if (skipSignIn) {
      setSignedIn(true);
      return;
    }
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signOut':
          setSignedIn(false);
          break;
        case 'signIn_failure':
          {
            const { message } = payload.data;
            if (message === 'invalid_grant') {
              setIsInvalidUser(true);
            } else if (message === 'invalid_request') {
              doRedirectToRequestedPage();
              // setSignInErrorOccurred(true);
            }
          }
          break;
        default:
        // No action
      }
    });
  }, [skipSignIn, userType]);

  useEffect(() => {
    if (skipSignIn) {
      return;
    }
    // Detect if the user is landing due to having just authenticated.
    if (signedIn) {
      doRedirectToRequestedPage();
    } else if (window.location.host !== redirectSignInURL.host) {
      window.location.assign(
        `${config.oauth.redirectSignIn}${window.location.pathname.slice(1)}`
      );
    } else {
      doSignIn();
    }
  }, [signedIn, skipSignIn, userType]);

  let authenticationStatus = null;
  if (signInErrorOccurred) {
    authenticationStatus = (
      <h3 data-test-id="unexpectedError">
        <Text color="white">An unexpected error occurred.</Text>
      </h3>
    );
  } else if (isInvalidUser) {
    authenticationStatus = (
      <h3 data-test-id="invalidUser">
        <Text color="white">Invalid user.</Text>
      </h3>
    );
  } else if (!signedIn) {
    authenticationStatus = (
      <h3 data-test-id="notSignedInMessage">
        <Text color="white">Please wait. Signing in.</Text>
      </h3>
    );
  }
  return (
    <GlobalContextProvider>
      <Grommet plain theme={Default}>
        <FeatureFlag flagName="DatePickerTest">
          <DatePickerTest />
        </FeatureFlag>
        <Grid
          fill
          rows={['auto', 'flex', 'auto']}
          columns={['flex']}
          areas={[['header'], ['main'], ['footer']]}
        >
          <Box gridArea="header" background="background">
            <SAHeader
              hasSearch={
                pathLocation.pathname !== '/' &&
                pathLocation.pathname !== '/tools/lookup'
              }
            />
          </Box>
          <Box
            gridArea="main"
            background={{
              image:
                pathLocation.pathname === '/'
                  ? `linear-gradient(rgba(0, 32, 56, 0.76), rgba(0, 32, 56, 0)), url(${SearchPageBackground})`
                  : 'background',
              repeat: 'no-repeat',
              position: 'top',
              color: '#f4f6fa'
            }}
          >
            <FeatureFlag flagName="OCTOBanner">
              <OctoBannerContainer>
                <Router basepath="/account/:accountNumber">
                  <OctoBanner path="/overview" />
                </Router>
              </OctoBannerContainer>
            </FeatureFlag>
            <StyledContentWrapper>
              <FeedbackCommentModal userEmail={email} userName={username} />
              {authenticationStatus !== null ? (
                authenticationStatus
              ) : (
                <>
                  <Router basepath="/account/:accountNumber/">
                    {/* // We're doing this because of https://github.com/reach/router/issues/267 */}
                    <UserNav path="overview" />
                    <UserNav path="billing" />
                    <UserNav path="policy/:policyNumber" />
                    <UserNav path="documents" />
                    <UserNav path="claims" />
                    <UserNav path="communications" />
                  </Router>
                  <Router>
                    <SearchPage path="/" />
                    <LookupToolsPage path="/tools/lookup" />
                    <QuotePage path="/quote/:quoteNumber" />
                  </Router>
                  <StyledInnerContentWrapper>
                    <Router basepath="/account/:accountNumber">
                      <ScrollToTop path="/">
                        <Redirect noThrow from="/" to="./overview" />
                        <AccountInformationPage path="/overview" />
                        <PolicyInformationPage path="/policy/:policyNumber" />
                        <BillingPage path="/billing" />
                        <DocumentsPage path="/documents" />
                        <ClaimsPage path="/claims" />
                        <CommunicationsPage path="/communications" />
                      </ScrollToTop>
                    </Router>
                  </StyledInnerContentWrapper>
                </>
              )}
            </StyledContentWrapper>
          </Box>
          <Box gridArea="footer" background="background">
            <FooterComponent />
          </Box>
        </Grid>
      </Grommet>
    </GlobalContextProvider>
  );
};

export default App;
