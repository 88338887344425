import React from 'react';
import styled from 'styled-components';
import NoIssuedPoliciesBanner from '../QuotePageNoIssuedPoliciesBanner';
import StartNewQuoteButton from '../quotePageStartNewQuoteButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 813px;
`;

export default () => {
  return (
    <Container>
      <NoIssuedPoliciesBanner />
      <StartNewQuoteButton />
    </Container>
  );
};
