import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 354px;
`;

export const HorizontalLine = styled.hr`
  border: none;
  width: 100%;
  height: 1px;
  background-color: #d9dfeb;
`;

export const LabelText = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #0173c6;
`;

export const PremiumText = styled.span`
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
`;
