import styled, { keyframes } from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ClaimsLeftWrapper = styled.div`
  width: 25%;
`;

export const ClaimsRightWrapper = styled.div`
  width: 70%;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const WcClaimsButton = styled.div`
  cursor: pointer;
  font: inherit;
  padding: 7px;
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #0173c6;
  color: #0173c6 !important;
  padding-left: 10px;
  padding-right: 10px;
`;
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  display: inline-block;
  animation: ${spinAnimation} 1s infinite linear;
  margin: 5px;
`;
export const Loading = styled.span`
  color: #0173c6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Icon = styled.span`
  justify-content: center;
`;
