import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useParams } from '@reach/router';
import moment from 'moment-timezone';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { ChangePolicyDropdown } from '.';
import {
  GET_CHANGE_POLICY_INFOVariables,
  GET_CHANGE_POLICY_INFO,
  GET_CHANGE_POLICY_INFO_account_items_policy_snapshots_items,
  GET_CHANGE_POLICY_INFO_account_items_policy
} from './graphql/__generated__/GET_CHANGE_POLICY_INFO';
import {
  MAKE_A_POLICY_CHANGE,
  MAKE_A_POLICY_CHANGEVariables
} from './graphql/__generated__/MAKE_A_POLICY_CHANGE';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';

const get_change_policy_info = loader(
  './graphql/Get_Change_Policy_Info.graphql'
);

const make_a_policy_change = loader('./graphql/Make_A_Policy_Change.graphql');

const policy_change_with_document_upload = loader(
  './graphql/Policy_Change_With_Document_Upload.graphql'
);

const findRenewalDate = (
  policy: GET_CHANGE_POLICY_INFO_account_items_policy | null | undefined
) => {
  if (policy?.status !== 'InForce') return null;
  const snapshots = policy.snapshots?.items;
  if (!snapshots?.length) return null;
  const currentDate = moment.tz('America/New_York');
  const filteredSnapshots = snapshots.filter(
    (
      snapshot: GET_CHANGE_POLICY_INFO_account_items_policy_snapshots_items | null
    ): snapshot is GET_CHANGE_POLICY_INFO_account_items_policy_snapshots_items => {
      if (!snapshot?.effective_date) return false;
      const snapshotDate = moment.tz(
        snapshot.effective_date,
        'YYYY-MM-DD',
        'America/New_York'
      );
      return snapshotDate.isAfter(currentDate) && snapshot.status === 'RENEWAL';
    }
  );
  if (filteredSnapshots.length) return filteredSnapshots[0].effective_date;
  return null;
};

export interface changePolicyDataContainerProps {
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

export default ({
  readOnlyAccess,
  readOnlyFeatureFlag
}: changePolicyDataContainerProps) => {
  let redirectURL: string | null | undefined = '';
  const [getURL, getURLResponse] = useLazyQuery<
    MAKE_A_POLICY_CHANGE,
    MAKE_A_POLICY_CHANGEVariables
  >(make_a_policy_change, {
    onCompleted: () => {
      if (redirectURL) {
        window.location.assign(redirectURL);
      }
      setIsLoading(false);
      setIsOpen(false);
      redirectURL = '';
    }
  });

  const { accountNumber, policyNumber } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  let isFarmPolicy: boolean = false;

  const policyInfo = useQuery<
    GET_CHANGE_POLICY_INFO,
    GET_CHANGE_POLICY_INFOVariables
  >(get_change_policy_info, {
    variables: {
      account_number: accountNumber,
      policy_number: policyNumber
    } as GET_CHANGE_POLICY_INFOVariables
  });

  const policyInfoData: GET_CHANGE_POLICY_INFO | undefined = policyInfo.data;

  const [clChangeWithDocument] = useMutation(
    policy_change_with_document_upload
  );
  const { flagDetails: policyCenterOutageFlagDetails } = useFeatureFlag(
    'PolicyCenter-Outage'
  );

  let code: string = '';

  let renewalDate: string | null = null;
  let type = '';
  let policyChangeData;
  let maxEffectiveDate;
  let minEffectiveDate;
  let status;
  let returnedMessages;
  let isCanceling = false;
  let multipleChangesIndicator;

  if (policyInfoData?.account?.items) {
    renewalDate = findRenewalDate(policyInfoData.account.items[0]?.policy);
    type = policyInfoData.account.items[0]?.policy?.product_code || '';
    code = policyInfoData.account.items[0]?.source_system_code || '';
    policyChangeData = policyInfoData.account.items[0]?.policy;
    policyInfoData.account.items[0]?.policy?.snapshots?.items?.forEach(
      snapshot => {
        if (snapshot?.status === 'CANCELING') isCanceling = true;
      }
    );
    status = isCanceling
      ? 'CANCELING'
      : policyInfoData.account.items[0]?.policy?.status;
    const validPolicyChangeInfo = policyChangeData?.valid_policy_change;
    if (validPolicyChangeInfo?.PolicyChangeMaxEffectiveDate) {
      maxEffectiveDate = validPolicyChangeInfo.PolicyChangeMaxEffectiveDate;
    }
    if (validPolicyChangeInfo?.PolicyChangeMinEffectiveDate)
      minEffectiveDate = validPolicyChangeInfo?.PolicyChangeMinEffectiveDate;
    if (validPolicyChangeInfo?.messages)
      returnedMessages = validPolicyChangeInfo.messages;
    if (validPolicyChangeInfo?.MultipleChangesIndicator)
      multipleChangesIndicator = validPolicyChangeInfo.MultipleChangesIndicator;
  }

  if (
    type === 'FARM & RANCH' ||
    type === 'FARMOWNERS AUTO' ||
    type === 'FARMOWNERS UMBRELLA'
  ) {
    isFarmPolicy = true;
  }

  let errorMessage: string | null | undefined;

  const {
    data: getURLResponseData,
    error: getURLResponseError
  } = getURLResponse;

  if (
    getURLResponseData &&
    !getURLResponseError &&
    getURLResponseData.account?.items
  ) {
    redirectURL =
      getURLResponseData.account.items[0]?.policy?.policy_change?.redirect_url;
    errorMessage =
      getURLResponseData.account.items[0]?.policy?.policy_change?.error_message;
  }

  return (
    <ChangePolicyDropdown
      policyNumber={policyNumber}
      accountNumber={accountNumber}
      sourceSysCode={code}
      clChangeWithDocument={clChangeWithDocument}
      makeAPolicyChange={getURL}
      maxEffectiveDate={maxEffectiveDate}
      minEffectiveDate={minEffectiveDate}
      test-attr="policy-change-dropdown"
      renewalDate={renewalDate}
      errorMessage={errorMessage}
      status={status}
      policyType={type}
      returnedMessage={returnedMessages}
      pcOutage={policyCenterOutageFlagDetails?.enabled}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      multipleChangesIndicator={multipleChangesIndicator}
      readOnlyAccess={readOnlyAccess}
      readOnlyFeatureFlag={readOnlyFeatureFlag}
      isFarmPolicy={isFarmPolicy}
    />
  );
};
