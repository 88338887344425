import React from 'react';
import { Modal } from '..';
import {
  ModalBody,
  ModalTitle,
  ModalInfoBanner,
  InfoIcon,
  AddressBlockWrap,
  AddressBlockLeft,
  AddressBlockRight,
  AddressTitle,
  AddressText,
  AddressCopy,
  ModalCloseButton
} from './PaymentAddressStyles';

interface AddressBlockProps {
  title: string;
  address: string[];
}

export function ModalAddressBlock({ title, address }: AddressBlockProps) {
  const copyAddress = () => {
    navigator.clipboard.writeText(address.join(', '));
  };

  return (
    <>
      <AddressTitle>{title}</AddressTitle>
      <AddressBlockWrap>
        <AddressBlockLeft>
          {address.map((addr: string) => (
            <AddressText key={addr}>{addr}</AddressText>
          ))}
        </AddressBlockLeft>
        <AddressBlockRight>
          <AddressCopy onClick={() => copyAddress()}>Copy</AddressCopy>
        </AddressBlockRight>
      </AddressBlockWrap>
    </>
  );
}

interface PaymentAddressProps {
  showModal: boolean;
  setShowModal: Function;
}

export default ({ showModal, setShowModal }: PaymentAddressProps) => {
  return (
    <Modal
      showModal={showModal}
      onEsc={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
    >
      <ModalBody>
        <ModalTitle>Payment Address</ModalTitle>
        <ModalInfoBanner>
          <InfoIcon /> Only mortgagee billed can be paid by mail.
        </ModalInfoBanner>
        <ModalAddressBlock
          title="Regular Mail"
          address={[
            'State Auto Insurance Companies',
            'PO Box 776721',
            'Chicago, IL 60677-6721'
          ]}
        />
        <ModalAddressBlock
          title="Overnight Mail"
          address={[
            'PNC Bank',
            'C/O State Automobile Mutual',
            'Insurance Lockbox #776721',
            '350 East Devon Ave',
            'Itasca, IL 60143'
          ]}
        />
        <ModalCloseButton onClick={() => setShowModal(false)}>
          CLOSE
        </ModalCloseButton>
      </ModalBody>
    </Modal>
  );
};
