import React from 'react';
import styled from 'styled-components';
import { Upload } from '../../icons';

const DropBox = styled.div`
  justify: center;
  align: center;
  border: 1px solid #80b8e2;
  border-radius: 4px;
  margin-top: 30px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
`;
const CloudBackground = styled.div`
  background-color: #f4f6fa;
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  width: 300px;
  margin: auto;
`;
const DropText = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
`;
export const SelectButton = styled.label`
  font-weight: 700;
  font-size: 14px;
  background-color: #0173c6;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #0173c6;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;
export const SelectFiles = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface dropProps {
  data: any;
  dispatch: any;
  stagingHandler: Function;
}

export default ({ data, dispatch, stagingHandler }: dropProps) => {
  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
  };
  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };
  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files: any[] = [];
    let i = 0;
    for (i = 0; i < e.dataTransfer.files.length; i += 1) {
      files.push(e.dataTransfer.files[i]);
    }

    dispatch({ type: 'ADD_FILE_TO_LIST', files });
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    stagingHandler(true);
  };
  const chooseFiles = (e: any) => {
    const files: any[] = [];
    let i = 0;
    for (i = 0; i < e.length; i += 1) {
      files.push(e[i]);
    }

    dispatch({ type: 'ADD_FILE_TO_LIST', files });
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    stagingHandler(true);
  };
  return (
    <DropBox
      onDrop={e => drop(e)}
      onDragOver={e => dragOver(e)}
      onDragLeave={e => dragLeave(e)}
    >
      <CloudBackground>
        <Upload />
        <DropText>Drag and Drop Files here</DropText>
      </CloudBackground>
      <DropText>or</DropText>

      <SelectButton htmlFor="file">
        SELECT FILES
        <SelectFiles
          type="file"
          name="file"
          id="file"
          multiple
          onChange={e => {
            chooseFiles(e.target.files);
          }}
          accept=".pdf, image/*"
        />
      </SelectButton>
    </DropBox>
  );
};
