import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/GrayCloseIcon.svg';

export const ModalBackground = styled.div`
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #041e41bf;
  z-index: 101;
`;

export const ModalBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  gap: 30px;
  width: 444px;
  margin: auto;
  border-radius: 3px;
`;

export const ModalHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderText = styled.span`
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: #4d840b;
  color: #ffffff;
  padding: 8px 25px 9px 25px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  cursor: pointer;
`;

export const ContentText = styled.span`
  font-size: 16px;
  line-height: 20px;
  width: 100%;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
