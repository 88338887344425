import React from 'react';
import { useParams, RouteComponentProps } from '@reach/router';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { AccountInformationPage } from '.';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import { useGlobalContext } from '../../util/globalContextProvider';
import { GET_POLICY_STATUS } from './graphql/__generated__/GET_POLICY_STATUS';
import {
  agencyAndDirectBilled,
  agencyBilledOnly
} from '../../util/agencyBilledUtils';
const get_system_code = loader(
  './graphql/Account_Info_Page_Get_Account_System_Code.graphql'
);

const get_policy_status = loader('./graphql/Get_Policy_Status.graphql');
const get_migrated_status = loader('../../graphql/Has_Migrated_Policy.graphql');

export default (_: RouteComponentProps) => {
  const { accountNumber } = useParams();
  const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();

  const { flagDetails: billingCenterOutageFlagDetails } = useFeatureFlag(
    'BillingCenter-Outage'
  );

  const { flagDetails: policyCenterOutageFlagDetails } = useFeatureFlag(
    'PolicyCenter-Outage'
  );

  const variables = {
    account_number: accountNumber
  };

  const queryResult = useQuery(get_system_code, {
    variables
  });

  const { loading, error } = queryResult;

  const { data } = queryResult;

  let systemCode = null;
  let billingmethod = null;
  let isAgencyBilledOnly = false;
  let isAgencyAndDirect = false;
  if (data?.account.items && data.account.items[0]?.source_system_code) {
    systemCode = data.account.items[0]?.source_system_code.toLowerCase();
  }
  if (data?.account.items && data.account.items[0]?.payplans?.items) {
    billingmethod = data.account.items[0]?.payplans.items[0]?.billing_method;
    if (billingmethod) {
      isAgencyBilledOnly = agencyBilledOnly(data);
      isAgencyAndDirect = agencyAndDirectBilled(data);
    }
  }
  // get migrated status
  const getMigratedStatusQueryResult = useQuery(get_migrated_status, {
    variables
  });
  const { data: migratedStatusData } = getMigratedStatusQueryResult;
  let isMigrated: boolean = false;
  if (
    migratedStatusData &&
    migratedStatusData?.hasMigratedPolicy &&
    migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy
  ) {
    isMigrated = migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy;
  }

  const props = {
    accountNumber,
    systemCode,
    billingCenterOutage: billingCenterOutageFlagDetails?.enabled,
    policyCenterOutage: policyCenterOutageFlagDetails?.enabled,
    readOnlyAccess,
    isMigrated,
    readOnlyFeatureFlag,
    isAgencyBilledOnly,
    isAgencyAndDirect
  };

  return <AccountInformationPage {...props} />;
};
