import React, { useState, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { GreenCalendar } from '../../icons';
import {
  Label,
  Input,
  Text,
  ChangeForm,
  InputGroup,
  EffectiveDateDivWithIcon,
  BelowEffectiveDateDiv,
  GreenCalendarDiv,
  ButtonContainer,
  CancelButton,
  StartChangeButton,
  SelectButton,
  SelectFiles,
  DocumentBox,
  DocumentName,
  DocumentText,
  ErrorMessage,
  TextArea,
  DatePickerRow,
  Loading,
  PolicyTermContainer,
  PolicyTermInfoWrap,
  InfoIcon,
  PolicyTermInfoText,
  PolicyTermTitle,
  PolicyTermOption,
  PolicyTermOptionsRadio,
  PolicyTermOptionsLabel,
  DescriptionTextOptional,
  DatePickerWrapper,
  DatePickerModalBackground,
  Checkbox,
  CheckboxText,
  CheckboxOverallContainer,
  CheckboxIndividualContainer,
  CheckboxContainer,
  IconDiv
} from './changePolicyStyles';
import SuccessMessage from './successMessage';
import { ReactComponent as OrangeAlertTriangle } from '../../assets/images/OrangeAlertTriangle.svg';
import spinnerLight from '../../assets/images/spinner-light.svg';
import { formatDate } from '../../util/formatters';
import { MAKE_A_POLICY_CHANGEVariables } from './graphql/__generated__/MAKE_A_POLICY_CHANGE';
import DatePicker from '../datePicker';
import { GET_CHANGE_POLICY_INFO_account_items_policy_valid_policy_change_messages } from './graphql/__generated__/GET_CHANGE_POLICY_INFO';
import { CommercialUmbrellaURLMessage } from '.';

const policyTermOptionsArr = ['Current Term', 'Current and Renewal Term'];

interface changeFormProps {
  changePolicy: Function;
  isOpen: boolean;
  policyNumber: string;
  accountNumber: string;
  maxEffectiveDate?: string | undefined;
  minEffectiveDate?: string | undefined;
  policyType: string | undefined;
  clChangeWithDocument: Function;
  setIsOpen: Function;
  renewalDate: string | null;
  status: string | undefined | null;
  returnedMessage?:
    | (GET_CHANGE_POLICY_INFO_account_items_policy_valid_policy_change_messages | null)[]
    | null;
  isLoading: boolean;
  setIsLoading: Function;
  multipleChangesIndicator: boolean | undefined;
  isFarmPolicy: boolean;
}

interface ErrorsProps {
  effectiveDate?: boolean;
  validateEffectiveDate?: boolean;
  description?: boolean;
  file?: boolean;
  api?: boolean;
}

export default ({
  changePolicy,
  isOpen,
  policyNumber,
  policyType,
  clChangeWithDocument,
  setIsOpen,
  renewalDate,
  accountNumber,
  minEffectiveDate,
  maxEffectiveDate,
  status,
  returnedMessage,
  isLoading,
  setIsLoading,
  multipleChangesIndicator,
  isFarmPolicy
}: changeFormProps) => {
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState<boolean>(
    false
  );
  const [effectiveDate, setEffectiveDate] = useState('');
  const [description, setDescription] = useState('');
  const [effectiveDateInputSelected, setEffectiveDateSelected] = useState(
    false
  );
  const [descriptionInputSelected, setDescriptionSelected] = useState(false);
  const [documentInputSelected, setDocumentSelected] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [newDate, setNewDate] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [policyTerm, setPolicyTerm] = useState<string>(
    renewalDate ? policyTermOptionsArr[1] : policyTermOptionsArr[0]
  );
  const [
    businessownersChangeChoices,
    setBusinessOwnersChangeChoices
  ] = useState([false, false, false, false, false]);
  const [spinnerText, setSpinnerText] = useState<string>('UPLOADING');
  useEffect(() => {
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  }, [effectiveDate]);

  useEffect(() => {
    setEffectiveDate('');
    setDescription('');
  }, [policyNumber]);

  let documentNeeded = false;
  const getDocumentContents: Function = (f: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = err => {
        reject(err);
        return null;
      };
    });
  if (
    policyType === 'WORKERS COMP' ||
    policyType === 'COMMERCIAL PACKAGE' ||
    policyType === 'COMMERCIAL PROPERTY' ||
    policyType === 'COMMERCIAL GENERAL LIABILITY'
  ) {
    documentNeeded = true;
  }
  const validateEffectiveDate = () => {
    const effective = moment(effectiveDate);
    const min = moment(minEffectiveDate).subtract(1, 'day');
    const max = moment(maxEffectiveDate).add(1, 'day');
    return (
      effectiveDate &&
      minEffectiveDate &&
      maxEffectiveDate &&
      effective.isBetween(min, max)
    );
  };
  const makeAPolicyChange = () => {
    const updatedErrors: ErrorsProps = {};
    if (!validateEffectiveDate()) updatedErrors.validateEffectiveDate = true;
    if (isFarmPolicy && !description) updatedErrors.description = true;
    if (Object.keys(updatedErrors).length) {
      setErrors(updatedErrors);
      return;
    }
    setSpinnerText('PROCESSING');
    setIsLoading(true);
    const variables: MAKE_A_POLICY_CHANGEVariables = {
      account_number: accountNumber,
      policy_number: policyNumber,
      effective_date: effectiveDate,
      description,
      businessowners_options: businessownersChangeChoices
    };
    if (renewalDate)
      variables.apply_change_to_renewal =
        policyTerm === 'Current and Renewal Term';
    changePolicy({
      variables
    });
    simpleGTMDataLayer({
      event: 'changePolicyClick',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'changePolicy'
    });
    simpleGTMDataLayer({
      event: 'changePolicyClickSnowplow',
      event_action: 'Button Click',
      event_category: window.location.pathname + '/Snowplow',
      event_label: 'changePolicy'
    });
  };

  const showDocumentError = errors.file && !file;
  const showDescriptionError = errors.description && !description.length;
  const showEffectiveDateError = errors.effectiveDate && !effectiveDate.length;

  const uploadDocumentAndChangePolicy: Function = async () => {
    simpleGTMDataLayer({
      event: 'changePolicyClick',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'changePolicy'
    });
    simpleGTMDataLayer({
      event: 'changePolicyClickSnowplow',
      event_action: 'Button Click',
      event_category: window.location.pathname + '/Snowplow',
      event_label: 'changePolicy'
    });
    const updatedErrors: ErrorsProps = {};
    if (!effectiveDate) updatedErrors.effectiveDate = true;
    if (!validateEffectiveDate()) updatedErrors.validateEffectiveDate = true;
    if (!description) updatedErrors.description = true;
    if (!file) updatedErrors.file = true;
    if (Object.keys(updatedErrors).length) {
      setErrors(updatedErrors);
      return;
    }
    setIsLoading(true);
    let documentData;
    if (file) documentData = await getDocumentContents(file);
    const policyChangeVariables = {
      variables: {
        document_data: documentData,
        file_name: file?.name,
        mime_type: file?.type,
        policy_number: policyNumber,
        description,
        policy_type: policyType,
        effective_date: effectiveDate
      }
    };
    await clChangeWithDocument(policyChangeVariables)
      .then((response: any) => {
        const responseAttributes =
          response?.data?.commercialLinesPolicyChangeWithDocumentUpload;
        if (
          responseAttributes?.uw_activity_created === 'Activity Created' &&
          responseAttributes?.create_time
        ) {
          setDisplaySuccessMessage(true);
        }
      })
      .catch((error: Error) => {
        console.error(error);
        setErrors({
          api: true
        });
        setIsLoading(false);
      });
  };

  let useCustomErrorMessage = false;
  let customErrorMessage = '';

  if (
    status?.toUpperCase() === 'CANCELLED' ||
    status?.toUpperCase() === 'CANCELING'
  ) {
    useCustomErrorMessage = true;
    customErrorMessage = `Policy is ${
      status.toUpperCase() === 'CANCELLED'
        ? 'cancelled'
        : 'pending cancellation'
    }. Unable to start policy change.`;
  } else if (
    returnedMessage &&
    returnedMessage[0] &&
    returnedMessage[0].message &&
    returnedMessage[0]?.name === 'PolicyChangeRenewalError' &&
    returnedMessage[0].message[0]
  ) {
    useCustomErrorMessage = true;
    customErrorMessage = returnedMessage[0].message[0];
  } else if (errors.api) {
    customErrorMessage =
      'We were unable to process your request. Please try again later or contact your underwriter for assistance.';
  } else if (policyType === 'COMMERCIAL UMBRELLA') {
    useCustomErrorMessage = true;
    customErrorMessage = '';
  } else if (errors.validateEffectiveDate) {
    customErrorMessage = `The date you entered is invalid, please enter a date between ${moment(
      minEffectiveDate
    ).format('MM/DD/YYYY')} and ${moment(maxEffectiveDate).format(
      'MM/DD/YYYY'
    )}`;
  } else {
    customErrorMessage = 'All the fields are required.';
  }

  return (
    <div>
      <ChangeForm test-attr="change-policy-dropdown-frame">
        {displaySuccessMessage ? (
          <SuccessMessage
            effectiveDate={effectiveDate}
            closeDropdown={setIsOpen}
          />
        ) : (
          <>
            {(Object.keys(errors).length > 0 ||
              useCustomErrorMessage === true) && (
              <ErrorMessage test-attr="policy-change-error-message">
                <IconDiv>
                  <OrangeAlertTriangle />
                </IconDiv>
                <span test-attr="policy-change-error-message-text">
                  {customErrorMessage}
                  <CommercialUmbrellaURLMessage
                    returnedMessage={returnedMessage}
                    policyType={policyType}
                  />
                </span>
              </ErrorMessage>
            )}
            {useCustomErrorMessage === false && (
              <div>
                {multipleChangesIndicator && (
                  <ErrorMessage>
                    <IconDiv>
                      <OrangeAlertTriangle />
                    </IconDiv>
                    <span test-attr="policy-change-multiple-changes-warning-message">
                      Please review the incomplete policy change transaction
                      prior to starting a new change.
                    </span>
                  </ErrorMessage>
                )}
                <InputGroup>
                  <Label
                    active={effectiveDateInputSelected}
                    showError={showEffectiveDateError}
                  >
                    Effective Date
                  </Label>
                  <DatePickerRow>
                    <EffectiveDateDivWithIcon
                      showError={showEffectiveDateError}
                      test-attr="policy-change-effective-date-with-icon"
                      onChange={event => {
                        setEffectiveDate(event.target.value);
                      }}
                      value={effectiveDate}
                    ></EffectiveDateDivWithIcon>
                    <GreenCalendarDiv
                      test-attr="policy-change-green-calendar-icon"
                      onClick={() => {
                        setShowCalendar(!showCalendar);
                      }}
                    >
                      <GreenCalendar />
                    </GreenCalendarDiv>
                  </DatePickerRow>
                  <BelowEffectiveDateDiv>
                    <Text>
                      Your date must be between{' '}
                      {moment(minEffectiveDate, 'YYYY-MM-DD').format(
                        'MM/DD/YYYY'
                      )}{' '}
                      and{' '}
                      {moment(maxEffectiveDate, 'YYYY-MM-DD').format(
                        'MM/DD/YYYY'
                      )}
                    </Text>
                  </BelowEffectiveDateDiv>
                  {showCalendar ? (
                    <DatePickerModalBackground
                      onClick={() => {
                        setShowCalendar(false);
                      }}
                    >
                      <DatePickerWrapper>
                        <DatePicker
                          setDate={setEffectiveDate}
                          minDate={minEffectiveDate}
                          maxDate={maxEffectiveDate}
                          onClickCancel={() => setShowCalendar(false)}
                        />
                      </DatePickerWrapper>
                    </DatePickerModalBackground>
                  ) : (
                    <></>
                  )}
                </InputGroup>
                {policyType === 'BUSINESSOWNERS' && (
                  <CheckboxOverallContainer test-attr="policy-change-businessowners-options">
                    {' '}
                    <CheckboxIndividualContainer>
                      <CheckboxContainer>
                        <Checkbox
                          test-attr="policy-change-businessowners-option-1"
                          type="checkbox"
                          onChange={() => {
                            setBusinessOwnersChangeChoices(prevState =>
                              prevState.map((state, index) =>
                                index === 0 ? !state : state
                              )
                            );
                          }}
                        />
                      </CheckboxContainer>
                      <CheckboxText>Business Information</CheckboxText>
                    </CheckboxIndividualContainer>
                    <CheckboxIndividualContainer>
                      <CheckboxContainer>
                        <Checkbox
                          test-attr="policy-change-businessowners-option-2"
                          type="checkbox"
                          onChange={() => {
                            setBusinessOwnersChangeChoices(prevState =>
                              prevState.map((state, index) =>
                                index === 1 ? !state : state
                              )
                            );
                          }}
                        />
                      </CheckboxContainer>
                      <CheckboxText>Industry/Classification Types</CheckboxText>
                    </CheckboxIndividualContainer>
                    <CheckboxIndividualContainer>
                      <CheckboxContainer>
                        <Checkbox
                          test-attr="policy-change-businessowners-option-3"
                          type="checkbox"
                          onChange={() => {
                            setBusinessOwnersChangeChoices(prevState =>
                              prevState.map((state, index) =>
                                index === 2 ? !state : state
                              )
                            );
                          }}
                        />
                      </CheckboxContainer>
                      <CheckboxText>
                        Location, Building, or Classification Details
                      </CheckboxText>
                    </CheckboxIndividualContainer>
                    <CheckboxIndividualContainer>
                      <CheckboxContainer>
                        <Checkbox
                          test-attr="policy-change-businessowners-option-4"
                          type="checkbox"
                          onChange={() => {
                            setBusinessOwnersChangeChoices(prevState =>
                              prevState.map((state, index) =>
                                index === 3 ? !state : state
                              )
                            );
                          }}
                        />
                      </CheckboxContainer>
                      <CheckboxText>
                        Limits, Coverages, or Deductibles
                      </CheckboxText>
                    </CheckboxIndividualContainer>
                    <CheckboxIndividualContainer>
                      <CheckboxContainer>
                        <Checkbox
                          test-attr="policy-change-businessowners-option-5"
                          type="checkbox"
                          onChange={() => {
                            setBusinessOwnersChangeChoices(prevState =>
                              prevState.map((state, index) =>
                                index === 4 ? !state : state
                              )
                            );
                          }}
                        />
                      </CheckboxContainer>
                      <CheckboxText>Other</CheckboxText>
                    </CheckboxIndividualContainer>
                  </CheckboxOverallContainer>
                )}
                <InputGroup alignLastItemRight>
                  {documentNeeded || isFarmPolicy ? (
                    <>
                      <TextArea
                        maxLength={250}
                        showError={showDescriptionError}
                        onFocus={() => setDescriptionSelected(true)}
                        onBlur={() => setDescriptionSelected(false)}
                        value={description}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setDescription(e.target.value.replace(/\n/g, ''));
                        }}
                        test-attr="policy-change-description-text-area"
                        placeholder="Please tell us the reason for policy change. (required)"
                      />
                      <Text>{description.length}/250</Text>
                    </>
                  ) : (
                    <>
                      <Label
                        active={descriptionInputSelected}
                        showError={showDescriptionError}
                      >
                        Description
                      </Label>
                      <Input
                        onFocus={() => setDescriptionSelected(true)}
                        onBlur={() => setDescriptionSelected(false)}
                        value={description}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setDescription(e.target.value);
                        }}
                        test-attr="policy-change-description-input"
                      />
                      <DescriptionTextOptional>
                        Optional
                      </DescriptionTextOptional>
                    </>
                  )}
                </InputGroup>
                {documentNeeded && (
                  <DocumentBox>
                    <DocumentName
                      showError={showDocumentError}
                      test-attr="policy-change-document-name"
                    >
                      <Label
                        active={documentInputSelected}
                        showError={showDocumentError}
                      >
                        Document
                      </Label>
                      {file?.name ? (
                        <DocumentText test-attr="policy-change-file-name">
                          {file.name.length > 25
                            ? `${file.name.substring(0, 25)}...`
                            : file.name}
                        </DocumentText>
                      ) : (
                        <div />
                      )}
                    </DocumentName>
                    <SelectButton htmlFor="file">
                      <SelectFiles
                        type="file"
                        name="file"
                        id="file"
                        accept=".pdf, image/*"
                        onChange={e => {
                          const files: FileList | null | undefined =
                            e?.target?.files;
                          if (files && files.length) {
                            setFile(files[0]);
                          }
                        }}
                        test-attr="policy-change-file-input"
                      />
                      Browse
                    </SelectButton>
                  </DocumentBox>
                )}
                {renewalDate && !documentNeeded && (
                  <PolicyTermContainer test-attr="policy-change-term-container">
                    <PolicyTermInfoWrap>
                      <InfoIcon />
                      <PolicyTermInfoText test-attr="policy-change-term-info-test">
                        The renewal term begins on&nbsp;
                        <b>{formatDate(renewalDate)}</b>
                      </PolicyTermInfoText>
                    </PolicyTermInfoWrap>
                    <PolicyTermTitle>
                      Apply the policy change to
                    </PolicyTermTitle>
                    {policyTermOptionsArr.map(option => (
                      <PolicyTermOption key={option}>
                        <PolicyTermOptionsRadio
                          id={option.replace(' ', '_')}
                          type="radio"
                          name="Policy_Term_Option"
                          value={option}
                          checked={option === policyTerm}
                          onChange={() => setPolicyTerm(option)}
                        />
                        <PolicyTermOptionsLabel
                          htmlFor={option.replace(' ', '_')}
                        >
                          {option}
                        </PolicyTermOptionsLabel>
                      </PolicyTermOption>
                    ))}
                  </PolicyTermContainer>
                )}
                <ButtonContainer>
                  <CancelButton
                    type="button"
                    onClick={() => {
                      setEffectiveDate('');
                      setDescription('');
                      setFile(null);
                      setErrors({});
                      simpleGTMDataLayer({
                        event: 'cancelPolicyChangeDropdownClick',
                        event_action: 'Button Click',
                        event_category: window.location.pathname,
                        event_label: 'cancelChangePolicyDropdown'
                      });
                      simpleGTMDataLayer({
                        event: 'cancelPolicyChangeDropdownClickSnowplow',
                        event_action: 'Button Click',
                        event_category: window.location.pathname + '/Snowplow',
                        event_label: 'cancelChangePolicyDropdown'
                      });
                    }}
                    test-attr="policy-change-cancel-button"
                  >
                    CANCEL
                  </CancelButton>
                  {isLoading ? (
                    <Loading>
                      <img src={spinnerLight} alt="spinner" />
                      <span>{spinnerText}</span>
                    </Loading>
                  ) : (
                    <StartChangeButton
                      test-attr="policy-change-start-change-button"
                      type="button"
                      onClick={async () => {
                        if (documentNeeded) {
                          await uploadDocumentAndChangePolicy();
                        } else {
                          makeAPolicyChange();
                        }
                      }}
                    >
                      START CHANGE
                    </StartChangeButton>
                  )}
                </ButtonContainer>
              </div>
            )}
          </>
        )}
      </ChangeForm>
    </div>
  );
};
