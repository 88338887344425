import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { customBase, colors } from '../../themes/default.theme';
import { formatDate } from '../../util/formatters';

const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;
const mediumBreakpoint = `${customBase.global.breakpoints.medium}px`;

const StyledCancelAndSubmitButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: 15px;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  cursor: pointer;
  border: 0px;
  border-radius: 4px;
  font-size: 18px;
  margin: 0;
  height: 45px;
  width: 163px;
`;

const ConfirmButton = styled.button`
  background-color: ${colors.green1};
  color: #ffffff;
  cursor: pointer;
  border: transparent;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  height: 45px;
  width: 163px;
`;

const StyledModal = styled.div`
  position: fixed;
  height: auto;
  background-color: #ffffff;
  border-radius: 3px;
  z-index: 100;
  *.StopPaymentModal {
    width: 502px;
    @media (max-width: ${xlargeBreakpoint}) {
      width: 402px;
    }
  }
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
`;

const StyledHeader = styled.div`
  justify-content: center;
  margin-top: 20px;
  margin-left: 25px;
  font-family: 'PT Sans';
  font-weight: bold;
  font-size: 18px;
`;

const StyledText = styled.div`
  margin: 20px 20px 5px 20px;
  @media (max-width: ${xlargeBreakpoint}) {
    margin-left: 10px;
  }
`;

interface cancelPaymentModalButtonProps {
  submitCancelPayment: Function;
  toggleShowModal: Function;
  numberOfPolicies: number;
  pmtDraftDate: string | null;
  errorState: boolean;
}

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export default ({
  submitCancelPayment,
  toggleShowModal,
  numberOfPolicies,
  pmtDraftDate,
  errorState
}: cancelPaymentModalButtonProps) => {
  const modal = useRef<any>();
  const documentClick = (e: MouseEvent) => {
    if (!modal?.current?.contains(e.target)) {
      toggleShowModal(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', documentClick);
    return () => {
      document.removeEventListener('mousedown', documentClick);
    };
  }, []);
  const cancelButtonWithFunction = (
    <CancelButton
      type="button"
      onClick={() => {
        simpleGTMDataLayer({
          event: 'cancelPaymentClick',
          event_action: 'Button Click',
          event_category: window.location.pathname,
          event_label: 'CANCEL STOP PAYMENT'
        });
        toggleShowModal();
      }}
    >
      Cancel
    </CancelButton>
  );

  const submitButtonWithFunction = (
    <ConfirmButton
      type="button"
      onClick={() => {
        simpleGTMDataLayer({
          event: 'cancelPaymentClick',
          event_action: 'Button Click',
          event_category: window.location.pathname,
          event_label: 'CONFIRM STOP PAYMENT'
        });
        submitCancelPayment();
      }}
    >
      CONFIRM
    </ConfirmButton>
  );
  let modalContainer: JSX.Element = <div />;
  if (errorState) {
    modalContainer = (
      <StyledModalContainer>
        <StyledText>
          We are unable to process your request. Please contact Customer
          Service.
        </StyledText>
        <StyledCancelAndSubmitButtons>
          {cancelButtonWithFunction}
        </StyledCancelAndSubmitButtons>
      </StyledModalContainer>
    );
  } else if (numberOfPolicies > 1) {
    modalContainer = (
      <StyledModalContainer>
        <StyledHeader>Stop Payment Notice</StyledHeader>
        <StyledText>
          The payment will be stopped for the payment due on,{' '}
          {formatDate(pmtDraftDate)}. If the payment is not made on the due
          date, and in the full amount, the policies are at risk for being
          canceled.
        </StyledText>
        <StyledText>
          Legal notice for these policies will be mailed and posted on the
          Customer Connect account, which will state the exact date and time of
          the cancellation. To keep these policies in force, payment must be
          received prior to the cancellation effective date, stated on the
          Notice of Cancellation for Non-Payment of Premium.
        </StyledText>

        <StyledCancelAndSubmitButtons>
          {cancelButtonWithFunction}
          {submitButtonWithFunction}
        </StyledCancelAndSubmitButtons>
      </StyledModalContainer>
    );
  } else {
    modalContainer = (
      <StyledModalContainer>
        <StyledHeader>Stop Payment Notice</StyledHeader>
        <StyledText>
          The payment will be stopped for the payment due on,{' '}
          {formatDate(pmtDraftDate)}. If the payment is not made on the due
          date, and in the full amount, the policy is at risk for being
          canceled.
        </StyledText>
        <StyledText>
          Legal notice for the policy will be mailed and posted on the Customer
          Connect account, which will state the exact date and time of the
          cancellation. To keep the policy in force, payment must be received
          prior to the cancellation effective date, stated on the Notice of
          Cancellation for Non-Payment of Premium.
        </StyledText>

        <StyledCancelAndSubmitButtons>
          {cancelButtonWithFunction}
          {submitButtonWithFunction}
        </StyledCancelAndSubmitButtons>
      </StyledModalContainer>
    );
  }
  return <StyledModal ref={modal} className='StopPaymentModal'>{modalContainer}</StyledModal>;
};
