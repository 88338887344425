import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import useFeatureFlag from './hooks/useFeatureFlag';

const get_user_id = loader('../components/changeDueDateModal/graphql/Change_Due_Date_Get_User_Id.graphql');

export interface contextProviderProps {
  children: any;
}

export const GlobalContext = createContext({
  readOnlyAccess: false,
  readOnlyFeatureFlag: false
});

export const useGlobalContext = () => useContext(GlobalContext);

const GlobalContextProvider = (props: contextProviderProps) => {
  const { flagDetails: readOnlyFeatureFlag } = useFeatureFlag('ReadOnlyAccess');
  const readOnlyResponse = useQuery(get_user_id);
  const { data: readOnlyData } = readOnlyResponse;
  return (
    <GlobalContext.Provider
      value={{
        readOnlyAccess: readOnlyData?.me?.readOnly,
        readOnlyFeatureFlag: readOnlyFeatureFlag?.enabled
      }}
    >
      {props?.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
