import React from 'react';
import IncidentDetails from './IncidentDetails';
import InjuredPersonDetails from './InjuredPersonDetails';
import InjuryDetails from './InjuryDetails';
import { Wrapper } from '../ExpandedViewStyles';
import { Spinner, Loading } from '../ExpandedViewStyles';
import { PrimarySpinner } from '../../../icons';
interface DetailsProps {
  getWCDetails?:any
  loading?: boolean;
  name?:string;
}
const Details: React.FC<DetailsProps> = ({ getWCDetails,loading,name}) => {
  return (
    <Wrapper>
      {loading ? (
        <Loading>
          <Spinner>
            <PrimarySpinner />
          </Spinner>
          Loading ...
        </Loading>
      ) : (
        <>
          <IncidentDetails getWCDetails={getWCDetails} />
          <InjuredPersonDetails name={name}/>
          <InjuryDetails getWCDetails={getWCDetails} />
        </>
      )}
    </Wrapper>
  );
};

export default Details;
