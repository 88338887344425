import React, { useState } from 'react';
import {
  Accident,
  PrimaryContact,
  ChevronUp,
  ChevronDownUnbolded
} from '../../../icons';
import {
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
  Label,
  Value,
  RowContainer,
  LabelDisplay,
  IconContainer,
  Icon
} from '../ExpandedViewStyles';
interface IncidentDetailsProps {
  getWCDetails: any;
}
const IncidentDetails: React.FC<IncidentDetailsProps> = ({ getWCDetails }) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <DropdownContainer open={open}>
      <DropdownHeader onClick={toggleDropdown} open={open}>
        <span>Incident Details</span>
        <Icon position='right' open={open}>{open ? <ChevronUp /> : <ChevronDownUnbolded />}</Icon>
      </DropdownHeader>
      <DropdownContent open={open}>
        <RowContainer>
          <IconContainer>
            <PrimaryContact />
          </IconContainer>
          <LabelDisplay>
            <Label>Primary Contact</Label>
            <Value>{getWCDetails?.employerContactDetails?.name || 'N/A'}</Value>
          </LabelDisplay>
  
        </RowContainer>
        <RowContainer>
          <IconContainer>
            <Accident />
          </IconContainer>
          <LabelDisplay>
            <Label>Cause of Injury</Label>
            <Value>{getWCDetails?.causeofinjury || 'N/A'}</Value>
          </LabelDisplay>
        </RowContainer>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default IncidentDetails;
