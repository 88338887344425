import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const Header = styled(Text)`
  display: flex;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  list-style: none;
  background-color: #f5f7fa;
  word-break: break-word;
`;

const HeaderRow = styled(Box)`
  flex-direction: row;
  background-color: #f5f7fa;
  width: 100%;
  padding: 10px 0px 10px 34px;
`;

const columns = [
  {
    property: 'date',
    header: 'Date'
  },
  {
    property: 'method',
    header: 'Method'
  },
  {
    property: 'status',
    header: 'Status'
  },
  {
    property: 'amount_paid',
    header: 'Amount'
  }
];

export default () => (
  <ResponsiveContext.Consumer>
    {responsive => (
      <HeaderRow
        fill={
          responsive === 'small' || responsive === 'medium'
            ? false
            : 'horizontal'
        }
        responsive
        overflow="auto"
      >
        {columns.map(column => (
          <Header key={column.property}>{column.header}</Header>
        ))}
      </HeaderRow>
    )}
  </ResponsiveContext.Consumer>
);
