import React from 'react';
import styled from 'styled-components';
import { DisplayOption } from '../SearchDisplayInterfaces';
import { colors } from '../../../themes/default.theme';


const StyledNormalDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledTextDiv = styled.div.attrs((props: {isNormal: boolean, option: DisplayOption}) => props)`
    font-weight: ${(props) => props.isNormal ? undefined : 'bold'};
    font-size: ${(props) => props.isNormal ? normalFontSize : bigFontSize};
    color: ${(props) => props.option.style === 'highlighted' ? colors.darkBlue2 : undefined};
    white-space: pre-wrap;
`;

const StyledTextDivWithNoOption = styled.div.attrs((props: {isNormal: boolean, option: DisplayOption}) => props)`
    font-weight: ${(props) => props.isNormal ? undefined : 'bold'};
    font-size: ${(props) => props.isNormal ? normalFontSize : bigFontSize};
`;

const bigFontSize = '16px';
const normalFontSize = '14px';

export const generateDisplayTextJSX = (
    isNormal: boolean,
    baseText: string,
    highlightText: DisplayOption[] = []
  ) => {
    if (highlightText.length) {
      return (
        <StyledNormalDiv>
          {highlightText.map((option: DisplayOption) => {
            return (
              <StyledTextDiv
                isNormal={isNormal}
                option={option}
              >
                {option.text}
              </StyledTextDiv>
            );
          })}
        </StyledNormalDiv>
      );
    }
    return (
      <StyledTextDivWithNoOption
      isNormal={isNormal}
      >
        {baseText}
      </StyledTextDivWithNoOption>
    );
  };