import styled from 'styled-components';

export const SelectPolicyModal = styled.div`
  width: 350px;

  font-style: 'PT-Sans';
  display: flex;
  flex-direction: column;
  background: #f4f6fa;
  border-radius: 2px;

`;

export const ModalHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const StyledText = styled.div`
  font-size: 14px;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 15px;
`;

export const StyledButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 90px;
  margin-bottom: 20px;
`;

export const StyledCancelButton = styled.button`
  font-size: 14px;
  background: transparent;
  border: none;
  height: 30px;
  min-width: 120px;
  border-radius: 3px;
  &:hover {
    background-color: #65a100;
    color: #f4f6fa;
  }
`;

export const StyledContinueButton = styled.button`
  font-size: 14px;
  background: transparent;
  border: none;
  min-width: 120px;
  border-radius: 3px;
  &:hover {
    background-color: #65a100;
    color: #f4f6fa;
  }
`;

export const StyledPolicyText = styled.div`
  margin-left: 5px;
  color: #4c4c4e;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContainer = styled.div`
  position: fixed;
  margin-left: 40%;
  margin-top: 250px;
`;

export const StyledDivHoldingPolicy = styled.div`
  font-size: 12px;
  position: absolute;
  margin-top: 92px;
  margin-left: 35px;
  background: #f4f6fa;
  text-align: left;
  z-index: 6;
  width: 50px;
`;

export const ChevronDiv = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  margin-left: 306px;
  margin-top: 113px;
  pointer-events: none;
  z-index: 6;
`;

export const PolicySelector = styled.div`
  height: 50px;
  margin-left: 20px;
  margin-right: 10px;
  text-align: left;
  vertical-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #00203833;
  &:hover {
    cursor: pointer;
  }
  background-color: #f4f6fa;
`;

export const StyledDropdownText = styled.div`
  margin-top: 8px;
`;

export const DropdownOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  height: 50px;
  margin-left: 20px;
  margin-right: 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 10px;
  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
  background-color: #f4f6fa;
`;

export const FullDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;
`;
export const Icon = styled.span`
  margin-top: 5px;
`;
export const PolicyListContainer = styled.div`
  max-height: 100px; 
  overflow-y: auto; 
  margin: 10px 0;
`;
