import React, { useState, useCallback, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  ChevronUp,
  ChevronDownUnbolded,
  Success,
  CloseSmall
} from '../../icons';
import { DropArea } from '.';
import { FileContent } from '..';
import {
  DocumentSelector,
  DropDown,
  Options,
  Content
} from '../uploadFileContent/FileContent';
import { GET_POLICIES_BY_ACCOUNT_account_items_policies_items } from '../billingPayPlans/graphql/__generated__/GET_POLICIES_BY_ACCOUNT';
import { SelectFiles } from './DropArea';
import spinnerLight from '../../assets/images/spinner-light.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

export interface modalProperties {
  staging: boolean;
  showModalHandler: Function;
  stagingHandler: Function;
  policies: GET_POLICIES_BY_ACCOUNT_account_items_policies_items[];
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

const Container = styled.div`
  z-index: 2;
  display: inline;
  position: absolute;
  margin: auto;
  align-self: center;
`;
const StaginModalContainer = styled.div`
  display: block;
  flex-direction: row;
  z-index: 1;
  left: 0;
  top: 0;
  max-width: 1000px;
  margin: auto;
  height: 600px;
`;
const StagingModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  border: 1px, solid, #979797;
  box-shadow: 0px 8px 32px -8px #002038;
  padding: 30px;
  background-color: #ffffff;
  align: center;
  justify: center;
  max-width: 1000px;
  align-content: center;
  justify-content: center;
`;
const UploadModalContainer = styled.div`
  display: inline;
  flex-direction: row;
  z-index: 1;
  left: 0;
  top: 0;
  max-width: 650px;
  margin: auto;
  max-height: 497px;
`;
const UploadModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  border: 1px, solid, #979797;
  box-shadow: 0px 8px 32px -8px #002038;
  padding: 30px;
  background-color: #ffffff;
  align: center;
  justify: center;
  max-width: 650px;
  max-height: 497px;
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;
const SubtitleText = styled.span`
  font-size: 14px;
`;
const SubtitleTextNote = styled.span`
  font-size: 14px;
  color: #9325b2;
  font-weight: 700;
`;
const StagingBox = styled.div`
  border: 1px solid #80b8e2;
  border-radius: 4px;
  margin-top: 30px;
  overflow: auto;
  height: 398px;
`;
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  vertical-align: middle;
`;
const FileControl = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;
export const CancelAnchor = styled.div`
  font-size: 14px;
  font-weight: 700;
  justify: end;
  align: end;
  text-align: right;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
`;
export const SelectButton = styled.label`
  font-weight: 700;
  font-size: 14px;
  background-color: #0173c6;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #0173c6;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;
export const UploadButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  background-color: #65a100;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #65a100;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const StagingGap = styled.div`
  margin-right: 260px;
`;

export const ErrorText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #e02020;
`;

const PolicyDropBox = styled.div`
  padding-left: 10px;
`;

const closing = keyframes`
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 30px;
    opacity: 0;
  }
`;
const persisting = keyframes`
from {
  bottom: 30px;
  opacity: 1;
}
to {
  bottom: 30px;
  opacity: 1;
}
`;

export const SuccessToast = styled.div`
  width: 370px;
  height: 77px;
  border-radius: 7px;
  border-left: 6px solid #65a100;
  background-color: #f2f8e6;
  z-index: 1;
  padding: 12px 27px 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  bottom: 0;
  align-self: center;
  position: absolute;
`;
const SuccessText = styled.div`
  font-weight: 700;
  size: 16px;
  color: #65a100;
`;
const DelayMessage = styled.div`
  color: #3b3b3b;
  size: 12px;
`;
const CheckSizing = styled.div`
  height: 20px;
  padding-right: 10px;
`;
export const CloseSizing = styled.div`
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;

export default ({
  staging,
  showModalHandler,
  stagingHandler,
  policies,
  readOnlyAccess,
  readOnlyFeatureFlag
}: modalProperties) => {
  const reducer = (
    state: { fileList: any[] },
    action: { type: any; inDropZone: any; files: any }
  ) => {
    switch (action.type) {
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'CLEAR_LIST':
        return { ...state, fileList: action.files };
      case 'REMOVE_FILE':
        return {
          ...state,
          fileList: state.fileList.filter(item => item !== action.files)
        };
      default:
        return { ...state, fileList: state.fileList.concat(action.files) };
    }
  };

  const [data, dispatch] = React.useReducer(reducer, {
    inDropZone: false,
    fileList: []
  });
  const [selectedPolicy, setPolicy] = useState('Select Policy');
  const [policyError, flagPolicyError] = useState(false);
  const [upload, setUpload] = useState(0);
  const [open, setOpen] = useState(false);
  const [spinnerOn, uploadFlag] = useState(0);
  const [successFlag, setSuccessFlag] = useState(false);
  const [successCount, setSuccessCount] = useState(1);
  const chooseFiles = (e: any) => {
    let files: any[] = [];
    let i = 0;
    for (i = 0; i < e.length; i += 1) {
      files.push(e[i]);
    }
    const existingFiles = data.fileList.map((f: { name: any }) => f.name);
    files = files.filter(f => !existingFiles.includes(f.name));

    dispatch({
      type: 'ADD_FILE_TO_LIST',
      inDropZone: false,
      files
    });
    stagingHandler(true);
  };
  const reset: any[] = [];
  const files = data.fileList;
  useEffect(() => {
    if (successCount === 0) {
      setSuccessFlag(true);
    }
  }, [successCount]);
  const [documentDrop, setDocumentDrop] = useState('');
  return (
    <Container id="Modal">
      {staging ? (
        <StaginModalContainer id="StagingModal">
          <StagingModal>
            <HeaderRow>
              <StagingGap>
                <HeaderText>Upload Document</HeaderText>
                <div>
                  <SubtitleTextNote>Note: </SubtitleTextNote>
                  <SubtitleText>
                    This area is not to be used for uploading claims related
                    documents.
                  </SubtitleText>
                </div>
              </StagingGap>
              <div>
                <DocumentSelector>
                  <DropDown
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {selectedPolicy}
                    {open ? <ChevronUp /> : <ChevronDownUnbolded />}
                  </DropDown>
                </DocumentSelector>
                {open && (
                  <Options>
                    {policies.map(item => {
                      return (
                        <Content
                          id={item?.policy_number!}
                          onClick={() => {
                            setPolicy(item?.policy_number!);
                            setOpen(false);
                          }}
                        >
                          {item.policy_number}
                        </Content>
                      );
                    })}
                  </Options>
                )}
                <PolicyDropBox>
                  {policyError && <ErrorText>Error: Select Policy</ErrorText>}
                </PolicyDropBox>
              </div>
            </HeaderRow>
            <StagingBox>
              {data.fileList.map((f: File) => {
                return (
                  <FileContent
                    name={f.name}
                    data={data}
                    dispatch={dispatch}
                    policy={selectedPolicy}
                    uploadState={upload}
                    file={f}
                    uploadFlag={uploadFlag}
                    spinnerOn={spinnerOn}
                    successCount={successCount}
                    setSuccessCount={setSuccessCount}
                    dropOn={documentDrop}
                    setDropOn={setDocumentDrop}
                  />
                );
              })}
            </StagingBox>
            <ButtonRow>
              <FileControl>
                <CancelAnchor
                  onClick={() => {
                    dispatch({
                      type: 'CLEAR_LIST',
                      inDropZone: false,
                      files: reset
                    });
                    stagingHandler(false);
                  }}
                >
                  CLEAR LIST
                </CancelAnchor>
                <SelectButton htmlFor="file">
                  ADD MORE FILES
                  <SelectFiles
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    onChange={e => {
                      chooseFiles(e.target.files);
                    }}
                    accept=".pdf, image/*"
                  />
                </SelectButton>
              </FileControl>
              <FileControl>
                <CancelAnchor
                  id="CloseButton"
                  onClick={() => {
                    simpleGTMDataLayer({
                      event: 'uploadDocumentClick',
                      event_action: 'Button Click',
                      event_category: window.location.pathname,
                      event_label: 'Cancel Upload Documents'
                    });
                    showModalHandler(false);
                  }}
                >
                  CLOSE
                </CancelAnchor>
                <UploadButton
                  onClick={() => {
                    simpleGTMDataLayer({
                      event: 'uploadDocumentClick',
                      event_action: 'Button Click',
                      event_category: window.location.pathname,
                      event_label: 'Upload Documents'
                    });
                    if (selectedPolicy === 'Select Policy') {
                      flagPolicyError(true);
                      setUpload(0);
                    } else {
                      flagPolicyError(false);
                      setUpload(upload + 1);
                      uploadFlag(files.length);
                      setSuccessCount(files.length);
                    }
                  }}
                  disabled={readOnlyAccess && readOnlyFeatureFlag}
                >
                  UPLOAD DOCUMENTS
                  {spinnerOn !== 0 && <img src={spinnerLight} alt="spinner" />}
                </UploadButton>
              </FileControl>
            </ButtonRow>
            {successFlag && (
              <SuccessToast>
                <CheckSizing>
                  <Success />
                </CheckSizing>
                <div>
                  <SuccessText>Your upload is complete</SuccessText>
                  <DelayMessage>
                    It may take several minutes for the document to display
                  </DelayMessage>
                </div>
                <CloseSizing
                  onClick={() => {
                    setSuccessFlag(false);
                  }}
                >
                  <CloseSmall />
                </CloseSizing>
              </SuccessToast>
            )}
          </StagingModal>
        </StaginModalContainer>
      ) : (
        <UploadModalContainer id="UploadModal">
          <UploadModal>
            <div>
              <HeaderText>Upload Document</HeaderText>
              <div>
                <SubtitleTextNote>Note: </SubtitleTextNote>
                <SubtitleText>
                  This area is not to be used for uploading claims related
                  documents.
                </SubtitleText>
              </div>
              <DropArea
                stagingHandler={stagingHandler}
                data={data}
                dispatch={dispatch}
              />
              <CancelAnchor
                id="CancelButton"
                onClick={() => {
                  simpleGTMDataLayer({
                    event: 'uploadDocumentClick',
                    event_action: 'Button Click',
                    event_category: window.location.pathname,
                    event_label: 'Cancel Upload Documents'
                  });
                  showModalHandler(false);
                }}
              >
                CANCEL
              </CancelAnchor>
            </div>
          </UploadModal>
        </UploadModalContainer>
      )}
    </Container>
  );
};
