import React from 'react';
import { DateTextBox, InfoIconSizing, Description, BoldedDescription } from './changeDueDateStyles';
import { AltInfo } from '../../icons';
import { daySuffix } from './changeDueDateModal';

interface dateProps {
  newDate: string;
  confirmation: boolean;
  current: string;
}

export default ({ newDate, confirmation, current }: dateProps) => {
  return (
    <DateTextBox>
      <InfoIconSizing>
        <AltInfo />
      </InfoIconSizing>
      <div>
        {confirmation ? (
          <Description>
            You have selected the{' '}
            {newDate ? (
              <BoldedDescription>
                {newDate}
                {daySuffix(newDate)}
              </BoldedDescription>
            ) : (
              <BoldedDescription>
                {current}
                {daySuffix(current)}
              </BoldedDescription>
            )}{' '}
            of each month for your new payment due date.
          </Description>
        ) : (
          <Description>
            Your current due date is{' '}
            <BoldedDescription>
              {current}
              {daySuffix(current)}
            </BoldedDescription>{' '}
            of every month.
          </Description>
        )}
      </div>
    </DateTextBox>
  );
};
