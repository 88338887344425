import React from 'react';
import { daySuffix } from './changeDueDateModal';
import { SuccessContainer, Success, SuccessIconSizing } from './changeDueDateStyles';
import { AltSuccess } from '../../icons';

interface successProps {
  newDate: string;
}

export default ({ newDate }: successProps) => {
  return (
    <SuccessContainer>
      <Success>
        <SuccessIconSizing>
          <AltSuccess />
        </SuccessIconSizing>
        <div>
          You have selected the {newDate}
          {daySuffix(newDate)} of each month for your new payment due day.
        </div>
      </Success>
    </SuccessContainer>
  );
};
