import React, { MouseEvent, MouseEventHandler } from 'react';
import {
  ModalBackground,
  ModalContent,
  HeaderText,
  StyledCloseIcon,
  InfoText,
  PhoneLink,
  CloseButton,
  TopRow,
  BottomRow
} from './ClaimsUnavaulableModalStyles';

interface ClaimsUnavailableModalProps {
  closeModal: Function;
}

export default ({ closeModal }: ClaimsUnavailableModalProps) => (
  <ModalBackground onClick={closeModal as MouseEventHandler<HTMLDivElement>}>
    <ModalContent
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <TopRow>
        <HeaderText>Claims is Currently Unavailable</HeaderText>
        <StyledCloseIcon
          onClick={closeModal as MouseEventHandler<HTMLOrSVGElement>}
        />
      </TopRow>
      <InfoText>
        Unfortunately, due to server maintenance, submitting and managing a
        claim online is currently unavailable. In the meantime, please call our
        Claims Department at{' '}
        <PhoneLink href="tel:+18777225246">1-877-SA-CLAIM</PhoneLink> to submit
        a claim or check back in 24 to 48 hours.
      </InfoText>
      <BottomRow>
        <CloseButton
          type="button"
          onClick={closeModal as MouseEventHandler<HTMLButtonElement>}
        >
          Close
        </CloseButton>
      </BottomRow>
    </ModalContent>
  </ModalBackground>
);
