import React from 'react';
import { Options, Content } from './FileContent';

interface OptionsProps {
  setOpenType: Function;
  setTypeOf: Function;
  setDocumentError: Function;
  setDocumentType: Function;
  state: string;
  AZEnabled: boolean;
  DDPEnabled: boolean;
}

const documentTypes = [
  ['prior_carrier_proof_Ext', 'Prior Carrier Proof'],
  ['cancellation_request_form_Ext', 'Cancellation Request Form'],
  ['no_loss_statement_Ext', 'No Loss Statement'],
  ['good_student_discount_Ext', 'Good Student Discount'],
  ['course_certificate_Ext', 'Course Certificate'],
  ['appraisal_Ext', 'Appraisal'],
  ['alarm_certificate_Ext', 'Alarm Certificate'],
  ['building_certificate_Ext', 'Building Certificate'],
  ['email', 'Email'],
  ['loss_history', 'Loss history'],
  ['underWritingLetter_Ext', 'State Auto - Underwriting Letter'],
  ['utility_upgrade_proof_Ext', 'Utility Roof Upgrade'],
  ['aerial_imagery_proof_Ext', 'Aerial Imagery Proof']
];
const filterDocTypes = (
  state: string,
  AZEnabled: boolean,
  DDPEnabled: boolean
) => {
  const filteredDocs = [];
  if (state === 'NC') {
    filteredDocs.push(['nc_proof_of_residency', 'NC Proof of Residence']);
  } else if (state === 'AZ' && AZEnabled) {
    filteredDocs.push([
      'firesubscription',
      'AZ - Proof of Fire Service Subscription'
    ]);
  } else if (state === 'MI') {
    filteredDocs.push([
      'medical_documentation_Ext',
      'MI only: Medical Documentation'
    ]);
  }
  if (
    DDPEnabled &&
    (state === 'AZ' ||
      state === 'IL' ||
      state === 'MI' ||
      state === 'MO' ||
      state === 'OH' ||
      state === 'TN')
  ) {
    filteredDocs.push(['driver_discovery_proof', 'Driver Discovery Proof']);
  }
  filteredDocs.push(['other', 'Other']);
  return filteredDocs;
};
export default ({
  setOpenType,
  setTypeOf,
  setDocumentError,
  setDocumentType,
  state,
  AZEnabled,
  DDPEnabled
}: OptionsProps) => {
  const filteredTypes = filterDocTypes(state, AZEnabled, DDPEnabled);
  const docTypes = documentTypes.concat(filteredTypes);
  return (
    <Options>
      {docTypes.map(item => {
        return (
          <Content
            id={item[0]}
            onClick={() => {
              setTypeOf(item[1]);
              setDocumentType(item[0]);
              setDocumentError(false);
              setOpenType(false);
            }}
          >
            {item[1]}
          </Content>
        );
      })}
    </Options>
  );
};
