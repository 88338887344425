import styled from 'styled-components';

export const TabRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 62px;
`;

interface TabProps {
  tabSelected: boolean;
}

export const Tab = styled.button<TabProps>`
  border: none;
  background-color: #ffffff;
  color: #3b3b3b;
  padding: 0;
  padding-bottom: 6px;
  border-bottom: 4px solid
    ${props => (props.tabSelected ? '#4d840b' : '#ffffff')};
`;

export const TabText = styled.span`
  font-size: 19.2px;
  font-weight: 700;
`;

export const Divider = styled.hr`
  margin: 0;
  border-top: 1px solid #d9dfeb;
`;

export const ButtonRow = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: end;
  gap: 14px;

  & > button {
    font-size: 19.2px;
    line-height: 23.04px;
    cursor: pointer;
  }
`;

export const DoneButton = styled.button`
  padding: 8px 15px 9px 15px;
  background-color: #4d840b;
  border: none;
  border-radius: 5px;
  color: #ffffff;
`;

export const CancelButton = styled.button`
  background-color: #ffffff;
  border: none;
  color: #24272d;
  padding: 8px 15px 9px;
`;
