import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import { GET_BILLING_PAY_PLAN_account_items_payplans_items } from './graphql/__generated__/GET_BILLING_PAY_PLAN';

import {
  CurrenctPoliciesListUI,
  CurrentPoliciesTableUI
} from './PayPlansCardBody';

export interface BillingPayPlansData
  extends GET_BILLING_PAY_PLAN_account_items_payplans_items {
  paid: number;
  remaining_balance: number;
}

export interface PayPlansCollection {
  billing_method: string;
  default_payment_method: string;
  pay_plan: string;
  payment_method_type: string;
  payplan_key: string;
  policy: string[];
  policy_number: string;
  policy_period_id: string;
  response_bill_indicator: string;
}

export interface paymentPlanData {
  payPlans: BillingPayPlansData[] | null | undefined;
}

interface billingpayPlansProps {
  payPlanDetailsFlag: boolean;
  loading?: boolean;
  error?: Error;
  plansData?: paymentPlanData;
  onClick: Function;
  method?: string;
  title?: string;
  automaticPaymentStatus?: string | null;
  policyPresent?: boolean;
  onPolicyNumberClick: Function;
}

export default ({
  payPlanDetailsFlag,
  loading,
  error,
  plansData,
  onClick,
  method,
  title,
  automaticPaymentStatus,
  policyPresent,
  onPolicyNumberClick
}: billingpayPlansProps) => {
  if (loading || payPlanDetailsFlag === undefined) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error</Text>;
  }

  return (
    <ResponsiveContext.Consumer>
      {responsive => (
        <Box align="start" flex overflow="hidden">
          {plansData?.payPlans?.length && policyPresent ? (
            <Box fill>
              {payPlanDetailsFlag ? (
                <CurrentPoliciesTableUI
                  payPlans={plansData?.payPlans}
                  method={method || ''}
                  onPolicyNumberClick={onPolicyNumberClick}
                />
              ) : (
                <CurrenctPoliciesListUI
                  payPlans={plansData?.payPlans}
                  onClick={onClick}
                  method={method || ''}
                  title={title || ''}
                  automaticPaymentStatus={automaticPaymentStatus || ''}
                />
              )}
            </Box>
          ) : (
            <Text size="medium">
              No payment plans found for this account...
            </Text>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
