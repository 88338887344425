import React, { useState } from 'react';
import styled from 'styled-components';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';
import {
  ButtonDiv,
  DisclaimerModal,
  ModalHeader,
  ModalText,
  StyledCancelButton,
  StyledOKButton,
  TextDiv
} from '../paymentOptionsStyles/makePaymentOptionsStyles';

export interface DisclaimerModalPLProps {
  showModal: Boolean;
  closeModal: Function;
  onContinue: Function;
  isMigrated?: Boolean;
  baseState?: string;
}

export default ({
  showModal,
  closeModal,
  onContinue,
  isMigrated,
  baseState
}: DisclaimerModalPLProps) => {
  if (showModal) {
    const disclaimerCTMsg = 'I understand that to pay by credit card/debit card I will be transferred to One Inc, a third-party payment processor, to collect my payment information and by clicking “OK” I will be transferred to One Inc. One Inc is a third-party payment solutions provider who will make a payment to State Auto on my behalf.';
    return (
      <DisclaimerModal>
        <TextDiv>
          <ModalHeader>Disclaimer</ModalHeader>
          {isMigrated ? (
            <ModalText>
              {baseState === 'Connecticut' ? disclaimerCTMsg : `I understand that by clicking "OK", I will be
              transferred to One Inc to collect my payment information. One Inc
              is a third-party payment solutions provider who will make a
              payment to State Auto on behalf of the insured. Payments made by
              ACH/eCHECK are not assessed this fee.`}
            </ModalText>
          ) : (
            <ModalText>
              {baseState === 'Connecticut' ? disclaimerCTMsg : `I understand that by clicking "OK", I will be
              transferred to One Inc to collect my payment information and a $5
              will apply to any payments made with my credit or debit card. One
              Inc is a third-party payment solutions provider who will make a
              payment to State Auto on behalf of the insured. Payments made by
              ACH/eCHECK are not assessed this fee.`}
            </ModalText>
          )}
        </TextDiv>
        <ButtonDiv>
          <StyledCancelButton
            onClick={() => {
              simpleGTMDataLayer({
                event: 'disclaimerModalCancelClickSnowplow',
                event_action: 'Snowplow PL Disclaimer Cancel Click',
                event_category: `${window.location.pathname}/Snowplow`,
                event_label:
                  'Snowplow PL Disclaimer Fee Modal Cancel Button Click'
              });
              simpleGTMDataLayer({
                event: 'disclaimerModalCancelClick',
                event_action: 'Disclaimer Cancel Click',
                event_category: window.location.pathname,
                event_label: 'PL Disclaimer Fee Modal Cancel Button Click'
              });
              closeModal();
            }}
          >
            CANCEL
          </StyledCancelButton>
          <StyledOKButton
            onClick={() => {
              simpleGTMDataLayer({
                event: 'disclaimerModalOKClickSnowplow',
                event_action: 'Snowplow Disclaimer OK Click',
                event_category: `${window.location.pathname}/Snowplow`,
                event_label: 'Snowplow PL Disclaimer Fee Modal OK Button Click'
              });
              simpleGTMDataLayer({
                event: 'disclaimerModalOKClick',
                event_action: 'Disclaimer OK Click',
                event_category: window.location.pathname,
                event_label: 'PL Disclaimer Fee Modal OK Button Click'
              });
              onContinue();
              closeModal();
            }}
          >
            OK
          </StyledOKButton>
        </ButtonDiv>
      </DisclaimerModal>
    );
  }
  return <div />;
};
