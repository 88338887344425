import React from 'react';
import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { Button, Anchor } from './default';
import { RowExpanded, RowCollapsed } from '../icons';

// Style guide https://stateautoinsurance.invisionapp.com/share/Q5V2ZZHNKE9#/screens/401254928

export const colors = {
  darkBlue1: '#041E41',
  darkBlue2: '#0173C6',
  lightGray1: '#D9DFEB',
  lightGray2: '#F4F6FA',
  darkGray1: '#3E5572',
  valueRed1: '#E02020',
  green1: '#65A100',
  purple: '#9325B2'
};

export const motifs = {
  primaryText: '#041E41',
  lightBorder: '#D9DFEB'
};

// These should be viewed as ranges
export const breakpoints = {
  xsmall: 0,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200
};

export const customBase = {
  select: {
    background: 'white',
    icons: {
      color: colors.green1
    }
  },
  global: {
    breakpoints: {
      small: {
        value: breakpoints.small
      },
      medium: {
        value: breakpoints.medium
      },
      large: {
        value: breakpoints.large
      },
      xlarge: {
        value: breakpoints.xlarge
      }
    },
    font: {
      family: 'PT Sans'
    },
    colors: {
      // Grommet overrides
      'accent-1': '#189ECC',
      'accent-2': '#F5A623',
      'accent-3': '#075097',
      'status-critical': '#D0021B',
      'status-error': '#D0021B',
      focus: 'rgba(0,0,0,0)',
      brand: '#0173C6',
      background: colors.lightGray2, // Site Content Background
      text: {
        dark: '#000000',
        light: motifs.primaryText
      },
      // State Auto style guide specific
      'action-color': colors.green1,
      'table-row-1': {
        background: '#FFFFFF'
      },
      'table-row-2': {
        background: '#F0F3F8'
      },
      'disabled-text': '#666666',
      active: '#E4E6EC'
    },
    focus: {
      border: {
        color: 'rgba(0,0,0,0)'
      }
    }
  },
  anchor: Anchor,
  button: Button,
  accordion: {
    icons: {
      collapse: RowExpanded,
      expand: RowCollapsed
    }
  }
};

const Default = deepMerge(grommet, customBase);

export default Default;
