import styled from 'styled-components';
import ChevronDown from '../../assets/images/dark-green-chevron-down.svg';

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;
  gap: 10px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataLabel = styled.span`
  font-weight: 700;
  font-size: 13.33px;
  line-height: 16px;
`;

export const DataValue = styled.span`
  font-size: 16px;
  line-height: 20.8px;
`;

interface PhoneRowProps {
  edit: boolean;
}

export const PhoneRow = styled.div<PhoneRowProps>`
  display: flex;
  gap: ${props => (props.edit ? '10px' : '30px')};

  & > div {
    ${props => (props.edit ? '160px;' : 'width: 112px;')}
  }
`;

export const FieldWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Dropdown = styled.select`
  box-sizing: border-box;
  width: 160px;
  padding: 10px;
  padding-right: 30px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${ChevronDown});
  background-repeat: no-repeat, repeat;
  background-position: right 10px top 50%, 0 0;
  border-radius: 4px;
  border-color: #e0e0e0;
  border-width: 1px;
  outline: none;
  font-size: 16px;
  line-height: 20.8px;
  color: #3b3b3b;
`;

interface FieldErrorProps {
  error?: boolean | undefined;
}

export const StyledInput = styled.input<FieldErrorProps>`
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  line-height: 20.8px;
  width: 160px;
  color: #3b3b3b;
  ${props => (props.error ? 'border-color: #b00020;' : '')};
`;

export const FieldLabel = styled.span<FieldErrorProps>`
  position: absolute;
  top: -12px;
  left: 7px;
  font-size: 12px;
  line-height: 18px;
  background-color: white;
  padding: 4px;
  ${props => (props.error ? 'color: #b00020;' : '')};
`;
