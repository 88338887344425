import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text, Select, Button } from 'grommet';
import styled from 'styled-components';
import moment from 'moment';
import { motifs } from '../../themes/default.theme';
import { StyledDataTable } from '..';
import { Email, Phone, Chat, ChevronRight, RowCollapsed } from '../../icons';
import { GET_COMMUNICATION_ITEMS_account_items_communications_items } from './graphql/__generated__/GET_COMMUNICATION_ITEMS';
import { formatDate } from '../../util/formatters';
import { CommType } from '../../__generated__/globalTypes';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import usePagination from '../../util/usePagination';

const CommIcon = styled.div`
  width: 0.875rem;

  padding-right: 16px;
  justify: start;
`;

const selectCommIcon = (type: CommType) => {
  if (type === CommType.EMAIL) {
    return <Email />;
  }
  if (type === CommType.Phone) {
    return <Phone />;
  }
  if (type === CommType.Chat) {
    return <Chat />;
  }
  return null;
};

const Chevron = styled(ChevronRight)`
  width: 1rem;
  flex-basis: 10%;
`;

export const Row = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${motifs.lightBorder};
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: space-between;
  padding: 1rem 0rem;
`;

const StyledText = styled.div`
  font-size: 0.875rem;
`;

const DateField = styled(StyledText)`
  font-weight: 700;
`;

const SelectWrapper = styled.div`
  button {
    border-color: ${motifs.lightBorder};
    border-radius: 3px;
    & > div {
      border-radius: 3px;
    }
    & input {
      font-weight: normal;
    }
  }
`;

const CommunicationPreview = styled.div`
  justify-content: start;
`;

const AccordionBox = styled(Box)`
  padding: 1rem 30px;
`;

enum CommunicationType {
  All = 'All communications',
  THIRTY = 'Last 30 days',
  SIXTY = 'Last 60 days',
  NINTY = 'Last 90 days'
}

const communicationTypeOptions = [
  CommunicationType.All,
  CommunicationType.THIRTY,
  CommunicationType.SIXTY,
  CommunicationType.NINTY
];

export interface CommunicationAccordionProps {
  isLoading?: boolean;
  error?: Error;
  onDocumentClick?: Function;
  pathname?: string;
  items?: GET_COMMUNICATION_ITEMS_account_items_communications_items[];
  accountNumber: string;
  onViewAll?: () => void | undefined;
}

export default ({
  items = [],
  isLoading,
  error,
  pathname,
  onDocumentClick,
  accountNumber,
  onViewAll
}: CommunicationAccordionProps) => {
  if (isLoading) {
    return <h3>Loading...</h3>;
  }

  if (error) {
    return <h3>Error</h3>;
  }

  const [selectedFilter, setSelectedFilter] = useState(CommunicationType.All);
  let numberOfDays: number;
  switch (selectedFilter) {
    case 'Last 30 days':
      numberOfDays = 30;
      break;
    case 'Last 60 days':
      numberOfDays = 60;
      break;
    case 'Last 90 days':
      numberOfDays = 90;
      break;
    default:
      numberOfDays = 0;
      break;
  }
  let filteredCommunications = items;
  if (numberOfDays) {
    filteredCommunications = items.filter(communication => {
      return moment(communication.created_date).isAfter(
        moment()
          .subtract(numberOfDays, 'days')
          .format()
      );
    });
  }
  const [pageCommunications, pageSelector] = usePagination({
    accountNumber,
    items: filteredCommunications,
    itemsPerPage: 25
  });

  filteredCommunications = filteredCommunications.slice(0, 10);

  return items.length ? (
    <Box>
      {pathname !== 'communications' && (
        <Box>
          <AccordionBox>
            {filteredCommunications.map((info: any, index: any) => (
              <Row
                key={index}
                flex={false}
                direction="row"
                gap="small"
                align="center"
                justify="start"
                onClick={() => {
                  if (onDocumentClick) {
                    onDocumentClick({
                      communication_id: info.id,
                      gtm_data: {
                        communicationType: info.type
                      }
                    });
                  }
                }}
              >
                <Item>
                  <Box direction="row">
                    <CommIcon>{selectCommIcon(info.type)}</CommIcon>
                    <Box pad={{ left: '10px' }}>
                      <DateField>{formatDate(info.created_date)}</DateField>
                      <CommunicationPreview>
                        {info.preview}
                      </CommunicationPreview>
                    </Box>
                  </Box>
                  <Chevron />
                </Item>
              </Row>
            ))}
          </AccordionBox>
          <Box
            fill="horizontal"
            align="end"
            pad={{ bottom: '30px', right: '30px' }}
          >
            <Box
              direction="row"
              gap="8px"
              justify="between"
              onClick={onViewAll}
            >
              <Text weight="bold" size="16px">
                VIEW ALL
              </Text>
              <Box height="9px">
                <RowCollapsed />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {pathname === 'communications' && (
        <Box responsive direction="column">
          <Box align="end" margin="small">
            <SelectWrapper>
              <Select
                options={communicationTypeOptions}
                value={selectedFilter}
                onChange={({ option }) => {
                  simpleGTMDataLayer({
                    event: 'click-communicationsFilter',
                    filterOption: option
                  });
                  setSelectedFilter(option);
                }}
              />
            </SelectWrapper>
          </Box>
          <Box background={{ color: 'white' }} fill="horizontal">
            <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
              {pageCommunications.length ? (
                <Box>
                  <StyledDataTable
                    onClickRow={({ datum }: any) => {
                      if (onDocumentClick) {
                        onDocumentClick({
                          communication_id: datum.id,
                          gtm_data: {
                            communicationType: datum.type
                          }
                        });
                      }
                    }}
                    sortable
                    columns={[
                      {
                        property: 'created_date',
                        header: (
                          <Text weight="bold" wordBreak="keep-all">
                            Date
                          </Text>
                        ),
                        render: datum => (
                          <Text>{formatDate(datum.created_date)}</Text>
                        ),
                        size: 'xxsmall'
                      },
                      {
                        property: 'Communications Page Icons',
                        header: '',
                        render: datum => (
                          <Text>{selectCommIcon(datum.type)}</Text>
                        ),
                        size: '8px',
                        align: 'center'
                      },
                      {
                        property: 'preview',
                        header: <Text weight="bold">Communication</Text>,
                        size: 'medium'
                      },
                      {
                        property: '',
                        header: '',
                        render: datum => (
                          <Button color="brand">View More</Button>
                        ),
                        align: 'end',
                        size: 'xsmall'
                      }
                    ]}
                    data={pageCommunications}
                    primaryKey="id"
                  />
                  <Box align="end">{pageSelector}</Box>
                </Box>
              ) : (
                <span page-data-test-id="noPageCommunications">
                  No Communications to display
                </span>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <span data-test-id="noCommunications">No Communications</span>
  );
};
