import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '../../assets/images/ErrorIcon.svg';

interface ContactErrorProps {
  errorMessage: string;
}

const ErrorWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #b00020;
  color: #590010;
  margin-top: 20px;
`;

const ErrorLightBackgorund = styled.div`
  box-sizing: border-box;
  margin-left: 5px;
  background-color: #f7e5e8;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 12px;
  gap: 15.75px;
  font-size: 16px;
  line-height: 20.8px;
  border-radius: 0 4px 4px 0;
  border: 1px solid #e8b2bc;
  border-left-width: 0;
`;

export default ({ errorMessage }: ContactErrorProps) => (
  <ErrorWrapper>
    <ErrorLightBackgorund>
      <ErrorIcon />
      <span>{errorMessage}</span>
    </ErrorLightBackgorund>
  </ErrorWrapper>
);
