import React from 'react';
import BillingPayments from './BillingPayments';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';

interface BillingPaymentProps {
  makePaymentClick: Function;
  allCancelled?: boolean;
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
  lastDayToPayFlag: boolean;
}

export default (props: BillingPaymentProps) => {
  const { flagDetails: billingCenterOutageFlagDetails } = useFeatureFlag(
    'BillingCenter-Outage'
  );
  return (
    <BillingPayments
      {...props}
      billingCenterOutage={billingCenterOutageFlagDetails?.enabled ?? false}
    />
  );
};
