import { navigate } from '@reach/router';
import getConfig, { Env } from '../../config';

const config = getConfig(process.env.REACT_APP_ENV as Env);

export default (reason_type: string, openURL: Function) => {
  let onClick: Function = () => {};
  switch (reason_type) {
    case 'IDCardDecPages':
      onClick = () => {
        navigate('./documents');
      };
      break;
    case 'PaymentReceived':
      onClick = () => {
        navigate('./billing');
      };
      break;
    case 'eSignature':
      onClick = () => {
        navigate('./documents');
      };
      break;
    case 'Refund':
      onClick = () => {
        navigate('./billing');
      };
      break;
    case 'PaymentChange':
      onClick = openURL;
      break;
    case 'MakeOneTimePayment':
      onClick = openURL;
      break;
    case 'CurrentBalance':
      onClick = () => {
        navigate('./billing');
      };
      break;
    case 'CompletePolicyChangeForAgent':
      onClick = () => {
        window.open(`${config.saconnect_url_endpoint}/?q=policies`, '_blank');
      };
      break;
    case 'Reinstatement':
      onClick = () => {};
      break;
    case 'BillingInquiry':
      onClick = () => {
        navigate('./billing');
      };
      break;
    /*  case 'DeviceQuestionIssue':
      onClick = () => {};
      break;  */
    case 'Fees':
      onClick = () => {
        navigate('./billing');
      };
      break;
    case 'PolicyCancellation':
      onClick = () => {
        window.open(`${config.saconnect_url_endpoint}/?q=policies`, '_blank');
      };
      break;
    case 'PremiumChange':
      onClick = () => {};
      break;
    case 'PremiumDifference':
      onClick = () => {};
      break;
    case 'StopPay':
      onClick = () => {
        navigate('./billing');
      };
      break;
    case 'LastDayToPay':
      onClick = () => {
        navigate('./billing');
      };
      break;
    case 'CompleteQuoteForAgent':
      onClick = () => {
        window.open(config.saconnect_url_endpoint, '_blank');
      };
      break;
    case 'EffectiveDateChanges':
      onClick = () => {
        window.open(`${config.saconnect_url_endpoint}/?q=policies`, '_blank');
      };
      break;
    default:
      onClick = () => {};
  }

  return onClick;
};
