import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import {
  FeedbackButtonContent,
  OpenFeedbackButton
} from './FeedbackModalStyles';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

interface feedbackCommentModalButtonProps {
  toggleShowModal: Function;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

export default ({
  toggleShowModal,
  readOnlyAccess,
  readOnlyFeatureFlag
}: feedbackCommentModalButtonProps) => (
  <OpenFeedbackButton
    type="button"
    onClick={() => {
      toggleShowModal();
      simpleGTMDataLayer({
        event: 'feedbackClick',
        event_action: 'Button Click',
        event_category: window.location.pathname,
        event_label: 'Open Feedback'
      });
      simpleGTMDataLayer({
        event: 'feedbackClickSnowplow',
        event_action: 'Button Click',
        event_category: `${window.location.pathname}/Snowplow`,
        event_label: 'Open Feedback'
      });
    }}
    disabled={readOnlyAccess && readOnlyFeatureFlag}
  >
    <FeedbackButtonContent>
      <EmailIcon fontSize="small" style={{ marginRight: '6px' }} /> FEEDBACK
    </FeedbackButtonContent>
  </OpenFeedbackButton>
);
