import React, { useState, useEffect, useRef } from 'react';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import {
  WarningMessage,
  InputContainer,
  WarningText,
  InputDropdownOptions,
  InputDropdownContainer,
  InputDropdownOption,
  InputName
} from './AdvancedFilterStyles';
import { RedInfo } from '../../icons';

interface InjuredNameInputProps {
  claimsList: ClaimsData[] | null;
  injuredName: string;
  setInjuredName: React.Dispatch<React.SetStateAction<string>>;
}
const InjuredNameInput = ({
  claimsList,
  injuredName,
  setInjuredName
}: InjuredNameInputProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInjuredName(event.target.value);
    setDropdownVisible(true);
  };
  const handleDropdownSelect = (value: string) => {
    setSelectedValue(value);
    setInjuredName(value);
    setDropdownVisible(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const filteredResults =
      claimsList && claimsList.length > 0
        ? claimsList
            .filter(
              (claim: ClaimsData | null): claim is ClaimsData =>
                claim !== null && typeof claim.name === 'string'
            )
            .filter(
              (claim: ClaimsData) =>
                claim.name &&
                claim.name.toLowerCase().includes(injuredName.toLowerCase())
            )
        : [];

    setShowWarning(filteredResults.length === 0);
  }, [claimsList, injuredName]);

  return (
    <>
      <InputContainer>
        <InputName
          showWarning={showWarning}
          type="text"
          value={injuredName}
          onChange={handleInputChange}
          placeholder="Injured Name"
        />
        {showWarning && (
          <WarningMessage>
            <RedInfo />
            <WarningText>No matching names found.</WarningText>
          </WarningMessage>
        )}
      </InputContainer>
      <InputDropdownContainer ref={dropdownRef}>
        {dropdownVisible && (
          <InputDropdownOptions visible={dropdownVisible}>
            {claimsList &&
              claimsList.map((claim: ClaimsData | null, index: number) => {
                if (claim && claim.name) {
                  const claimName = claim.name.toLowerCase();
                  const injuredNameLower = injuredName.toLowerCase();
                  if (claimName.includes(injuredNameLower)) {
                    return (
                      <InputDropdownOption
                        key={index}
                        onClick={() =>
                          claim.name && handleDropdownSelect(claim.name)
                        }
                        className={
                          selectedValue === claim.name ? 'selected' : ''
                        }
                      >
                        {claim.name}
                      </InputDropdownOption>
                    );
                  }
                }
                return null;
              })}
          </InputDropdownOptions>
        )}
      </InputDropdownContainer>
    </>
  );
};

export default InjuredNameInput;
