import React from 'react';
import {
  Container,
  LabelText,
  HorizontalLine,
  PremiumText
} from './QuotePremiumStyles';
import { formatMoney } from '../../util/formatters';

interface QuotePremiumProps {
  premium: number | null;
}

export default ({ premium }: QuotePremiumProps) => {
  return (
    <Container>
      <LabelText>Premium</LabelText>
      <HorizontalLine />
      <PremiumText test-attr="quote-premium-premium-text">
        {formatMoney(premium) ?? '-'}
      </PremiumText>
    </Container>
  );
};
