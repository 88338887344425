import React, { useState, FormEvent } from 'react';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import WcClaimsList from '../wcClaimsList/wcClaimsList';
import WcClaimsDetailWrapper from '../wcClaimsDetailWrapper/wcClaimsDetailWrapper';
import SearchAndFilterWCClaims from '../wcSearchAndFilter/SearchAndFilterWCClaims';
import SearchWCClaims from '../wcSearchAndFilter/SearchWCClaims';
import { ErrorIcon, PrimarySpinner } from '../../icons';
import {
  ButtonWrapper,
  ClaimsLeftWrapper,
  ClaimsRightWrapper,
  LayoutContainer,
  WcClaimsButton,
  Spinner,
  Loading
} from './WcClaimsTabStyles';

export interface ClaimsListProps {
  showOtherClaimHandler: Function;
  loading: boolean;
  claimsList: ClaimsData[] | null;
  accountNumber: string;
  claimsDetail: string;
}

export interface SelectedClaimsProps {
  accountNumber: string;
  policyNumber: string;
  claimNumber: string;
  hideClaim: boolean;
}

export default ({
  showOtherClaimHandler,
  loading,
  claimsList,
  accountNumber,
  claimsDetail
}: ClaimsListProps) => {
  const [selectedClaim, setSelectedClaim] = useState<string | boolean>(false);
  const [
    selectedClaimsObject,
    setSelectedClaimsObject
  ] = useState<SelectedClaimsProps | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewCloseIcon, setViewCloseIcon] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<ClaimsData[] | null>(null);
  const [emptySearchResults, setEmptySearchResults] = useState<boolean>(false);
  const selectClaimHandler = (
    claimNumber: string,
    policyNumber: string,
    hideClaim: boolean
  ) => {
    setSelectedClaim(claimNumber);
    setSelectedClaimsObject({
      claimNumber,
      accountNumber,
      policyNumber,
      hideClaim
    });
  };
  const handleReset = () => {
    handleSearch('');
    setEmptySearchResults(false);
    setSearchTerm('');
    handleSearch('');
    setViewCloseIcon(false);
  };

  const performSearch = (searchTerm: string) => {
    const filteredClaims = claimsList?.filter(
      claim =>
        (claim.claimnumber && claim.claimnumber.includes(searchTerm)) ||
        (claim.name &&
          claim.name.toUpperCase().includes(searchTerm.toUpperCase())) ||
        (claim.policynumber &&
          claim.policynumber.toUpperCase().includes(searchTerm.toUpperCase()))
    );

    setEmptySearchResults(filteredClaims ? filteredClaims.length === 0 : true);

    return filteredClaims ?? null;
  };
  const updateClaimsList = (newClaimsList: ClaimsData[] | null) => {
    setSearchResults(newClaimsList);
  };
  const handleSearch = (searchTerm: string) => {
    const results = performSearch(searchTerm);
    setSearchResults(results);
  };

  return (
    <>
      {loading ? (
        <>
          <Loading>
            <Spinner>
              <PrimarySpinner />
            </Spinner>
            Loading ...
          </Loading>
        </>
      ) : (
        <LayoutContainer>
          <ClaimsLeftWrapper>
            <ButtonWrapper>
              <WcClaimsButton
                onClick={() => {
                  showOtherClaimHandler();
                }}
              >
                RETURN TO OTHER CLAIMS
              </WcClaimsButton>
            </ButtonWrapper>

            {claimsList && (
              <>
                <SearchAndFilterWCClaims
                  onSearch={handleSearch}
                  searchBarPosition={true}
                  handleReset={handleReset}
                  viewCloseIcon={viewCloseIcon}
                  setViewCloseIcon={setViewCloseIcon}
                  claimsList={claimsList}
                  updateClaimsList={updateClaimsList}
                  setSearchResults={setSearchResults}
                  setEmptySearchResults={setEmptySearchResults}
                />
                <>
                  {emptySearchResults && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <div style={{ marginTop: '20px' }}>
                        <ErrorIcon width={90} height={90} />
                      </div>
                      <div style={{ marginTop: '10px' }}>No Results Found</div>
                      <div
                        style={{
                          border: '4px solid #0173C6',
                          backgroundColor: '#0173C6',
                          width: '100%',
                          height: '3vh',
                          marginTop: '20px'
                        }}
                      ></div>
                    </div>
                  )}
                  <WcClaimsList
                    selectClaimHandler={selectClaimHandler}
                    selectedClaim={selectedClaim}
                    claimsList={searchResults || claimsList}
                  />
                </>
              </>
            )}

            {!loading && !claimsList && <>No WC Claims available</>}
          </ClaimsLeftWrapper>
          <ClaimsRightWrapper>
            {selectedClaim && selectedClaimsObject ? (
              <WcClaimsDetailWrapper
                selectedClaim={selectedClaim}
                selectedClaimsObject={selectedClaimsObject}
              />
            ) : (
              <SearchWCClaims
                onSearch={handleSearch}
                searchBarPosition={false}
                handleReset={handleReset}
                viewCloseIcon={viewCloseIcon}
                setViewCloseIcon={setViewCloseIcon}
                claimsList={claimsList}
                setSearchResults={setSearchResults}
                setEmptySearchResults={setEmptySearchResults}
              />
            )}
          </ClaimsRightWrapper>
        </LayoutContainer>
      )}
    </>
  );
};
