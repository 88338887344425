import React from 'react';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

import {
  ClaimsPageRequestButton,
  PolicyPageRequestButtonWrap,
  RequestButtonIcon,
  RequestButtonText,
  RequestButtonLoading
} from './LossRunRequestButtonStyles';

import FileSRC from '../../../assets/images/File.svg';
import LoadingSRC from '../../../assets/images/spinner-primary.svg';

const GTMObj = (path: string) => {
  const event = `${path}-Loss Run Request`;
  return {
    event: `${event.replace(' ', '')}`,
    event_category: `${event}`,
    event_action: `${event} click`,
    event_label: `${event} click`,
    event_category_snowplow: `Snowplow ${event}`,
    event_action_snowplow: `Snowplow ${event} click`,
    event_label_snowplow: `Snowplow ${event} click`
  };
};

export const RequestBtnForClaims = ({ onClick }: { onClick: Function }) => (
  <ClaimsPageRequestButton
    onClick={() => {
      onClick();
      simpleGTMDataLayer(GTMObj('Claims'));
    }}
  >
    REQUEST A LOSS RUN
  </ClaimsPageRequestButton>
);

export const RequestButtonForPolicy = ({
  onClick,
  disabled
}: {
  onClick: Function;
  disabled: boolean;
}) => (
  <PolicyPageRequestButtonWrap
    className={`${disabled ? 'disabled' : ''}`}
    onClick={() => {
      if (!disabled) {
        onClick();
        simpleGTMDataLayer(GTMObj('Policy'));
      }
    }}
  >
    <RequestButtonIcon src={FileSRC} />
    <RequestButtonText>REQUEST A LOSS RUN</RequestButtonText>
    {disabled && <RequestButtonLoading src={LoadingSRC} />}
  </PolicyPageRequestButtonWrap>
);
