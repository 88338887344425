import React, { useState, useEffect } from 'react';
import { Box, Text, FormField, TextInput, Form, RadioButton } from 'grommet';
import styled from 'styled-components';
import { GET_CUSTOMER_INFO_account_items } from './graphql/__generated__/GET_CUSTOMER_INFO';
import { ResponsiveGrid } from '..';
import {
  formatPhone,
  formatTitleCase,
  formatZipcode
} from '../../util/formatters';
import { UPDATE_CONTACT_NUMBER_updateContactNumber } from './graphql/__generated__/UPDATE_CONTACT_NUMBER';
import { UPDATE_EMAIL_ADDRESS_updateEmailAddress } from './graphql/__generated__/UPDATE_EMAIL_ADDRESS';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

export interface AccountInformationEditProps {
  lastDayToPayFlag: boolean;
  isLoading?: boolean;
  error?: Error;
  accountInfo?: GET_CUSTOMER_INFO_account_items;
  phoneUpdate?: Function;
  phoneResponse?: UPDATE_CONTACT_NUMBER_updateContactNumber;
  accountNumber?: String;
  ID?: String;
  emailUpdate?: Function;
  emailResponse?: UPDATE_EMAIL_ADDRESS_updateEmailAddress;
  editMode: boolean;
  setEditable?: Function;
  saveState?: number;
  updateFinal?: Function;
  emailLoading?: boolean;

  homeUpdate?: Function;
  homeLoading?: boolean;
  homeError?: Error;
  cellUpdate?: Function;
  cellLoading?: boolean;
  cellError?: Error;
  workUpdate?: Function;
  workLoading?: boolean;
  workError?: Error;
  primaryUpdate?: Function;
  primaryLoading?: boolean;
  primaryError?: Error;
  emailError?: Error;
  setServiceLoading?: Function;
}

const ItemHeader = styled(Text)`
  font-weight: bold;
  font-size: 0.75rem;
  justify-content: start;
`;

const StyledInfoValue = styled(Text)`
  font-size: 1rem;
  justify-content: start;
`;

const SaveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  padding: 0 0 0 375px;
  background-color: #f5f7fa;
`;

const SaveLoader = styled.div`
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #041e41;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const InputError = styled(Text)`
  font-size: 0.8rem;
  color: red;
  line-height: 0.5;
`;

const ErrorBox = styled(Box)`
  padding: 5px;
`;

const PhoneStyledBox = styled(Box)`
  &.UIRedesign {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const PhoneEditStyledForm = styled(Form)`
  &.UIRedesign {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .phonenumber-formfield {
      width: 100%;
      ~ .phone-radio-wrapper {
        margin: 18px 0 12px;
        padding-top: 0;
        padding-right: 40px;
        flex-direction: row;
        align-items: center;
        span {
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
  }
`;

export const AccountInformationEdit = ({
  lastDayToPayFlag,
  isLoading,
  error,
  accountInfo,
  phoneUpdate,
  phoneResponse,
  accountNumber,
  ID,
  emailUpdate,
  emailResponse,
  editMode,
  setEditable,
  saveState,
  updateFinal,
  emailLoading,
  homeUpdate,
  homeLoading,
  homeError,
  cellUpdate,
  cellLoading,
  cellError,
  workUpdate,
  workLoading,
  workError,
  primaryUpdate,
  primaryLoading,
  primaryError,
  emailError,
  setServiceLoading
}: AccountInformationEditProps) => {
  let emailCode = null;
  let emailMsg = '';
  let phoneCode = null;
  let phoneMsg = '';
  if (emailResponse) {
    emailCode = emailResponse.responseCode;
    if (emailResponse.responseMsg !== null) {
      emailMsg = emailResponse.responseMsg;
    }
  }
  if (phoneResponse) {
    phoneCode = phoneResponse.responseCode;
    if (phoneResponse.responseMsg !== null) {
      phoneMsg = phoneResponse.responseMsg;
    }
  }
  const phoneGroupWidth = lastDayToPayFlag ? '100%' : 'auto';
  const formFieldWidth = lastDayToPayFlag ? '185px' : '45px';
  const formFieldPad = {
    top: 'medium',
    right: lastDayToPayFlag ? '15px' : '0',
    left: lastDayToPayFlag ? '8px' : '15px'
  };

  const [updatedHome, setHome] = useState('');
  const [updatedCell, setCell] = useState('');
  const [updatedWork, setWork] = useState('');
  const [primary, setPrimary] = useState(accountInfo?.primary_phone);
  const [errorPrimary, setErrorPrimary] = useState(false);

  const [emailResponseData, setEmailResponse] = useState({
    responseMsg: emailMsg,
    responseCode: emailCode
  });
  const [homeResponseData, setHomeResponse] = useState({
    responseMsg: phoneMsg,
    responseCode: phoneCode
  });
  const [mobileResponseData, setMobileResponse] = useState({
    responseMsg: phoneMsg,
    responseCode: phoneCode
  });
  const [workResponseData, setWorkResponse] = useState({
    responseMsg: phoneMsg,
    responseCode: phoneCode
  });
  const [primaryResponseData, setPrimaryResponse] = useState({
    responseMsg: phoneMsg,
    responseCode: phoneCode
  });
  const primaryPhone = accountInfo?.primary_phone;

  const displayEmail = accountInfo?.email_address;
  const [updatedEmail, setNewEmail] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  let oldCell: String = '';
  let oldHome: String = '';
  let oldWork: String = '';
  if (
    accountInfo?.cell_phone !== null &&
    accountInfo?.cell_phone !== undefined
  ) {
    oldCell = accountInfo?.cell_phone;
  } else {
    oldCell = '';
  }
  if (
    accountInfo?.home_phone !== null &&
    accountInfo?.home_phone !== undefined
  ) {
    oldHome = accountInfo?.home_phone;
  } else {
    oldHome = '';
  }
  if (
    accountInfo?.work_phone !== null &&
    accountInfo?.work_phone !== undefined
  ) {
    oldWork = accountInfo?.work_phone;
  } else {
    oldWork = '';
  }

  const emailInput = {
    account_number: accountNumber,
    oldEmail: displayEmail,
    newEmail: updatedEmail,
    contactID: ID,
    emailType: 'primary',
    eidUsername: sessionStorage.getItem('EIDuserName') ?? ''
  };

  let safeToClose: boolean = false;

  let oldPrimary: any;
  if (primaryPhone === 'HOME') {
    oldPrimary = oldHome;
  } else if (primaryPhone === 'WORK') {
    oldPrimary = oldWork;
  } else {
    oldPrimary = oldCell;
  }
  let newPrimary: any;

  const newAccountInfo = accountInfo;

  const validateAndSubmit = async () => {
    if (updatedHome === '' && updatedCell === '' && updatedWork === '') {
      if (setServiceLoading) setServiceLoading(false);
      return;
    }

    if (
      emailUpdate !== undefined &&
      updatedEmail !== '' &&
      updatedEmail !== accountInfo?.email_address
    ) {
      const update = await emailUpdate({
        variables: emailInput
      });

      setEmailResponse(update?.data?.updateEmailAddress);

      if (
        update?.data?.updateEmailAddress?.responseCode ||
        update?.data?.updateEmailAddress?.responseMsg.includes(
          'Not a valid Email Address'
        ) ||
        update?.data?.updateEmailAddress?.responseMsg.includes(
          'Email is not in proper syntax'
        )
      ) {
        safeToClose = false;
      }
    }
    let method = 'updateContactPhone';
    if (
      homeUpdate !== undefined &&
      workUpdate !== undefined &&
      cellUpdate !== undefined &&
      primaryUpdate !== undefined
    ) {
      switch (primary) {
        case 'HOME':
          newPrimary = updatedHome;
          break;
        case 'MOBILE':
          newPrimary = updatedCell;
          break;
        case 'WORK':
          newPrimary = updatedWork;
          break;
        default:
          break;
      }

      if (primary && primary !== primaryPhone && newPrimary) {
        const update = await primaryUpdate({
          variables: {
            account_number: accountNumber,
            method: 'updatePrimaryPhoneType',
            oldPhone: oldPrimary,
            newPhone: newPrimary,
            contactID: ID,
            phoneType: primary
          }
        });

        setPrimaryResponse(update?.data?.updateContactNumber);

        if (
          (update?.data?.updateContactNumber?.responseCode &&
            typeof update?.data?.updateContactNumber?.responseCode ===
              'string') ||
          !update?.data?.updateContactNumber?.responseMsg?.includes(
            'Contact Successfully updated'
          )
        ) {
          safeToClose = false;
        } else if (newAccountInfo) newAccountInfo.primary_phone = primary;
      } else if (!newPrimary) {
        safeToClose = false;
        setErrorPrimary(true);
        if (setServiceLoading) setServiceLoading(false);
      }

      if (
        updatedHome !== accountInfo?.home_phone &&
        !(updatedHome === '' && !accountInfo?.home_phone)
      ) {
        if (updatedHome === '' && accountInfo?.home_phone) {
          method = 'deleteContactPhone';
        } else if (updatedHome !== '' && !accountInfo?.home_phone) {
          method = 'addContactPhone';
        }

        if (
          !(accountInfo?.home_phone === null && updatedHome === '') &&
          updatedHome !== null
        ) {
          if (method === 'deleteContactPhone' && primary === 'HOME') {
            setErrorPrimary(true);
            if (setServiceLoading) setServiceLoading(false);
            return;
          }

          const update = await homeUpdate({
            variables: {
              account_number: accountNumber,
              method,
              oldPhone: oldHome,
              newPhone: updatedHome,
              contactID: ID,
              phoneType: 'HOME'
            }
          });

          setHomeResponse(update?.data?.updateContactNumber);

          if (
            update?.data?.updateContactNumber?.responseCode ||
            !update?.data?.updateContactNumber?.responseMsg.includes(
              'Contact Successfully updated'
            )
          ) {
            safeToClose = false;
          } else if (newAccountInfo && method === 'deleteContactPhone') {
            delete newAccountInfo.home_phone;
          }
        }
      }

      method = 'updateContactPhone';
      if (
        updatedCell !== accountInfo?.cell_phone &&
        !(updatedCell === '' && !accountInfo?.cell_phone)
      ) {
        if (updatedCell === '' && accountInfo?.cell_phone) {
          method = 'deleteContactPhone';
        } else if (updatedCell !== '' && !accountInfo?.cell_phone) {
          method = 'addContactPhone';
        }

        if (method === 'deleteContactPhone' && primary === 'MOBILE') {
          setErrorPrimary(true);
          if (setServiceLoading) setServiceLoading(false);
          return;
        }

        const update = await cellUpdate({
          variables: {
            account_number: accountNumber,
            method,
            oldPhone: oldCell,
            newPhone: updatedCell,
            contactID: ID,
            phoneType: 'MOBILE'
          }
        });

        setMobileResponse(update?.data?.updateContactNumber);

        if (
          update?.data?.updateContactNumber?.responseCode ||
          !update?.data?.updateContactNumber?.responseMsg.includes(
            'Contact Successfully updated'
          )
        ) {
          safeToClose = false;
        } else if (newAccountInfo && method === 'deleteContactPhone') {
          delete newAccountInfo.cell_phone;
        }
      }

      method = 'updateContactPhone';
      if (
        updatedWork !== accountInfo?.work_phone &&
        !(updatedWork === '' && !accountInfo?.work_phone)
      ) {
        if (updatedWork === '' && accountInfo?.work_phone) {
          method = 'deleteContactPhone';
        } else if (updatedWork !== '' && !accountInfo?.work_phone) {
          method = 'addContactPhone';
        }

        if (method === 'deleteContactPhone' && primary === 'WORK') {
          setErrorPrimary(true);
          if (setServiceLoading) setServiceLoading(false);
          return;
        }

        const update = await workUpdate({
          variables: {
            account_number: accountNumber,
            method,
            oldPhone: oldWork,
            newPhone: updatedWork,
            contactID: ID,
            phoneType: 'WORK'
          }
        });

        setWorkResponse(update?.data?.updateContactNumber);

        if (
          update?.data?.updateContactNumber?.responseCode ||
          !update?.data?.updateContactNumber?.responseMsg.includes(
            'Contact Successfully updated'
          )
        ) {
          safeToClose = false;
        } else if (newAccountInfo && method === 'deleteContactPhone') {
          delete newAccountInfo.work_phone;
        }
      }
    }

    if (setEditable) {
      if (safeToClose && accountInfo) {
        if (newAccountInfo && updateFinal) {
          if (updatedCell && updatedCell !== '') {
            simpleGTMDataLayer({
              event: 'field_Update',
              event_category: window.location.pathname,
              event_label: 'Cell Phone',
              event_action: 'phone updated'
            });
            newAccountInfo.cell_phone = updatedCell;
          }

          if (updatedWork && updatedWork !== '') {
            simpleGTMDataLayer({
              event: 'field_Update',
              event_category: window.location.pathname,
              event_label: 'Work Phone',
              event_action: 'phone updated'
            });
            newAccountInfo.work_phone = updatedWork;
          }

          if (updatedHome && updatedHome !== '') {
            simpleGTMDataLayer({
              event: 'field_Update',
              event_category: window.location.pathname,
              event_label: 'Home Phone',
              event_action: 'phone updated'
            });
            newAccountInfo.home_phone = updatedHome;
          }

          if (updatedEmail && updatedEmail !== '') {
            simpleGTMDataLayer({
              event: 'field_Update',
              event_category: window.location.pathname,
              event_label: 'Email',
              event_action: 'email updated'
            });
            newAccountInfo.email_address = updatedEmail;
          }

          updateFinal(newAccountInfo);
          setErrorPrimary(false);
        }
        if (setServiceLoading) setServiceLoading(false);
        setEditable(false);
      }
    }
  };

  useEffect(() => {
    if (saveState && saveState > 0) {
      safeToClose = true;

      validateAndSubmit();
    }
  }, [saveState]);
  useEffect(() => {
    if (
      emailLoading ||
      homeLoading ||
      cellLoading ||
      workLoading ||
      primaryLoading
    ) {
      if (setServiceLoading) setServiceLoading(true);
    } else if (setServiceLoading) setServiceLoading(false);
  }, [emailLoading, homeLoading, cellLoading, workLoading, primaryLoading]);
  useEffect(() => {
    if (emailError || homeError || cellError || workError || primaryError) {
      setShowHelp(true);
    } else {
      setShowHelp(false);
    }
  }, [emailError, homeError, cellError, workError, primaryError]);

  useEffect(() => {
    if (accountInfo && accountInfo.home_phone) {
      setHome(accountInfo.home_phone);
    }

    if (accountInfo && accountInfo.cell_phone) {
      setCell(accountInfo.cell_phone);
    }

    if (accountInfo && accountInfo.work_phone) {
      setWork(accountInfo.work_phone);
    }

    if (accountInfo && accountInfo.email_address) {
      setNewEmail(accountInfo.email_address);
    }
    if (accountInfo && accountInfo.primary_phone) {
      setPrimary(accountInfo.primary_phone);
    }
  }, [accountInfo]);

  useEffect(() => {
    if (accountNumber) {
      if (accountInfo) {
        if (accountInfo.home_phone) {
          setHome(accountInfo.home_phone);
        } else {
          setHome('');
        }

        if (accountInfo.cell_phone) {
          setCell(accountInfo.cell_phone);
        } else {
          setCell('');
        }

        if (accountInfo.work_phone) {
          setWork(accountInfo.work_phone);
        } else {
          setWork('');
        }

        if (accountInfo && accountInfo.email_address) {
          setNewEmail(accountInfo.email_address);
        }
        if (accountInfo && accountInfo.primary_phone) {
          setPrimary(accountInfo.primary_phone);
        } else {
          setPrimary('MOBILE');
        }
        setEmailResponse({
          responseMsg: '',
          responseCode: null
        });
        setHomeResponse({
          responseMsg: '',
          responseCode: null
        });
        setMobileResponse({
          responseMsg: '',
          responseCode: null
        });
        setWorkResponse({
          responseMsg: '',
          responseCode: null
        });
        setPrimaryResponse({
          responseMsg: '',
          responseCode: null
        });
      }
      if (setEditable) {
        if (setServiceLoading) setServiceLoading(false);
        setEditable(false);
      }
    }
  }, [accountNumber, accountInfo]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <h3>An Error Occured</h3>;
  }
  let sizing = lastDayToPayFlag ? '30px' : '0';
  if (editMode) {
    sizing = '1.25rem';
  }

  let adressDetailsHeight;

  if (lastDayToPayFlag && editMode) {
    adressDetailsHeight = `225px`;
  } else if (
    editMode ||
    (accountInfo?.cell_phone &&
      accountInfo?.home_phone &&
      accountInfo?.work_phone)
  ) {
    adressDetailsHeight = '250px';
  } else {
    adressDetailsHeight = '169px';
  }

  return (
    <Box
      fill="horizontal"
      pad={{
        left: lastDayToPayFlag ? '30px' : '0',
        right: '0'
      }}
      margin={{
        bottom: sizing,
        top: lastDayToPayFlag ? '0' : '20px'
      }}
      height={adressDetailsHeight}
    >
      <ResponsiveGrid
        rows={
          lastDayToPayFlag
            ? ['auto', 'auto', 'auto']
            : ['auto', 'auto', 'auto', 'auto']
        }
        columns={['1/2', '1/2']}
        gap={{
          column: '15px'
        }}
        areas={
          lastDayToPayFlag
            ? [
                { name: 'address', start: [0, 0], end: [1, 0] },
                { name: 'email', start: [0, 2], end: [1, 2] },
                { name: 'phones', start: [1, 0], end: [1, 2] }
              ]
            : {
                xsmall: [
                  { name: 'address', start: [0, 0], end: [0, 0] },
                  { name: 'email', start: [0, 1], end: [0, 1] },
                  { name: 'phones', start: [0, 2], end: [0, 3] }
                ],
                small: [
                  { name: 'address', start: [0, 0], end: [0, 0] },
                  { name: 'email', start: [0, 1], end: [0, 1] },
                  { name: 'phones', start: [0, 2], end: [0, 3] }
                ],
                medium: [
                  { name: 'address', start: [0, 0], end: [0, 0] },
                  { name: 'email', start: [0, 1], end: [0, 2] },
                  { name: 'phones', start: [1, 0], end: [1, 1] }
                ],
                large: [
                  { name: 'address', start: [0, 0], end: [0, 0] },
                  { name: 'email', start: [0, 1], end: [0, 2] },
                  { name: 'phones', start: [1, 0], end: [1, 1] }
                ],
                xlarge: [
                  { name: 'address', start: [0, 0], end: [0, 0] },
                  { name: 'email', start: [0, 1], end: [0, 2] },
                  { name: 'phones', start: [1, 0], end: [1, 1] }
                ]
              }
        }
      >
        <Box gridArea="address" margin={{ bottom: 'small' }}>
          <ItemHeader>Address</ItemHeader>
          <StyledInfoValue>
            {formatTitleCase(accountInfo?.address?.street_name)}
          </StyledInfoValue>
          <StyledInfoValue>
            {formatTitleCase(accountInfo?.address?.city)},{' '}
            {accountInfo?.address?.state}{' '}
            {formatZipcode(accountInfo?.address?.zip_code)}
          </StyledInfoValue>
        </Box>
        <Box gridArea="email">
          {editMode ? (
            <Box margin={{ right: 'small' }}>
              <ItemHeader color="#0173C6">Email</ItemHeader>
              <Form>
                <FormField
                  name="email"
                  error={
                    emailResponseData.responseCode !== null &&
                    emailResponseData.responseCode !== '01' && (
                      <InputError>Unable to Update Contact</InputError>
                    )
                  }
                  validate={{
                    regexp: /^(.)*@(\w)*.(.)*$/,
                    message: 'Email address is invalid'
                  }}
                >
                  <Box border={{ side: 'all', size: '1px' }} round="3px">
                    <TextInput
                      name="email"
                      value={updatedEmail}
                      onChange={event => setNewEmail(event.target.value)}
                      size="small"
                      plain
                    />
                  </Box>
                </FormField>
              </Form>
              <Box direction="row">
                {updatedEmail === '' && (
                  <InputError>Email cannot be deleted</InputError>
                )}

                {[
                  'Not a valid Email Address',
                  'Email is not in proper syntax'
                ].includes(emailResponseData.responseMsg) && (
                  <InputError>Invalid Email</InputError>
                )}
              </Box>

              {(emailResponseData.responseCode !== null &&
                emailResponseData.responseCode !== '01') ||
                (emailResponseData.responseMsg !== undefined &&
                  typeof emailResponseData.responseMsg !== 'string') ||
                (homeResponseData.responseCode !== null &&
                  homeResponseData.responseCode !== '01') ||
                (homeResponseData.responseMsg !== undefined &&
                  typeof homeResponseData.responseMsg !== 'string') ||
                (mobileResponseData.responseCode !== null &&
                  mobileResponseData.responseCode !== '01') ||
                (mobileResponseData.responseMsg !== undefined &&
                  typeof mobileResponseData.responseMsg !== 'string') ||
                (workResponseData.responseCode !== null &&
                  workResponseData.responseCode !== '01') ||
                (workResponseData.responseMsg !== undefined &&
                  typeof workResponseData.responseMsg !== 'string') ||
                (((primaryResponseData.responseCode !== null &&
                  primaryResponseData.responseCode !== '01') ||
                  (primaryResponseData.responseMsg !== undefined &&
                    typeof primaryResponseData.responseMsg !== 'string') ||
                  showHelp) && (
                  <Text size="8pt" weight="bold" color="red">
                    Please contact us at 833-SA-HELPS for further assistance.
                  </Text>
                ))}
            </Box>
          ) : (
            <Box>
              <ItemHeader>Email</ItemHeader>
              <StyledInfoValue>{displayEmail}</StyledInfoValue>
            </Box>
          )}
        </Box>
        <Box gridArea="phones">
          {editMode ? (
            <Box>
              {errorPrimary && (
                <InputError>Cannot remove Primary number.</InputError>
              )}
              {updatedHome === '' &&
                updatedCell === '' &&
                updatedWork === '' && (
                  <InputError>Phone number is required</InputError>
                )}
              <PhoneEditStyledForm
                className={lastDayToPayFlag ? 'UIRedesign' : ''}
                id="edit-form"
                onSubmit={() => {
                  validateAndSubmit();
                }}
              >
                <Box direction="row" width={phoneGroupWidth}>
                  <FormField
                    name="Home"
                    error={
                      homeResponseData !== undefined &&
                      homeResponseData.responseCode !== null &&
                      homeResponseData.responseCode !== undefined && (
                        <InputError>
                          {homeResponseData.responseCode === '01'
                            ? 'Invalid Phone Number'
                            : 'Unable to Update Contact'}
                        </InputError>
                      )
                    }
                    validate={{
                      regexp: /^[0-9]{10}$/,
                      message: 'Phone number is invalid'
                    }}
                    className="phonenumber-formfield"
                  >
                    <Box>
                      <Box direction="row">
                        <Text
                          size="10pt"
                          margin={{ right: 'small' }}
                          color="#0173C6"
                          weight="bold"
                        >
                          Home
                        </Text>
                      </Box>
                      <Box
                        border={{ side: 'all', size: '1px' }}
                        round="3px"
                        height={lastDayToPayFlag ? 'auto' : '35px'}
                      >
                        <TextInput
                          id="update_home_phone_input"
                          name="Home"
                          value={updatedHome}
                          onChange={event => {
                            setHome(event.target.value);
                          }}
                          size="small"
                          plain
                        />
                      </Box>
                    </Box>
                  </FormField>
                  <Box
                    className="phone-radio-wrapper"
                    width={formFieldWidth}
                    pad={formFieldPad}
                  >
                    <RadioButton
                      id="home_primary_phone_radio"
                      name="Home"
                      checked={
                        primary ? primary === 'HOME' : primaryPhone === 'HOME'
                      }
                      onChange={() => {
                        setPrimary('HOME');
                      }}
                      width="xsmall"
                    />
                    <Text size="8pt" margin={{ left: '-5px' }}>
                      {primary === 'HOME' ||
                      (!primary && primaryPhone === 'HOME')
                        ? 'Primary'
                        : ''}
                    </Text>
                  </Box>
                </Box>

                <Box direction="row" width={phoneGroupWidth}>
                  <FormField
                    name="Mobile"
                    error={
                      mobileResponseData !== undefined &&
                      mobileResponseData.responseCode !== null &&
                      mobileResponseData.responseCode !== undefined && (
                        <InputError>
                          {mobileResponseData.responseCode === '01'
                            ? 'Invalid Phone Number'
                            : 'Unable to Update Contact'}
                        </InputError>
                      )
                    }
                    validate={{
                      regexp: /^[0-9]{10}$/,
                      message: 'Phone number is invalid'
                    }}
                    className="phonenumber-formfield"
                  >
                    <Box>
                      <Box direction="row">
                        <Text
                          size="10pt"
                          margin={{ right: 'xsmall' }}
                          color="#0173C6"
                          weight="bold"
                        >
                          Mobile
                        </Text>
                      </Box>
                      <Box
                        border={{ side: 'all', size: '1px' }}
                        round="3px"
                        height={lastDayToPayFlag ? 'auto' : '35px'}
                      >
                        <TextInput
                          id="update_mobile_phone_input"
                          name="Mobile"
                          value={updatedCell}
                          onChange={event => setCell(event.target.value)}
                          size="small"
                          plain
                        />
                      </Box>
                    </Box>
                  </FormField>
                  <Box
                    className="phone-radio-wrapper"
                    width={formFieldWidth}
                    pad={formFieldPad}
                  >
                    <RadioButton
                      id="mobile_primary_phone_radio"
                      name="Mobile"
                      checked={
                        primary
                          ? primary === 'MOBILE'
                          : primaryPhone === 'MOBILE'
                      }
                      onChange={() => {
                        setPrimary('MOBILE');
                      }}
                      width="xsmall"
                    />
                    <Text size="8pt" margin={{ left: '-5px' }}>
                      {primary === 'MOBILE' ||
                      (!primary && primaryPhone === 'MOBILE')
                        ? 'Primary'
                        : ''}
                    </Text>
                  </Box>
                </Box>

                <Box direction="row" width={phoneGroupWidth}>
                  <FormField
                    name="Work"
                    error={
                      workResponseData !== undefined &&
                      workResponseData.responseCode !== null &&
                      workResponseData.responseCode !== undefined && (
                        <InputError>
                          {workResponseData.responseCode === '01'
                            ? 'Invalid Phone Number'
                            : 'Unable to Update Contact'}
                        </InputError>
                      )
                    }
                    validate={{
                      regexp: /^[0-9]{10}$/,
                      message: 'Phone number is invalid'
                    }}
                    className="phonenumber-formfield"
                  >
                    <Box>
                      <Box direction="row">
                        <Text
                          size="10pt"
                          margin={{ right: '17px' }}
                          color="#0173C6"
                          weight="bold"
                        >
                          Work
                        </Text>
                      </Box>
                      <Box
                        border={{ side: 'all', size: '1px' }}
                        round="3px"
                        height={lastDayToPayFlag ? 'auto' : '35px'}
                      >
                        <TextInput
                          id="update_work_phone_input"
                          name="Work"
                          value={updatedWork}
                          onChange={event => setWork(event.target.value)}
                          size="small"
                          plain
                        />
                      </Box>
                    </Box>
                  </FormField>
                  <Box
                    className="phone-radio-wrapper"
                    width={formFieldWidth}
                    pad={formFieldPad}
                  >
                    <RadioButton
                      id="work_primary_phone_radio"
                      name="Work"
                      checked={
                        primary ? primary === 'WORK' : primaryPhone === 'WORK'
                      }
                      onChange={() => {
                        setPrimary('WORK');
                      }}
                      width="xsmall"
                    />
                    <Text size="8pt" margin={{ left: '-5px' }}>
                      {primary === 'WORK' ||
                      (!primary && primaryPhone === 'WORK')
                        ? 'Primary'
                        : ''}
                    </Text>
                  </Box>
                </Box>
              </PhoneEditStyledForm>
            </Box>
          ) : (
            <PhoneStyledBox className={lastDayToPayFlag ? 'UIRedesign' : ''}>
              {accountInfo?.home_phone && (
                <Box width={phoneGroupWidth}>
                  <ItemHeader id="home_phone_label">
                    Home {accountInfo?.primary_phone === 'HOME' && ' (Primary)'}
                  </ItemHeader>
                  <StyledInfoValue id="home_phone_value">
                    {formatPhone(accountInfo?.home_phone)}
                  </StyledInfoValue>
                </Box>
              )}
              {accountInfo?.cell_phone && (
                <Box width={phoneGroupWidth}>
                  <ItemHeader id="mobile_phone_label">
                    Mobile
                    {accountInfo?.primary_phone === 'MOBILE' && ' (Primary)'}
                  </ItemHeader>
                  <StyledInfoValue id="mobile_phone_value">
                    {formatPhone(accountInfo?.cell_phone)}
                  </StyledInfoValue>
                </Box>
              )}
              {accountInfo?.work_phone && (
                <Box width={phoneGroupWidth}>
                  <ItemHeader id="work_phone_label">
                    Work {accountInfo?.primary_phone === 'WORK' && ' (Primary)'}
                  </ItemHeader>
                  <StyledInfoValue id="work_phone_value">
                    {formatPhone(accountInfo?.work_phone)}
                  </StyledInfoValue>
                </Box>
              )}
            </PhoneStyledBox>
          )}
        </Box>
      </ResponsiveGrid>
    </Box>
  );
};
