import React, { ReactElement } from 'react';
import moment, { Moment } from 'moment-timezone';

import {
  getMomentObj,
  today,
  days,
  yearRange,
  getMonthDaysArr,
  WeekObj
} from '../DatePickerUtil';

import {
  MonthSelect,
  NavigateMonth,
  MonthYear,
  DaysRow,
  Days,
  Weeks,
  DayPlaceholder,
  CalendarDay
} from './DateView.styles';
import {
  MonthSelectFilter,
  NavigateMonthFilter,
  MonthYearFilter,
  DaysRowFilter,
  DaysFilter,
  WeeksFilter,
  DayPlaceholderFilter,
  CalendarDayFilter
} from './DateViewFilter.styles';

import LeftSrc from '../../../assets/images/DatePickerLeft.svg';
import RightSrc from '../../../assets/images/DatePickerRight.svg';

interface DateViewProps {
  isPassedDateValid: boolean;
  selectedDate: Moment;
  setDate: Function;
  minDate?: string;
  maxDate?: string;
  currentMonthYear: Moment;
  setCurrentMonthYear: Function;
  isFilter?: boolean;
}

const DateView = ({
  isPassedDateValid,
  selectedDate,
  setDate,
  minDate,
  maxDate,
  currentMonthYear,
  setCurrentMonthYear,
  isFilter
}: DateViewProps): ReactElement => {
  const weeks: WeekObj[] = getMonthDaysArr(currentMonthYear);

  const isDateDisabled = (date: Moment) => {
    let disabled: boolean = false;

    if (typeof minDate === 'string') {
      disabled =
        parseInt(minDate.replace(/-/g, ''), 10) >
        parseInt(date.format('YYYYMMDD'), 10);
    }

    if (!disabled && typeof maxDate === 'string') {
      disabled =
        parseInt(maxDate.replace(/-/g, ''), 10) <
        parseInt(date.format('YYYYMMDD'), 10);
    }

    return disabled;
  };

  const previousMonth = () => {
    setCurrentMonthYear(moment(currentMonthYear).subtract(1, 'M'));
  };

  const nextMonth = () => {
    setCurrentMonthYear(moment(currentMonthYear).add(1, 'M'));
  };

  const handleDateChange = (date: Moment) => {
    if (!isDateDisabled(date)) {
      setDate(date.format('YYYY-MM-DD'));
    }
  };

  return (
    <>
      {isFilter ? (
        <>
          <MonthSelectFilter test-attr="date-picker-month-select">
            <NavigateMonthFilter
              onClick={() => previousMonth()}
              src={LeftSrc}
              disabled={
                currentMonthYear.format('YYYYMM') === `${yearRange[0]}01`
              }
            />
            <MonthYearFilter>
              {currentMonthYear.format('MMMM YYYY')}
            </MonthYearFilter>
            <NavigateMonthFilter
              onClick={() => nextMonth()}
              src={RightSrc}
              disabled={
                currentMonthYear.format('YYYYMM') === `${yearRange[1]}12`
              }
            />
          </MonthSelectFilter>
          <DaysRowFilter>
            {days.map((day: string, index: number) => (
              <DaysFilter key={`${day}${index + 1}`}>{day[0]}</DaysFilter>
            ))}
          </DaysRowFilter>
          {weeks.map(week => (
            <WeeksFilter key={week.id}>
              {week.days.map((day, index) =>
                day ? (
                  <CalendarDayFilter
                    key={day.format('YYYYMMDD')}
                    today={getMomentObj(today).isSame(day)}
                    disabled={isDateDisabled(day)}
                    selected={isPassedDateValid && selectedDate.isSame(day)}
                    onClick={() => handleDateChange(day)}
                  >
                    {day.date()}
                  </CalendarDayFilter>
                ) : (
                  <DayPlaceholderFilter key={`${week.id}${index + 1}`} />
                )
              )}
            </WeeksFilter>
          ))}
        </>
      ) : (
        <>
          <MonthSelect test-attr="date-picker-month-select">
            <NavigateMonth
              onClick={() => previousMonth()}
              src={LeftSrc}
              disabled={
                currentMonthYear.format('YYYYMM') === `${yearRange[0]}01`
              }
            />
            <MonthYear>{currentMonthYear.format('MMMM YYYY')}</MonthYear>
            <NavigateMonth
              onClick={() => nextMonth()}
              src={RightSrc}
              disabled={
                currentMonthYear.format('YYYYMM') === `${yearRange[1]}12`
              }
            />
          </MonthSelect>
          <DaysRow>
            {days.map((day: string, index: number) => (
              <Days key={`${day}${index + 1}`}>{day[0]}</Days>
            ))}
          </DaysRow>
          {weeks.map(week => (
            <Weeks key={week.id}>
              {week.days.map((day, index) =>
                day ? (
                  <CalendarDay
                    key={day.format('YYYYMMDD')}
                    today={getMomentObj(today).isSame(day)}
                    disabled={isDateDisabled(day)}
                    selected={isPassedDateValid && selectedDate.isSame(day)}
                    onClick={() => handleDateChange(day)}
                  >
                    {day.date()}
                  </CalendarDay>
                ) : (
                  <DayPlaceholder key={`${week.id}${index + 1}`} />
                )
              )}
            </Weeks>
          ))}
        </>
      )}
    </>
  );
};

DateView.defaultProps = {
  minDate: '',
  maxDate: ''
};

export default DateView;
