import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';

import SnapshotInvoicesTable from './SnapshotInvoicesTable';

const get_snapshot_invoices = loader(
  '../graphql/Get_Snapshot_Invoices.graphql'
);

interface Props {
  transactionNumber: string | null;
  amountChange: number | null;
  accountNumber: string | null;
  policyNumber: string | null;
}

export default ({
  transactionNumber,
  amountChange,
  accountNumber,
  policyNumber
}: Props) => {
  const { loading, error, data } = useQuery(get_snapshot_invoices, {
    variables: {
      account_number: accountNumber,
      policy_number: policyNumber,
      job_number: transactionNumber
    }
  });

  return (
    <SnapshotInvoicesTable
      amountChange={amountChange}
      error={Boolean(error)}
      loading={loading}
      tableData={data?.account?.items[0]?.policy?.invoiceByJobNumber}
    />
  );
};
