export const baseURL = {
  saconnect: {
    prod: 'https://saconnect.stateauto.com',
    nonprod: 'https://saconnect-l3-5.stateauto.com'
  },
  agentsite: {
    prod: 'https://echannel.stateauto.com/agentsite',
    nonprod: 'https://esystest.stateauto.com/agentsite'
  }
};

export interface MenuObj {
  label: string;
  gtmTag?: string;
  subMenu?: MenuObj[];
  prodOnly?: boolean;
  baseURL?: 'saconnect' | 'agentsite';
  link?: string;
}

export const menu: MenuObj[] = [
  {
    label: 'SA Connect Home',
    gtmTag: 'Header-SA Connect',
    baseURL: 'saconnect',
    link: '/'
  },
  {
    label: 'Accounts',
    gtmTag: 'Header-Accounts',
    baseURL: 'saconnect',
    link: '/?q=accounts'
  },
  {
    label: 'Policies',
    gtmTag: 'Header-Policies',
    baseURL: 'saconnect',
    link: '/?q=policies'
  },
  {
    label: 'Resources',
    subMenu: [
      {
        label: 'Tools',
        subMenu: [
          {
            label: 'Agent Training',
            gtmTag: 'Header-Agent Training',
            prodOnly: true,
            link: 'https://sahelps.com/'
          },
          {
            label: 'Underwriting Assessment Tools',
            gtmTag: 'Header-Underwriting Assessment Tools',
            baseURL: 'saconnect',
            link: '/?q=sapage/302'
          },
          {
            label: 'Processing Support',
            gtmTag: 'Header-Processing Support',
            baseURL: 'saconnect',
            link: '/?q=mappolicy'
          },
          {
            label: 'Claims Tools',
            gtmTag: 'Header-Claims Tools',
            baseURL: 'saconnect',
            link: '/?q=sapage/245'
          },
          {
            label: 'Payment & Billing Tools',
            gtmTag: 'Header-Payment & Billing Tools',
            baseURL: 'saconnect',
            link: '/?q=sapage/244'
          },
          {
            label: 'Pricing Tools',
            gtmTag: 'Header-Pricing Tools',
            baseURL: 'saconnect',
            link: '/?q=sapage/239'
          },
          {
            label: 'Associate Directory',
            gtmTag: 'Header-Associate Directory',
            baseURL: 'agentsite',
            link: '/associates.asp?subl=STD'
          },
          {
            label: 'Company Representatives',
            gtmTag: 'Header-Company Representatives',
            baseURL: 'saconnect',
            link: '/?q=company'
          }
        ]
      },
      {
        label: 'Reports',
        gtmTag: 'Header-Reports',
        baseURL: 'agentsite',
        link: '/custserv/tabAgency.asp'
      },
      {
        label: 'Forms',
        gtmTag: 'Header-Forms',
        baseURL: 'agentsite',
        link: '/forms_new/formssearch.asp'
      },
      {
        label: 'Manuals',
        subMenu: [
          {
            label: 'Personal Manuals',
            gtmTag: 'Header-Personal Manuals',
            baseURL: 'agentsite',
            link:
              '/software/Manuals_byState.asp?state=OH&view=Current&cat=defaul&subl=STD'
          },
          {
            label: 'Farm Manuals',
            gtmTag: 'Header-Farm Manuals',
            baseURL: 'agentsite',
            link:
              '/software/Manuals_byState.asp?state=OH&view=Current&cat=defaul&subl=STD'
          },
          {
            label: 'Commercial Manuals',
            gtmTag: 'Header-Commercial Manuals',
            baseURL: 'agentsite',
            link: '/software/Manuals_map.asp?subl=COM&map=yes'
          }
        ]
      }
    ]
  },
  {
    label: 'Sales',
    subMenu: [
      {
        label: 'Incentive Programs',
        gtmTag: 'Header-Incentive Programs',
        baseURL: 'saconnect',
        link: '/?q=node/257'
      },
      {
        label: 'Marketing',
        gtmTag: 'Header-Marketing',
        link: 'https://www.stateautomarketing.com/connect/'
      },
      {
        label: 'Product Information',
        gtmTag: 'Header-Product Information',
        baseURL: 'saconnect',
        link: '/?q=node/252'
      },
      {
        label: 'Sales Training',
        gtmTag: 'Header-Sales Training',
        baseURL: 'saconnect',
        link: '/?q=node/255'
      }
    ]
  },
  {
    label: 'News',
    subMenu: [
      {
        label: 'Agent Communications',
        gtmTag: 'Header-Agent Communications',
        baseURL: 'saconnect',
        link: '/?q=node/259'
      },
      {
        label: 'News / New Archives',
        gtmTag: 'Header-News / New Archives',
        link: 'https://saconnect.stateauto.com/?q=node/258'
      }
    ]
  },
  {
    label: 'Administration',
    subMenu: [
      {
        label: 'Agencies',
        gtmTag: 'Header-Agencies',
        baseURL: 'saconnect',
        link: '/?q=node/260'
      },
      {
        label: 'Onboarding',
        gtmTag: 'Header-Onboarding',
        baseURL: 'saconnect',
        link: '/?q=node/261'
      }
    ]
  },
  {
    label: 'SA Helps',
    gtmTag: 'Header-SA Helps',
    prodOnly: true,
    link: 'https://sahelps.com/'
  }
];
