const daysOfTheMonth: string[] = [
  '31-unavailable',
  '1-unavailable',
  '2-unavailable',
  '3-unavailable'
];
let i = 1;
for (i = 1; i < 32; i += 1) {
  daysOfTheMonth.splice(i, 0, i.toString());
}
export default daysOfTheMonth;
