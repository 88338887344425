import React, { useState } from 'react';
import { Box, Text, Anchor } from 'grommet';
import styled from 'styled-components';
import {
  Adjuster,
  Clock,
  Auto,
  Property,
  Injury,
  Medical,
  ChevronDownUnbolded,
  ChevronUp
} from '../../icons';
import ResponsiveGrid from '../responsiveGrid';
import { formatPhone, formatTitleCase } from '../../util/formatters';
import { ClaimsSummaryWrapperProps } from '../claimsSummaryWrapper/ClaimsSummaryWrapper';
import ClaimsSummaryWrapper from '../claimsSummaryWrapper';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const HoverableBox = styled(Box)`
  padding-top: 5px;
  &:hover {
    background-color: #0072c6;
  }
`;
const ClaimsBox = styled(Box)`
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
  background-color: #d9dfeb;
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

const AddressStyledText = styled(StyledText)`
  line-height: 18px;
`;

const Title = styled(Text)`
  font-size: 21px;
  font-weight: 700;
`;

const StatusMarker = styled(Box)`
  padding: auto;
  width: 140px;
  min-width: 140px;
  height: 30px;
  min-height: 30px;
`;

export interface VehicleProps {
  vin: string | null;
}

export interface PropertyProps {
  propertyLine1: string | null;
  propertyLine2: string | null;
  propertyLine3: string | null;
}

export interface InjuryProps {
  name: string | null;
}

export interface MedicalProps {
  name: string | null;
}

export interface AdjusterProps {
  displayName: string;
  email: string;
  phoneNumber: string;
}

export interface ClaimsDetailProps {
  publicID?: string | undefined;
  insuredAsset?: String | null | undefined;
  summary?: ClaimsSummaryWrapperProps | undefined;
  vehicle?: VehicleProps | null | undefined;
  property?: PropertyProps | null | undefined;
  injury?: InjuryProps | null | undefined;
  medical?: MedicalProps | null | undefined;
  adjuster?: AdjusterProps | undefined;
  status?: string | null | undefined;
  propertyName?: string | null;
}

export default ({
  publicID,
  insuredAsset,
  summary,
  vehicle,
  property,
  injury,
  medical,
  adjuster,
  status
}: ClaimsDetailProps) => {
  let gridArea1 = <Box />;
  if (vehicle) {
    gridArea1 = (
      <Box direction="row" alignSelf="start">
        <Box height="27px">
          <Auto />
        </Box>
        <Box pad={{ left: '30px' }}>
          <Title>{insuredAsset}</Title>
          <Box direction="row">
            <StyledText weight="bold" margin={{ right: '13px' }}>
              VIN:
            </StyledText>
            <StyledText wordBreak="break-all">{vehicle.vin}</StyledText>
          </Box>
        </Box>
      </Box>
    );
  }
  if (property) {
    const line1 = property.propertyLine1;
    const line2 = property.propertyLine2;
    const line2cont = property.propertyLine3;
    const outputLine1 = `${formatTitleCase(line1)}`;
    const outputLine2 = `${formatTitleCase(line2)}`;
    gridArea1 = (
      <Box direction="row" alignSelf="start">
        <Box>
          <Property />
        </Box>
        <Box pad={{ left: '30px' }}>
          <Title>{insuredAsset}</Title>
          <Box direction="row">
            <StyledText weight="bold" margin={{ right: '13px' }}>
              Address:
            </StyledText>
            <Box>
              <StyledText>{outputLine1}</StyledText>
              <AddressStyledText>
                {outputLine2}
                {line2cont}
              </AddressStyledText>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  if (injury) {
    gridArea1 = (
      <Box direction="row" alignSelf="start">
        <Box height="27px">
          <Injury />
        </Box>
        <Box pad={{ left: '30px' }}>
          <Title>{insuredAsset}</Title>
          <Box direction="row">
            <StyledText weight="bold" margin={{ right: '13px' }}>
              Injured Name:
            </StyledText>
            <StyledText wordBreak="break-all">{injury.name}</StyledText>
          </Box>
        </Box>
      </Box>
    );
  }
  if (medical) {
    gridArea1 = (
      <Box direction="row" alignSelf="start">
        <Box height="27px">
          <Medical />
        </Box>
        <Box pad={{ left: '30px' }}>
          <Title>{insuredAsset}</Title>
          <Box direction="row">
            <StyledText weight="bold" margin={{ right: '13px' }}>
              Medical Claim:
            </StyledText>
            <StyledText wordBreak="break-all">{medical.name}</StyledText>
          </Box>
        </Box>
      </Box>
    );
  }
  const [expanded, setExpanded] = useState(false);
  const [current, setCurrent] = useState('Summary');
  return (
    <ClaimsBox round="3px">
      <HoverableBox
        round={expanded ? { corner: 'top', size: '3px' } : '3px'}
        border={[
          { color: '#D9DFEB', side: 'bottom' },
          { color: '#D9DFEB', side: 'vertical' }
        ]}
        background={expanded ? '#0072c6' : '#FFFFFF'}
        fill="horizontal"
      >
        <Box
          background="#FFFFFF"
          pad={{ left: '30px', bottom: '27px', top: '25px' }}
          border={{ side: 'bottom', color: '#D9DFEB' }}
          direction="row"
          onClick={() => {
            simpleGTMDataLayer({
              event: 'click-claimsOpenIncident',
              incidentId: publicID
            });
            setExpanded(!expanded);
          }}
        >
          <ResponsiveGrid
            fill
            gap="5px"
            rows={['auto', 'auto', 'auto']}
            columns={['1/3', '1/3', '1/3']}
            areas={{
              xsmall: [
                { name: 'Grid1', start: [0, 0], end: [2, 0] },
                { name: 'Grid2', start: [0, 1], end: [2, 1] },
                { name: 'Grid3', start: [0, 2], end: [2, 2] }
              ],
              small: [
                { name: 'Grid1', start: [0, 0], end: [2, 0] },
                { name: 'Grid2', start: [0, 1], end: [2, 1] },
                { name: 'Grid3', start: [0, 2], end: [2, 2] }
              ],
              medium: [
                { name: 'Grid1', start: [0, 0], end: [2, 0] },
                { name: 'Grid2', start: [0, 1], end: [2, 1] },
                { name: 'Grid3', start: [0, 2], end: [2, 2] }
              ],
              large: [
                { name: 'Grid1', start: [0, 0], end: [2, 0] },
                { name: 'Grid2', start: [0, 1], end: [2, 1] },
                { name: 'Grid3', start: [0, 2], end: [2, 2] }
              ],
              xlarge: [
                { name: 'Grid1', start: [0, 0], end: [0, 0] },
                { name: 'Grid2', start: [1, 0], end: [1, 0] },
                { name: 'Grid3', start: [2, 0], end: [2, 0] }
              ]
            }}
          >
            <Box gridArea="Grid1">{gridArea1}</Box>
            <Box gridArea="Grid2" direction="row" align="start">
              <Box height="25px">
                <Adjuster />
              </Box>
              <Box pad={{ left: '30px' }}>
                <Title>Adjuster</Title>
                <Box direction="row">
                  <StyledText weight="bold" margin={{ right: '13px' }}>
                    Name:
                  </StyledText>
                  <StyledText wordBreak="break-all">
                    {formatTitleCase(adjuster?.displayName)}
                  </StyledText>
                </Box>
                {adjuster &&
                  adjuster.displayName.toLowerCase() !==
                    'state auto care property team' && (
                    <Box direction="row">
                      <StyledText weight="bold" margin={{ right: '14px' }}>
                        Email:
                      </StyledText>
                      <StyledText wordBreak="break-all">
                        {adjuster &&
                        (adjuster!.email === 'Error' ||
                          adjuster.email === 'Not Available') ? (
                          <StyledText>{adjuster.email}</StyledText>
                        ) : (
                          <Anchor
                            color="#0173C6"
                            href={`mailto:${adjuster?.email}}`}
                          >
                            {adjuster?.email}
                          </Anchor>
                        )}
                      </StyledText>
                    </Box>
                  )}
                <Box direction="row">
                  <StyledText weight="bold" margin={{ right: '10px' }}>
                    Phone:
                  </StyledText>
                  <StyledText>
                    {adjuster &&
                    (adjuster.phoneNumber === 'Error' ||
                      adjuster.phoneNumber) === 'Not Available' ? (
                      <StyledText>{adjuster.phoneNumber}</StyledText>
                    ) : (
                      <Anchor
                        color="#0173C6"
                        href={`tel:+${adjuster?.phoneNumber}`}
                      >
                        {formatPhone(adjuster?.phoneNumber)}
                      </Anchor>
                    )}
                  </StyledText>
                </Box>
              </Box>
            </Box>
            <Box gridArea="Grid3" direction="row" alignContent="stretch">
              <Box height="20px">
                <Clock />
              </Box>
              <Box pad={{ left: '30px' }}>
                <Title margin={{ bottom: '5px' }}>Status</Title>
                <StatusMarker round="15px" background="brand">
                  <StyledText
                    margin="auto"
                    weight="bold"
                    size="14px"
                    color="#FFFFFF"
                  >
                    {status === 'closed' ? 'Complete' : formatTitleCase(status)}
                  </StyledText>
                </StatusMarker>
              </Box>
              <Box
                alignSelf="end"
                pad={{ left: '10px', right: '30px', top: '10px' }}
                fill="vertical"
              >
                {!expanded ? <ChevronDownUnbolded /> : <ChevronUp />}
              </Box>
            </Box>
          </ResponsiveGrid>
        </Box>
      </HoverableBox>
      {expanded && (
        <Box pad="30px">
          <Box
            direction="row"
            gap="54px"
            border={{ side: 'bottom', color: '#B0BDD4' }}
            margin={{ bottom: '30px' }}
          >
            <Box
              border={
                current === 'Summary' && {
                  side: 'bottom',
                  color: '#65A100',
                  size: '4px'
                }
              }
              /*  onClick={() => {
                  setCurrent('Summary');
                }}  */
              pad={{ bottom: '6px' }}
            >
              <Text>Summary</Text>
            </Box>
            {/* <Box
              border={
                current === 'Notes' && {
                  side: 'bottom',
                  color: '#65A100',
                  size: '4px'
                }
              }
              onClick={() => {
                setCurrent('Notes');
              }}
              pad={{ bottom: '6px' }}
            >
              <Text>Notes</Text>
            </Box>
            <Box
              border={
                current === 'Photos' && {
                  side: 'bottom',
                  color: '#65A100',
                  size: '4px'
                }
              }
              onClick={() => {
                setCurrent('Photos');
              }}
              pad={{ bottom: '6px' }}
            >
              <Text>Photos & Files</Text>
            </Box>  */}
          </Box>
          <Box>
            {current === 'Summary' && <ClaimsSummaryWrapper {...summary} />}
            {/* current === 'Notes' && <Text>Notes Placeholder</Text> */}
            {/* current === 'Photos' && <Text>Photos Placeholder</Text> */}
          </Box>
        </Box>
      )}
    </ClaimsBox>
  );
};
