import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, useParams } from '@reach/router';
import { Box } from 'grommet';
import moment from 'moment-timezone';
import { DocumentsPage } from './DocumentsPage';
import { GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items as account_documents_item } from './graphql/__generated__/GET_DOCUMENTS_BY_ACCOUNT';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useDocumentDownload from '../../util/useDocumentDownload';
import passed90Days from '../../util/passed90Days';
import { useGlobalContext } from '../../util/globalContextProvider';

const getMomentObj = (date: string) => moment.tz(date, 'America/New_York');

const get_account_documents = loader(
  './graphql/Get_Documents_by_Account.graphql'
);
const get_policies_by_account = loader(
  './graphql/Documents_Page_Get_Policies_by_Account.graphql'
);

export default (_: RouteComponentProps) => {
  const { accountNumber } = useParams();
  const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();
  const [gtm, setGTM] = useState(false);
  const [shouldFetch, setshouldFetch] = useState(true);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [items, setItems] = useState<account_documents_item[]>([]);
  const variables = {
    account_number: accountNumber,
    limit: 50,
    nextToken
  };

  const { data, loading, error } = useQuery(get_account_documents, {
    variables,
    skip: !shouldFetch,
    onCompleted: () => {
      if (data?.account?.items) {
        const tmp = data.account.items[0]?.account_documents?.items.map(
          (document: account_documents_item) => {
            const changedDocument = document;
            if (document.file_type?.includes('APPLICATION/')) {
              changedDocument.file_type = document.file_type.slice(12);
            }
            if (document.classification) {
              changedDocument.classification =
                document.classification.charAt(0).toUpperCase() +
                document.classification.slice(1).toLowerCase();
            }
          }
        );
        setItems(prevItems =>
          prevItems.concat(
            data.account.items[0]?.account_documents?.items.sort(
              (a: account_documents_item, b: account_documents_item) =>
                getMomentObj(b.create_time || '').valueOf() -
                getMomentObj(a.create_time || '').valueOf()
            )
          )
        );
      }
      if (data?.account?.items[0]?.account_documents?.nextToken) {
        setshouldFetch(false);
        setNextToken(data.account.items[0].account_documents.nextToken);
      }
    }
  });

  const { onDocumentClick, documentModal } = useDocumentDownload(
    accountNumber,
    'document',
    'click-documentsPageDocument'
  );

  if (!gtm) {
    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Documents Page',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });
    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Documents Page',
      event_category: localStorage.getItem('lob')
        ? `${localStorage.getItem('lob')}/Snowplow`
        : '/Snowplow'
    });

    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/account/documents',
        title: 'SA360: Documents'
      }
    });
    setGTM(true);
  }

  const policyQueryResult = useQuery(get_policies_by_account, { variables });
  const { data: policyList } = policyQueryResult;
  let policiesList;
  const policies = [];
  if (policyList?.account?.items) {
    policiesList = policyList.account.items[0].policies.items;
    let i = 0;
    for (i = 0; i < policiesList.length; i += 1) {
      if (
        policiesList[i].expiration_date &&
        passed90Days(policiesList[i].expiration_date)
      ) {
        policies.push(policiesList[i]);
      }
    }
  }

  const nextClickHandler = () => {
    setshouldFetch(true);
  };

  return (
    <Box>
      {documentModal}
      <DocumentsPage
        onDocumentClick={onDocumentClick}
        isLoading={loading}
        information={items}
        error={error}
        accountNumber={accountNumber}
        policies={policies}
        readOnlyAccess={readOnlyAccess ?? false}
        readOnlyFeatureFlag={readOnlyFeatureFlag ?? false}
        nextClickHandler={nextClickHandler}
      />
    </Box>
  );
};
