import React, { Children, useState, ReactNode } from 'react';

import MenuAccordionWrap from './MenuAccordionGroupStyles';

import MenuOption from '../menuOption';

interface Props {
  label: string;
  children: ReactNode;
}

const MenuAccordionGroup = ({ label, children }: Props) => {
  const [showAccordion, setShowAccordion] = useState<boolean>(false);

  return (
    <>
      <MenuOption
        label={label}
        indent={false}
        light={false}
        chevron
        chevronRight={false}
        active={showAccordion}
        onClick={() => setShowAccordion(!showAccordion)}
      />
      <MenuAccordionWrap
        childCount={Children.count(children)}
        className={showAccordion ? 'active' : ''}
      >
        {children}
      </MenuAccordionWrap>
    </>
  );
};

export default MenuAccordionGroup;
