/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommType {
  Chat = "Chat",
  EMAIL = "EMAIL",
  Phone = "Phone",
}

export enum PolicyType {
  Auto = "Auto",
  BusinessOwners = "BusinessOwners",
  Commercial_Auto = "Commercial_Auto",
  Commercial_Package = "Commercial_Package",
  Commercial_Umbrella = "Commercial_Umbrella",
  FarmOwners_Auto = "FarmOwners_Auto",
  FarmOwners_Umbrella = "FarmOwners_Umbrella",
  Farm_and_Ranch = "Farm_and_Ranch",
  Home = "Home",
  Umbrella = "Umbrella",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
