import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './polyfills';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';
import Amplify, { Auth } from 'aws-amplify';
import { AuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from 'apollo-link';
import { Location } from '@reach/router';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import getConfig, { Env } from './config';

const config = getConfig(process.env.REACT_APP_ENV as Env);

Amplify.configure(config);

const url = config.aws_appsync_graphqlEndpoint;
const region = config.aws_appsync_region;
const auth = {
  type: config.aws_appsync_authenticationType,
  jwtToken: () =>
    Auth.currentSession()
      .then(session => session.getIdToken().getJwtToken())
      .catch(err => {
        console.error('error', err);
      })
} as AuthOptions;

const appSyncLinkConfig = { url, region, auth };

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    new AuthLink(appSyncLinkConfig),
    createSubscriptionHandshakeLink(
      appSyncLinkConfig,
      // This becomes the terminating-link
      new HttpLink({ uri: url })
    )
  ])
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Location>{({ location }) => <App pathLocation={location} />}</Location>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
