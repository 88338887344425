import React from 'react';
import { Box, Text as GrommetText } from 'grommet';
import styled from 'styled-components';
import { colors } from '../../themes/default.theme';
import { formatDate, formatMoney } from '../../util/formatters';
import { LastPaymentSection } from '../nextPaymentSection';

const Text = styled(GrommetText)`
  font-size: 16px;
  line-height: 35px;
`;

export const renderNumber = (value: string | undefined | null) => {
  if (value && parseFloat(value) > 0) {
    return <Text>{formatMoney(value)}</Text>;
  }
  if (value && parseFloat(value) === 0) {
    return <Text>-</Text>;
  }
  return <Text color={colors.valueRed1}>{formatMoney(value)}</Text>;
};

export interface displayData {
  lastAmountPaid: string | null | undefined;
  lastInvoiceDocument?: string | null | undefined;
  lastPaymentDate: string | null | undefined;
}

export interface billingInfoProps {
  lastDayToPayFlag: boolean;
  loading?: boolean;
  error?: Error;
  onInvoiceClick?: Function;
  infoData?: displayData;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

export default ({
  lastDayToPayFlag,
  loading,
  error,
  infoData
}: billingInfoProps) => {
  if (loading || lastDayToPayFlag === undefined) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error</Text>;
  }

  if (lastDayToPayFlag) {
    return (
      <LastPaymentSection
        lastPaymentAmt={
          infoData?.lastAmountPaid
            ? renderNumber(infoData?.lastAmountPaid)
            : '-'
        }
        lastPaymentDate={
          infoData?.lastPaymentDate
            ? formatDate(infoData?.lastPaymentDate)
            : '-'
        }
      />
    );
  }

  return (
    <Box background="white" fill="horizontal">
      <Row>
        <Text weight="bold">Payment Date</Text>
        <Text>
          {infoData?.lastPaymentDate
            ? formatDate(infoData?.lastPaymentDate)
            : '-'}
        </Text>
      </Row>
      <Row>
        <Text weight="bold">Amount Paid</Text>
        <Text>
          {infoData?.lastAmountPaid
            ? renderNumber(infoData?.lastAmountPaid)
            : '-'}
        </Text>
      </Row>
    </Box>
  );
};
