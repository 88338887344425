import styled from 'styled-components';

export const SectionTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #3b3b3b;
  margin: 0 0 10px;
`;

export const PaymentMethodPill = styled.div`
  margin-right: auto;
  padding: 4px 10px;
  background: #e0e0e0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13.33px;
  line-height: 16px;
  color: #3b3b3b;
  margin-bottom: 30px;
`;

export const CurrentPoliciesTable = styled.table`
  border-spacing: 0;
  text-align: left;
`;

export const CurrentPoliciesTableTR = styled.tr`
  border-bottom: 1px solid #eceff5;

  &:first-child {
    border: none;
  }

  &:nth-child(2) > td {
    padding-top: 20px;
  }

  &:last-child {
    border: none;
  }
`;

export const CurrentPoliciesTableTH = styled.th`
  background: #f4f6fa;
  padding: 12px 15px;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    text-align: right;
  }
`;

export const CurrentPoliciesTableTD = styled.td`
  padding: 10px 15px;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    text-align: right;
  }
`;

export const StyledHyperLink = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #0173C6;
  font-family: PT Sans, sans-serif;
`;
