import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import {
  MainWrapper,
  ClaimNumberText,
  ClaimNumberTextHeading,
  ClaimNumberWrapper
} from './wcClaimsDetailWrapperStyles';
import WcClaimsDetailBox from './wcClaimsDetailBox';
import { WCClaimsExpandedView } from '../wcClaimsExpandedView';
import { SelectedClaimsProps } from '../wcClaimsTab/WCClaimsTab';
const get_wc_claim_details = loader('./graphql/Get_WC_Claim_Details.graphql');

export interface WCClaimsDetailProps {
  selectedClaim: boolean | string;
  selectedClaimsObject: SelectedClaimsProps | null;
}

export default ({
  selectedClaim,
  selectedClaimsObject
}: WCClaimsDetailProps) => {
  const { data: claimDetailResult } = useQuery(get_wc_claim_details, {
    variables: selectedClaimsObject
  });
  const [expandedView, setExpandedView] = useState<boolean>(false);

  return (
    <MainWrapper>
      {claimDetailResult && (
        <>
          <ClaimNumberWrapper>
            {selectedClaimsObject && (
              <>
                <ClaimNumberTextHeading>Claim Number:</ClaimNumberTextHeading>
                <ClaimNumberText>
                  {selectedClaimsObject?.claimNumber}
                </ClaimNumberText>
              </>
            )}
          </ClaimNumberWrapper>
          <WcClaimsDetailBox
            claimDetailResult={claimDetailResult.getWCDetails}
            expandedView={expandedView}
            setExpandedView={setExpandedView}
            selectedClaimsObject={selectedClaimsObject}
          />
          {expandedView ? (
            <WCClaimsExpandedView
              accountNumber={claimDetailResult.getWCDetails.account_number}
              policyNumber={claimDetailResult.getWCDetails.policynumber}
              claimNumber={selectedClaim}
              adjusterName={
                claimDetailResult.getWCDetails.adjusterDetails.username
              }
              adjusterEmail={
                claimDetailResult.getWCDetails.adjusterDetails.email
              }
              adjusterPhone={
                claimDetailResult.getWCDetails.adjusterDetails.phone
              }
              name={claimDetailResult.getWCDetails.injuredContactDetails.name}
            />
          ) : null}
        </>
      )}
    </MainWrapper>
  );
};
