import { Box, ResponsiveContext, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import getConfig, { Env } from '../../config';
import { PrimaryButton } from '..';
import agencyBill from '../../assets/images/illustration-agency-bill.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const CardWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 550px;
  height: 182px;
`;

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 44px;
  padding-top: 15px;
  flex-wrap: nowrap;

  * {
    margin-bottom: 25px;
  }
`;

const CardHeader = styled(Text)`
  font-weight: 700;
  font-size: 24px;
`;

const CardBodyText = styled(Text)`
  font-weight: 400;
  font-size: 18px;
`;

const AgencyBillButton = styled(PrimaryButton)`
  width: 213px;
  height: 45px;
  border-radius: 4px;
  padding: 12px 25px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`;

const config = getConfig(process.env.REACT_APP_ENV as Env);

export const onClickHandler = () => {
  simpleGTMDataLayer({
    event: 'click-billingAgencyBillButton'
  });
  window.open(config.agency_bill_url, '_blank');
};

export default () => {
  return (
    <ResponsiveContext.Consumer>
      {responsive => (
        <CardWrapper>
          <img src={agencyBill} alt="agency billed icon" />
          <TextWrapper>
            <CardHeader data-test-id="agency-billing-text">
              This Account Uses Agency Billing
            </CardHeader>
            <CardBodyText>Need to see billing details?</CardBodyText>
            <AgencyBillButton
              data-test-id="agency-billing-button"
              onClick={() => onClickHandler()}
            >
              VIEW AGENCY BILL
            </AgencyBillButton>
          </TextWrapper>
        </CardWrapper>
      )}
    </ResponsiveContext.Consumer>
  );
};
