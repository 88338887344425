import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useParams } from '@reach/router';
import WcClaimsTab from '../../components/wcClaimsTab/WCClaimsTab';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import { PrimarySpinner } from '../../icons';
import styled,{ keyframes }  from 'styled-components';
const get_wc_claim_list = loader('./graphql/Get_WC_Claim_List.graphql');
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  display: inline-block;
  animation: ${spinAnimation} 1s infinite linear;
  margin: 5px;
`;
export const Loading = styled.span`
  color: #0173c6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export interface WcClaimsTabProps {
  showOtherClaimHandler: Function;
}


export default ({ showOtherClaimHandler }: WcClaimsTabProps) => {
  const { accountNumber } = useParams();
  let claimsList: [ClaimsData] | null = null;
  const variables = {
    accountNumber
  };
  const { data: getWCClaimsResult, loading: wcClaimsResultLoading } = useQuery(
    get_wc_claim_list,
    {
      variables
    }
  );
  if (getWCClaimsResult && getWCClaimsResult.wcClaimsList) {
    claimsList = getWCClaimsResult.wcClaimsList;
  }
 
  return (
    <div>
      {wcClaimsResultLoading || false ? (
       <>
       <Loading>
         <Spinner>
           <PrimarySpinner />
         </Spinner>
         Loading ...
       </Loading>
     </>
      ) : (
        claimsList ? (
          <WcClaimsTab
            showOtherClaimHandler={showOtherClaimHandler}
            loading={wcClaimsResultLoading || false}
            claimsList={claimsList}
            accountNumber={accountNumber}
            claimsDetail={'test' || false} 
          />
        ) : null
      )}
    </div>
  );
  
  
};
