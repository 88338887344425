import React, { useState } from 'react';
import { Box, Form, Text, TextInput, Button } from 'grommet';
import { navigate, RouteComponentProps } from '@reach/router';

interface changeEvent {
  target: {
    value: string;
  };
}

export default (_: RouteComponentProps) => {
  const [accountNumber, setAccountNumber] = useState('');

  const onChange = (e: changeEvent) => {
    setAccountNumber(e.target.value);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    navigate(`/account/${accountNumber}/overview`);
  };

  return (
    <Box align="center" gap="small">
      <Text>Enter the account number you wish to view.</Text>
      <Form onSubmit={onSubmit} style={{ display: 'flex' }}>
        <Box direction="row" align="center">
          <Box
            border={{ color: 'gray', size: 'small' }}
            fill
            direction="row"
            align="center"
          >
            <TextInput
              name="toolsSearch"
              onChange={onChange}
              value={accountNumber}
              placeholder="Account Number"
              plain
            />
          </Box>
          <Button type="submit" primary label="Go to Account" />
        </Box>
      </Form>
    </Box>
  );
};
