import React, { useState } from 'react';
import { Box, Select as GrommetSelect, Text } from 'grommet';
import styled from 'styled-components';
import { DocumentsList } from '.';
import pdfIcon from '../../assets/images/PDF Icon.svg';
import { GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items } from '../../pages/DocumentsPage/graphql/__generated__/GET_DOCUMENTS_BY_ACCOUNT';
import { motifs } from '../../themes/default.theme';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { UploadModal } from '..';
import { GET_POLICIES_BY_ACCOUNT_account_items_policies_items } from '../billingPayPlans/graphql/__generated__/GET_POLICIES_BY_ACCOUNT';

export function iconType(fileType: string) {
  let icon = <img src={pdfIcon} alt="PDF" />;
  if (fileType.includes('pdf')) {
    icon = <img src={pdfIcon} alt="PDF" />;
  }
  return icon;
}

const StyledDocumentsBox = styled(Box)`
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
  background-color: #ffffff;
`;

const SelectWrapper = styled.div`
  button {
    border-color: ${motifs.lightBorder};
    border-radius: 3px;
    & > div {
      border-radius: 3px;
    }
  }
`;

export const Select = styled(GrommetSelect)`
  font-weight: normal;
`;

export const UploadButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  background-color: #65a100;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #65a100;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    background: #e0e0e0;
    color: #979797;
    border: none;
  }
`;

// Alias for cleaner usage
type Document = GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items;

export interface DocumentsTableProps {
  documents: Document[];
  onDocumentClick: Function;
  accountNumber: string;
  policies: GET_POLICIES_BY_ACCOUNT_account_items_policies_items[];
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
  nextClickHandler: Function;
}

enum DocumentType {
  All = 'All Documents',
  Billing = 'Billing',
  Policy = 'Policy',
  PDF = 'PDF',
  HTML = 'HTML',
  Word = 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT',
  IdCard = 'ID Card',
  Declaration = 'Declaration Pages',
  EPayStatement = 'E-Pay Statement',
  Other = 'Other'
}

let documentTypeOptions = [
  DocumentType.All,
  DocumentType.Billing,
  DocumentType.Policy,
  DocumentType.PDF,
  DocumentType.HTML,
  DocumentType.IdCard,
  DocumentType.Declaration,
  DocumentType.EPayStatement,
  DocumentType.Other
];

const hasClassification = (type: string) => (document: Document) =>
  document.classification === type;
const hasFileType = (type: string) => (document: Document) =>
  document.file_type === type;
const hasFileName = (type: string) => (document: Document) =>
  document.file_name?.includes(type);
const isBilling = hasClassification('Billing');
const isPolicy = hasClassification('Policy');
const isPDF = hasFileType('PDF');
const isHTML = hasFileType('HTML');
const isIdCard = hasFileName('ID Card');
const isDeclaration = hasFileName('Declaration');
const isEPayStatement = hasFileName('E-Pay Statement');
const isOther = (document: Document) =>
  !(
    document.file_name?.includes('ID Card') ||
    document.file_name?.includes('Declaration') ||
    document.file_name?.includes('E-Pay Statement')
  );

const filterDocuments = (documents: Document[], filter: DocumentType) => {
  switch (filter) {
    case DocumentType.Billing:
      return documents.filter(isBilling);
    case DocumentType.Policy:
      return documents.filter(isPolicy);
    case DocumentType.PDF:
      return documents.filter(isPDF);
    case DocumentType.HTML:
      return documents.filter(isHTML);
    case DocumentType.IdCard:
      return documents.filter(isIdCard);
    case DocumentType.Declaration:
      return documents.filter(isDeclaration);
    case DocumentType.EPayStatement:
      return documents.filter(isEPayStatement);
    case DocumentType.Other:
      return documents.filter(isOther);
    case DocumentType.All:
    default:
      return documents;
  }
};

export default ({
  documents,
  onDocumentClick,
  accountNumber,
  policies,
  readOnlyAccess,
  nextClickHandler,
  readOnlyFeatureFlag
}: DocumentsTableProps) => {
  const [selectedFilter, setSelectedFilter] = useState(DocumentType.All);
  const Documents = DocumentsList;
  const [showModal, setShowModal] = useState(false);
  const [staging, setStaging] = useState(false);
  return (
    <Box fill>
      {showModal && (
        <UploadModal
          staging={staging}
          showModalHandler={setShowModal}
          stagingHandler={setStaging}
          policies={policies}
          readOnlyAccess={readOnlyAccess}
          readOnlyFeatureFlag={readOnlyFeatureFlag}
        />
      )}
      {documents.length === 0 ? (
        <Text size="medium">No documents found for this account...</Text>
      ) : (
        <Box responsive direction="column" gap="36px">
          <Box align="end" direction="row" justify="between">
            <UploadButton
              id="OpenModal"
              onClick={() => {
                simpleGTMDataLayer({
                  event: 'uploadDocumentClick',
                  event_action: 'Button Click',
                  event_category: window.location.pathname,
                  event_label: 'Open Upload Documents Modal'
                });
                setStaging(false);
                setShowModal(true);
              }}
              disabled={readOnlyAccess && readOnlyFeatureFlag}
            >
              UPLOAD DOCUMENTS
            </UploadButton>
            <SelectWrapper>
              <Select
                options={documentTypeOptions}
                value={selectedFilter}
                onChange={({ option }) => {
                  simpleGTMDataLayer({
                    event: 'click-documentsFilter',
                    filterOption: option
                  });
                  setSelectedFilter(option);
                }}
              />
            </SelectWrapper>
          </Box>
          <StyledDocumentsBox>
            <Documents
              documents={filterDocuments(
                documents,
                selectedFilter as DocumentType
              )}
              onDocumentClick={onDocumentClick}
              accountNumber={accountNumber}
              policies={policies}
              readOnlyAccess={readOnlyAccess}
              readOnlyFeatureFlag={readOnlyFeatureFlag}
              nextClickHandler={nextClickHandler}
            />
          </StyledDocumentsBox>
        </Box>
      )}
    </Box>
  );
};
