import React from 'react';

import moment from 'moment-timezone';

import {
  SnapshotInvoicesContainer,
  ContainerTitleWrap,
  LoadingIcon,
  TitleIcon,
  ContainerTitle,
  InvoiceTableHeader,
  InvoiceTableHeaderColumn,
  InvoiceRowsWrap,
  InvoiceTableRow,
  InvoiceTableRowColumn,
  InvoiceTableFooter,
  InvoiceTableFooterColumn,
  ErrorMessage
} from './SnapshotInvoicesTableStyles';

import ListSrc from '../../../assets/images/list.svg';
import SpinnerSrc from '../../../assets/images/spinner-primary.svg';

const getMomentObj = (date: string) =>
  moment.tz(date || '', 'YYYY-MM-DD', 'America/New_York');

const formatted_amount = (amount: number) => {
  const is_amount_negetive = Boolean(amount < 0);
  return `${is_amount_negetive ? '-' : ''}$${Math.abs(amount).toFixed(2)}`;
};

interface invoiceTableObj {
  invoice_item_amount: number | null;
  item_date: string | null;
  invoice_number: string | null;
}

interface Props {
  amountChange: number | null;
  error: boolean;
  loading: boolean;
  tableData: invoiceTableObj[];
}

const SnapshotInvoicesTable = ({
  amountChange,
  error,
  loading,
  tableData
}: Props) => {
  if (error) {
    return (
      <SnapshotInvoicesContainer>
        <ErrorMessage>Something went wrong.</ErrorMessage>
      </SnapshotInvoicesContainer>
    );
  }

  if (loading) {
    return (
      <SnapshotInvoicesContainer>
        <LoadingIcon src={SpinnerSrc} />
      </SnapshotInvoicesContainer>
    );
  }

  const total_amount = tableData.reduce(
    (total, { invoice_item_amount: amount }) => total + (amount || 0),
    0
  );

  return (
    <SnapshotInvoicesContainer>
      {tableData?.length ? (
        <>
          <ContainerTitleWrap>
            <TitleIcon src={ListSrc} />
            <ContainerTitle>Change Breakdown</ContainerTitle>
          </ContainerTitleWrap>
          <InvoiceTableHeader>
            <InvoiceTableHeaderColumn>Invoice Date</InvoiceTableHeaderColumn>
            <InvoiceTableHeaderColumn>Amount</InvoiceTableHeaderColumn>
          </InvoiceTableHeader>
          <InvoiceRowsWrap>
            {tableData
              .sort(
                (a: invoiceTableObj, b: invoiceTableObj) =>
                  getMomentObj(b.item_date || '').valueOf() -
                  getMomentObj(a.item_date || '').valueOf()
              )
              .map(
                ({
                  invoice_item_amount: amount,
                  item_date,
                  invoice_number
                }) => {
                  const is_amount_negetive = Boolean(amount && amount < 0);
                  const formatted_date = moment
                    .tz(item_date || '', 'YYYY-MM-DD', 'America/New_York')
                    .format('MM/DD/YYYY');

                  return (
                    <InvoiceTableRow key={invoice_number}>
                      <InvoiceTableRowColumn>
                        {formatted_date}
                      </InvoiceTableRowColumn>
                      <InvoiceTableRowColumn
                        className={is_amount_negetive ? 'font-red' : ''}
                      >
                        {amount !== null ? formatted_amount(amount) : '-'}
                      </InvoiceTableRowColumn>
                    </InvoiceTableRow>
                  );
                }
              )}
          </InvoiceRowsWrap>
          <InvoiceTableFooter>
            <InvoiceTableFooterColumn>Total Change</InvoiceTableFooterColumn>
            <InvoiceTableFooterColumn>
              {formatted_amount(amountChange || 0)}
            </InvoiceTableFooterColumn>
          </InvoiceTableFooter>
        </>
      ) : (
        <ErrorMessage>
          {amountChange === 0
            ? 'This change did not affect the premium.'
            : 'No invoices data.'}
        </ErrorMessage>
      )}
    </SnapshotInvoicesContainer>
  );
};

export default SnapshotInvoicesTable;
