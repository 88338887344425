import React, { useEffect, ReactElement } from 'react';

import { yearRangeArr } from '../DatePickerUtil';

import {
  YearSelectionContainer,
  Year,
  yearHeight,
  yearLineHeight,
  yearsContainerHeight
} from './YearView.styles';
import {
  YearSelectionContainerFilter,
  YearFilter,
  yearHeightFilter,
  yearLineHeightFilter,
  yearsContainerHeightFilter
} from './YearViewFilter.styles';

export interface YearViewProps {
  selectedYear: number;
  setCurrentYear: Function;
  isFilter?: boolean;
}

const YearView = ({
  selectedYear,
  setCurrentYear,
  isFilter
}: YearViewProps): ReactElement => {
  useEffect(() => {
    if(isFilter){const posY: number =
      yearRangeArr.indexOf(selectedYear) * yearHeightFilter -
      yearsContainerHeightFilter / 2 +
      yearLineHeightFilter / 2;
    const yearscontainer = window.document.getElementById('yearscontainer');
    if (yearscontainer) {
      yearscontainer.scrollTop = posY;
    }}
    else{
    const posY: number =
      yearRangeArr.indexOf(selectedYear) * yearHeight -
      yearsContainerHeight / 2 +
      yearLineHeight / 2;
    const yearscontainer = window.document.getElementById('yearscontainer');
    if (yearscontainer) {
      yearscontainer.scrollTop = posY;
    }
  }}, []);

  return (
    <>
      {isFilter ? (
        <YearSelectionContainerFilter id="yearscontainer">
          {yearRangeArr.map(year => (
            <YearFilter
              selected={year === selectedYear}
              key={year}
              onClick={() => setCurrentYear(year)}
            >
              {year}
            </YearFilter>
          ))}
        </YearSelectionContainerFilter>
      ) : (
        <YearSelectionContainer id="yearscontainer">
          {yearRangeArr.map(year => (
            <Year
              selected={year === selectedYear}
              key={year}
              onClick={() => setCurrentYear(year)}
            >
              {year}
            </Year>
          ))}
        </YearSelectionContainer>
      )}
    </>
  );
};

export default YearView;
