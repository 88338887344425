import { GET_POLICY_INFO_account_items_policy_insuredparties_items } from '../../components/policyInfo/graphql/__generated__/GET_POLICY_INFO';

export default (
  parties:
    | (GET_POLICY_INFO_account_items_policy_insuredparties_items | null)[]
    | null
    | undefined
) => {
  const uniqueParties: (string | null)[] = [];
  let i = 0;
  const partiesArray = parties ?? [];
  for (i = 0; i < partiesArray.length; i += 1) {
    if (
      ((partiesArray[i]!.retired && partiesArray[i]!.retired === 'N') ||
        partiesArray[i]!.retired_date === null) &&
      !uniqueParties.includes(partiesArray[i]!.display_name)
    ) {
      uniqueParties.push(partiesArray[i]!.display_name);
    }
  }
  return uniqueParties;
};
