import React from 'react';
import { SuccessText, ErrorText } from './cancellationRequestSentStyles';
import { ErrorIcon, AltSuccess } from '../../../icons';

interface RequestSentProps {
  systemCode: string;
  error: Error | undefined;
  acceptable: boolean;
  underwriting: boolean | null;
}

export default ({
  systemCode,
  error,
  acceptable,
  underwriting
}: RequestSentProps) => {
  return (
    <div>
      {underwriting ? (
        <>
          {error ? (
            <ErrorText>
              <ErrorIcon />
              Your cancellation request could not be processed. Please try again
              later or contact{' '}
              {systemCode === 'GWCL' ? 'Commercial' : 'Personal'} Underwriting.
            </ErrorText>
          ) : (
            <div>
              {acceptable ? (
                <SuccessText>
                  <AltSuccess />
                  Your cancellation request has been successfully submitted to
                  {systemCode === 'GWCL' ? ' Commercial' : ' Personal'}{' '}
                  Underwriting.
                </SuccessText>
              ) : (
                <ErrorText>
                  <ErrorIcon />
                  This cancellation is not allowed at this time.
                </ErrorText>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {error ? (
            <ErrorText>
              <ErrorIcon />
              Your cancellation request could not be processed. Please try again
              later or contact{' '}
              {systemCode === 'GWCL' ? 'Commercial' : 'Personal'} Underwriting.
            </ErrorText>
          ) : (
            <div>
              {acceptable ? (
                <SuccessText>
                  <AltSuccess />
                  Your policy has been cancelled successfully.
                </SuccessText>
              ) : (
                <ErrorText>
                  <ErrorIcon />
                  This cancellation is not allowed at this time.
                </ErrorText>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
