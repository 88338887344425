import styled from 'styled-components';
import { Box } from 'grommet';
export const MainWrapper = styled.div`
`;

export const ClaimNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
`;

export const ClaimNumberTextHeading = styled.div`
  color: rgb(59, 59, 59);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const ClaimNumberText = styled.div`
  color: rgb(59, 59, 59);
  font-size: 25px;
  font-weight: 700;
  line-height: 25px;
  margin-left: 10px;
  text-align: left;
  text-transform: none;
`;

export const ClaimDetailBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(59, 59, 59);
  border-radius: 4px;
  box-shadow: rgba(1, 83, 142, 0.2) 0px 2px 4px 0px;
  height: initial;
  min-height: initial;
  min-width: initial;
  overflow: hidden;
  width: initial;
`;

export const WorkerCompDetailCol = styled.div`
  width: 33%;
`;

export const WorkerCompDetailHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const WorkerCompDetailIcon = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export const WorkerCompDetailText = styled.div`
  color: rgb(59, 59, 59);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const AdjusterDetailCol = styled.div`
  width: 43%;
`;

export const AdjusterDetailHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const AdjusterDetailIcon = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export const AdjusterDetailText = styled.div`
  color: rgb(59, 59, 59);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const StatusDetailCol = styled.div`
  width: 23%;
`;

export const StatusDetailHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const StatusDetailIcon = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export const StatusDetailText = styled.div`
  color: rgb(59, 59, 59);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const StatusCapsuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-around;
`;

export const StatusCapsuleLeft = styled.div`
  background-color: transparent;
  border: 1px solid rgb(1, 116, 198);
  border-radius: 100px;
  color: rgb(1, 116, 198);
  display: inline-flex;
  font-size: 12px;
  font-weight: 700;
  padding: 2px 10px calc(3px);
  white-space: nowrap;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  height: 21px;
  margin-left:20px;
`;

export const StatusCapsuleRightIcon = styled.div`
  height: 30px;
  width: 30px;
`;

export const DetailBox = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export const DetailRowBox = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  margin-left:40px;
`;

export const DetailColumnOneBox = styled.div`
  color: rgb(59, 59, 59);
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const DetailColumnTwoBox = styled.div`
  margin-left: 10px;
  color: rgb(59, 59, 59);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  text-transform: none;
`;
export const WarningBanner = styled(Box)`
  padding: 10px;
  margin-bottom: 10px;
  /* background-color: rgb(231, 245, 250); */
  /* border-color: rgb(185, 226, 240) rgb(185, 226, 240) rgb(185, 226, 240) rgb(24, 160, 205); */
  display: flex;
  background: rgb(231, 245, 250);
  /* color: rgb(12, 80, 102); */
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: rgb(185, 226, 240) rgb(185, 226, 240) rgb(185, 226, 240)
  rgb(24, 160, 205);
  border-image: initial;
  border-radius: 4px;
  box-shadow: rgba(1, 83, 142, 0.2) 0px 2px 4px 0px;
`;
export const StatusContainer=styled.div`
margin-left:50px;
background-color: transparent;
border: 1px solid rgb(1, 116, 198);
border-radius: 100px;
color: rgb(1, 116, 198);
display: inline-flex;
font-size: 12px;
font-weight: 700;
padding: 2px 10px calc(3px);
white-space: nowrap;
-webkit-box-align: center;
align-items: center;
box-sizing: border-box;
height: 21px;
`;