/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */

import getConfig, { Env } from '../../config';

const config = getConfig(process.env.REACT_APP_ENV as Env);

let script: HTMLScriptElement;

class CPRAFooter {
  static initialize = () => {
    const loadScript = new Promise((resolve, reject) => {
      script = document.createElement('script');
      script.src = config.cpra_url;
      script.addEventListener('load', () => {
        resolve(true);
      });
      script.addEventListener('error', (e) => {
        reject(e);
      });
      document.body.appendChild(script);
    });
    loadScript.catch((err) => {
      console.error(err);
    });
  };
}

export { CPRAFooter };