import React, { useState } from 'react';
import styled from 'styled-components';
import NoClaimsIcon from '../../icons/NoClaimsIcon';
import { LossRunRequest, PrimaryButton } from '..';
import FeatureFlag from '../../components/featureFlags';
import getConfig, { Env } from '../../config';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import ClaimsSelectAPolicyDropdown from '../claimsSelectAPolicyDropdown';
import ViewWcButton from '../../pages/ClaimsPage/ViewWcButton';
const config = getConfig(process.env.REACT_APP_ENV as Env);

const OuterBox = styled.div`
  text-align: center;
`;

const BigBoldText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin-top: 45px;
`;

const SmallText = styled.p`
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const NewClaimButton = styled(PrimaryButton)`
  padding: 12px 25px 12px 25px;
  display: inline;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  width: 100%;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  margin-left: 40%;
  margin-top: 300px;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 230px;
`;

const BoxPaddingTop = styled.div`
  padding-top: 1.25rem;
`;


export interface ClaimsPageEmptyStateProps {
  UserDisplayName: string;
  showwcClaimHandler: Function;
  setWcClaim: Function;
  wcClaim: boolean;
}

export default ({
  UserDisplayName,
  showwcClaimHandler,
  setWcClaim,
  wcClaim
}: ClaimsPageEmptyStateProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <OuterBox>
     <ViewWcButton showwcClaimHandler={showwcClaimHandler} wcClaim={wcClaim}/>

      <ClaimsSelectAPolicyDropdown
        showModal={showModal}
        setShowModal={setShowModal}
        setWcClaim={setWcClaim}
      />
      <NoClaimsIcon />
      <BigBoldText>No Claims Reported {UserDisplayName}</BigBoldText>
      <SmallText>Need to file a new claim?</SmallText>
      <FlexBox>
        <NewClaimButton
          onClick={() => {
            simpleGTMDataLayer({
              event: 'click-claimsEmptyStartClaim'
            });
            setShowModal(true);
          }}
          // disabled={readOnlyAccess && readOnlyFeatureFlag}
        >
          START A NEW CLAIM
        </NewClaimButton>
        <BoxPaddingTop>
          <FeatureFlag flagName="ClaimsLossRuns">
            <LossRunRequest screenPath="claims" />
          </FeatureFlag>
        </BoxPaddingTop>
      </FlexBox>
    </OuterBox>
  );
};
