import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import CommunicationAccordion from '../../components/communicationAccordion';
import simpleGTMDataLayer from '../../util/GTMHelpers/simpleGTMDataLayer';

export default (_: RouteComponentProps) => {
  useEffect(() => {
    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Communications Page',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });
    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Communications Page',
      event_category: localStorage.getItem('lob')
        ? `${localStorage.getItem('lob')}/Snowplow`
        : '/Snowplow'
    });
    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/account/communications',
        title: 'SA360: Communications'
      }
    });
  }, []);
  return <CommunicationAccordion page="communications" />;
};
