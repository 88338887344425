import styled, { css } from 'styled-components';
export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

export const Form = styled.div`
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;
export const Reset = styled.button`
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
  display: block;
  margin: 0 auto;
  background-color: #e5f1f9;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  transition: border-color 0.3s ease, transform 0.3s ease;
  font-weight: bold;
  &:hover {
    border: 1px solid green;
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const CloseIconContainer = styled.div`
  margin-left: 10px;
`;
export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownToggle = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  color: #fff;
`;

export const DropdownMenu = styled.ul<{ open: boolean }>`
  position: absolute;
  top: 100%;
  right: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 1;
`;

export const DropdownMenuItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }
`;

export const FilterBox = styled.div`
  background-color: #0855a4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SearchBarContainer = styled.form`
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 10px;
  justify-content: center;
`;

export const Input = styled.input`
  padding: 10px 40px 10px 35px;
  border: 1px solid #0173C6s;
  width: ${props => props.width};
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #90ee90;
  }
`;

interface IconProps {
  position: string;
}
export const Icon = styled.span<IconProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => (props.position === 'left' ? 'left: 10px;' : 'right: 10px;')}
`;
export const SearchView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margintop: 120px;
  justify-content: center;
`;

export const SearchViewItems = styled.div`
  padding: 4px;
`;
export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
`;
export const SortIcon = styled.span`
  margin-left: 6px;
  display: inline-flex;
  height: 100%;
  margin-top:5px;
`;
