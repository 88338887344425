import { Text, ResponsiveContext, Box } from 'grommet';
import React from 'react';
import { GET_PAYMENT_HISTORY_account_items_payment_history_items } from './graphql/__generated__/GET_PAYMENT_HISTORY';
import Headers from './paymentHistoryTableHeaders';
import Rows from './PaymentHistoryRows';
import usePagination from '../../util/usePagination';

export interface PaymentHistoryProps {
  loading?: boolean;
  error?: Error;
  payments?: (GET_PAYMENT_HISTORY_account_items_payment_history_items | null)[];
  accountNumber: string;
}

export default ({
  loading,
  error,
  payments = [],
  accountNumber
}: PaymentHistoryProps) => {
  const [displayablePayments, pageSelector] = usePagination({
    accountNumber,
    items: payments,
    itemsPerPage: 25
  });
  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      {payments?.length === 0 ? (
        <Text>No payments found for this account...</Text>
      ) : (
        <ResponsiveContext.Consumer>
          {responsive => (
            <>
              <Headers />
              <Rows responsive={responsive} payments={displayablePayments} />
              <Box align="end">{pageSelector}</Box>
            </>
          )}
        </ResponsiveContext.Consumer>
      )}
    </>
  );
};
