import styled from 'styled-components';

export const LossRunModalWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(4, 30, 65, 0.7);
`;

export const LossRunModalContainer = styled.div`
  width: 450px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
`;

export const ModalHeaderRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

export const ModalHeaderText = styled.p`
  flex-grow: 1;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
  margin: 0;
`;

export const ModalCloseBtn = styled.img`
  display: block;
  width: 36px;
  height: auto;
  cursor: pointer;
  margin: -8px -8px 0 0;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const PolicySelectGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0 0;

  &.disabled {
    opacity: 0.7;

    * {
      cursor: not-allowed;
    }
  }
`;

export const SelectLabel = styled.span`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 6px;
  line-height: 18px;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  color: #666666;

  &.error {
    color: #b00020;
  }

  &.active {
    color: #65a100;
  }
`;

export const ElementWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-radius: 5px;
  flex-grow: 1;
  border-color: #e7e7e7;

  &.error {
    border-color: #b00020;
  }

  &.active {
    border-color: #65a100;
  }
`;

export const SelectElement = styled.select`
  flex-grow: 1;
  height: 56px;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  padding: 0 16px;
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
`;

export const SelectChevron = styled.img`
  display: block;
  position: absolute;
  height: auto;
  width: 14px;
  right: 16px;
  top: calc(50% - 4px);
  z-index: 0;
`;

export const ModalAlertWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ModalAlertIcon = styled.img`
  display: block;
  margin-bottom: 30px;
  width: 72px;
  height: auto;

  &.submitError {
    width: 100px;
    margin-top: -12.5px;
    margin-bottom: 17.5px;
  }
`;

export const ModalAlertTitle = styled.p`
  font-weight: 700;
  font-size: 19px;
  color: #3b3b3b;
  margin: 0 0 10px;
`;

export const ModalAlertDescription = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
  margin: 0;
`;

export const ModalBtnRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;

const ModalBtn = styled.p`
  cursor: pointer;
  padding: 0 25px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 19px;
  line-height: 50px;
  margin: 0;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const ModalCancelBtn = styled(ModalBtn)`
  color: #24272d;
  margin-right: 20px;
`;

export const ModalContinueBtn = styled(ModalBtn)`
  background-color: #4d840b;
  color: #ffffff;
`;
