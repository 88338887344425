import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useParams, RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { BillingPage } from '.';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  accountHasAgencyBilled,
  agencyBilledOnly
} from '../../util/agencyBilledUtils';
import { agencyBillClickHandler } from '../../components/paymentInfo/DataContainer';
import { GET_CANCELLATION_STATUS } from './graphql/__generated__/GET_CANCELLATION_STATUS';
import { useGlobalContext } from '../../util/globalContextProvider';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';

const get_user_id = loader('./graphql/Billing_Page_Get_User_Id.graphql');
const get_payplan_billing_method = loader(
  './graphql/Billing_Page_Get_Policy_PayPlan_Billing_Method.graphql'
);

const get_cancellation_status = loader(
  './graphql/Get_Cancellation_Status.graphql'
);

const get_migrated_status = loader('../../graphql/Has_Migrated_Policy.graphql');

const cancelledStatus = (data: GET_CANCELLATION_STATUS) => {
  let cancelled = true;
  if (
    data.account &&
    data.account.items &&
    data.account.items[0] &&
    data.account.items[0].policies
  ) {
    const statuses = data.account.items[0].policies.items;
    let i = 0;
    if (statuses) {
      for (i = 0; i < statuses.length; i += 1) {
        if (statuses[i]?.status !== 'Cancelled') {
          cancelled = false;
        }
      }
    }
    return cancelled;
  }
  return cancelled;
};

export default (_: RouteComponentProps) => {
  const { accountNumber } = useParams();

  const { flagDetails: billingCenterOutageFlagDetails } = useFeatureFlag(
    'BillingCenter-Outage'
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL'
  );

  const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();
  const variables = {
    account_number: accountNumber
  };
  const useAllQueries = () => {
    const { data: getUserIdResult, loading: userIdLoading } = useQuery(
      get_user_id
    );
    const {
      data: getPayPlanBillingMethodResult,
      loading: billingMethodLoading
    } = useQuery(get_payplan_billing_method, {
      variables
    });

    const billingLoading = userIdLoading || billingMethodLoading;

    return {
      getUserIdResult,
      getPayPlanBillingMethodResult,
      billingLoading
    };
  };

  // get migrated status
  const getMigratedStatusQueryResult = useQuery(get_migrated_status, {
    variables
  });

  const cancellationQueryResult = useQuery(get_cancellation_status, {
    variables
  });
  const {
    getUserIdResult,
    getPayPlanBillingMethodResult,
    billingLoading
  } = useAllQueries();
  const { data: cancellationData } = cancellationQueryResult;
  const { data: migratedStatusData } = getMigratedStatusQueryResult;
  const userID = getUserIdResult?.data?.me?.user_id || 'Not Available';
  const [openOneIncURL] = generateOpenOneIncURL(
    accountNumber,
    userID,
    SSOneINCURL?.enabled,
    sessionStorage?.userLOB?.includes('Commercial') ? 'GWCL' : 'GWPL'
  );
  const [gtm, setGTM] = useState(false);
  const editPaymentInfo = (isAltPaymentOptions = null) => {
    simpleGTMDataLayer({
      event: 'click-billingEditPaymentInfo'
    });
    openOneIncURL(isAltPaymentOptions);
  };
  let makePayment: Function;

  if (agencyBilledOnly(getPayPlanBillingMethodResult)) {
    makePayment = agencyBillClickHandler;
  } else {
    makePayment = (isAltPaymentOptions = null) => {
      simpleGTMDataLayer({
        event: 'click-billingMakePayment'
      });
      openOneIncURL(isAltPaymentOptions);
    };
  }

  if (!gtm) {
    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Billing Page',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });
    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Billing Page',
      event_category: localStorage.getItem('lob')
        ? `${localStorage.getItem('lob')}/Snowplow`
        : '/Snowplow'
    });

    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/account/billing',
        title: 'SA360: Billing'
      }
    });
    setGTM(true);
  }
  let allCancelled: boolean | undefined = false;
  if (cancellationData) {
    allCancelled = cancelledStatus(cancellationData);
  }

  let isDataMigrated: boolean = false;
  if (
    migratedStatusData &&
    migratedStatusData?.hasMigratedPolicy &&
    migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy
  ) {
    isDataMigrated = migratedStatusData?.hasMigratedPolicy?.hasMigratedPolicy;
  }

  return (
    <BillingPage
      editPaymentInfoClick={editPaymentInfo}
      makePaymentClick={makePayment}
      agencyBilled={accountHasAgencyBilled(getPayPlanBillingMethodResult)}
      billingLoading={billingLoading}
      agencyOnly={
        getPayPlanBillingMethodResult
          ? agencyBilledOnly(getPayPlanBillingMethodResult)
          : false
      }
      allCancelled={allCancelled}
      readOnlyAccess={readOnlyAccess}
      readOnlyFeatureFlag={readOnlyFeatureFlag}
      billingCenterOutage={billingCenterOutageFlagDetails?.enabled ?? false}
      isMigrated={isDataMigrated}
    />
  );
};
