import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Anchor } from 'grommet';
import styled from 'styled-components';
import { customBase } from '../../themes/default.theme';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import {
  BillingHistory,
  BillingPayPlans,
  PaymentHistory,
  BillingPayments,
  ChangeDueDate
} from '../../components';
import { CardLayout } from '../../components/cardLayout/CardLayout';
import { TabbedCardLayout } from '../../components/tabbedCardLayout/TabbedCardLayout';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import AgencyBillingCard from '../../components/agencyBilling/AgencyBillingCard';
import FeatureFlag from '../../components/featureFlags';
import OutageModal from '../../components/outageModal';
import { AltInfo } from '../../icons';

export interface BillingPageProps {
  accountNumber: string;
  editPaymentInfoClick: any;
  makePaymentClick: any;
  agencyBilled: boolean;
  billingLoading: boolean;
  agencyOnly?: boolean;
  allCancelled?: boolean;
  billingCenterOutage: boolean;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
  isMigrated: boolean;
}

const gridPadding = '1.25rem';
const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (min-width: ${xlargeBreakpoint}) {
  }
`;

const ContainerFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${gridPadding};
`;

const CenterModal = styled.div`
  align-items: start;
  justify-content: start;
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(CardLayout)`
  width: 100%;
`;

export interface payPlanDetailsFlagProps {
  payPlanDetailsFlag: boolean;
}

export const PaymentsCard = styled(StyledCard)<payPlanDetailsFlagProps>`
  align-self: flex-start;
  height: 100%;
  @media (min-width: ${xlargeBreakpoint}) {
    width: calc(
      ${props => (props.payPlanDetailsFlag ? 45 : 55)}% - ${gridPadding} / 2
    );
  }

  &.PaymentsCardWrap > div:last-child > div {
    position: relative;
    padding: 0;
  }
`;

export const PaymentPlansCard = styled(StyledCard)<payPlanDetailsFlagProps>`
  height: 100%;
  @media (min-width: ${xlargeBreakpoint}) {
    width: calc(
      ${props => (props.payPlanDetailsFlag ? 55 : 45)}% - ${gridPadding} / 2
    );
  }

  & .cardBody {
    padding: 30px;
  }
`;

const CardButtonWrapper = styled.div`
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }
  a {
    color: white;
  }
`;

const AgencyBillingContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 60px;
`;

export const EditPaymentInfoAnchor = styled(Anchor)`
  &:disabled {
    cursor: not-allowed;
  }
`;

export const SafecoModal = styled.div`
  margin-bottom: 20px;
`;

export const LeftSafecoModalContainer = styled.div`
  display: inline;
  flex-direction: row;
  background-color: #ffffff;
`;

export const SafecoModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #b5e6f0;
  border-radius: 5px;
  background: #e2f5f9;
  border-left: 5px solid #189ecc;
  color: #005c86;
  align-items: center;
  padding: 15px 20px;
`;

export const InfoIconSizing = styled.div`
  width: 15px;
  padding-right: 25px;
`;

export default ({
  editPaymentInfoClick,
  makePaymentClick,
  agencyBilled,
  billingLoading,
  agencyOnly,
  allCancelled,
  readOnlyAccess,
  readOnlyFeatureFlag,
  billingCenterOutage,
  isMigrated
}: RouteComponentProps<BillingPageProps>) => {
  const [openInvoices, setOpenInvoices] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { flagDetails: LastDayToPayFlag } = useFeatureFlag('LastDayToPay');
  const { flagDetails: PayPlanDetailsFlag } = useFeatureFlag(
    'PaymentPlansBillingDetails'
  );
  const [displayChangeDueDateOutage, setDisplayChangeDueDateOutage] = useState<
    boolean
  >(false);

  const openInvoice = (invoiceNumber: string) => {
    const tmpOpen: string[] = openInvoices.slice(0);
    tmpOpen.push(invoiceNumber);
    setOpenInvoices(tmpOpen);
  };

  const closeInvoice = (invoiceNumber: any) => {
    setOpenInvoices(
      openInvoices.filter(open => {
        return open !== invoiceNumber;
      })
    );
  };

  const tabOnClicks = [
    () => {
      simpleGTMDataLayer({
        event: 'click-billingBillingHistoryTab'
      });
    },
    () => {
      simpleGTMDataLayer({
        event: 'click-billingPaymentHistoryTab'
      });
    }
  ];

  const renderBillingCard = () => {
    if (agencyOnly) {
      return (
        <TabbedCardLayout
          tabTitle={['Agency Billed']}
          open={0}
          tabOnClicks={tabOnClicks}
        >
          <AgencyBillingContainer>
            <AgencyBillingCard />
          </AgencyBillingContainer>
          <Box />
        </TabbedCardLayout>
      );
    }
    if (agencyBilled && !billingLoading && !agencyOnly) {
      return (
        <TabbedCardLayout
          tabTitle={['Billing History', 'Agency Billed', 'Payment History']}
          open={0}
          tabOnClicks={tabOnClicks}
        >
          <BillingHistory
            openInvoices={openInvoices}
            openInvoice={openInvoice}
            closeInvoice={closeInvoice}
          />
          <AgencyBillingContainer>
            <AgencyBillingCard />
          </AgencyBillingContainer>
          <PaymentHistory />
        </TabbedCardLayout>
      );
    }

    if (!agencyBilled && !billingLoading) {
      return (
        <TabbedCardLayout
          tabTitle={['Invoices', 'Payment History']}
          open={0}
          tabOnClicks={tabOnClicks}
        >
          <BillingHistory
            openInvoices={openInvoices}
            openInvoice={openInvoice}
            closeInvoice={closeInvoice}
          />
          <PaymentHistory />
        </TabbedCardLayout>
      );
    }

    return <></>;
  };

  const safecoClickHandler = () => {
    window.open('https://now.agent.safeco.com/start', '_blank');
  };

  const safecoLink = () => {
    return (
      <Anchor
        color="#005c99"
        onClick={() => safecoClickHandler()}
        label="update the Safeco"
      />
    );
  };

  return (
    <CenterModal>
      {showModal && <ChangeDueDate setShowModal={setShowModal} />}
      <Container>
        {isMigrated && (
          <SafecoModal>
            <LeftSafecoModalContainer>
              <SafecoModalWrapper>
                <InfoIconSizing>
                  <AltInfo />
                </InfoIconSizing>
                <div>
                  <div>Make sure to {safecoLink()} account too!</div>
                  <div>
                    Any changes made to the billing or payment info here won’t
                    appear in The Safeco account.
                  </div>
                </div>
              </SafecoModalWrapper>
            </LeftSafecoModalContainer>
          </SafecoModal>
        )}
        <ContainerFlexRow>
          <PaymentsCard
            payPlanDetailsFlag={PayPlanDetailsFlag?.enabled}
            className={LastDayToPayFlag?.enabled ? 'PaymentsCardWrap' : ''}
            title="Payments"
            buttonComponent={
              <FeatureFlag flagName="ChangeDueDate">
                <CardButtonWrapper>
                  <Anchor
                    test-attr="billing-page-change-payment-anchor"
                    onClick={() => {
                      if (billingCenterOutage) {
                        setDisplayChangeDueDateOutage(true);
                      } else {
                        simpleGTMDataLayer({
                          event: 'click-billingChangeDueDate',
                          event_action: 'Button Click',
                          event_category: window.location.pathname,
                          event_category_snowplow: `${window.location.pathname}/Snowplow`,
                          event_label: 'Change Due Date'
                        });
                        setShowModal(true);
                      }
                    }}
                    label="CHANGE PAYMENT DUE DATE"
                  />
                </CardButtonWrapper>
              </FeatureFlag>
            }
          >
            <BillingPayments
              lastDayToPayFlag={LastDayToPayFlag?.enabled}
              makePaymentClick={makePaymentClick}
              allCancelled={allCancelled}
              readOnlyAccess={readOnlyAccess}
              readOnlyFeatureFlag={readOnlyFeatureFlag}
            />
          </PaymentsCard>
          <PaymentPlansCard
            payPlanDetailsFlag={PayPlanDetailsFlag?.enabled}
            bodyOverflow="auto"
            bodyHeight={PayPlanDetailsFlag?.enabled ? 'auto' : '165px'}
            title="Payment Plans"
            buttonComponent={
              <CardButtonWrapper>
                <EditPaymentInfoAnchor
                  onClick={() => {
                    editPaymentInfoClick();
                  }}
                  label="EDIT PAYMENT INFO"
                  disabled={readOnlyAccess && readOnlyFeatureFlag}
                />
              </CardButtonWrapper>
            }
          >
            <BillingPayPlans payPlanDetailsFlag={PayPlanDetailsFlag?.enabled} />
          </PaymentPlansCard>
        </ContainerFlexRow>
        {renderBillingCard()}
      </Container>
      {displayChangeDueDateOutage && (
        <OutageModal
          title="Change Payment Due Date is Unavailable"
          onClose={() => setDisplayChangeDueDateOutage(false)}
        >
          Unfortunately, due to server maintenance, changing your payment due
          date is currently unavailable. Please check back in 24 to 48 hours.
        </OutageModal>
      )}
    </CenterModal>
  );
};
