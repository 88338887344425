import React from 'react';
import { Box, Text, Button } from 'grommet';
import styled from 'styled-components';
import { Download } from '../../icons';
import { iconType, DocumentsTableProps } from './DocumentsTable';
import { StyledDataTable } from '..';
import { formatDate } from '../../util/formatters';
import usePagination from '../../util/usePagination';

const IconContainer = styled.div`
  width: 22px;
`;

const NavigationWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NextButton = styled.div`
  background-color: #0173c6;
  margin-top: 10px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  width: 10%;
  padding: 10px;
  cursor: pointer;
`;

export default ({
  documents,
  onDocumentClick,
  accountNumber,
  policies,
  nextClickHandler
}: DocumentsTableProps) => {
  const displayableDocs = documents?.map((document, index) => ({
    icon: (
      <IconContainer>
        {document.file_type ? iconType(document.file_type) : ''}
      </IconContainer>
    ),
    file_name: (
      <Box direction="row" gap="20px" align="center">
        <Text wordBreak="break-word">
          {document.file_name?.replace(/^\d{10}\s{1}/g, '')}
        </Text>
      </Box>
    ),
    classification: (
      <Text wordBreak="break-all">
        {document.policy?.policy_type === null
          ? 'Billing'
          : `${
              document.policy?.policy_type
            }-${document.policy_number?.substring(
              document.policy_number.length - 4,
              document.policy_number.length
            )}`}
      </Text>
    ),
    date_uploaded: document.create_time,
    download_link: (
      <Button
        onClick={() => {
          if (onDocumentClick) {
            onDocumentClick({
              document_key: document.documents_key,
              gtm_data: {
                documentId: document.documents_key
              }
            });
          }
        }}
        icon={<Download />}
      />
    )
  }));

  const [paginatedDocs, pageSelector] = usePagination({
    accountNumber,
    items: displayableDocs,
    itemsPerPage: 25
  });

  const table = (
    <StyledDataTable
      sortable
      columns={[
        {
          sortable: false,
          property: 'icon',
          header: '',
          align: 'start'
        },
        {
          sortable: false,
          property: 'file_name',
          header: (
            <Text weight="bold" size="14pt">
              File Name
            </Text>
          ),
          align: 'start'
        },
        {
          sortable: false,
          property: 'classification',
          header: (
            <Text weight="bold" wordBreak="break-all" size="14pt">
              Classification
            </Text>
          ),
          align: 'start'
        },
        {
          sortable: true,
          property: 'date_uploaded',
          header: (
            <Text weight="bold" wordBreak="break-all" size="14pt">
              Date Uploaded
            </Text>
          ),
          align: 'start',
          render: datum => <Text>{formatDate(datum.date_uploaded)}</Text>
        },
        {
          sortable: false,
          property: 'download_link',
          header: (
            <Text weight="bold" wordBreak="break-all" size="14pt">
              Download
            </Text>
          ),
          align: 'end'
        }
      ]}
      data={paginatedDocs}
      primaryKey="id"
    />
  );

  return (
    <Box margin={{ horizontal: '30px', vertical: '20px' }}>
      <Box fill="horizontal">{table}</Box>
      <NavigationWrapper>
        <NextButton onClick={() => nextClickHandler()}>
          Get More Documents
        </NextButton>
        <Box align="end">{pageSelector}</Box>
      </NavigationWrapper>
    </Box>
  );
};
