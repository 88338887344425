export default (phoneNumber: string | undefined | null) => {
  if (!phoneNumber) {
    return null;
  }

  const bareNumber = phoneNumber.replace(/\D/g, '');
  if (bareNumber.length !== 10) {
    return phoneNumber;
  }

  return `(${bareNumber.slice(0, 3)}) ${bareNumber.slice(
    3,
    6
  )}-${bareNumber.slice(6, 10)}`;
};
