import React, { useMemo, useState } from 'react';
import { Box, Nav } from 'grommet';
import { Match, navigate } from '@reach/router';
import styled from 'styled-components';
import { motifs } from '../../themes/default.theme';
import { GET_USERNAV_INFO_account_items_policies_items } from './graphql/__generated__/GET_USERNAV_INFO';
import ClaimsUnavailableModal from '../claimsUnavailableModal';
import FeatureFlag from '../featureFlags';
import GeneralInputErrorBannerHeader from '../generalInputErrorBanner/GeneralInputErrorBannerHeader';

export interface UserNavProps {
  userDisplayName?: string;
  policies?: GET_USERNAV_INFO_account_items_policies_items[];
  accountNumber: string;
  policyNumber?: string | boolean;
  isLoading?: boolean;
  error?: Error;
  onClick: Function;
  featureFlagStatuses: {
    claimsUnavailable: boolean | undefined;
  };
  showError?: boolean;
  isMigrated?: boolean;
}

const StyledNavLink = styled.a`
  color: ${motifs.primaryText};
  font-size: 1.125rem;
  text-decoration: none;
  cursor: pointer;

  &::after {
    content: '';
    border-bottom: 4px solid #65a100;
    display: block;
    width: 0%;
    padding-top: 1px;
    transition: width 0.2s linear;
  }

  &.selected {
    font-weight: bold;
    &::after {
      width: 100%;
    }
  }
  &:not(:last-child) {
    margin-right: 3.125rem;
  }
`;

const StyledUserName = styled.span`
  font-size: 1.5rem;
`;

const StyledNav = styled(Nav)`
  border-bottom: 1px solid ${motifs.lightBorder};
`;

export default ({
  userDisplayName,
  policies,
  accountNumber,
  policyNumber,
  isLoading,
  error,
  onClick,
  featureFlagStatuses,
  showError,
  isMigrated
}: UserNavProps) => {
  const [
    displayClaimsUnavailableModal,
    setDisplayClaimsUnavailableModal
  ] = useState(false);
  if (isLoading) {
    return <h3>Loading...</h3>;
  }
  if (error) {
    return <h3>An Error Occured</h3>;
  }

  const basepath = `/account/${accountNumber}`;

  const navlinks = [
    ['Overview', `${basepath}/overview`],
    ['Billing & Payments', `${basepath}/billing`],
    ['Claims', `${basepath}/claims`],
    ['Documents', `${basepath}/documents`],
    ['Communications', `${basepath}/communications`]
    // ['Alerts', 'alerts']
  ];

  if (policies && policyNumber) {
    navlinks.splice(1, 0, ['Policy', `${basepath}/policy/${policyNumber}`]);
  } else if (policies && policies[0]?.policy_number) {
    navlinks.splice(1, 0, [
      'Policy',
      `${basepath}/policy/${policies[0].policy_number}`
    ]);
  }

  return (
    <>
      <span id="nav-links">
        {showError && isMigrated && (
          <FeatureFlag flagName="GeneralInputErrorBanner">
            <GeneralInputErrorBannerHeader />
          </FeatureFlag>
        )}
        <Box data-test-id="displayName" pad={{ vertical: 'small' }}>
          <StyledUserName>{userDisplayName}</StyledUserName>
        </Box>
        <StyledNav wrap direction="row">
          {navlinks.map(([label, link]) => (
            <Match path={link} key={label}>
              {p => (
                <StyledNavLink
                  onClick={() => {
                    onClick(label);
                    if (
                      label === 'Claims' &&
                      featureFlagStatuses.claimsUnavailable
                    ) {
                      setDisplayClaimsUnavailableModal(true);
                      return null;
                    }
                    navigate(link);
                    return null;
                  }}
                  className={p.match ? 'selected' : ''}
                >
                  {label}
                </StyledNavLink>
              )}
            </Match>
          ))}
        </StyledNav>
      </span>
      {displayClaimsUnavailableModal && (
        <ClaimsUnavailableModal
          closeModal={() => setDisplayClaimsUnavailableModal(false)}
        />
      )}
    </>
  );
};
