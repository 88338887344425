import React from 'react';
import CancelPolicyDisabled from '../assets/images/CancelPolicyDisabled.svg';

export default () => {
  return (
    <div>
      <img src={CancelPolicyDisabled} alt="cancel-policy-icon-disabled" />
    </div>
  );
};
