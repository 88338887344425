import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import AccountListAndQuotesTableUI from '../accountListAndQuotesTableUI';
import {
  GET_POLICY_QUOTE_TABLE_INFO,
  GET_POLICY_QUOTE_TABLE_INFO_account_items_overviewPoliciesAndQuotes
} from './graphql/__generated__/GET_POLICY_QUOTE_TABLE_INFO';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import redirectOnQuotesClick from '../../util/redirectOnQuotesClick';

const get_policy_quote_table_info_query = loader(
  './graphql/Get_Policy_Quote_Table_Info.graphql'
);
const get_quote_search_selection_behavior = loader(
  './graphql/Get_Quote_Search_Selection_Behavior.graphql'
);
export const transformData = (data: GET_POLICY_QUOTE_TABLE_INFO) => {
  const accounts = data?.account?.items;
  const policiesAndQuotesList = accounts?.[0]?.overviewPoliciesAndQuotes;
  const overviewPoliciesAndQuotes: (GET_POLICY_QUOTE_TABLE_INFO_account_items_overviewPoliciesAndQuotes | null)[] = policiesAndQuotesList?.length
    ? [...policiesAndQuotesList]
    : [];

  return {
    overviewPoliciesAndQuotes
  };
};

export default () => {
  const { accountNumber } = useParams();

  const variables = {
    account_number: accountNumber
  };

  const queryResult = useQuery(get_policy_quote_table_info_query, {
    variables
  });
  const { loading, error } = queryResult;

  let { data } = queryResult;
  if (data) {
    data = transformData(data);
  }

  const [redirectOnQuoteClickAction] = useLazyQuery(
    get_quote_search_selection_behavior,
    {
      onCompleted: redirectOnQuotesClick
    }
  );

  const onPolicyNumberClick = (policyNumber: string) => {
    simpleGTMDataLayer({
      event: 'click-policyNumberLinkOverview',
      page: 'Policy',
      policyNumber
    });
  };

  return (
    <AccountListAndQuotesTableUI
      accountNumber={accountNumber}
      onPolicyNumberClick={onPolicyNumberClick}
      isLoading={loading}
      error={error}
      redirectOnQuoteClickAction={redirectOnQuoteClickAction}
      policiesAndQuotes={data?.overviewPoliciesAndQuotes}
    />
  );
};
