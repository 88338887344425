import React, { useState } from 'react';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

import {
  SidebarMenuContainer,
  SidebarHeader,
  BackButton,
  BackButtonChevron,
  CloseIcon,
  PrimaryOptionsWrap,
  SecondaryOptionsWrap
} from './SidebarMenuStyles';

import { MenuOption, MenuAccordionGroup } from '../util';

import CloseSrc from '../../../assets/images/CloseSmallIcon.svg';
import ChevronLeftSrc from '../../../assets/images/pagination-previous.svg';

import { baseURL, menu, MenuObj } from '../menuURL';

interface Props {
  active: boolean;
  closeMenu: Function;
}

const isProdEnv = process.env.REACT_APP_ENV === 'PROD';

const SidebarMenu = ({ active, closeMenu }: Props) => {
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [subMenuObj, setSubMenuObj] = useState<MenuObj>({} as MenuObj);

  const handleBack = () => {
    setShowSubMenu(false);
    setSubMenuObj({} as MenuObj);
  };

  const handleClose = () => {
    handleBack();
    closeMenu();
  };

  const handleClick = (menuObj: MenuObj) => {
    if ('subMenu' in menuObj) {
      setSubMenuObj(menuObj);
      setShowSubMenu(true);
    } else {
      let url = '';

      if (menuObj?.baseURL) {
        url += baseURL[menuObj.baseURL][isProdEnv ? 'prod' : 'nonprod'];
      }

      url += menuObj.link;

      const gtmTagCat = menuObj.gtmTag?.replace('-', ' ');

      simpleGTMDataLayer({
        event: menuObj.gtmTag,
        event_category: gtmTagCat,
        event_action: `${gtmTagCat} click`,
        event_label: `${gtmTagCat} click`,
        event_category_snowplow: `Snowplow ${gtmTagCat}`,
        event_action_snowplow: `Snowplow ${gtmTagCat} click`,
        event_label_snowplow: `Snowplow ${gtmTagCat} click`
      });

      handleClose();
      window.open(url, '_self');
    }
  };

  const filterSubMenuObj = (data: MenuObj[] | undefined) =>
    data?.filter((menuObj: MenuObj) => (menuObj.prodOnly ? isProdEnv : true)) ||
    [];

  return (
    <SidebarMenuContainer className={active ? 'active' : ''}>
      <SidebarHeader>
        {showSubMenu && (
          <BackButton onClick={() => handleBack()}>
            <BackButtonChevron alt="" src={ChevronLeftSrc} />
            BACK
          </BackButton>
        )}
        <CloseIcon onClick={() => handleClose()} src={CloseSrc} />
      </SidebarHeader>
      <PrimaryOptionsWrap className={showSubMenu ? '' : 'active'}>
        {filterSubMenuObj(menu).map((menuObj: MenuObj) => (
          <MenuOption
            key={menuObj.label}
            label={menuObj.label}
            indent
            light={false}
            chevron={'subMenu' in menuObj}
            chevronRight
            active={false}
            onClick={() => handleClick(menuObj)}
          />
        ))}
      </PrimaryOptionsWrap>
      <SecondaryOptionsWrap className={showSubMenu ? 'active' : ''}>
        {filterSubMenuObj(subMenuObj?.subMenu).map((menuObj: MenuObj) =>
          'subMenu' in menuObj ? (
            <MenuAccordionGroup key={menuObj.label} label={menuObj.label}>
              {filterSubMenuObj(menuObj.subMenu).map(
                (subSubMenuObj: MenuObj) => (
                  <MenuOption
                    key={subSubMenuObj.label}
                    label={subSubMenuObj.label}
                    indent
                    light
                    chevron={false}
                    chevronRight={false}
                    active={false}
                    onClick={() => handleClick(subSubMenuObj)}
                  />
                )
              )}
            </MenuAccordionGroup>
          ) : (
            <MenuOption
              key={menuObj.label}
              label={menuObj.label}
              indent={false}
              light={false}
              chevron={false}
              chevronRight={false}
              active={false}
              onClick={() => handleClick(menuObj)}
            />
          )
        )}
      </SecondaryOptionsWrap>
    </SidebarMenuContainer>
  );
};

export default SidebarMenu;
