import React, { ChangeEvent } from 'react';
import {
  InputGroup,
  Label,
  ElementWrap,
  ReasonSelect,
  SelectChevron,
  FieldSubText
} from '../cancelPolicyFormStyles';
import ChevronIconSrc from '../../../../assets/images/chevron-down.svg';

interface carrierMovedProps {
  product: string;
  carrierMovedTo: string;
  setCarrierMovedTo: Function;
  disabled: boolean;
  carrierError: {
    carrierError: boolean;
    carrierMsg: string;
  };
  setCarrierError: Function;
}

const wccCarriers = [
  ['Choose a Carrier', ''],
  ['No Prior Insurance', 'NoPriorInsurance'],
  ['Other', 'Other'],
  ['Assigned Risk Plan', 'AssignedRiskPlan'],
  ['A.I.M. Mutual Insurance Companies', 'AIMMutualInsuranceCompanies'],
  ['ACURITY A Mutual Insurance Co.', 'ACUITYAMutualInsuranceCo'],
  ['AF Group', 'AFGroup'],
  ['AIG', 'AIG'],
  ['Alaska National Insurance Co.', 'AlaskaNationalInsuranceCo.'],
  ['Allianz', 'Allianz'],
  ['American Family Insurance Group', 'AmericanFamilyInsuranceGroup'],
  ['American National', 'AmericanNational'],
  ['AMERISAFE', 'AMERISAFE'],
  ['Amerisure', 'Amerisure'],
  ['AmeriTrust', 'AmeriTrust'],
  ['AmTrust', 'AmTrust'],
  ['Arch Capital', 'ArchCapital'],
  ['Argo', 'Argo'],
  ['Auto-Owners Insurance Company', 'AutoOwnersInsuranceCompany'],
  ['AXA', 'AXA'],
  ['Beacon Mutual Insurance Co.', 'BeaconMutualInsuranceCo.'],
  ['Berkshire Hathaway Inc.', 'BerkshireHathawayInc.'],
  ['BIC Holdings LLC', 'BICHoldingsLLC'],
  ['Brickstreet Insurance', 'BrickstreetInsurance'],
  ['Builders Insurance Group', 'BuildersInsuranceGroup'],
  ["Chesapeake Employers' Ins Co.", 'ChesapeakeEmployersInsCo.'],
  ['Chubb', 'Chubb'],
  ['Church Mutual', 'ChurchMutual'],
  ['Cincinnati Insurance Companies', 'CincinnatiInsuranceCompanies'],
  ['CNA', 'CNA'],
  ['CompSource Mutual Ins Co.', 'CompSourceMutualInsCo.'],
  ['CopperPoint Insurance Companies', 'CopperPointInsuranceCompanies'],
  ['Dakota', 'Dakota'],
  ['Diamond Insurance Co.', 'DiamondInsuranceCo.'],
  ['Donegal Insurance Group', 'DonegalInsuranceGroup'],
  ['Employers Mutual Casualty Company (EMC)', 'EmployersMutualCasualtyCompany'],
  ['Employers', 'Employers'],
  ['Enstar', 'Enstar'],
  ['Erie Insurance', 'ErieInsurance'],
  ['Everest Re', 'EverestRe'],
  ['Fairfax Financial', 'FairfaxFinancial'],
  ['Farmers Insurance Group of Companies', 'FarmersInsuranceGroupofCompanies'],
  ['FCCI', 'FCCI'],
  ['Federated Insurance', 'FederatedInsurance'],
  ['FFVA Mutual', 'FFVAMutual'],
  ['Frankenmuth Insurance', 'FrankenmuthInsurance'],
  ['General Electric Co.', 'GeneralElectricCo.'],
  ['Great American Insurance', 'GreatAmericanInsurance'],
  ['GuideOne Insurance', 'GuideOneInsurance'],
  ['The Hanover Insurance Group', 'TheHanoverInsuranceGroup'],
  ['Hartford', 'Hartford'],
  ['Hastings Mutual Insurance Co.', 'HastingsMutualInsuranceCo.'],
  ["Hawaii Employers' Mutl Ins Co.", 'HawaiiEmployersMutlInsCo.'],
  ['ICW', 'ICW'],
  ['Idaho State Insurance Fund', 'IdahoStateInsuranceFund'],
  ['James River Group Hldgs Ltd.', 'JamesRiverGroupHldgsLtd.'],
  ["Kentucky Employers' Mutual Ins", 'KentuckyEmployersMutualIns'],
  ['Key Risk', 'KeyRisk'],
  ['Lackawanna Insurance', 'LackawannaInsurance'],
  ['Liberty Mutual', 'LibertyMutual'],
  ['Louisiana Workers Comp Corp.', 'LouisianaWorkersCompCorp.'],
  ["LUBA Workers' Comp", 'LUBAWorkersComp'],
  ['NHRMA Mutual Insurance Co.', 'NHRMAMutualInsuranceCo.'],
  ['Markel', 'Markel'],
  ['MEMIC', 'MEMIC'],
  ['MO Employers Mutual Ins Co.', 'MOEmployersMutualInsCo.'],
  ['Montana State Fund', 'MontanaStateFund'],
  ['MS and AD Insurance', 'MSADInsurance'],
  ['Nationwide Mutual Insurance Company', 'NationwideMutualInsuranceCompany'],
  ['New Mexico Mutual', 'NewMexicoMutual'],
  ['NJM Insurance', 'NJMInsurance'],
  ['Old Republic Insurance', 'OldRepublicInsurance'],
  ['Pekin Insurance', 'PekinInsurance'],
  ['Penn National Insurance', 'PennNationalInsurance'],
  ['Pharmacists Mutual Insurance Comapny', 'PharmacistsMutualInsuranceCompany'],
  ['Pinnocol Assurance', 'PinnocolAssurance'],
  ['PMA', 'PMA'],
  ['ProAssuance Corp.', 'ProAssuranceCorp.'],
  ['QBE Insurance Group Insurance', 'QBEInsuranceGroupLimited'],
  ['RetailFirst Insurance', 'RetailFirstInsurance'],
  ['SECURA Insurance Companies', 'SECURAInsuranceCompanies'],
  ['Selective', 'Selective'],
  ['Sentry Insurance A Mutual Company', 'SentryInsuranceAMutualCompany'],
  ['SFM Mutual Insurance Co.', 'SFMMutualInsuranceCo.'],
  ['Society Insurance a Mutual Co.', 'SocietInsuranceAMutualCo.'],
  ['Sompo', 'Sompo'],
  ['State Compensation Ins Fund', 'StateCompensationInsFund'],
  ['State Farm', 'StateFarm'],
  ["State Workers' Insurance Fund", 'StatWorkersInruanceFund'],
  ['Synergy Comp Insurance Co.', 'SynergyCompInsuranceCo.'],
  ['Texas Mutual Insurance Co', 'TexasMutualInsuranceCo'],
  ['Tokio Marine', 'TokioMarine'],
  ['Travelers', 'Travelers'],
  ['United Fire Group Insurance (UFC)', 'UnitedFireGroupInsurance'],
  ['Utica National Insurance Group', 'UticaNationalInsuranceGroup'],
  ['W.R. Berkley Corp.', 'WRBerkleyCorp'],
  ['WCF Insurance', 'WCFInsurance'],
  ['West Bend Mutual Insurance Company', 'WestBendMutualInsuranceCompany'],
  ['Western National Insurance', 'WesternNationalInsuance'],
  ['Westfield Group', 'WestfieldGroup'],
  ['Zenith', 'Zenith'],
  ['Zurich', 'Zurich']
];

const cppCarriers = [
  ['Choose a Carrier', ''],
  ['Auto-Owners', 'AutoOwners'],
  ['Chubb', 'Chubb'],
  ['CNA', 'CNA'],
  ['Erie', 'Erie'],
  ['Farmers', 'Farmers'],
  ['Hanover', 'Hanover'],
  ['Hartford', 'Hartford'],
  ['Liberty Mutual', 'LibertyMutual'],
  ['Nationwide', 'Nationwide'],
  ['Cincinnati', 'Cincinnati'],
  ['Travelers', 'Travelers'],
  ['W. R. Berkley', 'WRBerkley'],
  ['Other', 'Other']
];

export default ({
  product,
  carrierMovedTo,
  setCarrierMovedTo,
  disabled,
  carrierError: { carrierError, carrierMsg },
  setCarrierError
}: carrierMovedProps) => {
  if (product === 'WORKERS COMP') {
    return (
      <InputGroup test-attr="cancel-policy-carrier-moved-to">
        <Label error={carrierError}>Carrier Moved To</Label>
        <ElementWrap>
          <ReasonSelect
            value={carrierMovedTo}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setCarrierMovedTo(e.target.value);
              setCarrierError({ carrierError: false, carrierMsg: '' });
            }}
            disabled={disabled}
          >
            {wccCarriers.map(carrier => (
              <option value={carrier[1]}>{carrier[0]}</option>
            ))}
          </ReasonSelect>
          <SelectChevron src={ChevronIconSrc} />
        </ElementWrap>

        <FieldSubText error={carrierError}>{carrierMsg}</FieldSubText>
      </InputGroup>
    );
  }

  return (
    <InputGroup test-attr="cancel-policy-carrier-moved-to">
      <Label error={carrierError}>Carrier Moved To</Label>
      <ElementWrap>
        <ReasonSelect
          value={carrierMovedTo}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setCarrierMovedTo(e.target.value);
            setCarrierError({ carrierError: false, carrierMsg: '' });
          }}
          disabled={disabled}
        >
          {cppCarriers.map(carrier => (
            <option value={carrier[1]}>{carrier[0]}</option>
          ))}
        </ReasonSelect>
        <SelectChevron src={ChevronIconSrc} />
      </ElementWrap>
      <FieldSubText error={carrierError}>{carrierMsg}</FieldSubText>
    </InputGroup>
  );
};
