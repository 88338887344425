import React, { useState } from 'react';
import getConfig, { Env } from '../../config';
import { RadioSelect, Radio } from '../../icons';
import {
  ModalContainer,
  SelectPolicyModal,
  ModalHeader,
  StyledText,
  StyledDivHoldingPolicy,
  StyledDropdownText,
  Content,
  StyledButtonHolder,
  StyledCancelButton,
  StyledContinueButton,
  Backdrop,
  Icon,
  PolicyListContainer
} from './PolicyDropdownStyles';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const config = getConfig(process.env.REACT_APP_ENV as Env);

export interface ClaimsSelectAPolicyDropdownProps {
  modalInformation: any;
  code: any;
  closeModal: Function;
}

export default ({
  modalInformation,
  code,
  closeModal
}: ClaimsSelectAPolicyDropdownProps) => {
  const [selectedPolicyIndex, setSelectedPolicyIndex] = useState(-1);

  const CloseDropdownModal = () => {
    closeModal();
  };

  const StartNewClaim = () => {
    if (selectedPolicyIndex !== -1) {
      const selectedInfo = modalInformation[selectedPolicyIndex];
      simpleGTMDataLayer({
        event: 'claimsSelectPolicyDropdown',
        event_action: 'Button Click',
        event_category: window.location.pathname,
        event_label: 'Redirect to Claims'
      });
      closeModal();
      if (selectedInfo.policy_type === 'Workers Comp') {
        window.open('https://www.interactclaims.com/claim/RTWINCLogin3.jsp');
      } else {
        window.open(
          config.start_a_new_claim +
            selectedInfo.policy_number +
            code[selectedPolicyIndex] +
            '&utm_source=SA360&utm_medium=internal&utm_campaign=SA360'
        );
      }
    }
  };

  return (
    <Backdrop>
      <ModalContainer>
        <SelectPolicyModal>
          <ModalHeader>File a New Claim</ModalHeader>
          <StyledText>
            Choose the policy you would like to file a new claim for:
          </StyledText>
          <StyledDivHoldingPolicy></StyledDivHoldingPolicy>
          <PolicyListContainer>
            <PolicyListContainer>
              {modalInformation.length > 0 ? (
                modalInformation.map((info: any, index: number) => (
                  <Content
                    key={index}
                    onClick={() => {
                      setSelectedPolicyIndex(index);
                    }}
                  >
                    <Icon>
                      {selectedPolicyIndex === index ? (
                        <RadioSelect />
                      ) : (
                        <Radio />
                      )}
                    </Icon>
                    <StyledDropdownText>
                      {info.policy_type + ' - ' + info.policy_number}
                    </StyledDropdownText>
                  </Content>
                ))
              ) : (
                <p>No policy information available</p>
              )}
            </PolicyListContainer>
          </PolicyListContainer>
          <StyledButtonHolder>
            <StyledCancelButton onClick={CloseDropdownModal}>
              CANCEL
            </StyledCancelButton>
            <StyledContinueButton onClick={StartNewClaim}>
              CONTINUE
            </StyledContinueButton>
          </StyledButtonHolder>
        </SelectPolicyModal>
      </ModalContainer>
    </Backdrop>
  );
};
