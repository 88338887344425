import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, useParams } from '@reach/router';
import { Box } from 'grommet';
import BillingHistory from './BillingHistory';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useDocumentDownload from '../../util/useDocumentDownload';

const get_billing_history_query = loader(
  './graphql/Get_Billing_History.graphql'
);

interface dataContainerProps extends RouteComponentProps {
  openInvoices: string[];
  openInvoice: Function;
  closeInvoice: Function;
}

export default ({
  openInvoices,
  openInvoice,
  closeInvoice
}: dataContainerProps) => {
  const { accountNumber } = useParams();
  const [items, setItems] = useState([]);
  const variables = {
    account_number: accountNumber
  };

  const { data, loading, error } = useQuery(get_billing_history_query, {
    variables,
    onCompleted: () => {
      if (data?.account?.items[0]?.invoices?.items) {
        setItems(data?.account?.items[0]?.invoices?.items);
      }
    }
  });

  const { onDocumentClick, documentModal } = useDocumentDownload(
    accountNumber,
    'invoice',
    'click-billingHistoryDocument'
  );

  const openInvoiceDecorator = (invoice_number: string) => {
    simpleGTMDataLayer({
      event: 'click-openBillingHistoryBreakdown',
      invoiceNumber: invoice_number
    });
    openInvoice(invoice_number);
  };

  return (
    <Box>
      {documentModal}
      <BillingHistory
        onInvoiceClick={onDocumentClick}
        loading={loading}
        error={error}
        invoices={items}
        openInvoices={openInvoices}
        openInvoice={openInvoiceDecorator}
        closeInvoice={closeInvoice}
        accountNumber={accountNumber}
      />
    </Box>
  );
};
