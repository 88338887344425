import React from 'react';
import { Box } from 'grommet';
import vehicleIcon from '../../assets/images/VehicleIcon.svg';
import { formatTitleCase } from '../../util/formatters';
import { PolicyInfoProps } from './PolicyInfo';
import {
  VehicleItem,
  VehicleDescription,
  VehicleInfo,
  VehicleInfoLabel,
  VIN,
  StyledHR
} from './PolicyInfoStyles';
import {
  Column,
  CenteredHorizontally,
  DisplayableAssetsGrid,
  GridCell
} from './PolicyInfoUmbrellaStyles';
import usePagination from '../../util/usePagination/usePagination';
import { GET_POLICY_INFO_account_items_policy_insuredassets_items } from './graphql/__generated__/GET_POLICY_INFO';

export default ({
  information,
  accountNumber,
  policyNumber
}: PolicyInfoProps) => {
  const insuredAssets = information?.insuredassets?.items ?? [];
  const vehicleAssets: GET_POLICY_INFO_account_items_policy_insuredassets_items[] = insuredAssets
    .map(current => {
      if (
        current?.asset &&
        current?.asset_name &&
        !current?.asset_name?.toUpperCase().includes('LOCATION')
      ) {
        return current;
      }
      return null;
    })
    .filter(
      (
        current: GET_POLICY_INFO_account_items_policy_insuredassets_items | null
      ): current is GET_POLICY_INFO_account_items_policy_insuredassets_items => {
        return !!current;
      }
    );
  const [displayableAssets, pageSelector] = usePagination({
    accountNumber,
    policyNumber,
    items: vehicleAssets,
    itemsPerPage: 8
  });
  return (
    <Column>
      <DisplayableAssetsGrid>
        {displayableAssets.map((item, index) => (
          <GridCell
            gridRow={Math.floor(index / 2) + 1}
            gridColumn={(index % 2) + 1}
          >
            <StyledHR />
            <VehicleItem>
              <Box pad="medium">
                <img src={vehicleIcon} alt="Vehicle Icon" />
              </Box>
              <div>
                <VehicleDescription>
                  {formatTitleCase(item.asset_name)}
                </VehicleDescription>
                <VehicleInfo>
                  <VehicleInfoLabel>VIN</VehicleInfoLabel>
                  <VIN>{item.asset}</VIN>
                </VehicleInfo>
              </div>
            </VehicleItem>
          </GridCell>
        ))}
      </DisplayableAssetsGrid>
      {vehicleAssets.length > 8 && (
        <CenteredHorizontally test-attr="policy-info-umbrella-assets-page-selector">
          {pageSelector}
        </CenteredHorizontally>
      )}
    </Column>
  );
};
