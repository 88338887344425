import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useParams, RouteComponentProps } from '@reach/router';
import OctoBanner from './OctoBanner';
import {
  GET_TELEMATICS_VENDOR_account_items_policies_items_insuredassets_items,
  GET_TELEMATICS_VENDOR_account_items_policies_items
} from './graphql/__generated__/GET_TELEMATICS_VENDOR';

const get_telematics_assets = loader(
  './graphql/Get_Telematics_Vendor.graphql'
);
const update_octo_banner = loader(
  './graphql/Update_Octo_Banner.graphql'
);
const get_banner_status = loader(
  './graphql/Get_Octo_Banner_Status.graphql'
);

const telematicsAssets = (
  assets: (GET_TELEMATICS_VENDOR_account_items_policies_items_insuredassets_items | null)[] | null
) => {
  let i = 0;
    for (i = 0; (assets && i < assets?.length); i += 1) {
      if (assets[i]?.telematics_vendor === 'OCTO') {
        return true;
      }
    }
  return false;
};

export default (_: RouteComponentProps) => {
  const { accountNumber } = useParams();
  const variables = {
    account_number: accountNumber
  };

  const [updateOctoBanner, octoBannerResults] = useMutation(update_octo_banner);
  const { data: updateOctoResult } = octoBannerResults;

  const bannerResult = useQuery(get_banner_status, { variables });
  const { data: bannerStatus } = bannerResult;

  let bannerAvailable = false;
  if (bannerStatus) {
    bannerAvailable = bannerStatus.specialEventTracking?.agency360_octo_modal;
  }

  const assetResults = useQuery(get_telematics_assets, { variables });
  const { data: assets } = assetResults;
  let octoPresent = false;
  if (assets) {
    const { items } = assets.account.items[0].policies;
    let autoPolicy: GET_TELEMATICS_VENDOR_account_items_policies_items | null = null;
    if (items.length === 1) {
      if (items[0].policy_type.toLowerCase().includes('auto')) {
        octoPresent = telematicsAssets(items[0].insuredassets.items);
      }
    } else {
      let i = 0;
      for (i = 0; i < items.length; i += 1) {
        if (items[i].policy_type.toLowerCase().includes('auto')) {
          autoPolicy = items[i];
        }
      }
    }
    if (!octoPresent && autoPolicy && autoPolicy.insuredassets) {
      octoPresent = telematicsAssets(autoPolicy.insuredassets.items);
    }
  }

  return (
    <OctoBanner
      octoPresent={octoPresent}
      hasBeenSeen={bannerAvailable}
      updateBannerStatus={updateOctoBanner}
      updateStatusResult={updateOctoResult}
      accountNumber={accountNumber}
    />
  );
};
