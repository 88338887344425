import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import ResponsiveGrid from '../responsiveGrid/ResponsiveGrid';
import { customBase } from '../../themes/default.theme';

interface cardBodyProps {
  bodyOverflow?:
    | 'visible'
    | 'hidden'
    | 'scroll'
    | 'auto'
    | 'initial'
    | 'inherit';
  bodyHeight?: string;
}

export interface cardContent extends cardBodyProps {
  title: string;
  buttonComponent?: JSX.Element;
  children: JSX.Element | undefined;
  className?: string;
  edit?: boolean;
  additionalBottomGap?: string;
}

interface bottomGapProps {
  gapHeight?: string;
}

const gridPadding = '1.25rem';
const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;

const StyledCard = styled(Box)`
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
`;

const StyledTitleBox = styled(Box)`
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #cccccc;
`;

const TitleOuterBox = styled(Box)`
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
`;

const StyledCardLayoutBody = styled.div<cardBodyProps>`
  padding: 1.25rem 1.875rem;
  overflow: ${props => (props.bodyOverflow ? props.bodyOverflow : 'visible')};
  height: ${props => (props.bodyHeight ? props.bodyHeight : 'auto')};
`;

const StyledCardEditBody = styled.div`
  width: 100%;
  padding: 0;
`;

const AdditionalBottomGap = styled.div<bottomGapProps>`
  height: ${props => (props.gapHeight ? props.gapHeight : '0px')};
`;

const doubleColumnGrid = {
  xsmall: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  small: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  medium: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  large: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  xlarge: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ]
};

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${xlargeBreakpoint}) {
    & > div:last-child {
      margin-bottom: 0;
    }
  }
`;

const Column1 = styled(Column)`
  justify-content: space-between;
  flex-direction: row;
  @media (min-width: ${xlargeBreakpoint}) {
    width: 100%;
  }
`;

const BoxContainer = styled(Box)`
  height: 100%;
  background-color: #ffffff;
`;

export const CardLayoutShell = ({
  title,
  buttonComponent,
  children,
  className,
  edit,
  bodyOverflow,
  bodyHeight,
  additionalBottomGap
}: cardContent) => {
  return (
    <BoxContainer className={className}>
      <Column1>
        <TitleOuterBox>
          <StyledTitleBox
            pad="small"
            round={{ size: 'xxsmall', corner: 'top' }}
            direction="row"
          >
            {buttonComponent ? (
              <ResponsiveGrid
                rows={['auto']}
                columns={['2/3', '1/3', '2/3']}
                areas={doubleColumnGrid}
              >
                <Box gridArea="title">
                  <Text color="#041E41" weight="bold">
                    {title}
                  </Text>
                </Box>
                <Box gridArea="action" align="end">
                  {buttonComponent}
                </Box>
              </ResponsiveGrid>
            ) : (
              <Box gridArea="title">
                <Text color="#041E41" weight="bold">
                  {title}
                </Text>
              </Box>
            )}
          </StyledTitleBox>
        </TitleOuterBox>
        {edit ? (
          <StyledCardEditBody className="cardShellBody">
            {children}
          </StyledCardEditBody>
        ) : (
          <div>
            <StyledCardLayoutBody
              bodyOverflow={bodyOverflow}
              bodyHeight={bodyHeight}
              className="cardShellBody"
            >
              {children}
            </StyledCardLayoutBody>
            <AdditionalBottomGap gapHeight={additionalBottomGap} />
          </div>
        )}
      </Column1>
    </BoxContainer>
  );
};
