import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import LossRunModal, { PolicyObj } from '../lossRunModal';
import { RequestBtnForClaims } from '../lossRunRequestButton';

const get_claims_policies_by_account = loader(
  '../graphql/Get_Claims_Policies_by_Account.graphql'
);

interface Props {
  accountNumber: string;
  disabled: boolean;
  submit: Function;
  submitError: boolean;
  submitSuccess: boolean;
  setSubmitError: Function;
  setSubmitSuccess: Function;
}

export default ({
  accountNumber,
  disabled,
  submit,
  submitError,
  submitSuccess,
  setSubmitError,
  setSubmitSuccess
}: Props) => {
  const [showLossRunModal, setShowLossRunModal] = useState<boolean>(false);
  const [policyOptions, setPolicyOptions] = useState<PolicyObj[]>([]);

  const { data: policiesByAccount } = useQuery(get_claims_policies_by_account, {
    variables: {
      account_number: accountNumber
    }
  });

  useEffect(() => {
    if (policiesByAccount?.account?.items[0]) {
      const { policies } = policiesByAccount.account.items[0];
      if (policies?.items) {
        setPolicyOptions(
          policiesByAccount.account.items[0].policies.items.map(
            ({ policy_number, policy_type }: PolicyObj) => ({
              policy_number,
              policy_type
            })
          )
        );
      }
    }
  }, [policiesByAccount]);

  return (
    <>
      <RequestBtnForClaims onClick={() => setShowLossRunModal(true)} />
      {showLossRunModal && (
        <LossRunModal
          disabled={disabled}
          options={[
            {
              label: 'Select a Policy',
              value: { policy_number: '', policy_type: '' }
            },
            ...policyOptions.map(
              ({ policy_number, policy_type }: PolicyObj) => ({
                label: `${policy_type}...${policy_number.slice(-4)}`,
                value: { policy_number, policy_type }
              })
            )
          ]}
          close={() => {
            setShowLossRunModal(false);
            if (submitError) setSubmitError(false);
            if (submitSuccess) setSubmitSuccess(false);
          }}
          submit={submit}
          submitError={submitError}
          submitSuccess={submitSuccess}
        />
      )}
    </>
  );
};
