import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import BillingInfo, { displayData } from './BillingInfo';
import { GET_LAST_PAYMENT_INFO } from './graphql/__generated__/GET_LAST_PAYMENT_INFO';

const get_last_payment_info = loader('./graphql/Get_Last_Payment_Info.graphql');

export const transformData = (data: GET_LAST_PAYMENT_INFO) => {
  const billingInfoData: displayData = {
    lastAmountPaid:
      data?.account?.items?.[0]?.next_payment_no_mortgagee_billed
        ?.lastPaymentAmount ?? null,
    lastPaymentDate:
      data?.account?.items?.[0]?.next_payment_no_mortgagee_billed
        ?.lastPaymentDate ?? null
  };
  return billingInfoData;
};

export default ({ lastDayToPayFlag }: { lastDayToPayFlag: boolean }) => {
  const { accountNumber } = useParams();

  const variables = {
    account_number: accountNumber
  };

  const lastPaymentQueryResult = useQuery(get_last_payment_info, {
    variables
  });
  const { loading, error } = lastPaymentQueryResult;
  let { data: lastPaymentDetails } = lastPaymentQueryResult;
  if (lastPaymentDetails) {
    lastPaymentDetails = transformData(lastPaymentDetails);
  }

  return (
    <BillingInfo
      lastDayToPayFlag={lastDayToPayFlag}
      loading={loading}
      error={error}
      infoData={lastPaymentDetails}
    />
  );
};
