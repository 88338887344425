import React from 'react';
import { Box, Text, Button } from 'grommet';
import styled from 'styled-components';
import ResponsiveGrid from '../responsiveGrid';
import { PercentRing } from '../../icons';
import MakePaymentOptionsWrapper from '../makePaymentOptionsWrapper';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

export interface reason {
  display: string;
  score: number;
  onClick: any;
}

interface customerIntentProps {
  loading: boolean;
  error: any;
  call_reasons: reason[];
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
}

const HoverableButton = styled(Button).attrs(
  (props: { changeToDisabledCursor: boolean }) => props
)`
  & {
    color: #000000;
    background-color: #f5f7fa;
    text-decoration: none;
    border: none;
  }

  &:hover {
    color: #ffffff;
    background-color: #0173c6;
  }

  &:hover svg > g > g {
    fill: #ffffff;
    opacity: 0.36;
  }

  &:hover svg > g > path {
    stroke: #ffffff;
  }

  &:disabled,
  &:hover:disabled {
    cursor: ${props => props?.changeToDisabledCursor && 'not-allowed'};
    background-color: #e0e0e0;
    color: #000000;
    svg > g {
      g {
        fill: #ffffff;
        opacity: 0.36;
      }
      path {
        stroke: #ffffff;
      }
    }
  }
`;

const StyledOtherReasonText = styled(Text)`
  font-size: 0.75rem;
`;

const StyledReasonText = styled(Text)`
  font-size: 0.875rem;
`;

const StyledBox = styled(Box)`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    height: 100%;
  }
`;

const onClickDecorator = (reason: reason) => {
  const data = {
    event: 'click-customerintent',
    intent: reason.display,
    score: reason.score
  };

  simpleGTMDataLayer(data);
  if (reason.display !== 'Make a One Time Payment') {
    return reason.onClick();
  }
  return null;
};

// I swtiched to generating every button beforehand because having the map within the return as well as an extra button
// Was causing everything to get underlined
const generateButton = (
  index: number,
  possible_reason: reason,
  readOnlyAccess: boolean | undefined,
  readOnlyFeatureFlag: boolean | undefined
) => {
  return (
    <Box gridArea={`region-${index}`} pad="4px">
      <HoverableButton
        fill
        onClick={() => onClickDecorator(possible_reason)}
        disabled={readOnlyAccess && readOnlyFeatureFlag}
        changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
      >
        <Box pad="xsmall" align="center" direction="row" gap="small">
          <Box height="27px">
            <PercentRing percentage={possible_reason.score} />
          </Box>
          {possible_reason.display === 'Make a One Time Payment' ? (
            <MakePaymentOptionsWrapper
              page="overview"
              noModalClickHandler={possible_reason.onClick}
              readOnlyAccess={readOnlyAccess}
              readOnlyFeatureFlag={readOnlyFeatureFlag}
            >
              <StyledReasonText>{possible_reason.display}</StyledReasonText>
            </MakePaymentOptionsWrapper>
          ) : (
            <StyledReasonText>{possible_reason.display}</StyledReasonText>
          )}
        </Box>
      </HoverableButton>
    </Box>
  );
};

export default ({
  loading,
  error,
  call_reasons,
  readOnlyAccess,
  readOnlyFeatureFlag
}: customerIntentProps) => {
  if (loading) {
    return (
      <Box pad="small">
        <Text>Loading...</Text>
      </Box>
    );
  }

  if (error || call_reasons.length < 1) {
    return (
      <Box pad="small">
        <Text>Error</Text>
      </Box>
    );
  }

  const buttons = [];
  const rows = [];
  const small_areas = [];
  const normal_areas = [];
  for (let index = 0; index < call_reasons.length; index += 1) {
    rows.push('flex');
    small_areas.push({
      name: `region-${index}`,
      start: [0, index],
      end: [1, index]
    });
    const normal_col = index % 2;
    const normal_row = Math.floor(index / 2);
    if (index === call_reasons.length - 1 && normal_col === 0) {
      normal_areas.push({
        name: `region-${index}`,
        start: [0, normal_row],
        end: [1, normal_row]
      });
    } else {
      normal_areas.push({
        name: `region-${index}`,
        start: [normal_col, normal_row],
        end: [normal_col, normal_row]
      });
    }
    buttons.push(
      generateButton(
        index,
        call_reasons[index],
        readOnlyAccess,
        readOnlyFeatureFlag
      )
    );
  }

  rows.push('fill');
  small_areas.push({
    name: 'other',
    start: [0, small_areas.length],
    end: [1, small_areas.length]
  });
  normal_areas.push({
    name: 'other',
    start: [0, rows.length - 1],
    end: [1, rows.length - 1]
  });

  buttons.push(
    <Box gridArea="other" pad="5px">
      <HoverableButton
        disabled={readOnlyAccess && readOnlyFeatureFlag}
        changeToDisabledCursor={readOnlyAccess && readOnlyFeatureFlag}
      >
        <Box align="center" pad="small">
          <StyledOtherReasonText size="small">
            Customer Called For A Different Reason
          </StyledOtherReasonText>
        </Box>
      </HoverableButton>
    </Box>
  );

  return (
    <StyledBox background="white" height="177px">
      <ResponsiveGrid
        rows={rows}
        columns={['flex', 'flex']}
        areas={{
          xsmall: small_areas,
          small: small_areas,
          medium: normal_areas,
          large: normal_areas,
          xlarge: normal_areas
        }}
      >
        {buttons.map((button, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <React.Fragment key={index}>{button}</React.Fragment>;
        })}
      </ResponsiveGrid>
    </StyledBox>
  );
};
