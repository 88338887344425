import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { GET_PAYMENT_HISTORY_account_items_payment_history_items } from './graphql/__generated__/GET_PAYMENT_HISTORY';
import PaymentHistory from './PaymentHistory';

const get_payment_history_query = loader(
  './graphql/Get_Payment_History.graphql'
);

export default (_: RouteComponentProps) => {
  const { accountNumber } = useParams();
  const [items, setItems] = useState<
    GET_PAYMENT_HISTORY_account_items_payment_history_items[]
  >([]);
  const variables = {
    account_number: accountNumber
  };

  const { data, loading, error } = useQuery(get_payment_history_query, {
    variables,
    onCompleted: () => {
      if (data?.account?.items) {
        setItems(
          data.account.items[0].payment_history?.items.filter(function(
            payment: GET_PAYMENT_HISTORY_account_items_payment_history_items
          ) {
            return payment.method !== 'RESPONSIVE RESPONSIVE';
          })
        );
      } else {
        console.log(
          "Part of the payment history data came back null or undefined. Here's what was returned: ",
          data
        );
      }
    }
  });

  return (
    <PaymentHistory
      loading={loading}
      error={error}
      payments={items}
      accountNumber={accountNumber}
    />
  );
};
