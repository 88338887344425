import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import InvoiceSnapshot from './InvoiceSnapshot';
import {
  GET_INVOICE_IN_ACCOUNT,
  GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items
} from './graphql/__generated__/GET_INVOICE_IN_ACCOUNT';

const get_invoice_in_account_query = loader(
  './graphql/Get_Invoice_In_Account.graphql'
);

export const transformData = (data: GET_INVOICE_IN_ACCOUNT) => {
  let details: (GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items | null)[] = [];

  if (
    data?.account?.items != null &&
    data.account.items[0]?.invoice?.invoice_snapshots?.items != null
  ) {
    details = data.account.items[0].invoice.invoice_snapshots.items;
  }
  return details;
};

interface Props {
  invoiceNumber: string;
}

export default ({ invoiceNumber }: Props) => {
  const { accountNumber } = useParams();
  const variables = {
    account_number: accountNumber,
    invoice_number: invoiceNumber
  };

  const queryResult = useQuery(get_invoice_in_account_query, { variables });
  const { loading, error } = queryResult;
  let { data } = queryResult;
  if (data) {
    data = transformData(data);
  }

  return (
    <InvoiceSnapshot isLoading={loading} error={error} invoiceDetails={data} />
  );
};
