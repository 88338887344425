import styled from 'styled-components';

const MenuAccordionWrap = styled.div<{ childCount: number }>`
  display: block;
  overflow: hidden;
  height: 0;
  transition: all 0.25s;

  &.active {
    height: ${({ childCount }) => 51 * childCount - 5}px;
  }
`;

export default MenuAccordionWrap;
