import React, { useState } from 'react';
import { ChevronUp, ChevronDownUnbolded } from '../../../icons';
import {
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
  RowContainer,
  Label,
  Icon,
  Value
} from '../ExpandedViewStyles';
interface ClaimAndLiabilityProps {
  claimStatus?: string | null;
  liabilityStatus?: string | null;
}
const ClaimAndLiability: React.FC<ClaimAndLiabilityProps> = ({
  claimStatus,
  liabilityStatus
}) => {
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <DropdownContainer open={open}>
      <DropdownHeader onClick={toggleDropdown} open={open}>
        <span>Claim and Liability Status</span>
        <Icon position='right' open={open}>{open ? <ChevronUp /> : <ChevronDownUnbolded />} </Icon>
      </DropdownHeader>
      <DropdownContent open={open}>
        <RowContainer>
          <Value>Claim Status : </Value>
          <Label
            style={{
              color: claimStatus === 'Open' ? '#65A100' : 'red'
            }}
          >
            {claimStatus || 'N/A'}
          </Label>
        </RowContainer>
        <RowContainer>
          <Value>Liablity Status : </Value>
          <Label>{liabilityStatus  || 'N/A'}</Label>
        </RowContainer>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default ClaimAndLiability;
