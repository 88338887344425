import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Form, TextInput, Button, Box, ThemeContext } from 'grommet';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { getPreviouslyViewed } from './AutoComplete';
import { Search, SearchClose, CloseSmall } from '../../icons';
import { DisplayableResult } from './SearchDisplayInterfaces';
import handleSearchResultNavigation from './helpers/handleSearchResultNavigation';

const BoundingRegion = styled(Box)`
  &:focus-within {
    width: 845px;
  }
`;

const HeaderSearch = styled(Search)`
  & {
    height: 15px;
    width: 15px;
  }
  g {
    stroke: #65a100;
  }
`;

const StyledBox = styled(Box)`
  & {
    border-radius: 5px;
  }
`;

interface formattedResults {
  label: JSX.Element;
  value: string;
}

interface searchFunctions {
  header?: boolean | undefined;
  callSearch: any;
  data: formattedResults[];
  getQuoteSearchSelectionBehavior: Function;
}

interface changeEvent {
  target: {
    value: string;
  };
}

interface submitEvent {
  preventDefault: () => void;
}

export default ({
  header,
  callSearch,
  data,
  getQuoteSearchSelectionBehavior
}: searchFunctions) => {
  const [insured, setInsured] = useState<string | 1 | 2>('');
  const [viewableResults, setViewableResults] = useState<formattedResults[]>(
    []
  );

  const textInput: any = useRef(null);
  const boundingBox: any = useRef(null);

  let searchTerm: string = '';
  if (typeof insured === 'string') {
    searchTerm = insured;
  }

  const delayedSearch = useCallback(
    throttle(
      (input: string) =>
        callSearch({
          variables: {
            search_term: input.trim(),
            pre_tag: '<>',
            post_tag: '</>'
          }
        }),
      50
    ),
    []
  );

  useEffect(() => {
    if (searchTerm !== '') {
      delayedSearch(searchTerm);
    } else {
      setViewableResults(getPreviouslyViewed());
    }
  }, [insured]);

  useEffect(() => {
    if (data && searchTerm !== '') {
      setViewableResults(data);
    }
  }, [data]);

  const searchChanged = (e: changeEvent) => {
    setInsured(e.target.value);
  };

  const clearSearch = (e: submitEvent) => {
    textInput.current.focus();
    setInsured('');
  };

  let barIcon = <Box />;
  if (header) {
    if (searchTerm.length) {
      barIcon = (
        <Box
          round={{ size: 'small', corner: 'right' }}
          align="center"
          justify="center"
          height="35px"
          pad={{ right: '12px' }}
        >
          <Button icon={<CloseSmall />} type="reset" onClick={clearSearch} />
        </Box>
      );
    } else {
      barIcon = (
        <Box
          round={{ size: 'small', corner: 'right' }}
          align="center"
          justify="center"
          height="35px"
          pad={{ right: '14px' }}
        >
          <Box align="center">
            <HeaderSearch />
          </Box>
        </Box>
      );
    }
  } else if (searchTerm.length) {
    barIcon = (
      <Box
        round={{ size: 'small', corner: 'right' }}
        align="center"
        justify="center"
        height="50px"
        pad={{ right: '12px' }}
      >
        <Button icon={<SearchClose />} type="reset" onClick={clearSearch} />
      </Box>
    );
  } else {
    barIcon = (
      <Box
        round={{ size: 'small', corner: 'right' }}
        align="center"
        justify="center"
        height="50px"
        pad={{ right: '30px' }}
      >
        <Box align="center">
          <Search />
        </Box>
      </Box>
    );
  }

  let usesRecent = false;
  if (
    viewableResults.length &&
    viewableResults[0].value === 'reset:viewedInsureds'
  ) {
    usesRecent = true;
  }

  return (
    <BoundingRegion ref={boundingBox} width={header ? '310px' : '845px'}>
      <Form style={{ display: 'flex' }} id="searchForm">
        <StyledBox
          fill
          background="#FFFFFF"
          direction="row"
          round="small"
          height="40px"
          align="center"
          justify="center"
          border={{ color: 'light-3' }}
        >
          <ThemeContext.Extend
            value={{
              global: {
                active: {
                  background: {
                    color: '#9325B2',
                    opacity: '100%'
                  }
                },
                hover: {
                  background: {
                    color: '#9325B2',
                    opacity: '100%'
                  }
                }
              },
              textInput: {
                suggestions: {
                  extend: `
                    li:nth-child(${usesRecent ? 'even' : 'odd'}) {
                      background-color: #FFFFFF;
                    }
                    li:nth-child(${usesRecent ? 'odd' : 'even'}) {
                      background-color: #F8F9FC;
                    }
                  `
                }
              }
            }}
          >
            <TextInput
              id="customer_lookup_input"
              ref={textInput}
              name="search"
              data-testid="search-input"
              plain
              value={searchTerm}
              dropAlign={{
                top: 'bottom',
                right: 'right'
              }}
              onChange={searchChanged}
              placeholder="Search insureds"
              suggestions={viewableResults}
              onSelect={e => {
                if (e.suggestion.value === 'reset:viewedInsureds') {
                  if (typeof Storage !== 'undefined') {
                    localStorage.removeItem('viewedInsureds');
                  }
                  setInsured(1);
                } else if (e.suggestion.value === 'NoResultsFound') {
                  window.open(
                    'https://saconnect.stateauto.com/?q=policies',
                    '_blank'
                  );
                } else {
                  const selection = JSON.parse(e.suggestion.value);
                  if (typeof Storage !== 'undefined') {
                    if (localStorage.viewedInsureds !== undefined) {
                      const storageContainer = JSON.parse(
                        localStorage.viewedInsureds
                      );
                      storageContainer.previouslyViewed = storageContainer.previouslyViewed.filter(
                        (result: DisplayableResult) => {
                          if (
                            result.keyAccountNumber !==
                            selection.keyAccountNumber
                          ) {
                            return true;
                          }
                          return false;
                        }
                      );
                      if (storageContainer.previouslyViewed.length < 4) {
                        storageContainer.previouslyViewed.unshift(selection);
                      } else {
                        storageContainer.previouslyViewed.unshift(selection);
                        storageContainer.previouslyViewed.pop();
                      }
                      localStorage.viewedInsureds = JSON.stringify(
                        storageContainer
                      );
                    } else {
                      localStorage.viewedInsureds = JSON.stringify({
                        previouslyViewed: [selection]
                      });
                    }
                  }
                  handleSearchResultNavigation(
                    selection,
                    getQuoteSearchSelectionBehavior
                  );
                  textInput.current.blur();

                  if (!insured) {
                    setInsured(2);
                  } else {
                    setInsured('');
                  }
                }
              }}
              dropTarget={boundingBox.current}
            />
          </ThemeContext.Extend>
          {barIcon}
        </StyledBox>
      </Form>
    </BoundingRegion>
  );
};
