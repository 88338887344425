import { navigate } from '@reach/router';
import { DisplayableResult } from '../SearchDisplayInterfaces';

export default async (
  selection: DisplayableResult,
  getQuoteSearchSelectionBehavior: Function
) => {
  if (selection.documentType !== 'QUOTE') {
    navigate(`/account/${selection.keyAccountNumber}/overview`);
    return null;
  }
  if (selection.quoteNumber)
    getQuoteSearchSelectionBehavior({
      variables: {
        quote_number: selection.quoteNumber
      }
    });
  return null;
};
