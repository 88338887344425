import React, { useState } from 'react';
import Overview from './Overview/DataContainer';
import DetailsScreen from './Details/DataContainer';
import AdjusterInfo from './AdJusterInfo';
import Payments from './Payments/Payments';
import {
  TabButton,
  TitleTab,
  TabContent,
  WCExpandedView
} from './ExpandedViewStyles';

interface TabProps {
  title: string;
  index: number;
  active: boolean;
  onClick: (index: number) => void;
  accountNumber?: string;
  policyNumber?: string;
  claimNumber?: boolean | string;
}

const Tab: React.FC<TabProps> = ({ title, index, active, onClick }) => {
  return (
    <TabButton active={active} onClick={() => onClick(index)}>
      {title}
    </TabButton>
  );
};

interface WCClaimsExpandedViewProps {
  accountNumber: string;
  policyNumber: string;
  claimNumber: boolean | string;
  adjusterName: string;
  adjusterPhone: string;
  adjusterEmail: string;
  name: string;
}
const WCClaimsExpandedView = ({
  accountNumber,
  policyNumber,
  claimNumber,
  adjusterEmail,
  adjusterName,
  adjusterPhone,
  name
}: WCClaimsExpandedViewProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const changeTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <WCExpandedView>
      <TitleTab>
        <Tab
          title="Overview &amp; Updates"
          index={0}
          active={activeTab === 0}
          onClick={changeTab}
        />
        <Tab
          title="Details"
          index={1}
          active={activeTab === 1}
          onClick={changeTab}
        />
        <Tab
          title="Adjuster Info"
          index={2}
          active={activeTab === 2}
          onClick={changeTab}
        />
        <Tab
          title="Payments"
          index={3}
          active={activeTab === 3}
          onClick={changeTab}
        />
      </TitleTab>

      <TabContent>
        {activeTab === 0 && (
          <Overview
            accountNumber={accountNumber}
            claimNumber={claimNumber}
            policyNumber={policyNumber}
          />
        )}
        {activeTab === 1 && (
          <DetailsScreen
            accountNumber={accountNumber}
            claimNumber={claimNumber}
            policyNumber={policyNumber}
            name={name}
          />
        )}
        {activeTab === 2 && (
          <AdjusterInfo
            name={adjusterName}
            email={adjusterEmail}
            phone={adjusterPhone}
          />
        )}
        {activeTab === 3 && (
          <Payments
            active={activeTab === 3}
            accountNumber={accountNumber}
            claimNumber={claimNumber}
            policyNumber={policyNumber}
          />
        )}
      </TabContent>
    </WCExpandedView>
  );
};

export default WCClaimsExpandedView;
