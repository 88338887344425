import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronDownUnbolded, ChevronUp, CloseSmall } from '../../icons';
import pdfIcon from '../../assets/images/PDF Icon.svg';
import spinnerPrimary from '../../assets/images/spinner-primary.svg';
import { DocumentOptions } from '.';

const FileContent = styled.div`
  display: flex;
  flex-direction: row;
  &:nth-child(even) {
    background-color: #f4f6fa;
  }
  justify-content: space-between;
  padding: 10px;
`;

export const DocumentSelector = styled.div`
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d9dfeb;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
`;
export const DropDown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  gap: 10px;
  width: 200px;
  &:hover {
    cursor: pointer;
  }
`;
export const Options = styled.div`
  overflow: auto;
  max-height: 190px;
  width: 217px;
  word-wrap: break-word;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
`;
export const Content = styled.div`
  padding: 10px;
  word-wrap: break-word;
  width: 200px;
  border: 1px solid #d9dfeb;
  background-color: #ffffff;
  z-index: 100;
  box-shadow: 0px 0px 6px -2px #002038;
  &:hover {
    cursor: pointer;
  }
`;
export const IconSpacing = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const StatusText = styled.div`
  font-weight: 700;
  font-size: 14px;
`;
export const DropDownContainer = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
`;
const ErrorText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #e02020;
`;
export const IconStyling = styled.div`
  height: 20px;
`;
const ButtonContainer = styled.div`
  padding-top: 20px;
`;
export const FinalSelection = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
`;

export interface uploadProps {
  data: any;
  dispatch: any;
  name?: string;
  policy?: string;
  loading?: boolean;
  error?: Error;
  uploadDoc: Function;
  uploadState: number;
  file: File;
  uploadData: any;
  systemCode: string;
  uploadFlag: Function;
  spinnerOn: number;
  successCount: number;
  setSuccessCount: Function;
  dropOn: string;
  setDropOn: Function;
  state: string;
  AZEnabled: boolean;
  DDPEnabled: boolean;
}

export default ({
  data,
  dispatch,
  name,
  policy,
  loading,
  error,
  uploadDoc,
  uploadState,
  file,
  uploadData,
  systemCode,
  uploadFlag,
  spinnerOn,
  successCount,
  setSuccessCount,
  dropOn,
  setDropOn,
  state,
  AZEnabled,
  DDPEnabled
}: uploadProps) => {
  const [openSend, setOpenSend] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [sendTo, setSendTo] = useState('Send Document To');
  const [typeOf, setTypeOf] = useState('Select Document Type');
  const [securityType, setSecurityType] = useState('');
  const [documentType, setDocumentType] = useState('');
  const securityTypes = [
    ['sensitive', 'State Auto Only'],
    ['unrestricted', 'Customer & State Auto']
  ];
  let safeToUpload = false;
  const [securityError, setSecurityError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [successfulUpload, setSuccess] = useState(false);

  let docData: any;
  const translateDocument = (f: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = err => {
        reject(err);
        return null;
      };
    });

  const validateAndUpload = async () => {
    if (
      uploadData &&
      uploadData.responseMessage === 'Document Uploaded Successfully'
    ) {
      safeToUpload = false;
      setSuccess(true);
      uploadFlag(spinnerOn - 1);
    }
    if (securityType === '') {
      safeToUpload = false;
      setSecurityError(true);
    }
    if (documentType === '' && systemCode !== 'GWCL') {
      safeToUpload = false;
      setDocumentError(true);
    }
    if (!policy) {
      safeToUpload = false;
    }
    if (!successfulUpload && safeToUpload) {
      docData = await translateDocument(file);
    }
    if (!successfulUpload && safeToUpload && docData && file.type) {
      await uploadDoc({
        variables: {
          policy_number: policy,
          file_name: name,
          mime_type: file.type,
          security_type: securityType,
          document_type: documentType,
          source_system_code: systemCode,
          document_data: docData
        }
      });
    }
  };
  useEffect(() => {
    if (uploadState && uploadState > 0 && !successfulUpload) {
      safeToUpload = true;
      validateAndUpload();
    }
  }, [uploadState]);

  useEffect(() => {
    if (dropOn !== name) {
      setOpenType(false);
    }
  }, [dropOn]);
  useEffect(() => {
    if (uploadData && !error) {
      setSuccessCount(successCount - 1);
    }
    if (uploadData) {
      uploadFlag(spinnerOn - 1);
    }
  }, [uploadData]);
  return (
    <FileContent>
      <IconSpacing>
        <IconStyling>
          <img src={pdfIcon} alt="PDF" />
        </IconStyling>
        <div>
          {loading && <StatusText>Uploading...</StatusText>}
          {error && <ErrorText>Error: Could Not Upload. Try Again.</ErrorText>}
          {!error && uploadData && <StatusText>Upload Complete</StatusText>}
          {securityError && documentError ? (
            <ErrorText>
              Error: Recipient and Document Type Not Selected.
            </ErrorText>
          ) : (
            <div>
              <ErrorText>
                {securityError && 'Error: Recipient Not Selected.'}
              </ErrorText>
              <ErrorText>
                {documentError && 'Error: Document Type Not Selected.'}
              </ErrorText>
            </div>
          )}
          {!uploadData && !error && (
            <StatusText>{loading ? '' : 'Pending'}</StatusText>
          )}
          {name && name.length > 40 ? `${name?.substring(0, 40)}...` : name}
        </div>
      </IconSpacing>
      <DropDownContainer>
        <div>
          <div>
            {uploadData && !error ? (
              <FinalSelection>
                <StatusText>Sent To</StatusText>
                {sendTo}
              </FinalSelection>
            ) : (
              <DocumentSelector>
                <DropDown
                  onClick={() => {
                    setOpenSend(!openSend);
                  }}
                >
                  {sendTo}
                  {openSend ? <ChevronUp /> : <ChevronDownUnbolded />}
                </DropDown>
              </DocumentSelector>
            )}
          </div>
          {openSend && (
            <Options>
              {securityTypes.map(item => {
                return (
                  <Content
                    id={item[0]}
                    onClick={() => {
                      setSendTo(item[1]);
                      setSecurityType(item[0]);
                      setSecurityError(false);
                      setOpenSend(false);
                    }}
                  >
                    {item[1]}
                  </Content>
                );
              })}
            </Options>
          )}
        </div>
        {systemCode !== 'GWCL' && (
          <div>
            <div>
              {uploadData && !error ? (
                <FinalSelection>
                  <StatusText>Document Type</StatusText>
                  {typeOf}
                </FinalSelection>
              ) : (
                <DocumentSelector>
                  <DropDown
                    onClick={() => {
                      setOpenType(!openType);
                      setDropOn(name);
                    }}
                  >
                    {typeOf}
                    {openType ? <ChevronUp /> : <ChevronDownUnbolded />}
                  </DropDown>
                </DocumentSelector>
              )}
            </div>
            {openType && (
              <DocumentOptions
                setOpenType={setOpenType}
                setDocumentError={setDocumentError}
                setDocumentType={setDocumentType}
                setTypeOf={setTypeOf}
                state={state}
                AZEnabled={AZEnabled}
                DDPEnabled={DDPEnabled}
              />
            )}
          </div>
        )}
        {loading ? (
          <ButtonContainer>
            <img src={spinnerPrimary} alt="spinner" />
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            {uploadData && !error ? (
              <ButtonContainer />
            ) : (
              <IconStyling
                onClick={() => {
                  dispatch({
                    type: 'REMOVE_FILE',
                    inDropZone: false,
                    files: file
                  });
                }}
              >
                <CloseSmall />
              </IconStyling>
            )}
          </ButtonContainer>
        )}
      </DropDownContainer>
    </FileContent>
  );
};
