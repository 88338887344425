import styled from 'styled-components';

export const Container = styled.div`
  min-width: 291px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
`;

export const DatePickerHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #0173c6;
  align-items: flex-start;
`;

const HeaderText = styled.span`
  cursor: pointer;
  display: inline;
  padding: 4px;
  border-radius: 4px;
  align-self: flex-start;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const Year = styled(HeaderText)`
  margin: 0 0 4px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #aebcd0;
`;

export const Date = styled(HeaderText)`
  margin: 0;
  font-size: 34px;
  line-height: 34px;
  color: #ffffff;
`;

export const DatePickerBody = styled.div`
  display: flex;
  flex-direction: column;
 
`;

export const DatePickerFooter = styled.div`
  display: flex;
  align-items: right;
  padding: 0 20px 20px;
  justify-content: end;
`;

export const FooterButton = styled.button`
  cursor: pointer;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0173c6;
  border: none;
  padding: 4px;
  margin-left: 30px;
  :first-child {
    margin-left: 0;
  }
`;
