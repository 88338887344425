import React from 'react';
import { Anchor, Text, Box, ResponsiveContext, Button } from 'grommet';
import { Alert } from 'grommet-icons';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

const UnderlineAnchor = styled(Anchor)`
  text-decoration: underline;
`;

interface alertInfo {
  showWarning: boolean;
  message: string;

  viewUrl: string;
}

export interface AlertPanelProps {
  alert: alertInfo;
}

export const AlertPanel = ({ alert }: RouteComponentProps<AlertPanelProps>) => {
  if (!alert) {
    return null;
  }

  const panelColor = alert.showWarning ? 'status-warning' : 'status-critical';
  return (
    <ResponsiveContext.Consumer>
      {responsive => (
        <Box
          direction="row"
          height="flex"
          background={{ color: panelColor, opacity: 'weak' }}
        >
          <Box pad="xsmall" background={panelColor} />
          <Box
            pad="small"
            margin={{ horizontal: 'medium' }}
            direction="row"
            fill="horizontal"
            alignContent="center"
          >
            <Box alignContent="center">
              <Alert size="large" color={panelColor} />
            </Box>
            <Box
              pad={
                !(responsive === 'large') && !(responsive === 'medium')
                  ? { left: 'large' }
                  : 'none'
              }
            >
              <Text size="medium" margin="small" alignSelf="center">
                {alert.message}
                <UnderlineAnchor
                  margin="small"
                  label="View More"
                  href={alert.viewUrl}
                />
              </Text>
            </Box>
          </Box>
          <Box pad="small" align="end" alignSelf="start" justify="start">
            <Button>CLOSE</Button>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
