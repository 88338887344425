import moment, { Moment } from 'moment-timezone';

export const getMomentObj = (date: string) =>
  moment.tz(date, 'YYYY-MM-DD', 'America/New_York');

export interface WeekObj {
  id: number | string;
  days: (null | Moment)[];
}

export const yearRange: [number, number] = [1900, 2100];

export const yearRangeArr: number[] = (() => {
  const arr: number[] = [];
  for (let i = yearRange[0]; i <= yearRange[1]; i += 1) {
    arr.push(i);
  }
  return arr;
})();

export const days: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const today = (() =>
  moment.tz('America/New_York').format('YYYY-MM-DD'))();

export const getMonthDaysArr = (monthYear: Moment) => {
  const numOfDays: number = monthYear.daysInMonth();
  const firstWeekIndex: number = monthYear.week();

  const weeksArr: WeekObj[] = [];

  for (let i = 1; i <= numOfDays; i += 1) {
    const date: Moment = moment(monthYear).date(i);
    let week: number = date.week();

    if (date.month() === 11 && week === 1) {
      week = 53;
    }

    if (!weeksArr[week - firstWeekIndex]) {
      const objX: WeekObj = {
        id: week,
        days: [null, null, null, null, null, null, null]
      };
      weeksArr[week - firstWeekIndex] = objX;
    }
    weeksArr[week - firstWeekIndex].days[date.day()] = date;
  }

  return weeksArr;
};
