import React, { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { navigate, RouteComponentProps } from '@reach/router';
import { Box, Anchor } from 'grommet';
import styled from 'styled-components';
import {
  PaymentInfo,
  PolicyInfo,
  PolicyDropdown,
  CommunicationAccordion,
  PayAsYouGoDropdown,
  Modal,
  ResponsiveGrid,
  ChangePolicyDropdown,
  CancelPolicy,
  LossRunRequest,
  SnapshotsAndQuotesTable
} from '../../components';
import {
  Column1,
  Column2,
  Container,
  LayoutContainer
} from '../../components/policyInfo/PolicyInfoStyles';
import { CardLayout } from '../../components/cardLayout/CardLayout';
import { TabbedCardLayout } from '../../components/tabbedCardLayout/TabbedCardLayout';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import getConfig, { Env } from '../../config';
import NoPolicyorAccount from '../../assets/images/No_policies.png';
import { Checklist, Redirect, SmallInfo } from '../../icons';
import FeatureFlag from '../../components/featureFlags';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import { colors } from '../../themes/default.theme';

export interface PolicyInformationPageProps {
  policyNumber: string;
  accountNumber: string;
  payAsYouGo: any;
  agencyBilled: boolean | undefined;
  policyType: string;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

const gridPadding = '1.25rem';

const AccordionStyledCard = styled(CardLayout)`
  & .cardBody {
    padding: 0;
  }
`;

export const PayAsYouGoStyledCard = styled(CardLayout)`
  & .cardBody {
    padding: 30px;
  }
  margin-bottom: 20px;
`;

export const StyledPolicyDropdown = styled(PolicyDropdown)`
  margin-bottom: ${gridPadding};
`;

const StyledTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledNewQuoteButton = styled.button`
  height: 50px;
  width: 240px;
  color: #189ecc;
  border: solid 1px #189ecc;
  border-radius: 4px;
  background-color: transparent;
  font-weight: bold;
  font-size: 15px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    color: #bdbdbd;
    border: solid 1px #bdbdbd;
  }
`;

const OuterBox = styled.div`
  text-align: center;
`;

const BigBoldText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin-top: 45px;
`;

const SmallText = styled.p`
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 30px;
`;

const DetailsIcon = styled.div`
  background: #0072c6;
  border: 0px;
  border-radius: 4px 0px 0px 4px;
  max-width: 70px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
`;
const RedirectIcon = styled.div`
  background: #ffffff;
  border: 0px;
  border-radius: 0px 4px 4px 0px;
  max-width: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
`;
export const PolicyDetailsButton = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  margin-bottom: 20px;
  height: 60px;
  align-content: center;
  border-radius: 4px;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;
const PolicyDetailsText = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 0px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  justify: end;
  gap: 100px;
  flex-grow: 5;
`;

const ToolTip = styled.span`
  display: flex;
  flex-direction: row;
  gap: 5px;
  visibility: hidden;
  background-color: ${colors.darkGray1};
  color: #ffffff;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  border-radius: 3px;
  box-shadow: 0px 4px 6px -2px #002038;
  padding: 5px;
  width: 240px;
`;

const ToolTipContainer = styled.div`
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
  }
`;

export default ({
  accountNumber,
  policyNumber,
  payAsYouGo,
  agencyBilled,
  policyType,
  readOnlyAccess,
  readOnlyFeatureFlag
}: RouteComponentProps<PolicyInformationPageProps>) => {
  const onSwitchPolicy = useCallback(_policyNumber => {
    navigate(`/account/${accountNumber}/policy/${_policyNumber}`);
  }, []);

  const { flagDetails: StartNewQuoteButton } = useFeatureFlag(
    'StartNewQuoteButton'
  );

  const onSelectDateRange = (dueDate: string) => {
    simpleGTMDataLayer({
      event: 'click-payasyougo-date-range',
      event_label: 'Date Range Change',
      event_action: dueDate
    });
  };

  const filtered = payAsYouGo?.account?.items[0].policies.items[0].payasyougo?.items.filter(
    (item: any) =>
      item.audit_periodstartdate !== null && item.audit_periodenddate !== null
  );
  const showPAYG = filtered?.length > 0;

  const ErrorFallback = () => {
    simpleGTMDataLayer({
      event: 'invalidAccountOrPolicy',
      event_action: 'Policy Information',
      event_category: policyNumber
    });

    return (
      <OuterBox>
        <img
          height="250px"
          width="250px"
          src={NoPolicyorAccount}
          alt="No Policies Found"
        />
        <BigBoldText>
          No Active Policies Available for Account {accountNumber}
        </BigBoldText>
        <SmallText>If a Policy was just bound, check back shortly</SmallText>
      </OuterBox>
    );
  };

  const onStartNewQuote = () => {
    simpleGTMDataLayer({
      event: 'newQuoteClick',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'Start a New Quote'
    });
    simpleGTMDataLayer({
      event: 'snowplow-newQuoteClick',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'Start a New Quote'
    });
    const config = getConfig(process.env.REACT_APP_ENV as Env);
    window.open(config.saconnect_url_endpoint);
  };

  const showDetails = !!(policyType === 'Home' || policyType === 'Auto');
  const openPolicyDetails = () => {
    const config = getConfig(process.env.REACT_APP_ENV as Env);
    if (config.oauth.redirectSignIn.includes('agency360.stateauto.com')) {
      window.open(
        `https://std-spa-personal-services.stateauto.com/policy-review/${policyType?.toLowerCase()}/${policyNumber}`
      );
    } else {
      window.open(
        `https://std-spa-personal-services-l3-5.stateauto.com/policy-review/${policyType?.toLowerCase()}/${policyNumber}`
      );
    }
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LayoutContainer>
        <StyledTopBar>
          <StyledPolicyDropdown onChange={onSwitchPolicy} />
          {StartNewQuoteButton?.enabled ? (
            <StyledNewQuoteButton
              type="button"
              onClick={onStartNewQuote}
              disabled={readOnlyAccess && readOnlyFeatureFlag}
            >
              START A NEW QUOTE
            </StyledNewQuoteButton>
          ) : (
            <ToolTipContainer>
              <ToolTip>
                <SmallInfo />
                {StartNewQuoteButton?.value?.message ||
                  'State Auto Personal Lines will close for new business after 12/31/2022'}
              </ToolTip>
              <StyledNewQuoteButton
                type="button"
                onClick={onStartNewQuote}
                disabled={readOnlyAccess && readOnlyFeatureFlag}
              >
                START A NEW QUOTE
              </StyledNewQuoteButton>
            </ToolTipContainer>
          )}
        </StyledTopBar>
        <Container>
          <Column1>
            <CardLayout title="Policy Summary">
              <PolicyInfo />
            </CardLayout>
            <CardLayout title="Snapshot">
              <SnapshotsAndQuotesTable history={false} />
            </CardLayout>
          </Column1>
          <Column2>
            {showPAYG ? (
              <PayAsYouGoStyledCard title="Pay-As-You-Go">
                <PayAsYouGoDropdown onChange={onSelectDateRange} />
              </PayAsYouGoStyledCard>
            ) : (
              <Box />
            )}
            {showDetails ? (
              <PolicyDetailsButton
                onClick={() => {
                  openPolicyDetails();
                }}
              >
                <DetailsIcon>
                  <Checklist />
                </DetailsIcon>
                <PolicyDetailsText>POLICY DETAILS </PolicyDetailsText>
                <RedirectIcon>
                  <Redirect />
                </RedirectIcon>
              </PolicyDetailsButton>
            ) : (
              <div />
            )}
            <FeatureFlag flagName="ChangePolicy">
              <ChangePolicyDropdown
                readOnlyAccess={readOnlyAccess ?? false}
                readOnlyFeatureFlag={readOnlyFeatureFlag ?? false}
              />
            </FeatureFlag>
            <FeatureFlag flagName="CancelPolicy">
              <CancelPolicy
                readOnlyAccess={readOnlyAccess ?? false}
                readOnlyFeatureFlag={readOnlyFeatureFlag ?? false}
              />
            </FeatureFlag>
            <FeatureFlag flagName="LossRunRequest">
              <LossRunRequest screenPath="policy" />
            </FeatureFlag>
            <AccordionStyledCard title="Communications History">
              <CommunicationAccordion page="policy" />
            </AccordionStyledCard>
          </Column2>
        </Container>
      </LayoutContainer>
    </ErrorBoundary>
  );
};
