import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import ClaimsDetail from '../claimsDetail';
import { ClaimsDetailProps } from '../claimsDetail/ClaimsDetail';

const WrapperBox = styled(Box)`
  margin: 0;
  padding: 0;
`;

export interface ClaimsDetailContainerProps {
  claimsIncidentsList: (ClaimsDetailProps | undefined)[];
}

export default ({ claimsIncidentsList }: ClaimsDetailContainerProps) => {
  return (
    <Box gap="20px" align="start">
      {claimsIncidentsList.map(displayedItem => (
        <WrapperBox fill="horizontal" key={displayedItem?.publicID}>
          <ClaimsDetail {...displayedItem} />
        </WrapperBox>
      ))}
    </Box>
  );
};
