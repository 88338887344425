import styled, { keyframes } from 'styled-components';
export const Wrapper = styled.div`
  padding: 50px 20px 50px 20px;
`;

export const DropdownContainer = styled.div<{ open: boolean }>`
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 20px 0;
  overflow: hidden;
  margin-bottom: ${({ open }) => (open ? '10px' : '0')};
`;

export const DropdownHeader = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 35px;
  color: #0173c6;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: ${({ open }) => (open ? '17px' : '15px')};
  padding-left: 10px;
  padding-right: 0px;
`;

export const DropdownContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: 100%;
  background-color: white;
  margin: 0px;
  padding: 5px;
`;
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  display: inline-block;
  animation: ${spinAnimation} 1s infinite linear;
  margin: 5px;
`;
export const Loading = styled.span`
  color: #0173c6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
`;

export const LabelDisplay = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const Label = styled.span`
  font-weight: bold;
  margin: 5px;
`;

export const Value = styled.span`
  margin: 5px;
`;
export const IconContainer = styled.span`
  padding-top: 5px;
`;
interface IconProps {
  position: string;
  open?: boolean;
}
export const Icon = styled.span<IconProps>`
  position: absolute;
  top: ${props => (props.open ? '20px' : '50%')};

  transform: translateY(-50%);
  ${props => (props.position === 'left' ? 'left: 10px;' : 'right: 10px;')}
`;
//claims expanded view styles
export const TabButton = styled.span<{ active?: boolean }>`
  position: relative;
  background: transparent;
  color: white;
  font-size: 20px;
  padding: 8px 16px;

  margin-right: 8px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${({ active }) => (active ? '#65A100' : 'transparent')};
    transition: transform 0.3s ease-in-out;
  }
  ${({ active }) =>
    active &&
    `&::before {
    transform: translateY(0);
  }
  font-weight: bold;
`}
`;

export const TitleTab = styled.div`
  background-color: #0173c6;
  height: 30px;
  margin-top: 5px;
  padding-top: 25px;
`;

export const TabContent = styled.div`
 
`;
export const WCExpandedView = styled.div`
  background-color: rgb(230, 234, 241);
`;
//reserves
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

 export const TableRow = styled.tr`
  height: 100px;
`;

export const TableCell = styled.td`
  width:80px;
  text-align: center;
`;
export const Line =  styled.td`
  border-bottom: 1px solid #dbdfe1;
  width: 100%;
`;