import React from 'react';
import { Box, Text, Grid } from 'grommet';
import styled from 'styled-components';
import { formatDate, formatTitleCase } from '../../util/formatters';
import { colors } from '../../themes/default.theme';

const HoverableBox = styled(Box)`
  padding-left: 4px;
  &:hover {
    background-color: #9325b2;
  }
`;

const StyledBox = styled(Box)`
  border: 1px solid #65a100;
  width: 44px;
  min-width: 44px;
`;

const ClaimNumber = styled(Text)`
  font-size: 16px;
  padding-bottom: 3px;
  font-weight: 700;
`;

const StyledText = styled(Text)`
  font-size: 12px;
  line-height: 18px;
`;

const StatusButton = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  margin: auto;
  color: #65a100;
`;

const SecondStatusButton = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  margin: auto;
`;

const SecondHoverableBox = styled(Box)`
  padding-left: 4px;
  &:hover {
    background-color: #9325b2;
  }

  &:hover > div > div:last-child > span {
    color: ${colors.valueRed1};
  }

  &:hover > div > div:last-child {
    border-color: ${colors.valueRed1};
  }

  &:hover > div > div > div > span {
    color: #041e41;
  }
  &:hover > div > div > span {
    color: #041e41;
  }
`;

export interface displayData {
  claimNumber: string | null | undefined;
  lossLocation: string | null | undefined;
  dateOfLoss: string | null | undefined;
  status: string;
  selected: boolean;
}
export default ({
  claimNumber,
  lossLocation,
  dateOfLoss,
  status,
  selected
}: displayData) => {
  if (status.toLowerCase() === 'open') {
    return (
      <HoverableBox
        background={selected ? '#9325b2' : 'white'}
        fill="horizontal"
      >
        <Box
          direction="row"
          background="white"
          pad={{ left: '26px', right: '30px', top: '15px', bottom: '15px' }}
          align="start"
          justify="between"
        >
          <Box>
            <ClaimNumber>{claimNumber}</ClaimNumber>
            <Grid
              rows={['flex', 'flex']}
              columns={['flex', 'flex']}
              gap={{ column: '16px' }}
              areas={[
                { name: 'LossLocationLabel', start: [0, 0], end: [0, 0] },
                { name: 'LossLocationValue', start: [1, 0], end: [1, 0] },
                { name: 'DateOfLossLabel', start: [0, 1], end: [0, 1] },
                { name: 'DateOfLossValue', start: [1, 1], end: [1, 1] }
              ]}
              fill
            >
              <StyledText gridArea="LossLocationLabel">
                Loss Location:
              </StyledText>
              <StyledText gridArea="LossLocationValue">
                {lossLocation}
              </StyledText>
              <StyledText gridArea="DateOfLossLabel">Date of Loss:</StyledText>
              <StyledText gridArea="DateOfLossValue">
                {formatDate(dateOfLoss)}
              </StyledText>
            </Grid>
          </Box>

          <StyledBox round="12.5px">
            <StatusButton>{formatTitleCase(status)}</StatusButton>
          </StyledBox>
        </Box>
      </HoverableBox>
    );
  }
  if (status.toLowerCase() === 'closed') {
    return (
      <SecondHoverableBox
        background={selected ? '#9325b2' : 'white'}
        fill="horizontal"
      >
        <Box
          direction="row"
          background="white"
          pad={{ left: '26px', right: '30px', top: '15px', bottom: '15px' }}
          align="start"
          justify="between"
        >
          <Box>
            <ClaimNumber color={selected ? '#041e41' : '#547199'}>
              {claimNumber}
            </ClaimNumber>
            <Grid
              rows={['flex', 'flex']}
              columns={['flex', 'flex']}
              gap={{ column: '16px' }}
              areas={[
                { name: 'LossLocationLabel', start: [0, 0], end: [0, 0] },
                { name: 'LossLocationValue', start: [1, 0], end: [1, 0] },
                { name: 'DateOfLossLabel', start: [0, 1], end: [0, 1] },
                { name: 'DateOfLossValue', start: [1, 1], end: [1, 1] }
              ]}
              fill
            >
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="LossLocationLabel"
              >
                Loss Location:
              </StyledText>
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="LossLocationValue"
              >
                {lossLocation}
              </StyledText>
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="DateOfLossLabel"
              >
                Date of Loss:
              </StyledText>
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="DateOfLossValue"
              >
                {formatDate(dateOfLoss)}
              </StyledText>
            </Grid>
          </Box>
          <Box
            width={{ min: '60px', max: '60px' }}
            round="12.5px"
            border={{
              color: selected ? colors.valueRed1 : '#3e5572',
              size: '1px',
              style: 'solid'
            }}
          >
            <SecondStatusButton color={selected ? colors.valueRed1 : '#3e5572'}>
              Closed
            </SecondStatusButton>
          </Box>
        </Box>
      </SecondHoverableBox>
    );
  }
  if (status.toLowerCase() === 'complete') {
    return (
      <SecondHoverableBox
        background={selected ? '#9325b2' : 'white'}
        fill="horizontal"
      >
        <Box
          direction="row"
          background="white"
          pad={{ left: '26px', right: '30px', top: '15px', bottom: '15px' }}
          align="start"
          justify="between"
        >
          <Box>
            <ClaimNumber color={selected ? '#041e41' : '#547199'}>
              {claimNumber}
            </ClaimNumber>
            <Grid
              rows={['flex', 'flex']}
              columns={['flex', 'flex']}
              gap={{ column: '16px' }}
              areas={[
                { name: 'LossLocationLabel', start: [0, 0], end: [0, 0] },
                { name: 'LossLocationValue', start: [1, 0], end: [1, 0] },
                { name: 'DateOfLossLabel', start: [0, 1], end: [0, 1] },
                { name: 'DateOfLossValue', start: [1, 1], end: [1, 1] }
              ]}
              fill
            >
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="LossLocationLabel"
              >
                Loss Location:
              </StyledText>
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="LossLocationValue"
              >
                {lossLocation}
              </StyledText>
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="DateOfLossLabel"
              >
                Date of Loss:
              </StyledText>
              <StyledText
                color={selected ? '#041e41' : '#547199'}
                gridArea="DateOfLossValue"
              >
                {formatDate(dateOfLoss)}
              </StyledText>
            </Grid>
          </Box>
          <Box
            width={{ min: '60px', max: '60px' }}
            round="12.5px"
            border={{
              color: selected ? colors.valueRed1 : '#3e5572',
              size: '1px',
              style: 'solid'
            }}
          >
            <SecondStatusButton color={selected ? colors.valueRed1 : '#3e5572'}>
              Complete
            </SecondStatusButton>
          </Box>
        </Box>
      </SecondHoverableBox>
    );
  }
  return <Box />;
};
