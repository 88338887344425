import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  DifficultOrEasyDiv,
  StyledButtons,
  StyledCancelAndSubmitButtons,
  StyledCloseButton,
  StyledInputBox,
  StyledModalContainer,
  StyledModal,
  StyledQuestionHeader,
  StyledStarDiv,
  StyledTextAboveInputBox,
  FeedbackErrorMessage,
  ErrorText
} from './FeedbackModalStyles';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const RatingStar = withStyles(theme => ({
  root: {
    width: '52px',
    height: '52px',
    [theme.breakpoints.down(280)]: {
      width: '44px',
      height: '44px'
    }
  }
}))(Star);

const RatingStarBorderIcon = withStyles(theme => ({
  root: {
    width: '52px',
    height: '52px',
    [theme.breakpoints.down(280)]: {
      width: '44px',
      height: '44px'
    }
  }
}))(StarBorderIcon);

const useStyles = makeStyles(theme => ({
  backgroundRoot: {
    background: 'linear-gradient(rgba(0,0,0,0) 70%, rgba(1,115,198,0.4))',
    [theme.breakpoints.down(400)]: {
      background: 'linear-gradient(rgba(0,0,0,0) 13%, rgba(1,115,198,0.4))'
    }
  },
  icon: {
    width: '56px',
    marginLeft: '5px',
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '2px',
      marginRight: '2px',
      width: '52px'
    },
    [theme.breakpoints.down(270)]: {
      marginLeft: '0px',
      marginRight: '0px',
      width: '48px'
    }
  },
  cancelIconRoot: {
    position: 'absolute',
    top: '-15px',
    color: '#575757',
    backgroundImage:
      'radial-gradient(rgba(255,255,255) 60%, rgba(0,0,0,0) 60%)',
    'z-index': 100,
    left: '90%',
    [theme.breakpoints.down(400)]: {
      left: '85%'
    },
    [theme.breakpoints.down(315)]: {
      left: '80%'
    }
  }
}));

export interface UserFeedbackForm {
  rating: string;
  feedbackMessage: string;
}

export interface FeedbackCommentModalProps {
  submitFeedback: Function;
  toggleShowModal: Function;
  errorState: boolean;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

export default ({
  submitFeedback,
  toggleShowModal,
  errorState,
  readOnlyAccess,
  readOnlyFeatureFlag
}: FeedbackCommentModalProps) => {
  const [rating, setValue] = useState<number>(0);
  const [userOptionalFeedback, setFeedback] = useState<string>('');
  const [ratingError, flagRatingError] = useState<boolean>(false);

  const submitUserFeedback = () => {
    const input = {
      rating,
      feedback: userOptionalFeedback
    };
    simpleGTMDataLayer({
      event: 'feedbackClick',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'Submit Feedback'
    });
    simpleGTMDataLayer({
      event: 'feedbackClickSnowplow',
      event_action: 'Button Click',
      event_category: window.location.pathname + '/Snowplow',
      event_label: 'Submit Feedback'
    });
    submitFeedback(input);
  };

  const classes = useStyles();

  return (
    <StyledModal>
      <StyledCloseButton
        onClick={() => {
          simpleGTMDataLayer({
            event: 'feedbackClick',
            event_action: 'Button Click',
            event_category: window.location.pathname,
            event_label: 'Close Button'
          });
          simpleGTMDataLayer({
            event: 'feedbackClickSnowplow',
            event_action: 'Button Click',
            event_category: window.location.pathname + '/Snowplow',
            event_label: 'Close Button'
          });
          toggleShowModal();
        }}
      >
        x
      </StyledCloseButton>
      <StyledModalContainer>
        <StyledQuestionHeader>
          How easy was it to find the information you needed today?
        </StyledQuestionHeader>
        <StyledStarDiv>
          <Rating
            name="starRating"
            size="large"
            icon={<RatingStar fontSize="large" />}
            classes={{ icon: classes.icon }}
            value={rating}
            onChange={(event, newValue) => {
              if (newValue) {
                setValue(newValue);
              }
              simpleGTMDataLayer({
                event: 'feedbackClick',
                event_action: 'Rating Click',
                event_category: window.location.pathname,
                event_label: newValue
              });
              simpleGTMDataLayer({
                event: 'feedbackClickSnowplow',
                event_action: 'Rating Click',
                event_category: window.location.pathname + '/Snowplow',
                event_label: newValue
              });
            }}
            emptyIcon={<RatingStarBorderIcon fontSize="large" />}
          />
        </StyledStarDiv>
        <DifficultOrEasyDiv>
          <div>Difficult</div>
          <div>Easy</div>
        </DifficultOrEasyDiv>
        {ratingError && (
          <ErrorText test-attr="error-div">Rating is required</ErrorText>
        )}
        <StyledTextAboveInputBox>
          Please Provide Additional Feedback (Optional)
        </StyledTextAboveInputBox>
        <StyledInputBox
          id="text"
          value={userOptionalFeedback}
          onChange={event => {
            setFeedback(event.target.value);
          }}
        />

        {errorState && (
          <FeedbackErrorMessage>
            <div>An error occurred when trying to send your feedback.</div>
            <div>Please try again.</div>
          </FeedbackErrorMessage>
        )}

        <StyledCancelAndSubmitButtons>
          <StyledButtons
            type="button"
            onClick={() => {
              simpleGTMDataLayer({
                event: 'feedbackClick',
                event_action: 'Button Click',
                event_category: window.location.pathname,
                event_label: 'Cancel'
              });
              simpleGTMDataLayer({
                event: 'feedbackClickSnowplow',
                event_action: 'Button Click',
                event_category: window.location.pathname + '/Snowplow',
                event_label: 'Cancel'
              });
              toggleShowModal();
            }}
          >
            CANCEL
          </StyledButtons>
          <StyledButtons
            type="submit"
            test-attr="feedback-submit"
            onClick={() => {
              if (rating === 0) {
                flagRatingError(true);
              } else {
                submitUserFeedback();
              }
            }}
            disabled={readOnlyAccess && readOnlyFeatureFlag}
          >
            SUBMIT
          </StyledButtons>
        </StyledCancelAndSubmitButtons>
      </StyledModalContainer>
    </StyledModal>
  );
};
