import Recat from 'react';
import styled, { keyframes } from 'styled-components';
import { dropdownUIProps, textProps } from './changePolicyDropdown';
import { colors } from '../../themes/default.theme';
import { AltInfo } from '../../icons';

interface InputProps {
  showError?: boolean;
}

export const WhiteSpace = styled.div`
  height: 10px;
`;

export const Frame = styled.div`
  width: 300px;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
`;

export const YearHeader = styled.div`
  width: 300px;
  background-color: #0072c6;
  font-size: 20px;
  color: #ffffff;
  padding: 20px 0px 0px 0px;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
`;

export const Header = styled.div`
  width: 300px;
  background-color: #0072c6;
  color: #ffffff;
  font-size: 36px;
  padding: 0px 0px 20px 0px;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
`;

export const HeaderText = styled.div`
  font-family: 'PT Sans', sans-serif;
  margin-left: 20px;
`;

export const MonthHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
`;

export const Button = styled.div`
  cursor: pointer;
`;

export const Day = styled.div`
  width: 14.2%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:focus {
    background-color: #0072c6;
    color: #ffffff;
    border-radius: 2px;
  }
`;

export const BlockedDay = styled.div`
  width: 14.2%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-decoration: line-through;
  color: lightgray;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
`;

export const BoundingBox = styled.div`
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
`;

export const Dropdown = styled.div<dropdownUIProps>`
  display: flex;
  padding: 0px;
  border-radius: 4px;
  min-height: 60px;
  box-shadow: ${props =>
    props.isOpen ? '0 2px 4px 0 rgba(1, 83, 142, 0.2)' : '0px'};
  &:hover > div:first-child {
    background-color: ${colors.green1};
  }
  cursor: ${props => props?.disabled && 'not-allowed'};
  pointer-events: ${props => (props?.disabled ? 'none' : 'auto')};
  color: ${props => props?.disabled && '#979797'};
  & > div:first-child {
    background-color: ${props => props?.disabled && '#e0e0e0'};
  }
  & > * {
    cursor: ${props => props?.disabled && 'not-allowed'};
  }
`;

export const ChangeForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 23px 30px 30px 30px;
  min-height: 60px;
  border-radius: 4px;
  & > *:not(:first-child) {
    margin-top: 12px;
  }

  & > *:last-child:not(:first-child) {
    margin-top: 46px;
  }
`;

export const IconOuterBox = styled.div`
  display: flex;
  border-radius: 4px 0px 0px 4px;
  width: 80px;
  height: 60px;
  background-color: #0072c6;
`;

export const WhiteBox = styled.div`
  display: flex;
  width: 100%;
  margin: auto 20px;
  justify-content: space-between;
`;

export const BoldText = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

export const IconInnerBox = styled.div`
  margin: auto;
`;

export const ChevronBox = styled.div`
  margin-right: 14px;
`;

export const Input = styled.input<InputProps>`
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  height: 56px;
  width: 97%;
  padding-left: 15px;
  ${props => (props.showError ? 'border-color: #B00020;' : '')}
  &:focus {
    outline-color: #65a100;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-top: 20px;
  & > * > button:not(:first-child) {
    margin-left: 15px;
  }
`;

export const Text = styled.span<textProps>`
  color: ${props => (props.active ? '#65A100' : '#666666')};
  font-size: 12px;
  line-height: 18px;
  margin-left: 16px;
`;

interface LabelProps {
  showError?: boolean;
}

export const Label = styled(Text)<LabelProps>`
  position: absolute;
  background-color: #ffffff;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: -10px;
  ${props => (props.showError ? 'color: #B00020;' : '')}
`;

export const CancelButton = styled.button`
  height: 30px;
  width: 88px;
  background-color: #ffffff;
  border: transparent;
  font-weight: bold;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
`;

export const StartChangeButton = styled.button`
  height: 30px;
  width: 135px;
  border-radius: 3px;
  background-color: #65a100;
  color: #ffffff;
  border: transparent;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
`;

interface InputGroupProps {
  alignLastItemRight?: boolean;
}

export const InputGroup = styled.div<InputGroupProps>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.alignLastItemRight
      ? `
  *:last-child {
    margin-left: auto;
  }
  `
      : ''}
`;

export const DescriptionTextOptional = styled(Text)`
  :last-child {
    margin-left: 16px;
  }
`;

export const EffectiveDateDivWithIcon = styled.input.attrs({
  type: 'date'
})<InputProps>`
  display: flex;
  text-transform: uppercase;
  flex-direction: row;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  width: 100%;
  padding-left: 15px;
  background-color: #ffffff;
  ::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  ${props => (props.showError ? 'border-color: #B00020;' : '')}
`;

export const EffectiveDateStyling = styled.div`
  margin-top: 10px;
`;

export const DatePickerRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  height: 40px;
  margin-right: -5px;
`;

export const GreenCalendarDiv = styled.div`
  margin-left: -41px;
  margin-top: 7px;
`;

export const BelowEffectiveDateDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const SelectButton = styled.label`
  font-weight: 700;
  font-size: 16px;
  background-color: #ffffff;
  color: #0173c6;
  border-radius: 3px;
  border: 1px solid #0173c6;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;
export const SelectFiles = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const DocumentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DocumentName = styled.div<InputProps>`
  align-self: start;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  height: 50px;
  width: 75%;
  &:focus {
    outline-color: #65a100;
  }
  margin-right: 10px;
  ${props => (props.showError ? 'border-color: #B00020;' : '')}
`;
export const DocumentText = styled.div`
  margin: 16px 13px 12px 13px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #f5a524;
  border-left: 5px solid #f5a524;
  border-radius: 3px;
  background-color: #fff6e9;
  color: #772f1d;
  padding: 15px 18px;
  gap: 13px;
  margin-bottom: 25px;
  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const IconDiv = styled.div`
  height: 25px;
  width: 25px;
`;

export const TextArea = styled.textarea<InputProps>`
  resize: none;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  height: 114px;
  ${props => (props.showError ? 'border-color: #B00020;' : '')}
  &:focus {
    outline-color: #65a100;
  }
  padding: 14px;
  padding-top: 9px;
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 30px;
  border-radius: 3px;
  background-color: #65a100;
  color: #ffffff;
  border: transparent;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 25px;
  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const PolicyTermContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 0;
`;

export const PolicyTermInfoWrap = styled.div`
  display: flex;
  background: #e2f5f9;
  border: 1px solid #189ecc;
  border-radius: 3px;
  padding: 12px 16px;
`;

export const InfoIcon = styled(AltInfo)`
  display: inline;
`;

export const PolicyTermInfoText = styled.p`
  padding-left: 8px;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #041e41;
`;

export const PolicyTermTitle = styled.p`
  margin: 16px 0 0;
  font-size: 16px;
  line-height: 19px;
  color: #3b3b3b;
`;

export const PolicyTermOption = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
`;

export const PolicyTermOptionsRadio = styled.input`
  cursor: pointer;
  display: inline;
  margin: 0;
`;

export const PolicyTermOptionsLabel = styled.label`
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding-left: 8px;
`;

export const ErrorModal = styled.div`
  z-index: 2;
  display: inline;
  position: fixed;
  margin-top: -19%;
  margin-left: -30%;
`;

export const ChangePolicyContainer = styled.div`
  display: inline;
  flex-direction: row;
  z-index: 1;
  left: 0;
  top: 0;
  margin: auto;
  background-color: #ffffff;
  position: relative;
`;

export const closing = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const persisting = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 1;
}
`;

export const Unavailable = styled.div`
  display: flex;
  flex-direction: row;
  width: 578px;
  height: 88px;
  border: 1px solid #e8b2bc;
  border-radius: 5px;
  background: #f7e5e8;
  border-left: 5px solid #b00020;
  color: #590010;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
`;

export const InfoIconSizing = styled.div`
  width: 15px;
  padding-right: 15px;
`;

export const CloseIconSizing = styled.div`
  width: 15px;
  padding-right: 15px;
  &:hover {
    cursor: pointer;
  }
`;

export const DatePickerWrapper = styled.div`
  position: fixed;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const DatePickerModalBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 4px 0px hsla(205, 99%, 28%, 0.2);
  z-index: 1;
`;

export const GreyedOutButton = styled.button`
  cursor: 'not-allowed';
  background-color: #ffffff;
  color: #bdbdbd;
  border: 1px solid;
  padding: 7px 10px;
  border-radius: 3px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: bold;
`;

export const LinkText = styled.div`
  color: #0000ff;
  font-family: 'PT Sans', sans-serif;
  width: 30px;
  cursor: pointer;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox'
})`
height: 25px;
width: 25px;
position: absolute;
margin-top: 0px;
margin-left: 0px;
display: none;
&:checked{
  content: '';
  display: block;
  border: solid pink;
  border-width: 0 2px 2px 0;
  margin-top: 0px;
  margin-left: 0px;
`;

export const CheckboxText = styled.span`
  font-weight: 400px;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
  margin: auto;
  margin-left: 11px;
`;

export const CheckboxOverallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 20px;
`;

export const CheckboxIndividualContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const CheckboxContainer = styled.label`
  border: 1px solid #0173c6;
  border-radius: 3px;
  height: 25px;
  width: 25px;
`;
