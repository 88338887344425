import styled from 'styled-components';
import { motifs } from '../../themes/default.theme';

export const StyledRightColumn = styled.div`
  padding-right: 30px;
  display: flex;
  flex-direction: column;
`;

export const StyledRightColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledPreviouslyViewedInnerContent = styled.div`
  margin-top: 7px;
`;

export const StyledPreviouslyViewedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin-right: 14px;
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: ${motifs.lightBorder};
`;

export const StyledPreviouslyViewedWrapper = styled.div`
  display: flex;
  font-style: 'PT Sans';
  font-weight: 700px;
  font-size: 20px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

export const StyledMarginSpace = styled.div.attrs(
  (props: { index: number }) => props
)`
  height: 10px;
  margin-left: 10px;
  width: auto;
  background-color: ${props => (props.index % 2 === 0 ? '#FFFFFF' : '#F8F9FC')};
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeaderText = styled.div`
  align-content: right;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 14px;
  margin-right: 5px;
`;

export const StyledSearchResult = styled.div.attrs(
  (props: { index: number }) => props
)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40%;
  font-family: 'PT Sans';
  margin-left: 10px;
  justify-content: space-between;
  background-color: ${props => (props.index % 2 === 0 ? '#FFFFFF' : '#F8F9FC')};
  padding: 10px 0px;
`;

export const StyledLeftColumn = styled.div`
  margin-left: 10px;
  padding-right: 19px;
  width: 200px;
`;

export const StyledMiddleColumn = styled.div`
  width: 320px;
`;

export const StyledHit = styled.div`
  border-bottom: 1px solid #d9dfeb;
  background: transparent;
`;
