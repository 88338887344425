import styled from 'styled-components';

interface NavigateMonthProps {
  disabled: boolean;
}

interface CalendarDayProps {
  today: boolean;
  disabled: boolean;
  selected: boolean;
}

export const MonthSelectFilter = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 7px 0;
`;

export const NavigateMonthFilter = styled.img<NavigateMonthProps>`
  display: block;
  width: 6px;
  height 6px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => (props.disabled ? 'opacity: 0.72;' : '')}
  padding: 6px;
  border-radius: 50%;
  ${props =>
    props.disabled
      ? ''
      : `:hover {
    background-color: #e3e9f1;
  }`}  
`;

export const MonthYearFilter = styled.span`
  flex-grow: 1;
  font-weight: 600;
  font-size: 12px;
  line-height: 9.5px;
  color: #000000;
  text-align: center;
`;

export const DaysRowFilter = styled.div`
  display: flex;
  padding: 10px 12px 8px;
  justify-content: space-between;
`;

export const DaysFilter = styled.span`
  font-size: 10px;
  line-height: 7px;
  width: 7px;
  text-align: center;
  color: #000000;
  opacity: 0.48;
`;

export const WeeksFilter = styled.div`
  display: flex;
  margin: 0 6px;
  padding: 2px 0;
  justify-content: space-between;
  :last-child {
    margin-bottom: 4.5px;
  }
`;

export const DayPlaceholderFilter = styled.span`
  width: 9px;
  height: 9px;
  margin: 0 1px;
`;

export const CalendarDayFilter = styled.span<CalendarDayProps>`
  width: ${props => (props.today ? 17.5 : 18)}px;
  height: ${props => (props.today ? 17.5 : 18)}px;
  line-height: ${props => (props.today ? 17.5 : 18)}px;
  font-size: 12px;
  ${props => (props.today ? 'border: 1px solid #0173c6;' : '')}
  text-align: center;
  border-radius: 50%;
  color: #000000;
  margin: 0 1px;
  ${props => (props.disabled ? 'opacity: .4;' : '')}
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: #${props => (props.selected ? '0173c6' : 'ffffff')};
  ${props => (props.selected ? 'color: #ffffff;' : '')}
  ${props =>
    props.disabled
      ? ''
      : `:hover {
    background-color: #${props.selected ? '0173c6' : 'e3e9f1'};
  }`}
`;
