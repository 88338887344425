import React, { useState, useRef, useEffect } from 'react';
import moment, { Moment } from 'moment';
import DatePicker from '../datePicker';
import { Calendar, RedInfo } from '../../icons';
import {
  Icon,
  CalendarContainer,
  WarningMessage,
  DateInput,
  BorderLabel,
  CalendarOptions,
  WarningText
} from './AdvancedFilterStyles';

interface CustomDatePickerProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  inputName: string;
}

const CustomDatePicker = ({
  selectedDate,
  setSelectedDate,
  inputName
}: CustomDatePickerProps) => {
  const [displayDate, setDisplayDate] = useState<Date | string | null>('');
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const [futureDate, setFutureDate] = useState<boolean>(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedDate === null) {
      setDisplayDate('');
      setInvalidDate(false);
      setFutureDate(false);
    } else {
      setDisplayDate(selectedDate);
    }
  }, [selectedDate]);
  const handleCalendarButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (showCalendar) {
      setShowCalendar(false);
    } else {
      setShowCalendar(true);
    }
  };

  const isDateInFuture = (parsedDate: Moment): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const selectedDate = parsedDate.toDate();
    selectedDate.setHours(0, 0, 0, 0);
    return selectedDate > currentDate;
  };

  const validDate = (parsedDate: Moment): boolean => {
    const dateString = parsedDate.format('MM/DD/YYYY');

    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!dateRegex.test(dateString)) {
      return false;
    }

    const [month, day, year] = dateString.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return (
      date.getFullYear() === Number(year) &&
      date.getMonth() === Number(month) - 1 &&
      date.getDate() === Number(day)
    );
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    setDisplayDate(inputDate);

    const parsedDate = moment(inputDate, 'MM/DD/YYYY', true);
    if (validDate(parsedDate)) {
      setInvalidDate(false);
      if (isDateInFuture(parsedDate)) {
        setSelectedDate(null);
        setFutureDate(true);
      } else {
        setSelectedDate(parsedDate.toDate());
        setFutureDate(false);
      }
    } else {
      setSelectedDate(null);
      setInvalidDate(true);
    }
  };

  const handleDateSelection = (
    date: Moment,
    event: React.MouseEvent | undefined
  ) => {
    if (event) {
      event.preventDefault();
    }
    const dateMoment = moment(date);
    if (isDateInFuture(dateMoment)) {
      setSelectedDate(null);
      setFutureDate(true);
    } else {
      setSelectedDate(dateMoment.toDate());
      setFutureDate(false);
    }
    setDisplayDate(dateMoment.format('MM/DD/YYYY'));
    setShowCalendar(false);
    setInvalidDate(false);
  };

  const handleClickOutsideCalendar = (event: MouseEvent) => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target as Node)
    ) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideCalendar);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCalendar);
    };
  }, []);

  return (
    <CalendarContainer>
      {showCalendar && (
        <CalendarOptions ref={calendarRef}>
          <DatePicker
            date={selectedDate?.toISOString()}
            setDate={handleDateSelection}
            onClickCancel={() => setShowCalendar(false)}
            onClickOk={() => setShowCalendar(false)}
            isFilter={true}
          />
        </CalendarOptions>
      )}
      <DateInput
        type="text"
        invalid={invalidDate}
        value={displayDate instanceof Date ? displayDate.toLocaleDateString() : displayDate || ''}
        onChange={handleDateChange}
        placeholder="MM/DD/YYYY"
      />
      <Icon onClick={handleCalendarButtonClick}>
        <Calendar />
      </Icon>

      <BorderLabel>{inputName}</BorderLabel>
      {invalidDate ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'center',
            marginTop: ' 1px'
          }}
        >
          <WarningMessage>
            <RedInfo />
           <WarningText>Invalid Date</WarningText>
          </WarningMessage>
        </div>
      ) : null}
      {futureDate ? (
         <WarningMessage>
         <RedInfo />
         <WarningText>{inputName} cannot be a future Date</WarningText>
       </WarningMessage>
      ) : null}
    </CalendarContainer>
  );
};

export default CustomDatePicker;
