import React from 'react';
import styled from 'styled-components';
import { DataTable } from 'grommet';

interface stylingProps {
  gapSize: string;
  footerChildSelector: string;
  numFooterCells: number;
}

interface column {
  align?: 'end' | 'start' | 'center' | undefined;
  aggregate?: 'max' | 'min' | 'sum' | 'avg' | undefined;
  footer?:
    | {
        aggregate: string | boolean;
      }
    | string;
  header: JSX.Element | string;
  primary?: boolean;
  property: string;
  render?: (datum: any) => JSX.Element;
  search?: boolean;
  sortable?: boolean;
  size?: string;
}

interface partialFooter {
  side: 'left' | 'right';
  numCells: number;
  gap?: boolean;
}

export interface dataTableProps {
  primaryKey?: string | boolean;
  columns: column[];
  data: any[];
  a11yTitle?: string;
  sortable?: boolean;
  size?: string;
  footerSize?: partialFooter;
  onClickRow?: (event: { datum?: any; index?: number }) => void;
}

const StyledTable = styled(DataTable).attrs(
  ({ gapSize, footerChildSelector, numFooterCells }: stylingProps) => ({
    // we can define static props
    gapSize: gapSize || '',

    // or we can define dynamic ones
    footerChildSelector: footerChildSelector || 'nth-child',

    numFooterCells: numFooterCells || 0
  })
)`
thead th span {
  font-size: 0.875rem;
}
tbody td,
tbody td > * {
  font-size: 1rem;
}

thead th {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
tbody td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

tfoot td {
  border: 0px;
}
& > tbody > tr:first-child > td {
  border: 0px;
}

& > tbody > tr:first-child > th {
  border: 0px;
}

& > tbody > tr:last-child > td {
  ${props => props.gapSize}
}

& > tbody > tr:last-child > th {
  ${props => props.gapSize}
}
tfoot td > * {
  font-weight: bold;
  font-size: 1rem;
}
tfoot th > * {
  font-size: 1rem;
}

tfoot tr td:${props => props.footerChildSelector}(-n + ${props =>
  props.numFooterCells}){
  background-color: #F4F6FA;
}

`;

export default ({
  columns,
  data,
  primaryKey,
  a11yTitle,
  sortable,
  size,
  footerSize,
  onClickRow
}: dataTableProps) => {

  let footerChildSelector = 'nth-child';
  if (footerSize?.side === 'right') {
    footerChildSelector = 'nth-last-child';
  }

  let gapSize = '';
  if (footerSize?.gap) {
    gapSize = 'padding-bottom: 20px;';
  }

  return (
    <StyledTable
      footerChildSelector={footerChildSelector}
      numFooterCells={footerSize?.numCells}
      onClickRow={onClickRow}
      gapSize={gapSize}
      primaryKey={primaryKey}
      columns={columns}
      size={size}
      data={data}
      background={{
        header: '#F5F7FA'
      }}
      border={{
        header: {
          size: '0px'
        },
        body: {
          color: '#D9DFEB',
          side: 'top',
          size: '1px'
        }
      }}
      a11yTitle={a11yTitle}
      sortable={sortable}
    />
  );
};
