const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency'
});

export default (amount: number | string | undefined | null) => {
  if (amount === undefined || amount === null) {
    return null;
  }

  if (['paid in full'].includes(amount?.toString()?.toLowerCase())) {
    return 'Paid in Full';
  }

  // Strip out leading dollar symbol and commas and cast to Number.
  let numAmount = Number(amount.toString().replace(/[$,]/g, ''));

  if (Number.isNaN(numAmount)) {
    return null;
  }

  // This treats the -0 edge case, which seems to be a valid number in JS
  if (numAmount === 0) {
    numAmount = Math.abs(numAmount);
  }

  return numAmount < 0
    ? `(${formatter.format(Math.abs(numAmount))})`
    : formatter.format(numAmount);
};
