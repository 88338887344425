import styled from 'styled-components';

export const OctoBannerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Octobanner = styled.div`
  margin: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 0px 0px;
  height: 76px;
  background: #e7f5fa;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
`;

export const ColorStrip = styled.div`
  width: 5px;
  height: 100%;
  background: #18a0cd;
  flex-grow: 0;
`;

export const InfoWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 13px;
`;

export const BannerTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

export const BannerTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0c5066;
  margin: 2px 0;
`;

export const BannerDescription = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: #0c5066;
  margin: 2px 0;
`;

export const BannerLinksWrap = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

export const BannerLinkDivider = styled.p`
  margin: 3px 0;
`;

export const BannerLink = styled.a`
  color: #0c5066;
  text-decoration: none;

  :first-child {
    padding: 4px;
    margin-right: 5px;
  }

  :last-child {
    padding: 4px;
    margin-left: 5px;
  }
`;

export const CloseWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px 0 10px;
  padding: 4px;
  cursor: pointer;
`;
