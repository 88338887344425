import { Box, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import { PolicyInfoAuto, PolicyInfoProperty, PolicyInfoUmbrella } from '.';
import { GET_POLICY_INFO_account_items_policy } from './graphql/__generated__/GET_POLICY_INFO';
import { GET_NEXT_PAYMENT_NO_MORTGAGEE_BILLED_account_items_pastDue } from './graphql/__generated__/GET_NEXT_PAYMENT_NO_MORTGAGEE_BILLED_DETAILS';
import { ContentsTag } from '../billingHistory';
import { StyledHR, Subtitle } from './PolicyInfoStyles';
import { formatDate } from '../../util/formatters';
import { Info, SmallInfo, Medical, BlueDownloadIcon } from '../../icons';
import PolicyInfoUmbrellaAssets from './PolicyInfoUmbrellaAssets';

const ContentPillsContainer = styled(Box)`
  flex-direction: row;

  div[data-test-id='policy-status-content-tag'] {
    padding: 3px 8px;
  }
`;
const TitleText = styled(Text)`
  font-size: 12px;
  font-weight: 700;
`;
const ContentText = styled(Text)`
  font-size: 16px;
`;

const ToolTipText = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: #0173c6;
`;

const PolicySpacing = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-around;
  top: 0%;
`;

const InfoPopUp = styled.span`
  visibility: hidden;
  background-color: #3e5572;
  width: 260px;
  color: white;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: -50px;
  border-radius: 3px;
  box-shadow: 0px 4px 6px -2px #002038;
  padding: 5px;
`;

const InfoContainer = styled.div`
  position: relative;
  &:hover ${InfoPopUp} {
    visibility: visible;
  }
`;

const PolicySummaryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2px;
`;

const PolicyBasicInfoContainer = styled.div`
  display: inline-flex;
  gap: 10px;
`;

export const StyledDownloadIconContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export interface PolicyInfoProps {
  isLoading?: boolean;
  error?: Error;
  information: GET_POLICY_INFO_account_items_policy;
  accountNumber: string;
  policyNumber: string;
  agencyBilled: boolean;
  onDocumentClick?: Function;
  lastDayToPayFlag: boolean;
  lastDayToPayDetails: GET_NEXT_PAYMENT_NO_MORTGAGEE_BILLED_account_items_pastDue;
}

export const PolicyInfo = ({
  isLoading,
  error,
  information,
  accountNumber,
  policyNumber,
  agencyBilled,
  onDocumentClick,
  lastDayToPayFlag,
  lastDayToPayDetails
}: PolicyInfoProps) => {
  if (isLoading) {
    return <p>Loading...</p>;
  }
  let displayUmbrellaAssets: boolean = false;
  const {
    // eslint-disable-next-line prefer-const
    policy_type: policyType,
    // eslint-disable-next-line prefer-const
    policy_number: policyNum,
    status
  } = information ?? {};
  let PolicyInformation;
  if (policyType?.toUpperCase()?.includes('AUTO')) {
    PolicyInformation = PolicyInfoAuto;
  } else if (
    ['UMBRELLA', 'PACKAGE'].includes(policyType?.toUpperCase() as string)
  ) {
    PolicyInformation = PolicyInfoUmbrella;
    displayUmbrellaAssets = true;
  } else {
    PolicyInformation = PolicyInfoProperty;
  }
  let color = '';

  const hasPolicyPassedLastDue =
    lastDayToPayDetails?.hasPastDue &&
    policyNumber?.includes(
      lastDayToPayDetails?.policy?.policy_number as string
    ) &&
    ['CANCELING'].includes(lastDayToPayDetails?.policy?.status as string);
  const lowerCaseStatus = status?.toLowerCase();
  if (
    ['in force', 'renewal', 'scheduled'].includes(lowerCaseStatus as string) &&
    !hasPolicyPassedLastDue
  ) {
    color = 'green';
  } else if (
    ['cancelled', 'expired'].includes(lowerCaseStatus as string) ||
    hasPolicyPassedLastDue
  ) {
    color = 'red';
  }

  return (
    <Box>
      <PolicySummaryHeader>
        <PolicyBasicInfoContainer>
          <Box alignSelf="start">
            <Subtitle>
              {policyType} Policy: {policyNum}
            </Subtitle>
          </Box>
          <ContentPillsContainer>
            <Box>
              {agencyBilled && (
                <ContentsTag color="purple" testId="agency-billed-content-tag">
                  Agency Billed
                </ContentsTag>
              )}
            </Box>
            <Box>
              <ContentsTag color={color} testId="policy-status-content-tag">
                {hasPolicyPassedLastDue && lastDayToPayFlag
                  ? 'Pending Cancellation'
                  : `${status}`}
              </ContentsTag>
            </Box>
          </ContentPillsContainer>
        </PolicyBasicInfoContainer>
        <StyledDownloadIconContainer
          onClick={() => {
            if (onDocumentClick) {
              onDocumentClick({
                policy_number: policyNumber,
                gtm_data: {
                  policyNumber
                }
              });
            }
          }}
        >
          <BlueDownloadIcon />
        </StyledDownloadIconContainer>
      </PolicySummaryHeader>
      <StyledHR />
      <PolicySpacing>
        <Box
          direction="row"
          fill
          align="start"
          justify="between"
          margin={{ top: '10px' }}
        >
          {policyType?.toUpperCase()?.includes('AUTO') ? (
            <Box direction="column" align="start" justify="between">
              <Box width="100%">
                {/* {information?.agent_record_name && (
                  <Box align="start" pad={{ top: '18px', bottom: '20px' }}>
                    <TitleText>Agent of Record</TitleText>
                    <ContentText>{information.agent_record_name}</ContentText>
                    <InfoContainer>
                      <ToolTipText>({information.agency_code})</ToolTipText>
                      <InfoPopUp>
                        <Box>
                          <Box direction="row" margin={{ left: '5px' }}>
                            <SmallInfo />
                            <Text
                              weight="bold"
                              margin={{ left: 'xsmall' }}
                              size="10pt"
                            >
                              Agency Code
                            </Text>
                          </Box>
                          <Text size="10pt" margin={{ left: '25px' }}>
                            The Agency Code for the Agent of Record.
                          </Text>
                        </Box>
                      </InfoPopUp>
                    </InfoContainer>
                  </Box>
                )} */}
                <PolicyInformation
                  accountNumber={accountNumber}
                  policyNumber={policyNumber}
                  information={information}
                  agencyBilled={agencyBilled}
                  onDocumentClick={onDocumentClick}
                  lastDayToPayFlag={lastDayToPayFlag}
                  lastDayToPayDetails={lastDayToPayDetails}
                />
              </Box>
            </Box>
          ) : (
            <Box direction="row" justify="between" flex>
              <Box width="100%">
                <PolicyInformation
                  accountNumber={accountNumber}
                  policyNumber={policyNumber}
                  information={information}
                  agencyBilled={agencyBilled}
                  onDocumentClick={onDocumentClick}
                  lastDayToPayFlag={lastDayToPayFlag}
                  lastDayToPayDetails={lastDayToPayDetails}
                />
              </Box>
              {/* {information?.agent_record_name && (
                <Box align="start" pad={{ top: '18px', right: '30px' }}>
                  <TitleText>Agent of Record</TitleText>
                  <ContentText>{information.agent_record_name}</ContentText>
                  <InfoContainer>
                    <ToolTipText>({information.agency_code})</ToolTipText>
                    <InfoPopUp>
                      <Box>
                        <Box direction="row" margin={{ left: '5px' }}>
                          <SmallInfo />
                          <Text
                            weight="bold"
                            margin={{ left: 'xsmall' }}
                            size="10pt"
                          >
                            Agency Code
                          </Text>
                        </Box>
                        <Text size="10pt" margin={{ left: '25px' }}>
                          The Agency Code for the Agent of Record.
                        </Text>
                      </Box>
                    </InfoPopUp>
                  </InfoContainer>
                </Box>
              )} */}
            </Box>
          )}
          <Box
            direction="row"
            align="center"
            justify="end"
            background="#F5F7FA"
            gap="30px"
            pad={{ top: '18px', bottom: '15px', left: '30px', right: '30px' }}
            max-width="260px"
          >
            <Box>
              <TitleText>Effective Date</TitleText>
              <ContentText>
                {formatDate(information?.effective_date)}
              </ContentText>
            </Box>
            <Box>
              <TitleText>Expiration Date</TitleText>
              <ContentText>
                {formatDate(information?.expiration_date)}
              </ContentText>
            </Box>
          </Box>
        </Box>
      </PolicySpacing>
      {displayUmbrellaAssets && (
        <PolicyInfoUmbrellaAssets
          accountNumber={accountNumber}
          policyNumber={policyNumber}
          information={information}
          agencyBilled={agencyBilled}
          onDocumentClick={onDocumentClick}
          lastDayToPayFlag={lastDayToPayFlag}
          lastDayToPayDetails={lastDayToPayDetails}
        />
      )}
    </Box>
  );
};
