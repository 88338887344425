import styled from 'styled-components';
export interface SAFooterProps {
  removeBranding?: boolean;
  CARightsRequired?: boolean;
  [additionalProps: string]: any;
}
export const Footer = styled.footer`
  ${(props: SAFooterProps) => {
    return `
      align-items: center;
     
      display: flex;
      flex-direction: row;
     
      justify-content: center;
      text-align: center;
      @media only screen and (max-width: 700px) {
        flex-direction: column;
      }
    `;
  }};
`;
export const PopUpLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  &: link;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vw;
`;
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LeftWrapper = styled.div`
  margin-left: 10px;
  align-self: center;
`;

export const RightWrapper = styled.div`
  align-items: center;
`;
export const Anchor = styled.a`
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #0173c6;
    text-decoration: underline;
  }
  &:active {
    color: darkviolet;
  }
`;
export const Span = styled.span`
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: black;
`;