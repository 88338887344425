import React from 'react';
import {
  Container,
  StyledInfo,
  BannerText
} from './NoIssuedPoliciesBannerStyles';

export default () => {
  return (
    <Container>
      <StyledInfo />
      <BannerText>This account does not have any issued policy.</BannerText>
    </Container>
  );
};
