import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import OverviewAndUpdates from './OverviewAndUpdates';

const getWCDetailsQuery = loader('./graphql/Get_WC_Overview.graphql');

export interface WCDetailsData {
  claim_status?: string;
  liability?: string;
  pk?: string;
  paymentSumDetails?: {
    expense_ptd?: number;
    indemnity_ptd?: number;
    legal_ptd?: number;
    medical_ptd?: number;
    voc_rehab_ptd?: number;
  };
  claimsReserveDetails?: {
    expense_reserves?: number;
    indemnity_reserves?: number;
    legal_reserves?: number;
    medical_reserves?: number;
    voc_rehab_reserves?: number;
  };
  claimInformation?: {
    agww?: number;
    claimtrid?: string;
    firstdayindemnity?: string;
    firstdayoflosttime?: string;
    mmidate?: string;
    primarydiagnosis?: string;
    returntoclinicactualflag?: string;
    returntoclinicdate?: string;
    returntoclinicprovider?: string;
    secondarydiagnosis?: string;
    treatingprovider?: string;
    ttdrate?: string;
    workstatus?: string;
    workstatusdate?: string;
  };
}

export interface OverviewProps {
  accountNumber: string;
  policyNumber: string;
  claimNumber: string | boolean;
}

const Overview: React.FC<OverviewProps> = ({
  accountNumber,
  policyNumber,
  claimNumber
}) => {
  const variables = {
    accountNumber,
    policyNumber,
    claimNumber
  };
  const {
    data: getWCDetailsResult,
    loading: wcDetailsResultLoading
  } = useQuery(getWCDetailsQuery, {
    variables
  });
  const getWCDetails = getWCDetailsResult?.getWCDetails || {};
  return (
    <OverviewAndUpdates
      getWCDetails={getWCDetails}
      loading={wcDetailsResultLoading || false}
    />
  );
};

export default Overview;
