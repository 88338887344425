import React from 'react';

import {
  MenuOptionWrap,
  MenuOptionText,
  MenuOptionChevron
} from './MenuOptionStyles';

interface Props {
  label: string;
  indent: boolean;
  light: boolean;
  chevron: boolean;
  chevronRight: boolean;
  active: boolean;
  onClick: Function;
}

const MenuOption = ({
  label,
  indent,
  light,
  chevron,
  chevronRight,
  active,
  onClick
}: Props) => {
  return (
    <MenuOptionWrap
      className={indent ? 'indent' : ''}
      onClick={() => onClick()}
    >
      <MenuOptionText className={light ? 'light' : ''}>{label}</MenuOptionText>
      {chevron && (
        <MenuOptionChevron
          className={`${active ? 'active' : ''} ${
            chevronRight ? 'chevronRight' : ''
          }`}
        />
      )}
    </MenuOptionWrap>
  );
};

export default MenuOption;
