import styled from 'styled-components';

interface YearProps {
  selected: boolean;
}

export const yearsContainerHeight: number = 280;
const yearMargin: number = 28;
export const yearLineHeight: number = 21;
export const yearHeight: number = yearLineHeight + yearMargin;

export const YearSelectionContainer = styled.div`
  height: ${yearsContainerHeight}px;
  overflow-y: scroll;
`;

export const Year = styled.div<YearProps>`
  cursor: pointer;
  margin-bottom: ${yearMargin}px;
  font-size: ${props => (props.selected ? '24' : '16')}px;
  line-height: ${yearLineHeight}px;
  text-align: center;
  color: #${props => (props.selected ? '0173C6' : '000000')};
`;
