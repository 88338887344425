import React from 'react';
import Search from '../assets/images/SearchIcon.svg';

interface searchIconProps {
  className?: any;
}

export default ({ className }: searchIconProps) => {
  return (
    <svg
      className={className}
      width="23px"
      height="23px"
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>SearchIcon</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Search/Icon/Search" stroke="#0173C6">
          <g id="SearchIcon">
            <rect
              id="Rectangle"
              strokeWidth="3"
              x="1.5"
              y="1.5"
              width="15"
              height="15"
              rx="7.5"
            />
            <rect
              id="Rectangle"
              fill="#0173C6"
              transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) "
              x="16.5"
              y="12.5"
              width="2"
              height="10"
              rx="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
