import React, { useState, useRef, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import {
  PaymentWrapper,
  PaymentContainer,
  NoPaymentPresent,
  SearchContainerWrapper,
  ClearFilterButton,
  FilterPaymentsButton,
  SearchContainer,
  ClearFilterButtonWrapper,
  FilterPaymentsButtonWrapper,
  InputContainer,
  Icon,
  OptionText,
  SelectedItem,
  DropdownContainer,
  DropdownOption,
  DropdownOptions,
  CustomInput,
  BorderLabelLocation,
  NavButton,
  SortContainer,
  IconContainer,
  NavButtonContainer
} from './PaymentsStyles';
import { PaymentDetails } from './graphql/Get_WC_CLAIM_PAYMENTS';

import {
  ChevronDownUnbolded,
  ChevronUp,
  PrimarySpinner,
  EmptyCheckBox,
  CheckedCheckBox,
  BlackCross,
  LeftArrow,
  RightArrow,
  ChevronRightArrow,
  ChevronLeftArrow,
  ChevronUpDisabled,
  ChevronDownDisabled
} from '../../../icons';
import { Spinner, Loading, Wrapper } from '../ExpandedViewStyles';
import PayeeDetails from './PaymentDetails';
const get_wc_claim_payments = loader('./graphql/Get_WC_Claim_Payments.graphql');

export interface PaymentsProps {
  active: boolean;
  accountNumber: string;
  policyNumber: string;
  claimNumber: string | boolean;
}

export interface MinMax<T> {
  min?: T;
  max?: T;
}

export interface WCClaimPaymentsFilters {
  payeeName?: string[];
  chargedToDate?: MinMax<number>;
  paidToDate?: MinMax<number>;
  checkNumber?: string;
  singleCharge?: MinMax<number>;
  singlePayment?: MinMax<number>;
  checkDate?: MinMax<string>;
  serviceDate?: MinMax<string>;
  status?: string[];
}

export interface VendorPaymentDetails {
  name?: string;
  chargedtodate?: number;
  paidtodate?: number;
  paymentDetails?: PaymentDetails[];
}

const Payments: React.FC<PaymentsProps> = ({
  accountNumber,
  policyNumber,
  claimNumber
}: PaymentsProps) => {
  const variables = {
    accountNumber,
    policyNumber,
    claimNumber
  };
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [paymentSummary, setPaymentSummary] = useState<PaymentDetails[] | null>(
    null
  );
  
  const [paymenList, setPaymentList] = useState<PaymentDetails[] | null>(null);
  
  const [asc, setAsc] = useState<boolean>(true);
  const [startIndex, setStartIndex] = useState(0);
  const totalPayments = paymentSummary ? paymentSummary.length : 0;
  const displayCount = 5;
  const lastPageIndex = Math.max(
    0,
    Math.ceil(totalPayments / displayCount) - 1
  );
  const [currentPage, setCurrentPage] = useState(0);
  const handleFirstPage = () => {
    setStartIndex(0);
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setStartIndex(lastPageIndex * displayCount);
    setCurrentPage(lastPageIndex);
  };

  const handleNext = () => {
    if (paymentSummary && startIndex + displayCount < paymentSummary.length) {
      setStartIndex(startIndex + displayCount);
      setCurrentPage((startIndex +displayCount)/displayCount);
    }
  };

  const handlePrev = () => {
    if (paymentSummary && startIndex - displayCount >= 0) {
      setStartIndex(startIndex - displayCount);
      setCurrentPage((startIndex -displayCount)/displayCount);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const { data: wcClaimPaymentsData, loading: dataLoading } = useQuery(
    get_wc_claim_payments,
    {
      variables
    }
  );

  useEffect(() => {
    if (wcClaimPaymentsData && wcClaimPaymentsData.getWCDetails) {
       setPaymentSummary(wcClaimPaymentsData.getWCDetails.paymentDetails);
       setPaymentList(wcClaimPaymentsData.getWCDetails.paymentDetails);
    }
  }, [wcClaimPaymentsData]);

  if (dataLoading) {
    return (
      <Wrapper>
        <Loading>
          <Spinner>
            <PrimarySpinner />
          </Spinner>
          Loading ...
        </Loading>
      </Wrapper>
    );
  }

  const resetFilters = () => {
    setSelectedOptions([]);
    setPaymentSummary(paymenList);
  };

  const filterData = () => {
    if (paymentSummary === null || paymentSummary === undefined) {
      return;
    }
    let filteredPayments = paymentSummary.filter(
      (payment: any) => payment.name && selectedOptions.includes(payment.name)
    );
    if (paymentSummary !== null && paymentSummary !== undefined) {
      setPaymentSummary(filteredPayments);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOptions(prevSelectedOptions => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter(
          selectedOption => selectedOption !== option
        );
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };
  const handleClose = (option: string) => {
    setSelectedOptions(prevSelectedOptions => {
      return prevSelectedOptions.filter(
        selectedOption => selectedOption !== option
      );
    });
  };

  const handleSort = () => {
    if (!paymentSummary) {
      return;
    }
    let sortedPaymentSummary;
    if (asc) {
      sortedPaymentSummary = [...paymentSummary].sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedPaymentSummary = [...paymentSummary].sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      });
    }
    setAsc(!asc);
    setPaymentSummary(sortedPaymentSummary);
  };

  let options = null;
  if (paymentSummary) {
    const uniqueOptions = new Set<string>();

    options = paymentSummary.map((obj: any, index: number) => {
      const optionValue = obj.name;
      if (!uniqueOptions.has(optionValue)) {
        uniqueOptions.add(optionValue);
        const isChecked = selectedOptions.includes(optionValue);

        return (
          <DropdownOption
            key={index}
            className={isChecked ? 'selected' : ''}
            onClick={() => handleOptionClick(optionValue)}
          >
            {isChecked ? <CheckedCheckBox /> : <EmptyCheckBox />}
            <OptionText>{optionValue}</OptionText>
          </DropdownOption>
        );
      }

      return null;
    });
  }
  return (
    <PaymentWrapper>
      <PaymentContainer>
        <SearchContainerWrapper>
          <SearchContainer>
            <DropdownContainer ref={dropdownRef}>
              <InputContainer>
                <CustomInput
                  className={isOpen ? 'open' : ''}
                  onClick={toggleDropdown}
                >
                  {selectedOptions.map(option => (
                    <SelectedItem>
                      {option}
                      <span onClick={() => handleClose(option)}>
                        <BlackCross />
                      </span>
                    </SelectedItem>
                  ))}
                </CustomInput>

                <Icon>{isOpen ? <ChevronUp /> : <ChevronDownUnbolded />}</Icon>
                <BorderLabelLocation>Payee Name</BorderLabelLocation>
              </InputContainer>
              {isOpen && <DropdownOptions>{options}</DropdownOptions>}
            </DropdownContainer>
            <ClearFilterButtonWrapper>
              <ClearFilterButton onClick={resetFilters}>
                Clear Filter
              </ClearFilterButton>
            </ClearFilterButtonWrapper>
            <FilterPaymentsButtonWrapper>
              <FilterPaymentsButton onClick={filterData}>
                Filter Payments
              </FilterPaymentsButton>
            </FilterPaymentsButtonWrapper>
          </SearchContainer>
        </SearchContainerWrapper>

        <SearchContainer>
          <SortContainer onClick={handleSort}>
            <span>Sort: Payee A-Z</span>
            <IconContainer>
              {asc ? <ChevronDownDisabled /> : <ChevronUpDisabled />}
            </IconContainer>
          </SortContainer>

          <NavButtonContainer>
            <NavButton>
              {currentPage + 1} of {lastPageIndex + 1}
            </NavButton>
            <NavButton onClick={handleFirstPage}>
              <LeftArrow />
            </NavButton>
            <NavButton onClick={handlePrev}>
              <ChevronLeftArrow />
            </NavButton>
            <NavButton onClick={handleNext}>
              <ChevronRightArrow />
            </NavButton>
            <NavButton onClick={handleLastPage}>
              <RightArrow />
            </NavButton>
          </NavButtonContainer>
        </SearchContainer>
        {paymentSummary &&
        paymentSummary.slice(startIndex, startIndex + displayCount).length >
          0 ? (
          paymentSummary
            .slice(startIndex, startIndex + displayCount)
            .map((pay: any) => <PayeeDetails pay={pay} />)
        ) : (
          <NoPaymentPresent>
            No payments found. If filters are applied, try expanding your search
            criteria.
          </NoPaymentPresent>
        )}
      </PaymentContainer>
    </PaymentWrapper>
  );
};

export default Payments;
