import React, { useState } from 'react';
import {
  Container,
  Form,
  DropdownContainer,
  DropdownToggle,
  DropdownMenu,
  DropdownMenuItem,
  FilterBox,
  Reset,
  CenteredDiv,
  ArrowContainer,
  SortIcon
} from './SearchAndFilterWCClaimsStyles';
import { colors } from '../../themes/default.theme';
import {
  VectorUpArrow,
  VectorDownArrow,
  UpArrow,
  DownArrow
} from '../../icons';
import SearchWCClaims from './SearchWCClaims';
import AdvancedFilter from '../wcAdvancedFilter/AdvancedFilter';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
export interface SearchAndFilterWCClaimsProps {
  onSearch: (searchTerm: string) => void;
  searchBarPosition: boolean;
  handleReset: () => void;
  viewCloseIcon: boolean;
  setViewCloseIcon: React.Dispatch<React.SetStateAction<boolean>>;
  claimsList: ClaimsData[] | null;
  updateClaimsList: (newClaimsList: ClaimsData[] | null) => void;
  setSearchResults: React.Dispatch<React.SetStateAction<ClaimsData[] | null>>;
  setEmptySearchResults: React.Dispatch<React.SetStateAction<boolean>>;
}
const dropdownItems = [
  { label: 'Claim Number', value: 'claimnumber' },
  { label: 'Claim Status', value: 'claim_status' },
  { label: 'Date Of Injury', value: 'dateofloss' },
  { label: 'Injured Name', value: 'name' }
];
const SearchFilterWCClaims = ({
  onSearch,
  searchBarPosition,
  handleReset,
  viewCloseIcon,
  setViewCloseIcon,
  claimsList,
  updateClaimsList,
  setSearchResults,
  setEmptySearchResults
}: SearchAndFilterWCClaimsProps) => {
  const [arrow, setArrow] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Claim Number');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleMenuItemClick = (menuItem: string) => {
    setSelectedItem(menuItem);
    setIsOpen(false);
  };
  const handleSort = () => {
    const selectedItemObj = dropdownItems.find(
      item => item.label === selectedItem
    );
    if (selectedItemObj && Array.isArray(claimsList)) {
      const sortedList = [...claimsList];
      const customSort = (a: ClaimsData, b: ClaimsData) => {
        let valueA, valueB;
        switch (selectedItemObj.value) {
          case 'claimnumber':
            valueA = a.claimnumber;
            valueB = b.claimnumber;
            break;
          case 'claim_status':
            valueA = a.claim_status;
            valueB = b.claim_status;
            break;
          case 'dateofloss':
            valueA = a.dateofloss;
            valueB = b.dateofloss;
            break;
          case 'name':
            valueA = a.name;
            valueB = b.name;
            break;
          default:
            return 0;
        }
        if (
          (typeof valueA === 'string' || typeof valueA === 'number') &&
          (typeof valueB === 'string' || typeof valueB === 'number')
        ) {
          if (valueA < valueB) {
            return arrow ? -1 : 1;
          }
          if (valueA > valueB) {
            return arrow ? 1 : -1;
          }
        }
        return 0;
      };

      sortedList.sort(customSort);
      updateClaimsList(sortedList);
    }
    setArrow(prevState => !prevState);
  };

  return (
    <Form color={colors.darkBlue2}>
      <SearchWCClaims
        onSearch={onSearch}
        searchBarPosition={searchBarPosition}
        handleReset={handleReset}
        viewCloseIcon={viewCloseIcon}
        setViewCloseIcon={setViewCloseIcon}
        claimsList={claimsList}
        setSearchResults={setSearchResults}
        setEmptySearchResults={ setEmptySearchResults}
      />
      <Container>
        <Reset onClick={handleReset} color={colors.darkBlue2}>
          Reset Search
        </Reset>
        <CenteredDiv>
          <AdvancedFilter
            searchBarPosition={searchBarPosition}
            claimsList={claimsList}
            setSearchResults={setSearchResults}
            setEmptySearchResults={ setEmptySearchResults}
          />
        </CenteredDiv>
        <FilterBox>
          <CenteredDiv>
            {arrow ? (
              <ArrowContainer onClick={() => handleSort()}>
                <UpArrow />
              </ArrowContainer>
            ) : (
              <ArrowContainer onClick={() => handleSort()}>
                <DownArrow />
              </ArrowContainer>
            )}
          </CenteredDiv>
          <DropdownContainer color={colors.darkBlue2}>
            <DropdownToggle onClick={toggleDropdown}>
              <b>
                Sort: {selectedItem}
              </b>
             <SortIcon> {isOpen ? <VectorUpArrow /> : <VectorDownArrow />}</SortIcon>
            </DropdownToggle>
            <DropdownMenu open={isOpen}>
              {dropdownItems.map(item => (
                <DropdownMenuItem
                  key={item.label}
                  onClick={() => handleMenuItemClick(item.label)}
                >
                  {item.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          </DropdownContainer>
        </FilterBox>
      </Container>
    </Form>
  );
};

export default SearchFilterWCClaims;
