import React from 'react';
import {
  StyledQuoteHit,
  StyledSearchResult,
  LeftColumnQuoteSearch,
  StyledName,
  MiddleColumnQuoteSearch,
  StyledGeneralTextFields,
  RightColumnQuoteSearch,
  StyledQuoteNumberAndPolicyNumberRow,
  StyledPolicyNumbersColumn,
  StyledBoldRightColumnHeaders,
  StyledEffectiveDateDiv,
  StyledQuoteNumberAndPolicyNumberColumn
} from './QuoteSearchStyled';
import { SearchResultProps } from './SearchDisplayInterfaces';
import { generateDisplayTextJSX } from './helpers/searchHelperfunctions';
import { formatMoney, formatDate } from '../../util/formatters';
import QuotePill from './QuotePill';

export default ({
  result,
  index,
  displayOptionsPolicyNumbers,
  policyNumbers
}: SearchResultProps) => {
  return (
    <StyledQuoteHit>
      <StyledSearchResult index={index}>
        <LeftColumnQuoteSearch>
          <StyledName>
            {generateDisplayTextJSX(false, result.name, result.displayableName)}
          </StyledName>
          <QuotePill result={result} />
        </LeftColumnQuoteSearch>
        <MiddleColumnQuoteSearch>
          <StyledGeneralTextFields>
            {result.lineOfBusiness}
          </StyledGeneralTextFields>
          <StyledGeneralTextFields>
            {generateDisplayTextJSX(
              true,
              result.address,
              result.displayableAddress
            )}
          </StyledGeneralTextFields>
        </MiddleColumnQuoteSearch>
        <RightColumnQuoteSearch>
          <StyledQuoteNumberAndPolicyNumberRow>
            <StyledPolicyNumbersColumn>
              <StyledBoldRightColumnHeaders>
                Quote #:
              </StyledBoldRightColumnHeaders>
              <StyledBoldRightColumnHeaders>
                Policy #:
              </StyledBoldRightColumnHeaders>
              {result.status !== 'Quote Declined' &&
                result.status !== 'Issuance In-Progress' && (
                  <StyledBoldRightColumnHeaders>
                    Premium:
                  </StyledBoldRightColumnHeaders>
                )}
              <StyledEffectiveDateDiv>Effective Date:</StyledEffectiveDateDiv>
            </StyledPolicyNumbersColumn>
            <StyledQuoteNumberAndPolicyNumberColumn>
              <StyledGeneralTextFields>
                {result.quoteNumber
                  ? generateDisplayTextJSX(
                      true,
                      result.quoteNumber,
                      result.displayableQuoteNumber
                    )
                  : '-'}
              </StyledGeneralTextFields>
              <StyledGeneralTextFields>
                {displayOptionsPolicyNumbers.map(displayOption =>
                  generateDisplayTextJSX(true, '', displayOption)
                )}
                {policyNumbers.map(policyNumber =>
                  generateDisplayTextJSX(true, policyNumber)
                )}
              </StyledGeneralTextFields>
              {result.status !== 'Quote Declined' &&
                result.status !== 'Issuance In-Progress' && (
                  <StyledGeneralTextFields>
                    {typeof result.premium === 'number' &&
                    !Number.isNaN(result.premium)
                      ? formatMoney(result.premium)
                      : '-'}
                  </StyledGeneralTextFields>
                )}
              <StyledGeneralTextFields>
                {result.quoteEffectiveDate
                  ? formatDate(result.quoteEffectiveDate)
                  : '-'}
              </StyledGeneralTextFields>
            </StyledQuoteNumberAndPolicyNumberColumn>
          </StyledQuoteNumberAndPolicyNumberRow>
        </RightColumnQuoteSearch>
      </StyledSearchResult>
    </StyledQuoteHit>
  );
};
