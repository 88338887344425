import getConfig, { Env } from '../config';

declare global {
  class chatWidget {
    static loadChatWidget: Function;
    static hide: Function;
    static show: Function;
  }
}

export interface CognitoUser {
  username: string;
  attributes: CognitoAttributes
}

interface CognitoAttributes {
  email: string;
  name: string;
  given_name: string;
}

const config = getConfig(process.env.REACT_APP_ENV as Env);
let script: HTMLScriptElement;
class FreshChat {
  static initialize = (user: CognitoUser) => {
    const userId = user.username.replace('EmpowerId_', '');
    const loadScript = new Promise(function(resolve, reject) {
      script = document.createElement('script');
      script.src = config.chat_widget;
      script.addEventListener('load', function() {
        resolve(true);
      });
      script.addEventListener('error', function(e: ErrorEvent) {
        reject(e);
      });
      document.body.appendChild(script);
    });

    loadScript.then(() => {
      chatWidget.loadChatWidget({
        debug: true,
        userId: userId,
        token: config.freshchat_token,
        siteId: 'Agency360',
        email: user.attributes.email,
        firstName: user.attributes.name,
        lastName: user.attributes.given_name
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  };

  static hide = () => {
    if (script) {
      chatWidget.hide();
    }
  };

  static show = () => {
    if (script) {
      chatWidget.show();
    }
  };
}

export { FreshChat };
