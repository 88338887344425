import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import {
  BillingInfo as LastPayment,
  PaymentInfo as NextPayment,
  CancelPayment
} from '..';

import { colors } from '../../themes/default.theme';
import PrimaryButton from '../PrimaryButton';
import MakePaymentOptionsWrapper from '../makePaymentOptionsWrapper';
import OutageModal from '../outageModal';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
`;

const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.lightGray1};
`;

const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${colors.lightGray1};
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-align: center;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface BillingPaymentProps extends RouteComponentProps {
  lastDayToPayFlag: boolean;
  makePaymentClick: Function;
  allCancelled?: boolean;
  billingCenterOutage: boolean;
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
}

export default (props: BillingPaymentProps) => {
  const {
    lastDayToPayFlag,
    billingCenterOutage,
    makePaymentClick,
    allCancelled,
    readOnlyAccess,
    readOnlyFeatureFlag
  } = props;
  const [PIF, setPIF] = useState(false);

  const [displayMakePaymentOutage, setDisplayMakePaymentOutage] = useState<
    boolean
  >(false);

  if (lastDayToPayFlag) {
    return (
      <>
        <NextPayment page="billing" {...props} paidInFull={setPIF} />
        <LastPayment {...props} />
      </>
    );
  }

  return (
    <Box direction="row" gap="15px" fill>
      <Column>
        <Box pad={{ bottom: '20px' }}>
          <Text color={colors.darkBlue2} weight="bold">
            Next Payment
          </Text>
          <HorizontalDivider />
        </Box>
        <NextPayment
          page="billing"
          {...props}
          paidInFull={setPIF}
          readOnlyAccess={readOnlyAccess}
          readOnlyFeatureFlag={readOnlyFeatureFlag}
        />
      </Column>
      <VerticalDivider />
      <Column>
        <Box>
          <Box pad={{ bottom: '20px' }}>
            <Text color={colors.darkBlue2} weight="bold">
              Last Payment
            </Text>
            <HorizontalDivider />
          </Box>
          <LastPayment {...props} />
          <HorizontalDivider />
        </Box>
        <ButtonBox>
          <CancelPayment />
          {PIF && allCancelled ? (
            <div />
          ) : (
            <MakePaymentOptionsWrapper
              page="billing"
              noModalClickHandler={makePaymentClick}
              readOnlyAccess={readOnlyAccess}
              readOnlyFeatureFlag={readOnlyFeatureFlag}
            >
              <StyledPrimaryButton
                alignSelf="end"
                onClick={(event: MouseEvent) => {
                  if (billingCenterOutage) {
                    event.stopPropagation();
                    setDisplayMakePaymentOutage(true);
                  }
                }}
                disabled={readOnlyAccess && readOnlyFeatureFlag}
              >
                MAKE A PAYMENT
              </StyledPrimaryButton>
            </MakePaymentOptionsWrapper>
          )}
        </ButtonBox>
      </Column>
      {displayMakePaymentOutage && (
        <OutageModal
          title="Making a Payment is Unavailable"
          onClose={() => setDisplayMakePaymentOutage(false)}
        >
          Unfortunately, due to server maintenance, making your payment is
          currently unavailable. Please check back in 24 to 48 hours.
        </OutageModal>
      )}
    </Box>
  );
};