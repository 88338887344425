import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { Auth } from 'aws-amplify';
import { AuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import FeatureFlag from '../../components/featureFlags';
import {
  RecentClaimsContainer,
  ClaimsDetailContainer,
  LossRunRequest
} from '../../components';
import { RecentClaimData } from '../../components/recentClaimsContainer/RecentClaimsContainer';
import { customBase } from '../../themes/default.theme';
import { ClaimsDetailContainerProps } from '../../components/claimsDetailContainer/ClaimsDetailContainer';
import ClaimsPageEmptyState from '../../components/claimsPageEmptyState';
import getConfig, { Env } from '../../config';
import PrimaryButton from '../../components/PrimaryButton/primaryButton';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import ClaimsSelectAPolicyDropdown from '../../components/claimsSelectAPolicyDropdown';
import WcClaimsTab from './WcClaim';
import ViewWcButton from './ViewWcButton';
const config = getConfig(process.env.REACT_APP_ENV as Env);

const gridPadding = '1.25rem';
const xlargeBreakpoint = `${customBase.global.breakpoints.xlarge.value}px`;
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  aeflex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-bottom: ${gridPadding};
  }
  @media (min-width: ${xlargeBreakpoint}) {
    & > div:last-child {
      margin-bottom: 0;
    }
  }
`;

const Column1 = styled(Column)`
  @media (min-width: ${xlargeBreakpoint}) {
    width: calc(calc(7 / 24 * 100%) - calc(${gridPadding} / 2));
    margin-right: 20px;
  }
  & > div:first-child {
    margin-bottom: 3px;
  }
`;

export const Column2 = styled(Column)`
  & > div:last-child {
    margin-bottom: 0;
  }
  @media (min-width: ${xlargeBreakpoint}) {
    width: calc(calc(17 / 24 * 100%) - calc(${gridPadding} / 2));
    margin-right: 0px;
  }
`;

const ClaimsPageEmptyStateBox = styled(Box)`
  margin-left: auto;
  margin-top: 50px;
`;

const NewClaim = styled(PrimaryButton)`
  padding: 8px;
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  background-color: #4d840b;
`;

export interface ClaimsPageProps extends ClaimsDetailContainerProps {
  loading: boolean;
  error: Error | undefined;
  recentClaimsList: RecentClaimData[];
  selected: string;
  setSelected: Function;
  loadingIncidentStatus: string;
  UserDisplayName: string;
  /* readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean; */
}
export default ({
  loading,
  error,
  recentClaimsList,
  claimsIncidentsList,
  selected,
  setSelected,
  loadingIncidentStatus,
  UserDisplayName
}: ClaimsPageProps) => {
  const [wcClaim, setWcClaim] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWCClaims, setShowWCClaims] = useState(false);
  const url = config.aws_appsync_graphqlClaimsEndpoint;
  const region = config.aws_appsync_region;
  const auth = {
    type: config.aws_appsync_authenticationType,
    jwtToken: () =>
      Auth.currentSession()
        .then(session => session.getIdToken().getJwtToken())
        .catch(err => {
          console.error('error', err);
        })
  } as AuthOptions;

  const appSyncLinkConfig = { url, region, auth };
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      new AuthLink(appSyncLinkConfig),
      createSubscriptionHandshakeLink(
        appSyncLinkConfig,
        // This becomes the terminating-link
        new HttpLink({ uri: url })
      )
    ])
  });

  const showwcClaimHandler = () => {
    setShowWCClaims(true);
  };

  const showOtherClaimHandler = () => {
    setShowWCClaims(false);
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }
  if (error) {
    return <Text>Error, please refresh and try again.</Text>;
  }
  if (showWCClaims) {
    return (
      <ApolloProvider client={client}>
        <WcClaimsTab showOtherClaimHandler={showOtherClaimHandler} />
      </ApolloProvider>
    );
  }
  if (!recentClaimsList.length) {
    return (
      <ClaimsPageEmptyStateBox>
        <ClaimsPageEmptyState
          UserDisplayName={UserDisplayName}
          showwcClaimHandler={showwcClaimHandler}
          setWcClaim={setWcClaim}
          wcClaim={wcClaim}
        />
      </ClaimsPageEmptyStateBox>
    );
  }
  return (
    <>
      {showWCClaims ? (
        <ApolloProvider client={client}>
          <WcClaimsTab showOtherClaimHandler={showOtherClaimHandler} />
        </ApolloProvider>
      ) : (
        <LayoutContainer>
          <ClaimsSelectAPolicyDropdown
            showModal={showModal}
            setShowModal={setShowModal}
            setWcClaim={setWcClaim}
          />
          <Column1>
            <Box>
              <ViewWcButton
                showwcClaimHandler={showwcClaimHandler}
                wcClaim={wcClaim}
              />
              <NewClaim
                onClick={() => {
                  simpleGTMDataLayer({
                    event: 'click-claimsNewClaim'
                  });
                  setShowModal(true);
                }}
              >
                START A NEW CLAIM
              </NewClaim>
              <FeatureFlag flagName="LossRunRequest">
                <LossRunRequest screenPath="claims" />
              </FeatureFlag>
            </Box>
            <RecentClaimsContainer
              list={recentClaimsList}
              changeSelected={setSelected}
              selected={selected}
            />
          </Column1>
          <Column2>
            {loadingIncidentStatus !== '' ? (
              <Text>{loadingIncidentStatus}</Text>
            ) : (
              <ClaimsDetailContainer
                claimsIncidentsList={claimsIncidentsList}
              />
            )}
          </Column2>
        </LayoutContainer>
      )}
    </>
  );
};
