import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useParams, RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { Box } from 'grommet';
import { PolicyInformationPage, PolicyInformationPageProps } from '.';
import { accountHasAgencyBilled } from '../../util/agencyBilledUtils';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useGlobalContext } from '../../util/globalContextProvider';

const get_payplan_billing_method = loader(
  './graphql/Policy_Info_Page_Get_Policy_PayPlan_Billing_Method.graphql'
);

const get_payasyougo = loader(
  './graphql/Policy_Info_Page_Get_Policy_PayAsYouGo.graphql'
);

const get_policy_type = loader('./graphql/Get_Policy_Type.graphql');

const get_outage_banner = loader('./graphql/Get_Outage_Banner.graphql');

export default (_: RouteComponentProps) => {
  const { policyNumber, accountNumber } = useParams();
  const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();
  const [gtm, setGTM] = useState(false);

  const {
    data: getPayPlanBillingMethodData,
    error: getPayPlanBillingMethodError
  } = useQuery(get_payplan_billing_method, {
    variables: {
      account_number: accountNumber
    }
  });

  const { data: getPasAsYouGoData, error: getPasAsYouGoError } = useQuery(
    get_payasyougo,
    {
      variables: {
        account_number: accountNumber
      }
    }
  );

  const { data: policyTypeData, error: policyTypeError } = useQuery(
    get_policy_type,
    {
      variables: { account_number: accountNumber, policy_number: policyNumber }
    }
  );

  const { data: outageBannerMessage, error: outageBannerError } = useQuery(
    get_outage_banner,
    {
      variables: { policy_number: policyNumber, account_number: accountNumber }
    }
  );

  useEffect(() => {
    if (
      outageBannerMessage &&
      outageBannerMessage?.account?.items?.policy?.outageBannerByPolicyNumber
        ?.result
    ) {
      sessionStorage.setItem(
        'outageBannerMessage',
        outageBannerMessage.account.items.policy.outageBannerByPolicyNumber
          .result
      );
    } else {
      sessionStorage.removeItem('outageBannerMessage');
    }
  }, [outageBannerMessage]);

  const props: PolicyInformationPageProps = {
    policyNumber,
    accountNumber,
    payAsYouGo: getPasAsYouGoData,
    agencyBilled: accountHasAgencyBilled(getPayPlanBillingMethodData),
    policyType: policyTypeData
      ? policyTypeData.account?.items[0]?.policy?.policy_type
      : '',
    readOnlyAccess: readOnlyAccess ?? false,
    readOnlyFeatureFlag: readOnlyFeatureFlag ?? false
  };

  if (!gtm) {
    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Policy Information',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });
    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Policy Information',
      event_category: localStorage.getItem('lob')
        ? `${localStorage.getItem('lob')}/Snowplow`
        : '/Snowplow'
    });

    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/account/policy',
        title: 'SA360: Policy'
      }
    });
    setGTM(true);
  }

  return (
    <Box>
      <PolicyInformationPage {...props} />
    </Box>
  );
};
