import React from 'react';
import styled from 'styled-components';
import { CardLayout } from '../cardLayout/CardLayout';
import QuotePremium from '../quotePremium';
import { QuoteAccountDetails } from '..';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
`;

const BoundingBox = styled.div`
  max-width: 813px;
`;

export default () => {
  return (
    <BoundingBox>
      <CardLayout title="Quote" test-attr="quote-account-info-card">
        <Container>
          <QuoteAccountDetails />
          <QuotePremium />
        </Container>
      </CardLayout>
    </BoundingBox>
  );
};
