import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { useLazyQuery } from 'react-apollo';
import { CancelPolicy } from '.';
import {
  CancelPolicyAccordionWrap,
  CancelPolicyAccordionHead,
  HeaderIconWrap,
  HeaderIcon,
  HeaderTitleWrap,
  HeaderTitle,
  HeaderChevronWrap,
  CancelPolicyAccordionBody,
  DisabledText
} from './cancelPolicyStyles';
import CancelPolicyIconSrc from '../../assets/images/CancelPolicy.svg';
import {
  ChevronUp,
  ChevronDownUnbolded,
  Warning,
  CancelPolicyDisabled,
  ChevronDownDisabled
} from '../../icons';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';

const upload_policy_document_mutation = loader(
  './graphql/Cancel_Policy_Upload_Policy_Document.graphql'
);
const get_cancel_policy_info = loader(
  './graphql/Get_Cancel_Policy_Info.graphql'
);
const cancel_policy = loader('./graphql/Cancel_Policy.graphql');

export interface cancelPolicyProps {
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

export default ({ readOnlyAccess, readOnlyFeatureFlag }: cancelPolicyProps) => {
  const { policyNumber, accountNumber } = useParams();
  const [uploadDoc, { error }] = useMutation(upload_policy_document_mutation);
  const [cancelPolicy, { data: cancelData, error: cancelError }] = useMutation(
    cancel_policy
  );
  const { flagDetails: policyCenterOutageFlagDetails } = useFeatureFlag(
    'PolicyCenter-Outage'
  );
  const cancelPolicyInfoResult = useQuery(get_cancel_policy_info, {
    variables: {
      account_number: accountNumber,
      policy_number: policyNumber,
      refund_method: 'Pro Rata',
      description: "Insured's Request",
      holiday: false,
      request_source: 'Insured',
      cancel_date: null
    }
  });
  const [getAcceptance, acceptanceIndicatorResponse] = useLazyQuery(
    get_cancel_policy_info
  );
  let acceptable = false;
  const {
    data: acceptanceData,
    loading: acceptanceLoading
  } = acceptanceIndicatorResponse;
  if (acceptanceIndicatorResponse) {
    acceptable =
      acceptanceData?.account?.items[0]?.policy?.valid_policy_cancellation
        ?.cancellationAcceptance;
  }

  const { data, loading: dataLoading } = cancelPolicyInfoResult;
  let status = '';
  let code = 'GWCL';
  let cancelAvailable = false;
  let maxDate = '';
  let minDate = '';
  let payplans = [];
  let product = '';
  let openChanges = false;
  if (data && !dataLoading) {
    status = data?.account?.items[0]?.policy?.status;
    code = data?.account?.items[0].source_system_code;
    cancelAvailable =
      data?.account?.items[0].policy?.valid_policy_cancellation
        ?.cancellationEligibility;
    maxDate =
      data?.account?.items[0].policy?.valid_policy_cancellation
        ?.maxEffectiveDate || data?.account?.items[0].policy?.expiration_date;
    minDate =
      data?.account?.items[0].policy?.valid_policy_cancellation
        ?.minEffectiveDate;
    payplans = data?.account?.items[0]?.payplans.items;
    product = data?.account?.items[0]?.policy?.product_code;
    openChanges =
      data?.account?.items[0].policy?.valid_policy_cancellation?.openChanges;
  }
  let billing = '';
  if (payplans.length > 0) {
    if (payplans.length === 1) {
      billing = payplans[0].billing_method;
    } else {
      let i = 0;
      for (i = 0; i < payplans.length; i += 1) {
        if (payplans[i].policy_number === policyNumber) {
          billing = payplans[i].billing_method;
        }
      }
    }
  }
  const [isOpen, setIsOpen] = useState(false);
  if (
    product === 'COMMERCIAL UMBRELLA' ||
    status.toUpperCase() === 'CANCELING'
  ) {
    return (
      <CancelPolicyAccordionWrap>
        <CancelPolicyAccordionHead
          disabled={readOnlyAccess && readOnlyFeatureFlag}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <HeaderIconWrap>
            <>
              {readOnlyAccess && readOnlyFeatureFlag ? (
                <CancelPolicyDisabled />
              ) : (
                <HeaderIcon src={CancelPolicyIconSrc} />
              )}
            </>
          </HeaderIconWrap>
          <HeaderTitleWrap>
            <HeaderTitle>CANCEL THIS POLICY</HeaderTitle>
            <HeaderChevronWrap>
              {isOpen ? (
                <ChevronUp />
              ) : (
                <>
                  {readOnlyAccess && readOnlyFeatureFlag ? (
                    <ChevronDownDisabled />
                  ) : (
                    <ChevronDownUnbolded />
                  )}
                </>
              )}
            </HeaderChevronWrap>
          </HeaderTitleWrap>
        </CancelPolicyAccordionHead>
        <CancelPolicyAccordionBody isOpen={isOpen}>
          <DisabledText>
            <Warning />
            {product === 'COMMERCIAL UMBRELLA'
              ? `Canceling this policy online is currently unavailable at this time. Please contact Underwriting with your request.`
              : `The policy is pending cancellation for non-payment and no changes can be made at this time.`}
          </DisabledText>
        </CancelPolicyAccordionBody>
      </CancelPolicyAccordionWrap>
    );
  }
  if (status === 'Cancelled' || status === 'Expired' || !cancelAvailable) {
    return <div />;
  }

  return (
    <CancelPolicy
      upload={uploadDoc}
      uploadError={error}
      policyNumber={policyNumber}
      systemCode={code}
      cancel={cancelPolicy}
      cancelData={cancelData}
      cancelError={cancelError}
      minDate={minDate}
      maxDate={maxDate}
      agencyBill={billing === 'Agency Bill'}
      product={product}
      getAcceptance={getAcceptance}
      acceptable={acceptable}
      openChanges={openChanges}
      accountNumber={accountNumber}
      acceptanceLoading={acceptanceLoading}
      pcOutage={policyCenterOutageFlagDetails?.enabled}
      readOnlyAccess={readOnlyAccess}
      readOnlyFeatureFlag={readOnlyFeatureFlag}
    />
  );
};
