import React from 'react';

import { Menu } from 'grommet';
import styled from 'styled-components';
import accountMenu from '../../assets/images/AccountMenuIcon.svg';
import getConfig, { Env } from '../../config';

const config = getConfig(process.env.REACT_APP_ENV as Env);

export const ProfileMenu = styled(Menu)`
  &:disabled {
    cursor: not-allowed;
  }
`;
export interface accountDropDownProps {
  readOnlyAccess: boolean | undefined;
  readOnlyFeatureFlag: boolean | undefined;
}

export default ({
  readOnlyAccess,
  readOnlyFeatureFlag
}: accountDropDownProps) => {
  return (
    <ProfileMenu
      alignSelf="center"
      dropProps={{ align: { top: 'bottom', left: 'left' } }}
      items={[
        {
          label: 'Connect',
          onClick: () => {
            return window.location.assign(config.saconnect_url_endpoint);
          }
        }
      ]}
      icon={<img src={accountMenu} alt="" />}
      disabled={readOnlyAccess && readOnlyFeatureFlag}
    />
  );
};
