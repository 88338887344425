import styled from 'styled-components';

interface YearProps {
  selected: boolean;
}

export const yearsContainerHeightFilter: number = 140;
const yearMargin: number = 14;
export const yearLineHeightFilter: number = 10.5;
export const yearHeightFilter: number = yearLineHeightFilter + yearMargin;

export const YearSelectionContainerFilter = styled.div`
  height: ${yearsContainerHeightFilter}px;
  overflow-y: scroll;
`;

export const YearFilter = styled.div<YearProps>`
  cursor: pointer;
  margin-bottom: ${yearMargin}px;
  font-size: ${props => (props.selected ? '15' : '13')}px;
  line-height: ${yearLineHeightFilter}px;
  text-align: center;
  color: #${props => (props.selected ? '0173C6' : '000000')};
`;
