import React from 'react';
import { Box, Text, ResponsiveContext, Button } from 'grommet';
import styled from 'styled-components';
import { GET_BILLING_HISTORY_account_items_invoices_items } from './graphql/__generated__/GET_BILLING_HISTORY';
import { formatDate } from '../../util/formatters';
import DownloadIcon from '../../assets/images/DownloadIcon.svg';
import { ExpandingTable } from '.';
import usePagination from '../../util/usePagination';

export interface displayableInvoice {
  index: number;
  __typename: 'InsuredInvoice';
  invoice_number: string | null;
  due_date: JSX.Element | string | null;
  contents: string[];
  docuid: JSX.Element | string;
  invoice_amount: number | null;
  paid_amount: number | null;
  amount_due: number | null;
}

export interface BillingHistoryProps {
  loading?: boolean;
  error?: Error;
  invoices: GET_BILLING_HISTORY_account_items_invoices_items[];
  onInvoiceClick?: Function;
  openInvoices: string[];
  openInvoice: Function;
  closeInvoice: Function;
  accountNumber: string;
}

const Data = styled.li`
  display: flex;
  width: 11.6666667%;
  min-width: 81.66px;
  &:nth-child(2) {
    width: 30%;
    min-width: 190px;
  }
  list-style: none;
  margin: 0;
  padding: 10px;
  background-color: #f5f7fa;
  word-break: break-word;
`;

const DataText = styled(Text)`
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
`;

export default ({
  loading,
  error,
  invoices,
  onInvoiceClick,
  openInvoices,
  openInvoice,
  closeInvoice,
  accountNumber
}: BillingHistoryProps) => {
  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error</Text>;
  }
  const display_invoices: displayableInvoice[] = invoices.map(
    (
      invoice: GET_BILLING_HISTORY_account_items_invoices_items,
      index: number
    ) => {
      const displayable: displayableInvoice = {
        index,
        __typename: 'InsuredInvoice',
        invoice_number: invoice.invoice_number,
        due_date: invoice.due_date,
        contents:
          invoice.contents?.filter((content): content is string => {
            return content != null;
          }) || [],
        docuid: '',
        invoice_amount: invoice.invoice_amount,
        paid_amount: invoice.paid_amount,
        amount_due: invoice.amount_due
      };
      if (invoice.docuid) {
        displayable.docuid = (
          <Button
            margin={{ left: 'small' }}
            onClick={e => {
              e.stopPropagation();
              if (onInvoiceClick) {
                onInvoiceClick({
                  invoice_number: invoice.invoice_number,
                  gtm_data: {
                    invoiceNumber: invoice.invoice_number
                  }
                });
              }
            }}
          >
            <img src={DownloadIcon} alt="" />
          </Button>
        );
      } else {
        displayable.docuid = <Text margin={{ left: 'medium' }}>-</Text>;
      }
      displayable.due_date = formatDate(invoice.due_date) as string;
      return displayable;
    }
  );
  const [paginatedInvoices, pageSelector] = usePagination({
    accountNumber,
    items: display_invoices,
    itemsPerPage: 25
  });
  const columns = [
    {
      property: 'due_date',
      header: <DataText>Due Date</DataText>,
      margin: { left: 'xsmall' }
    },
    {
      property: 'contents',
      header: <DataText>Contents</DataText>
    },
    {
      property: 'docuid',
      header: <DataText>Document</DataText>
    },
    {
      property: 'invoice_amount',
      header: <DataText>Invoice Amount</DataText>
    },
    {
      property: 'paid_amount',
      header: <DataText>Amount Paid</DataText>
    },
    {
      property: 'amount_due',
      header: <DataText>Due</DataText>
    },
    {
      header: <DataText />
    }
  ];
  const count = paginatedInvoices.length - 1;

  return (
    <ResponsiveContext.Consumer>
      {responsive => (
        <Box background="white">
          <Box
            fill={
              responsive === 'small' || responsive === 'medium'
                ? false
                : 'horizontal'
            }
            responsive
            overflow="auto"
          >
            <Box direction="row">
              {columns.map(column => (
                <Data key={column.property}>{column.header}</Data>
              ))}
            </Box>

            <Box>
              {paginatedInvoices.map((invoice, index) => {
                const visible = openInvoices.includes(invoice.invoice_number);
                return (
                  <Box
                    border={
                      index === count
                        ? { side: 'bottom', color: 'white' }
                        : { side: 'bottom', color: '#D9DFEB' }
                    }
                    key={invoice.invoice_number}
                  >
                    <ExpandingTable
                      invoice={invoice}
                      visible={visible}
                      onClick={visible ? closeInvoice : openInvoice}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box align="end">{pageSelector}</Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
