import React from 'react';
import { Box, Text, Anchor } from 'grommet';
import styled from 'styled-components';
import ResponsiveGrid from '../responsiveGrid/ResponsiveGrid';

interface cardBodyProps {
  bodyOverflow?:
    | 'visible'
    | 'hidden'
    | 'scroll'
    | 'auto'
    | 'initial'
    | 'inherit';
  bodyHeight?: string;
}

export interface cardContent extends cardBodyProps {
  title: string;
  buttonComponent?: JSX.Element;
  children: JSX.Element | undefined;
  className?: string;
  edit?: boolean;
  additionalBottomGap?: string;
}

interface bottomGapProps {
  gapHeight?: string;
}

const StyledCard = styled(Box)`
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
`;

const StyledCardLayoutBody = styled.div<cardBodyProps>`
  padding: 1.25rem 1.875rem;
  overflow: ${props => (props.bodyOverflow ? props.bodyOverflow : 'visible')};
  height: ${props => (props.bodyHeight ? props.bodyHeight : 'auto')};
`;

const StyledCardEditBody = styled.div`
  padding: 0;
`;

const AdditionalBottomGap = styled.div<bottomGapProps>`
  height: ${props => (props.gapHeight ? props.gapHeight : '0px')};
`;

const doubleColumnGrid = {
  xsmall: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  small: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  medium: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  large: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ],
  xlarge: [
    { name: 'title', start: [0, 0], end: [0, 0] },
    { name: 'action', start: [1, 0], end: [1, 0] }
  ]
};

export const CardLayout = ({
  title,
  buttonComponent,
  children,
  className,
  edit,
  bodyOverflow,
  bodyHeight,
  additionalBottomGap
}: cardContent) => {
  return (
    <StyledCard className={className} round="xxsmall" background="white">
      <Box
        pad="small"
        background="brand"
        round={{ size: 'xxsmall', corner: 'top' }}
        direction="row"
      >
        {buttonComponent ? (
          <ResponsiveGrid
            rows={['auto']}
            columns={['auto', 'auto']}
            areas={doubleColumnGrid}
          >
            <Box gridArea="title">
              <Text color="white" weight="bold" margin={{ left: 'small' }}>
                {title}
              </Text>
            </Box>
            <Box gridArea="action" margin={{ right: 'small' }} align="end">
              {buttonComponent}
            </Box>
          </ResponsiveGrid>
        ) : (
          <Box gridArea="title">
            <Text color="white" weight="bold" margin={{ left: 'small' }}>
              {title}
            </Text>
          </Box>
        )}
      </Box>

      {edit ? (
        <StyledCardEditBody className="cardBody">{children}</StyledCardEditBody>
      ) : (
        <div>
          <StyledCardLayoutBody
            bodyOverflow={bodyOverflow}
            bodyHeight={bodyHeight}
            className="cardBody"
          >
            {children}
          </StyledCardLayoutBody>
          <AdditionalBottomGap gapHeight={additionalBottomGap} />
        </div>
      )}
    </StyledCard>
  );
};
