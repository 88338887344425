import React from 'react';
import { Header, Box } from 'grommet';
import styled from 'styled-components';
import LogoButton from '../homelogobutton';
import { SearchPanel } from '../searchPanel';
import AccountDropDown from '../accountDropDown';
import { ResponsiveGrid } from '..';
import FeatureFlag from '../featureFlags';
import BannerErrorHeader from '../bannerErrorHeader/BannerErrorHeader';
import HeaderMenu from '../headerMenu';
import GlobalOutageBanner from '../globalOutageBanner/GlobalOutageBanner';
import { useGlobalContext } from '../../util/globalContextProvider';

interface headerProps {
  hasSearch?: boolean | undefined;
}

const ShadowHeader = styled(Header)`
  box-shadow: 0px 2px 4px 0px #00203833;
`;

const AccountWrapper = styled.div`
  top: 8px;
  right: 17px;
  position: relative;
`;

const SearchWrapper = styled.div`
  top: 15px;
  position: relative;
  right: 30px;
`;

const ReadOnlyBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #f5a524;
`;

const ReadOnlyBanner = styled.span`
  font-size: 13px;
  font-weight: 700;
  color: #3b3b3b;
  padding: 2px;
  word-spacing: 1px;
`;

export default ({ hasSearch }: headerProps) => {
  const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();
  const mainText = 'The site is currently under scheduled maintenance.';
  const secondaryText =
    'Some services may not be fully available at this time. Please check back in 24 to 48 hours.';
  return (
    <div>
      <FeatureFlag flagName="ErrorBanner">
        <BannerErrorHeader />
      </FeatureFlag>
      <FeatureFlag flagName="GWOutage-Global">
        <GlobalOutageBanner mainText={mainText} secondaryText={secondaryText} />
      </FeatureFlag>
      {sessionStorage.getItem('outageBannerMessage') && (
        <FeatureFlag flagName="EnterpriseOutageBanner">
          <GlobalOutageBanner
            mainText={sessionStorage.getItem('outageBannerMessage')}
          />
        </FeatureFlag>
      )}
      <ShadowHeader
        background="brand"
        direction="row-responsive"
        alignContent="center"
        height="98px"
      >
        <FeatureFlag flagName="HeaderMenu">
          <HeaderMenu />
        </FeatureFlag>
        <ResponsiveGrid
          pad={{ top: 'small' }}
          columns={['flex', 'flex', 'auto']}
          rows={['80px', 'auto']}
          areas={{
            xsmall: [
              { name: 'logo', start: [0, 0], end: [1, 0] },
              { name: 'searchpanel', start: [0, 1], end: [2, 1] },
              { name: 'usermenu', start: [2, 0], end: [2, 0] }
            ],
            small: [
              { name: 'logo', start: [0, 0], end: [1, 0] },
              { name: 'searchpanel', start: [0, 1], end: [2, 1] },
              { name: 'usermenu', start: [2, 0], end: [2, 0] }
            ],
            medium: [
              { name: 'logo', start: [0, 0], end: [0, 0] },
              { name: 'searchpanel', start: [1, 0], end: [1, 0] },
              { name: 'usermenu', start: [2, 0], end: [2, 0] }
            ],
            large: [
              { name: 'logo', start: [0, 0], end: [0, 0] },
              { name: 'searchpanel', start: [1, 0], end: [1, 0] },
              { name: 'usermenu', start: [2, 0], end: [2, 0] }
            ],
            xlarge: [
              { name: 'logo', start: [0, 0], end: [0, 0] },
              { name: 'searchpanel', start: [1, 0], end: [1, 0] },
              { name: 'usermenu', start: [2, 0], end: [2, 0] }
            ]
          }}
        >
          <Box align="start" pad="small" alignSelf="center" gridArea="logo">
            <LogoButton />
          </Box>
          {hasSearch ? (
            <SearchWrapper>
              <Box
                align="end"
                pad="small"
                justify="between"
                gridArea="searchpanel"
                alignSelf="center"
              >
                <SearchPanel header />
              </Box>
            </SearchWrapper>
          ) : (
            <Box />
          )}
          <Box
            align="end"
            justify="between"
            alignSelf="center"
            gridArea="usermenu"
          >
            <AccountWrapper>
              <AccountDropDown
                readOnlyAccess={readOnlyAccess}
                readOnlyFeatureFlag={readOnlyFeatureFlag}
              />
            </AccountWrapper>
          </Box>
        </ResponsiveGrid>
      </ShadowHeader>
      {readOnlyFeatureFlag && readOnlyAccess && (
        <ReadOnlyBannerContainer>
          <ReadOnlyBanner>
            Internal users - some capabilities have been disabled. (read-only)
          </ReadOnlyBanner>
        </ReadOnlyBannerContainer>
      )}
    </div>
  );
};
