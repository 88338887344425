import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
  CloseIconContainer,
  SearchBarContainer,
  Input,
  Icon,
  SearchView,
  SearchViewItems,
  SortIcon
} from './SearchAndFilterWCClaimsStyles';
import AdvancedFilter from '../wcAdvancedFilter/AdvancedFilter';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import { SearchGreen, BlueCross, FileShield } from '../../icons';

export interface SearchWCClaimsProps {
  onSearch: (searchTerm: string) => void;
  searchBarPosition: boolean;
  handleReset: () => void;
  viewCloseIcon: boolean;
  setViewCloseIcon: React.Dispatch<React.SetStateAction<boolean>>;
  claimsList: ClaimsData[] | null;
  setSearchResults: React.Dispatch<React.SetStateAction<ClaimsData[] | null>>;
  setEmptySearchResults:React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchWCClaims = ({
  onSearch,
  searchBarPosition,
  handleReset,
  viewCloseIcon,
  setViewCloseIcon,
  claimsList,
  setSearchResults,
  setEmptySearchResults
}: SearchWCClaimsProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(searchTerm);
    setViewCloseIcon(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    onSearch(value);
    setViewCloseIcon(true);
  };

  const handlePlaceholderText = (searchBarPosition: boolean) => {
    if (searchBarPosition) {
      return 'Claim Number,Name,Policy Number';
    }
    return 'Search Claim Number';
  };

  const handleLocalReset = () => {
    setSearchTerm('');
    onSearch('');
    handleReset();
    setViewCloseIcon(false);
  };

  return (
    <>
      {searchBarPosition ? (
        <SearchBarContainer onSubmit={handleSubmit}>
          <Input
           width="14vw"
            type="text"
            placeholder={handlePlaceholderText(searchBarPosition)}
            value={searchTerm}
            onChange={handleInputChange}
          />
          <Icon position="left">
            <SearchGreen />
          </Icon>
          {viewCloseIcon ? (
            <Icon position="right">
              <CloseIconContainer onClick={handleLocalReset}>
                <BlueCross height="15px" width="15px" />
              </CloseIconContainer>
            </Icon>
          ) : (
            <></>
          )}
        </SearchBarContainer>
      ) : (
        <SearchView>
          <SearchViewItems>
            <FileShield />
          </SearchViewItems>
          <SearchViewItems>
            Select a Claim to View or Search for a Claim
          </SearchViewItems>
          <SearchViewItems>
            <SearchBarContainer onSubmit={handleSubmit}>
              <Input
                width="15vw"
                type="text"
                placeholder={handlePlaceholderText(searchBarPosition)}
                value={searchTerm}
                onChange={handleInputChange}
              />
              <Icon position="left">
                <SearchGreen />
              </Icon>
              {viewCloseIcon ? (
                <Icon position="right">
                  <CloseIconContainer onClick={handleLocalReset}>
                    <BlueCross height="15px" width="15px" />
                  </CloseIconContainer>
                </Icon>
              ) : (
                <></>
              )}
            </SearchBarContainer>
          </SearchViewItems>
          <SearchViewItems>
            <AdvancedFilter
              searchBarPosition={searchBarPosition}
              claimsList={claimsList}
              setSearchResults={setSearchResults}
              setEmptySearchResults={ setEmptySearchResults}
            />
          </SearchViewItems>
        </SearchView>
      )}
    </>
  );
};

export default SearchWCClaims;
