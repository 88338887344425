import React from 'react';
import { loader } from 'graphql.macro';
import { useParams } from '@reach/router';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES,
  SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTESVariables,
  SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes
} from './graphql/__generated__/SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES';
import { SnapshotsAndQuotesTable } from '.';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import useDocumentDownload from '../../util/useDocumentDownload';
import redirectOnQuotesClick from '../../util/redirectOnQuotesClick';

const get_snapshots_and_quotes = loader(
  './graphql/Get_Snapshots_And_Quotes.graphql'
);

const get_quote_search_selection_behavior = loader(
  './graphql/Snapshots_And_Quotes_Get_Quote_Search_Selection_Behavior.graphql'
);

export interface historyProp {
  history?: boolean;
}

export default (showAll: historyProp) => {
  const { accountNumber, policyNumber } = useParams();
  const queryResult = useQuery<
    SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES,
    SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTESVariables
  >(get_snapshots_and_quotes, {
    variables: {
      account_number: accountNumber,
      policy_number: policyNumber
    } as SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTESVariables
  });

  const { error, data } = queryResult;

  let snapshotsAndQuotes:
    | SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes[]
    | undefined;
  const { onDocumentClick, documentModal } = useDocumentDownload(
    accountNumber,
    'snapshot',
    'click-policySnapshotDocument'
  );
  const { flagDetails: snapshotInvoiceFlagDetails } = useFeatureFlag(
    'SnapshotInvoice'
  );
  let snapshotInvoiceFlag = false;
  if (data?.account?.items) {
    snapshotInvoiceFlag =
      snapshotInvoiceFlagDetails?.enabled &&
      data?.account?.items[0]?.source_system_code === 'GWPL';
    snapshotsAndQuotes = data.account.items[0]?.policy?.quotes?.filter(
      (
        snapshotOrQuote: SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes | null
      ): snapshotOrQuote is SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes => {
        return !!snapshotOrQuote;
      }
    );
  }

  const [redirectOnQuoteClickAction] = useLazyQuery(
    get_quote_search_selection_behavior,
    {
      onCompleted: redirectOnQuotesClick
    }
  );

  return (
    <>
      {documentModal}
      <SnapshotsAndQuotesTable
        snapshotInvoiceFlag={snapshotInvoiceFlag}
        error={error}
        snapshotsAndQuotes={snapshotsAndQuotes}
        onDocumentClick={onDocumentClick}
        accountNumber={accountNumber}
        policyNumber={policyNumber}
        {...showAll}
        redirectOnQuoteClickAction={redirectOnQuoteClickAction}
      />
    </>
  );
};
