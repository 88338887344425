import styled from 'styled-components';

export const AccountDetail = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LabelText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #0173c6;
`;
export const HorizontalLine = styled.hr`
  border: none;
  width: 100%;
  height: 1px;
  background-color: #d9dfeb;
`;
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
`;
export const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
export const DetailTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #041e41;
`;
export const DetailText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;
