import React, { useState } from 'react';
import { ChevronUp, ChevronDownUnbolded } from '../../../icons';
import { formatMoney } from '../../../util/formatters';
import {
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
  Label,
  Value,
  Icon,
  Table,
  TableRow,
  TableCell,
  Line
} from '../ExpandedViewStyles';

interface ReservesProps {
  rowOne: any;
  rowTwo: any;
}
const Reserves: React.FC<ReservesProps> = ({ rowOne, rowTwo }) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <DropdownContainer open={open}>
      <DropdownHeader onClick={toggleDropdown} open={open}>
        <span>Reserves</span>
        <Icon position='right' open={open}> {open ? <ChevronUp /> : <ChevronDownUnbolded />}</Icon>
      </DropdownHeader>
      <DropdownContent open={open}>
        <Table>
          <TableRow style={{ backgroundColor: '#dbdfe1' }}>
            <TableCell>
              <Label>Summary</Label>
            </TableCell>
            <TableCell>
              <Label>Medical</Label>
            </TableCell>
            <TableCell>
              <Label>Indemnity</Label>
            </TableCell>
            <TableCell>
              <Label>Expense</Label>
            </TableCell>
            <TableCell>
              <Label>Legal</Label>
            </TableCell>
            <TableCell>
              <Label>Voc Rehab</Label>
            </TableCell>
            <TableCell>
              <Label>TOTAL</Label>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Label>Incurred Reserves</Label>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.medical_reserves || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.indemnity_reserves || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.expense_reserves || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.legal_reserves || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.voc_rehab_reserves || 0)}</Value>
            </TableCell>
            <TableCell>
              <Label style={{ color: '#65A100' }}>
                {formatMoney(rowOne.medical_reserves +
                  rowOne.indemnity_reserves +
                  rowOne.expense_reserves +
                  rowOne.legal_reserves +
                  rowOne.voc_rehab_reserves)}
              </Label>
            </TableCell>
          </TableRow>
          <Line colSpan={7}/>
          <TableRow>
            <TableCell>
              <Label>Paid to Date</Label>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowTwo.medical_ptd || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowTwo.indemnity_ptd || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowTwo.expense_ptd || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowTwo.legal_ptd || 0)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowTwo.voc_rehab_ptd || 0)}</Value>
            </TableCell>
            <TableCell>
              <Label style={{ color: '#65A100' }}>
                {formatMoney(rowTwo.medical_ptd +
                  rowTwo.indemnity_ptd +
                  rowTwo.expense_ptd +
                  rowTwo.legal_ptd +
                  rowTwo.voc_rehab_ptd)}
              </Label>
            </TableCell>
          </TableRow>
          <Line colSpan={7}/>
          <TableRow>
            <TableCell>
              <Label>Remaining Reserves</Label>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.medical_reserves - rowTwo.medical_ptd)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.indemnity_reserves - rowTwo.indemnity_ptd)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.expense_reserves - rowTwo.expense_ptd)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.legal_reserves - rowTwo.legal_ptd)}</Value>
            </TableCell>
            <TableCell>
              <Value>{formatMoney(rowOne.voc_rehab_reserves - rowTwo.voc_rehab_ptd)}</Value>
            </TableCell>
            <TableCell>
              <Label style={{ color: '#65A100' }}>
                {formatMoney(rowOne.medical_reserves -
                  rowTwo.medical_ptd +
                  (rowOne.indemnity_reserves - rowTwo.indemnity_ptd) +
                  (rowOne.expense_reserves - rowTwo.expense_ptd) +
                  (rowOne.legal_reserves - rowTwo.legal_ptd) +
                  (rowOne.voc_rehab_reserves - rowTwo.voc_rehab_ptd))}
              </Label>
            </TableCell>
          </TableRow>
          <Line colSpan={7}/>
        </Table>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default Reserves;
