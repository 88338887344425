import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Select as GrommetSelect } from 'grommet';
import { POLICY_DROPDOWN_GET_POLICIES_BY_ACCOUNT_account_items_policies_items } from './graphql/__generated__/POLICY_DROPDOWN_GET_POLICIES_BY_ACCOUNT';
import { ChevronDown } from '../../icons';
import { motifs } from '../../themes/default.theme';

// alias
// eslint-disable-next-line max-len
type PolicyDropdownOption = POLICY_DROPDOWN_GET_POLICIES_BY_ACCOUNT_account_items_policies_items;

// TODO: Extract this and StyledSelect into Select component
const SelectWrapper = styled.div`
  button {
    border-color: ${motifs.lightBorder};
    border-radius: 3px;
    & > div {
      border-radius: 3px;
    }
  }
`;

export const Select = styled(GrommetSelect)`
  font-weight: 100;
`;

export interface PolicyDropdownProps {
  isLoading?: boolean;
  error?: Error;
  value?: string;
  onChange: (...args: any[]) => void;
  options?: PolicyDropdownOption[];
  className?: string;
}
export const PolicyDropdown = ({
  isLoading,
  error,
  value,
  onChange,
  options = [],
  className
}: PolicyDropdownProps) => {
  const formattedOptions = useMemo(
    () =>
      options.map(({ policy_type, policy_number }: PolicyDropdownOption) => ({
        label: `${policy_type}…${policy_number!.slice(-4)}`,
        value: policy_number
      })),
    [options]
  );

  const currentlySelected = formattedOptions.filter(option => {
    return option.value === value;
  });

  return (
    <SelectWrapper className={className}>
      <Select
        placeholder="Select Policy"
        disabled={isLoading || Boolean(error)}
        value={
          formattedOptions.length && currentlySelected.length
            ? currentlySelected[0]
            : ''
        }
        onChange={selection => {
          onChange(selection.value.value);
        }}
        options={formattedOptions}
        labelKey="label"
        valueKey="value"
        icon={ChevronDown}
      />
    </SelectWrapper>
  );
};
