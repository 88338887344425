import React, { useState, useEffect, cloneElement } from 'react';
import callApiWithAuth from '../../util/callApiWithAuth/callApiWithAuth';
import getConfig, { Env } from '../../config';

const config = getConfig(process.env.REACT_APP_ENV as Env);

interface featureFLagProps {
  flagName: string;
  children: any;
}
export default ({ flagName, children }: featureFLagProps) => {
  const [flagDetails, setFlagDetails] = useState<any>({});
  useEffect(() => {
    callApiWithAuth({
      method: 'POST',
      url: config.feature_flag_api,
      data: {
        application_name: 'Agency360',
        flag_name: flagName
      }
    })
      .then(res => {
        if (res.data?.Items && res.data.Items[0]?.enabled)
          setFlagDetails(res.data.Items[0]);
      })
      .catch(err => console.error('Error: ', err));
  }, []);

  let filteredChildren: any = [];

  if (Array.isArray(children)) {
    filteredChildren = children.filter((child: any) => {
      return !(typeof child === 'string' && child.trim() === '');
    });
  }

  if (flagDetails.enabled && Array.isArray(children))
    return (
      <>
        {filteredChildren.map((child: any) => {
          return child
            ? cloneElement(child, { featureFlagValue: flagDetails.value })
            : child;
        })}
      </>
    );
  if (flagDetails.enabled && children)
    return (
      <>{cloneElement(children, { featureFlagValue: flagDetails.value })}</>
    );
  return <></>;
};
