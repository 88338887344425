import styled from 'styled-components';

export const StyledQuoteHit = styled.div`
  border-bottom: 1px solid #d9dfeb;
  background: transparent;
`;

export const LeftColumnQuoteSearch = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  width: 282px;
`;

export const MiddleColumnQuoteSearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 322px;
  font-size: 14px;
`;

export const RightColumnQuoteSearch = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 201px;
  line-height: 20px;
`;

export const BluePillBox = styled.div`
    border-radius: 30px;
    background-color: #E1F5FF;
    color: #0173C6;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    width: fit-content;
    padding: 4px 10px
    margin-right: 10%;
    border: 1px solid #0173C6;
`;

export const RedPillBox = styled.div`
    border-radius: 30px;
    background-color: #FDEAEE;
    color: #B00020;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    width: fit-content;
    padding: 4px 10px
    margin-right: 10%;
    border: 1px solid #B00020;
`;

export const GrayPillBox = styled.div`
    border-radius: 30px;
    background-color: #E0E0E0;
    color: #3B3B3B;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    width: fit-content;
    padding: 4px 10px
    margin-right: 10%;
    border: 1px solid #3B3B3B;
`;

export const StyledBubbleText = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
`;

export const StyledName = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #041e41;
  margin-bottom: 10px;
`;

export const StyledGeneralTextFields = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #041e41;
`;

export const StyledPolicyNumbersColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-left: auto;
`;

export const StyledBoldRightColumnHeaders = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

export const StyledQuoteNumberAndPolicyNumberColumn = styled.div`
  margin-left: 10%;
`;

export const StyledEffectiveDateDiv = styled.div`
  margin-bottom: 5%;
  font-weight: 700;
  font-size: 14px;
`;

export const StyledQuoteNumberAndPolicyNumberRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSearchResult = styled.div.attrs(
  (props: { index: number }) => props
)`
  display: flex;
  flex-direction: row;
  font-family: 'PT Sans';
  margin-left: 10px;
  background-color: ${props => (props.index % 2 === 0 ? '#FFFFFF' : '#F8F9FC')};
  padding: 15px 0px;
`;
