import moment, { Moment } from 'moment-timezone';

export const lastWeekdayOfMonth = (weekday: number, endOfMonth: Moment) => {
  // [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday]
  const lastWeekday = endOfMonth.day();
  if (lastWeekday === weekday) {
    return endOfMonth.format('YYYY-MM-DD');
  }
  if (lastWeekday < weekday) {
    const difference = weekday - lastWeekday;
    const holiday = endOfMonth.subtract(7 - difference, 'days');
    return holiday.format('YYYY-MM-DD');
  }
  const difference = lastWeekday - weekday;
  const holiday = endOfMonth.subtract(difference, 'days');
  return holiday.format('YYYY-MM-DD');
};

export const xWeekdayOfMonth = (
  weekday: number,
  desiredNumber: number,
  startOfMonth: Moment
) => {
  // [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday]
  const firstWeekday = startOfMonth.day();
  let holiday = null;
  if (firstWeekday === weekday) {
    holiday = startOfMonth;
    if (desiredNumber !== 1) {
      holiday.add(7 * (desiredNumber - 1), 'days'); // adding 7 days if 2nd of weekday, & so on
    }
    return holiday.format('YYYY-MM-DD');
  }

  const difference = weekday - firstWeekday;
  holiday = startOfMonth; // initialize object to manipulate
  if (firstWeekday < weekday) {
    holiday.add(difference, 'days');
  } else {
    // weekday < firstWeekday, eg Wednesday to Monday: 7 + (-2)
    holiday.add(7 + difference, 'days');
  }
  if (desiredNumber !== 1) {
    holiday.add(7 * (desiredNumber - 1), 'days');
  }
  return holiday.format('YYYY-MM-DD');
};

export default () => {
  const setYear = moment.tz('America/New_York');
  const currentYear = setYear.year();
  const previousYear = setYear.year() - 1; // date range may incapsulate part of previous or next year from current date
  const nextYear = setYear.year() + 1;
  const holidayList = [
    `${previousYear}-01-01`,
    `${previousYear}-06-19`,
    `${previousYear}-11-11`,
    `${previousYear}-12-25`,
    `${currentYear}-01-01`,
    `${currentYear}-06-19`,
    `${currentYear}-11-11`,
    `${currentYear}-12-25`,
    `${nextYear}-01-01`,
    `${nextYear}-06-19`,
    `${nextYear}-11-11`,
    `${nextYear}-12-25`
  ];

  const thisJanuary = moment.tz(holidayList[4], 'America/New_York');
  const nextJanuary = moment.tz(holidayList[8], 'America/New_York');
  const lastJanuary = moment.tz(holidayList[0], 'America/New_York');
  holidayList.push(xWeekdayOfMonth(1, 3, thisJanuary)); // MLK Day
  holidayList.push(xWeekdayOfMonth(1, 3, nextJanuary));
  holidayList.push(xWeekdayOfMonth(1, 3, lastJanuary));

  const thisFebruary = moment.tz(`${currentYear}-02-01`, 'America/New_York');
  const nextFebruary = moment.tz(`${nextYear}-02-01`, 'America/New_York');
  const lastFebruary = moment.tz(`${previousYear}-02-01`, 'America/New_York');
  holidayList.push(xWeekdayOfMonth(1, 3, thisFebruary)); // President's Day
  holidayList.push(xWeekdayOfMonth(1, 3, nextFebruary));
  holidayList.push(xWeekdayOfMonth(1, 3, lastFebruary));

  const thisMay = moment.tz(`${currentYear}-05-31`, 'America/New_York'); // last Monday in May - count back simplified vs 3rd Monday of potential 5
  const nextMay = moment.tz(`${nextYear}-05-31`, 'America/New_York');
  const lastMay = moment.tz(`${previousYear}-05-31`, 'America/New_York');
  holidayList.push(lastWeekdayOfMonth(1, thisMay)); // Memorial Day
  holidayList.push(lastWeekdayOfMonth(1, nextMay));
  holidayList.push(lastWeekdayOfMonth(1, lastMay));

  const thisSeptember = moment.tz(`${currentYear}-09-01`, 'America/New_York');
  const nextSeptember = moment.tz(`${nextYear}-09-01`, 'America/New_York');
  const lastSeptember = moment.tz(`${previousYear}-09-01`, 'America/New_York');
  holidayList.push(xWeekdayOfMonth(1, 1, thisSeptember)); //Labor Day
  holidayList.push(xWeekdayOfMonth(1, 1, nextSeptember));
  holidayList.push(xWeekdayOfMonth(1, 1, lastSeptember));

  const thisOctober = moment.tz(`${currentYear}-10-01`, 'America/New_York');
  const nextOctober = moment.tz(`${nextYear}-10-01`, 'America/New_York');
  const lastOctober = moment.tz(`${previousYear}-10-01`, 'America/New_York');
  holidayList.push(xWeekdayOfMonth(1, 2, thisOctober)); //Columbus Day
  holidayList.push(xWeekdayOfMonth(1, 2, nextOctober));
  holidayList.push(xWeekdayOfMonth(1, 2, lastOctober));

  const thisNovember = moment.tz(`${currentYear}-11-01`, 'America/New_York');
  const nextNovember = moment.tz(`${nextYear}-11-01`, 'America/New_York');
  const lastNovember = moment.tz(`${previousYear}-11-01`, 'America/New_York');
  const thisNovember2 = moment.tz(`${currentYear}-11-01`, 'America/New_York');
  const nextNovember2 = moment.tz(`${nextYear}-11-01`, 'America/New_York');
  const lastNovember2 = moment.tz(`${previousYear}-11-01`, 'America/New_York');
  holidayList.push(xWeekdayOfMonth(4, 4, thisNovember)); //Thanksgiving
  holidayList.push(xWeekdayOfMonth(5, 4, thisNovember2)); //Day after Thanksgiving
  holidayList.push(xWeekdayOfMonth(4, 4, nextNovember));
  holidayList.push(xWeekdayOfMonth(5, 4, nextNovember2));
  holidayList.push(xWeekdayOfMonth(4, 4, lastNovember));
  holidayList.push(xWeekdayOfMonth(5, 4, lastNovember2));

  return holidayList;
};
