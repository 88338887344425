import styled, { css } from 'styled-components';

export const SidebarMenuContainer = styled.div`
  position: fixed;
  height: 100vh;
  overflow: hidden;
  top: 0;
  background-color: #ffffff;
  z-index: 9;
  transition: all 0.25s;
  box-shadow: 0px 2px 5px 0px rgba(1, 83, 142, 0.2);
  width: 350px;
  left: -350px;

  &.active {
    left: 0;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 64px;
`;

const SidebarHeaderButton = css`
  display: block;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px;
  margin: 0 12px;
  background-color: #0173c600;
  transition: all 0.2s;

  &:hover {
    background-color: #0173c62d;
  }
`;

export const BackButton = styled.p`
  ${SidebarHeaderButton}
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: 16px;
  line-height: 16px;
`;

export const BackButtonChevron = styled.img`
  display: block;
  height: 14px;
  width: auto;
  margin-right: 16px;
`;

export const CloseIcon = styled.img`
  ${SidebarHeaderButton}
  width: auto;
  height: 16px;
`;

const SidebarMenuOptionsWrap = css`
  position: absolute;
  display: block;
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
  top: 64px;
  transition: all 0.25s;

  &.active {
    left: 0%;
  }
`;

export const PrimaryOptionsWrap = styled.div`
  ${SidebarMenuOptionsWrap}
  left: -100%;
`;

export const SecondaryOptionsWrap = styled.div`
  ${SidebarMenuOptionsWrap}
  left: 100%;
`;
