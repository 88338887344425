import React from 'react';
import AdjusterIcon from '../assets/images/user-check.svg';

interface AdjusterProps {
  width?: number;
  height?: number;
}
 const Adjuster: React.FC<AdjusterProps> = ({ width = 33, height = 27 }) => {
  return (
    <div>
      <img src={AdjusterIcon} width={width} height={height} />
    </div>
  );
};
export default Adjuster;