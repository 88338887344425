import styled from 'styled-components';

export const ItemWrapper = styled.div`
  background: rgb(237, 237, 237);
  border-top: 1px solid rgb(206, 219, 219);
  display: flex;
  flex-direction: row;
`;

export const ItemLeftWrapper = styled.div`
  width: 80%;
  cursor: pointer;
`;

export const ItemRightWrapper = styled.div`
  width: 20%;
  background-color: #eee;
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemRightWrapperSelected = styled.div`
  width: 20%;
  background-color: #fff;
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemRightIconWrapper = styled.div`
  border: 1px solid green;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
`;

export const ClaimNumberBox = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
`;

export const SelectedClaim = styled.div`
  border-left: 4px solid #0173c6;
  background-color: #fff;
`;

export const ClaimNumberText = styled.div`
  color: rgb(59, 59, 59);
  font-size: 16px;
  font-weight: 900;
  line-height: 16px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const InsuredNameBox = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(59, 59, 59)
  border: 1px solid yellow;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const InsuredNameTextLeft = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const InsuredNameTextRight = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
  padding: 0px;
  text-align: left;
  text-transform: uppercase;
`;

export const DateOfInjuryBox = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(59, 59, 59)
  border: 1px solid yellow;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const DateOfInjuryTextLeft = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const DateOfInjuryTextRight = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const InjuryDetailsBox = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(59, 59, 59)
  border: 1px solid yellow;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const InjuryDetailsTextLeft = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const InjuryDetailsTextRight = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const LossLocationBox = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(59, 59, 59)
  border: 1px solid yellow;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const LossLocationTextLeft = styled.div`
  width: 33%;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const LossLocationTextRight = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const ClaimStatusBox = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(59, 59, 59)
  border: 1px solid yellow;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const ClaimStatusTextLeft = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  text-transform: none;
`;

export const ClaimStatusOpenTextRight = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
  padding: 0px;
  text-align: left;
  text-transform: none;
  color: green;
`;

export const ClaimStatusCloseTextRight = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
  padding: 0px;
  text-align: left;
  text-transform: none;
  color: rgb(207, 2, 26);
`;
