import styled from 'styled-components';

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 75px;
  background-color: #e7f5fa;
  border: 1px solid #b9e2f0;
  border-left: none;
  margin-bottom: 10px;
  border-radius: 4px;
  font-family: PT Sans, sans-serif;
`;

export const BoxWrapper = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
`;

export const LeftColoredTab = styled.div`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 1%;
  height: 100%;
  background-color: #0173c6;
`;

export const InfoIconSizing = styled.div`
  display: flex;
  margin-left: 15px;
  margin-top: 23px;
  height: 30px;
  width: 15px;
  padding-right: 15px;
`;

export const OuterOuterWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BoldedBlueNumber = styled.span`
  color: #0c5066;
  font-weight: bold;
`;
