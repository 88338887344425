import React from 'react';
import { useParams } from '@reach/router';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import QuotePremium from './QuotePremium';
import {
  GET_QUOTE_PREMIUMVariables,
  GET_QUOTE_PREMIUM
} from './graphql/__generated__/GET_QUOTE_PREMIUM';

const get_quote_premium_query = loader('./graphql/Get_Quote_Premium.graphql');

export default () => {
  const { quoteNumber } = useParams();
  const { data } = useQuery<GET_QUOTE_PREMIUM, GET_QUOTE_PREMIUMVariables>(
    get_quote_premium_query,
    {
      variables: {
        quote_number: quoteNumber
      }
    }
  );
  let premium: number | null = null;
  let status: string | null = null;
  if (data) {
    premium = data.quote?.premium ?? null;
    status = data.quote?.status ?? null;
  }
  if (status === 'Quote Declined' || status === 'Issuance In-Progress') {
    return <></>;
  }
  return <QuotePremium premium={premium} />;
};
