import React, { useState } from 'react';
import { formatDate } from '../../../util/formatters';
import { ChevronUp, ChevronDownUnbolded } from '../../../icons';
import {
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
  Label,
  Value,
  RowContainer,
  LabelDisplay,
  Icon
} from '../ExpandedViewStyles';
interface InjuryDetailsProps {
  getWCDetails: any;
}
const InjuryDetails: React.FC<InjuryDetailsProps> = ({ getWCDetails }) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <DropdownContainer open={open}>
      <DropdownHeader onClick={toggleDropdown} open={open}>
        <span>Injury Details </span>
        <Icon position='right' open={open}>{open ? <ChevronUp /> : <ChevronDownUnbolded />}</Icon>
      </DropdownHeader>
      <DropdownContent open={open}>
        <RowContainer>
          <LabelDisplay>
            <Label>Description/Nature of Injury</Label>
            <Value>{getWCDetails?.natureofinjury || 'N/A'}</Value>
          </LabelDisplay>
          <LabelDisplay>
            <Label>Body Part Injured</Label>
            <Value>{getWCDetails?.partofbody || 'N/A'}</Value>
          </LabelDisplay>
        </RowContainer>
        <RowContainer>
          <LabelDisplay>
            <Label>Date of Injury</Label>
            <Value>{formatDate(getWCDetails?.dateofloss) || 'N/A'}</Value>
          </LabelDisplay>
        </RowContainer>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default InjuryDetails;
