import React from 'react';
import { Box } from 'grommet';
import ClaimsLatestPayments from '../claimsLatestPayments';
import ClaimsAlerts from '../claimsAlerts';
import { ClaimsLatestPayment } from '../claimsLatestPayments/ClaimsLatestPayments';
import { ClaimsAlertProps } from '../claimsAlerts/ClaimsAlerts';
import { ResponsiveGrid } from '..';

export interface ClaimsSummaryWrapperProps {
  payments?: ClaimsLatestPayment | undefined;
  alerts?: ClaimsAlertProps | undefined;
}

export default ({ payments, alerts }: ClaimsSummaryWrapperProps) => {
  return (
    <Box>
      <ResponsiveGrid
        fill
        gap="30px"
        rows={['auto']}
        columns={['flex', 'flex']}
        areas={{
          xsmall: [
            { name: 'Grid1', start: [0, 0], end: [2, 0] },
            { name: 'Grid2', start: [0, 1], end: [2, 1] }
          ],
          small: [
            { name: 'Grid1', start: [0, 0], end: [2, 0] },
            { name: 'Grid2', start: [0, 1], end: [2, 1] }
          ],
          medium: [
            { name: 'Grid1', start: [0, 0], end: [0, 0] },
            { name: 'Grid2', start: [1, 0], end: [1, 0] }
          ],
          large: [
            { name: 'Grid1', start: [0, 0], end: [0, 0] },
            { name: 'Grid2', start: [1, 0], end: [1, 0] }
          ],
          xlarge: [
            { name: 'Grid1', start: [0, 0], end: [0, 0] },
            { name: 'Grid2', start: [1, 0], end: [1, 0] }
          ]
        }}
      >
        <Box height="327px" gridArea="Grid1" direction="column">
          <ClaimsLatestPayments {...payments} />
        </Box>
        <Box height="327px" gridArea="Grid2" direction="column">
          <ClaimsAlerts {...alerts} />
        </Box>
      </ResponsiveGrid>
    </Box>
  );
};