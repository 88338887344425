import React from 'react';
import ChevronDownDisabled from '../assets/images/chevron-down-disabled.svg';

export default () => {
  return (
    <div>
      <img src={ChevronDownDisabled} alt="expand-row-icon-disabled" />
    </div>
  );
};
