import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GrayBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f6fa;
  padding: 16px 30px 32px;
  gap: 12px;
`;

export const Header = styled.span`
  size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const MainText = styled.span`
  line-height: 24px;
  font-size: 16px;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const CloseButton = styled.button`
  background-color: #ffffff;
  border: none;
  margin-left: auto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-top: 255px;
  cursor: pointer;
`;
