import styled from 'styled-components';
import { motifs } from '../../themes/default.theme';

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InsuredNameHolder = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  flex-direction: column;
  padding-bottom: 12px;
`;

export const StyledInsuredName = styled.span`
  font-size: 1.5rem;
`;

export const StyledNav = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  flex-direction: column;
  flex: 0 0 auto;
  border-bottom: 1px solid ${motifs.lightBorder};
`;

export const StyledNavLink = styled.span`
  color: ${motifs.primaryText};
  font-size: 1.125rem;
  text-decoration: none;
  font-weight: 400;

  &::after {
    content: '';
    border-bottom: 4px solid #65a100;
    display: block;
    width: 4.5rem;
    padding-top: 1px;
    transition: width 0.2s linear;
  }
`;
