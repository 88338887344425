import React, { MouseEventHandler } from 'react';
import {
  ModalBackground,
  ModalBody,
  ModalHeaderRow,
  HeaderText,
  ButtonRow,
  CloseButton,
  ContentText,
  StyledCloseIcon
} from './OutageModalStyles';

interface OutageModalProps {
  title: string;
  onClose: Function;
  children: string;
}

export default ({ title, onClose, children }: OutageModalProps) => {
  return (
    <ModalBackground>
      <ModalBody>
        <ModalHeaderRow>
          <HeaderText>{title}</HeaderText>
          <StyledCloseIcon
            onClick={onClose as MouseEventHandler<SVGSVGElement>}
          />
        </ModalHeaderRow>
        <ContentText>{children}</ContentText>
        <ButtonRow>
          <CloseButton
            type="button"
            onClick={onClose as MouseEventHandler<HTMLButtonElement>}
          >
            CLOSE
          </CloseButton>
        </ButtonRow>
      </ModalBody>
    </ModalBackground>
  );
};
