import styled from 'styled-components';

export const RequestSentWrap = styled.div`
  padding: 30px;
  background: #f4f6fa;
  border-radius: 3px;
  margin: 30px 30px 22px;
  display: flex;
`;

export const RequestSentTitle = styled.p`
  margin: 0 0 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #3b3b3b;
`;

export const RequestSentText = styled.p`
  margin: 0 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: #3b3b3b;

  :last-child {
    margin-bottom: 0;
  }
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 8px;
  margin: 0 22px 22px 0;
  float: right;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #3b3b3b;
`;

export const SuccessText = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #edf2e6;
  border: 1px solid #c9dab5;
  border-left: 5px solid #4d840b;
  border-radius: 4px;
  color: #264205;
  margin: 30px;
  padding: 16px;
  font-size: 14px;
  gap: 16px;
`;
export const ErrorText = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f7e5e8;
  border: 1px solid #e8b2bc;
  border-left: 5px solid #b00020;
  border-radius: 4px;
  color: #590010;
  margin: 30px;
  padding: 16px;
  font-size: 14px;
  gap: 16px;
`;
