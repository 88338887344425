/* eslint-disable no-unused-expressions */
export const accountHasAgencyBilled = (data: any) => {
  return data?.account?.items?.reduce((x: boolean, item: any) => {
    return item?.payplans.items?.reduce((y: boolean, payplan: any) => {
      if (payplan.billing_method === 'Agency Bill') {
        return true;
      }

      return y;
    }, false);
  }, false);
};

export const agencyAndDirectBilled = (data: any) => {
  let agencyBilled: boolean = false;
  let directBilled: boolean = false;

  data?.account?.items?.forEach((account: any) => {
    account?.payplans.items?.forEach((payplan: any) => {
      if (payplan.billing_method === 'Agency Bill') {
        agencyBilled = true;
      } else {
        directBilled = true;
      }
    });
  });
  return agencyBilled && directBilled;
};

export const agencyBilledOnly = (data: any) => {
  let agencyIndicator = false;
  const reducedList = Array.from(
    new Set(
      data?.account?.items[0]?.payplans?.items?.map(
        (item: { billing_method: any }) => item.billing_method
      )
    )
  );
  if (reducedList.length === 1 && reducedList.includes('Agency Bill')) {
    agencyIndicator = true;
  }
  return agencyIndicator;
};

export const policyIsAgencyBilled = (
  data: any,
  policyNumber: string | undefined
) => {
  let matchingPayPlan: any;

  data?.account?.items?.forEach((account: any) => {
    account?.payplans.items?.forEach((payplan: any) => {
      if (payplan.policy_number === policyNumber) {
        matchingPayPlan = payplan;
      }
    });
  });

  return matchingPayPlan?.billing_method === 'Agency Bill';
};
