import styled from 'styled-components';

export const SnapshotInvoicesContainer = styled.div`
  padding: 20px;
`;

export const ContainerTitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingIcon = styled.img`
  display: block;
  margin: auto;
`;

export const TitleIcon = styled.img`
  display: block;
  width: 24px;
  height: auto;
  margin-right: 10px;
`;

export const ContainerTitle = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  color: #3b3b3b;
`;

export const InvoiceTableHeader = styled.div`
  display: flex;
  background: #f4f6fa;
  margin-top: 15px;
`;

export const InvoiceTableHeaderColumn = styled.div`
  width: 50%;
  padding: 11.5px 15px;
  font-weight: 700;
  font-size: 13px;
  color: #3b3b3b;
`;

export const InvoiceRowsWrap = styled.div`
  max-height: 492px;
  overflow-y: auto;
`;

export const InvoiceTableRow = styled.div`
  display: flex;
  border-bottom: 1px solid #f4f6fa;

  &:nth-last-child(1) {
    border: none;
  }
`;

export const InvoiceTableRowColumn = styled.div`
  width: 28%;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 16px;
  color: #3b3b3b;

  &:last-child {
    text-align: right;
  }

  &.font-red {
    color: #cc1d33;
  }
`;

export const InvoiceTableFooter = styled.div`
  display: flex;
  background: #619424;
`;

export const InvoiceTableFooterColumn = styled.div`
  width: 28%;
  padding: 10px 15px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;

  &:last-child {
    text-align: right;
  }
`;

export const ErrorMessage = styled.p`
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: #3b3b3b;
`;
