import React from 'react';
import styled from 'styled-components';
import Alert from '../../icons/AlertHeaderIcon';

const ErrorHeader = styled.div`
  background: #00b05e;
  width: 100%;
  height: 60px;
  color: white;
  font-style: 'PT Sans';
`;

const IconDiv = styled.div`
  margin-top: 20px;
`;

const ErrorFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

const ErrorMessageFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
`;

const MainErrorMessage = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

interface bannerHeaderInterface {
    featureFlagValue?: any | undefined;
}

export default ({featureFlagValue}: bannerHeaderInterface) => {
  return (
    <ErrorHeader>
      <ErrorFlexDiv>
        <ErrorMessageFlexDiv>
          <MainErrorMessage>
            {featureFlagValue?.bannerMessageMain || 'We are not able to update the content due to server error'}
          </MainErrorMessage>
        </ErrorMessageFlexDiv>
      </ErrorFlexDiv>
    </ErrorHeader>
  );
};