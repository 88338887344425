import React, { useState } from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { GET_BILLING_PAY_PLAN_account_items_payplans_items } from './graphql/__generated__/GET_BILLING_PAY_PLAN';
import { Vehicle, Umbrella, Location, Email } from '../../icons';
import { motifs } from '../../themes/default.theme';
import PaymentAddressModal from './PaymentAddressModal';

const PolicyRow = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${motifs.lightBorder};
  }
  &:not(:first-child) {
    margin-top: 12px;
  }
  display: flex;
  flex-direction: row;
`;

const PointerText = styled(Text)`
  cursor: pointer;
`;

interface PolicyPayPlanProps {
  planData: GET_BILLING_PAY_PLAN_account_items_payplans_items;
  onClick: Function;
}

function policyIcon(policyType: string | null | undefined) {
  let icon = <Box />;
  if (policyType?.toUpperCase().includes('AUTO')) {
    icon = <Vehicle />;
  } else if (
    policyType?.toUpperCase().includes('UMBRELLA') ||
    policyType?.toUpperCase().includes('PACKAGE')
  ) {
    icon = <Umbrella />;
  } else if (policyType !== null) {
    icon = <Location />;
  }
  return icon;
}

export default ({ planData, onClick }: PolicyPayPlanProps) => {
  const [showModal, setShowModal] = useState(false);

  if (
    planData?.policy?.policy_type &&
    planData.policy.policy_type.toLowerCase() === 'workers comp'
  ) {
    if (planData.pay_plan?.toLowerCase() === 'premium reporting') {
      Object.assign(planData, {
        pay_plan: 'Pay-As-You-Go'
      });
      Object.assign(planData.policy, {
        policy_type: "Workers' Compensation"
      });
    }
  }

  let payPlan: {} | null | undefined;

  if (
    planData?.pay_plan &&
    planData?.pay_plan.toLowerCase() === 'mortgagee billed'
  ) {
    payPlan = (
      <PointerText
        size="14px"
        wordBreak="keep-all"
        color="#0173C6"
        onClick={() => setShowModal(true)}
      >
        <Email /> {planData.pay_plan}
      </PointerText>
    );
  } else {
    payPlan = (
      <Text size="14px" wordBreak="keep-all">
        {planData.pay_plan}
      </Text>
    );
  }

  return (
    <>
      <PolicyRow
        direction="row"
        alignContent="center"
        justify="between"
        gap="2px"
        flex
      >
        <Box direction="row" gap="10px">
          <Box width="20px">{policyIcon(planData?.policy?.policy_type)}</Box>
          <Box direction="column">
            <Text weight="bold" size="14px" wordBreak="keep-all">
              {planData?.policy?.policy_type}
            </Text>
            <Text size="14px">
              {planData.billing_method === 'Agency Bill'
                ? planData.billing_method
                : ''}
            </Text>
          </Box>
        </Box>
        <Box align="end" margin={{ bottom: '12px' }}>
          <Box align="end" justify="end">
            <Link
              onClick={() => onClick(planData?.policy?.policy_number)}
              to={`../policy/${planData?.policy?.policy_number}`}
              style={{ textDecoration: 'none' }}
            >
              <Text size="14px" color="brand">
                {planData?.policy?.policy_number}
              </Text>
            </Link>
          </Box>
          {payPlan}
        </Box>
      </PolicyRow>
      <PaymentAddressModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
