import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CenteredHorizontally = styled.div`
  display: flex;
  margin: 0 auto;
`;

export const DisplayableAssetsGrid = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-template-columns: 2;
  grid-template-rows: 2;
`;

interface GridCellProps {
  gridColumn: number;
  gridRow: number;
}

export const GridCell = styled.div<GridCellProps>`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
`;
