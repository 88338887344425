import React, { ChangeEvent } from 'react';

import {
  InputGroup,
  Label,
  ElementWrap,
  DocumentUpload,
  DocumentUploadZWrap,
  FileName,
  BrowseButton,
  FieldSubText,
  SelectorContainer,
  SecuritySelector,
  SecurityOption
} from '../cancelPolicyFormStyles';

interface initialState {
  fileName: string;
  file: File | null;
  error: boolean;
  msg: string;
}

interface CancellationDocumentProps {
  state: initialState;
  onChange: Function;
  disabled: boolean;
  setSecurityType: Function;
  setUploadStatus: Function;
  requestedBy: string;
  uploadStatus: string;
  securityType: string;
}

export const initialState: initialState = {
  fileName: '',
  file: null,
  error: false,
  msg: ''
};

export const isValid = (
  uploadStatus: string,
  file: File | null,
  fileName: string
) => {
  if (uploadStatus !== 'upload') {
    return true;
  }
  return file && fileName;
};

export default ({
  state: { fileName, error, msg },
  onChange,
  disabled,
  setSecurityType,
  setUploadStatus,
  requestedBy,
  uploadStatus,
  securityType
}: CancellationDocumentProps) => (
  <InputGroup test-attr="cancel-policy-cancellation-document">
    {requestedBy !== 'Agent' && (
      <div>
        <SelectorContainer>
          <SecuritySelector
            type="radio"
            name="UploadOption"
            value="upload"
            checked={uploadStatus === 'upload'}
            onChange={() => setUploadStatus('upload')}
          />
          <SecurityOption>Upload Document</SecurityOption>
        </SelectorContainer>
        <SelectorContainer>
          <SecuritySelector
            type="radio"
            name="UploadOption"
            value="later"
            checked={uploadStatus === 'later'}
            onChange={() => setUploadStatus('later')}
          />
          <SecurityOption>Upload Later</SecurityOption>
        </SelectorContainer>
        <SelectorContainer>
          <SecuritySelector
            type="radio"
            name="UploadOption"
            value="complete"
            checked={uploadStatus === 'complete'}
            onChange={() => setUploadStatus('complete')}
          />
          <SecurityOption>Already Uploaded</SecurityOption>
        </SelectorContainer>
      </div>
    )}
    {uploadStatus === 'upload' && (
      <div>
        <Label error={error}>Cancellation Document</Label>
        <ElementWrap error={error}>
          <DocumentUpload
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const files: FileList | null | undefined = e?.target?.files;
              if (files && files.length) {
                onChange(files[0], files[0].name || 'No File Name');
              }
            }}
            disabled={disabled}
            accept=".pdf"
          />
          <DocumentUploadZWrap>
            <FileName>{fileName}</FileName>
            <BrowseButton>Browse</BrowseButton>
          </DocumentUploadZWrap>
        </ElementWrap>
        <div>
          <InputGroup test-attr="cancel-policy-document-security-type">
            <SelectorContainer>
              <SecuritySelector
                type="radio"
                name="SecurityType"
                value="sensitive"
                checked={securityType === 'sensitive'}
                onChange={() => {
                  setSecurityType('sensitive');
                }}
              />
              <SecurityOption>Send to State Auto Only</SecurityOption>
            </SelectorContainer>
            <SelectorContainer>
              <SecuritySelector
                type="radio"
                name="SecurityType"
                value="unrestricted"
                checked={securityType === 'unrestricted'}
                onChange={() => setSecurityType('unrestricted')}
              />
              <SecurityOption>Send to Customer and State Auto</SecurityOption>
            </SelectorContainer>
          </InputGroup>
        </div>
        <FieldSubText error={error}>{msg}</FieldSubText>
      </div>
    )}
  </InputGroup>
);
