import React, { useState, ChangeEvent } from 'react';

import {
  LossRunModalWrap,
  LossRunModalContainer,
  ModalHeaderRow,
  ModalHeaderText,
  ModalCloseBtn,
  PolicySelectGroup,
  SelectLabel,
  ElementWrap,
  SelectElement,
  SelectChevron,
  ModalAlertWrap,
  ModalAlertIcon,
  ModalAlertTitle,
  ModalAlertDescription,
  ModalBtnRow,
  ModalCancelBtn,
  ModalContinueBtn
} from './LossRunModalStyles';

import CloseSrc from '../../../assets/images/ErrorAltClose.svg';
import ChevronIconSrc from '../../../assets/images/chevron-down.svg';
import SuccessSrc from '../../../assets/images/Success-Alt.svg';
import ErrorSrc from '../../../assets/images/ErrorIcon.svg';

export type PolicyObj = {
  policy_number: string;
  policy_type: string;
};

interface Props {
  disabled: boolean;
  options: { label: string; value: PolicyObj }[];
  close: Function;
  submit: Function;
  submitError: boolean;
  submitSuccess: boolean;
}

const LossRunModal = ({
  disabled,
  options,
  close,
  submit,
  submitError,
  submitSuccess
}: Props) => {
  const [selectedPolicy, setSelectedPolicy] = useState<string>(
    options[0]?.label
  );
  const [active, setActive] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = () => {
    if (selectedPolicy !== options[0].label) {
      submit(
        options.find(option => option.label === selectedPolicy)!.value
          .policy_number
      );
    } else {
      setError(true);
    }
  };

  const policySelect = (
    <PolicySelectGroup className={`${disabled ? 'disabled' : ''}`}>
      <SelectLabel
        className={`${active ? 'active' : ''} ${error ? 'error' : ''}`}
      >
        Policy
      </SelectLabel>
      <ElementWrap
        className={`${active ? 'active' : ''} ${error ? 'error' : ''}`}
      >
        <SelectElement
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          value={selectedPolicy}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            if (!disabled) {
              e.target.blur();
              setSelectedPolicy(e.target.value);
              if (error) setError(false);
            }
          }}
          disabled={disabled}
        >
          {options.map(option => (
            <option key={option.label}>{option.label}</option>
          ))}
        </SelectElement>
        <SelectChevron src={ChevronIconSrc} />
      </ElementWrap>
    </PolicySelectGroup>
  );

  const submissionAlert = (
    <ModalAlertWrap>
      <ModalAlertIcon
        className={`${submitError ? 'submitError' : ''}`}
        src={submitError ? ErrorSrc : SuccessSrc}
      />
      <ModalAlertTitle>
        {submitError
          ? 'An error occurred.'
          : 'Your request is being processed.'}
      </ModalAlertTitle>
      <ModalAlertDescription>
        {submitError
          ? 'Please contact underwriting for assistance.'
          : 'It may take several minutes for the document to display. Please view the Documents tab for the document.'}
      </ModalAlertDescription>
    </ModalAlertWrap>
  );

  return (
    <LossRunModalWrap>
      <LossRunModalContainer>
        <ModalHeaderRow>
          {!(submitError || submitSuccess) && (
            <ModalHeaderText>Select a Policy</ModalHeaderText>
          )}
          <ModalCloseBtn
            className={`${disabled ? 'disabled' : ''}`}
            onClick={() => {
              if (!disabled) close();
            }}
            src={CloseSrc}
          />
        </ModalHeaderRow>
        {!(submitError || submitSuccess) ? policySelect : submissionAlert}
        <ModalBtnRow>
          {submitError || submitSuccess ? (
            <ModalContinueBtn onClick={() => close()}>CLOSE</ModalContinueBtn>
          ) : (
            <>
              <ModalCancelBtn
                className={`${disabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!disabled) close();
                }}
              >
                CANCEL
              </ModalCancelBtn>
              <ModalContinueBtn
                className={`${disabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!disabled) handleSubmit();
                }}
              >
                CONTINUE
              </ModalContinueBtn>
            </>
          )}
        </ModalBtnRow>
      </LossRunModalContainer>
    </LossRunModalWrap>
  );
};

export default LossRunModal;
