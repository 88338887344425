import styled from 'styled-components';

export const LastPaymentWrap = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 15px;
  border: 1px solid #bfcbdc;
  border-radius: 4px;
`;

export const LastPaymentAmtText = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #3b3b3b;
`;

export const LastPaymentDateWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LastPaymentSuccessIcon = styled.img`
  display: block;
  width: 16px;
  height: auto;
  margin-right: 5px;
`;

export const LastPaymentDate = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  color: #3b3b3b;
`;

export const NextPaymentWrap = styled.div`
  padding: 30px;
`;

export const NextPaymentSectionTitle = styled.p`
  font-weight: 700;
  font-size: 19px;
  line-height: 19px;
  color: #3b3b3b;
  margin: 0 0 15px;
`;

export const NextPaymentAmount = styled.p`
  font-weight: 700;
  font-size: 33px;
  line-height: 33px;
  color: #005c86;
  margin: 0 0 8px;
`;

export const NextPaymentDate = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
  margin: 0 0 6px;
  display: grid;

  b {
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
  }
`;

export const NextPaymentAmtDisclaimer = styled.p`
  font-size: 13px;
  line-height: 13px;
  color: #3b3b3b;
  margin: 0;
`;

export const PastDuePill = styled.div`
  display: inline-flex;
  align-items: center;
  background: #fdeaee;
  border: 1px solid #b00020;
  border-radius: 30px;
  padding: 6px 12px;
  margin-top: 25px;
`;

export const PastDuePillIcon = styled.img`
  display: block;
  width: 13px;
  height: auto;
  margin-right: 6px;
`;

export const PastDuePillText = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #b00020;
  margin: 0;
`;

export const LastDayToPayText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #7a5212;
  margin-top: 10px;
`;

export const FooterButtonsWrap = styled.div`
  position: relative;
  display: flex;
  margin-top: 35px;

  & > div:first-child {
    position: relative;
    z-index: 1;

    button {
      padding: 8px 20px;
      width: auto;
      height: auto;
      background: #4d840b;
      border-radius: 5px;
      margin-right: 10px;

      span {
        font-size: 19px;
        font-weight: 700;
      }
    }
  }

  & > div:last-child button {
    border-radius: 5px;
    padding: 8px 20px;
    font-size: 19px;
    font-weight: 700;
  }
`;
