import React from 'react';
import {
  Calendar,
  NewDate,
  UnavailableDateButton,
  SingleDate
} from './changeDueDateStyles';
import daysOfTheMonth from '../../util/generaters/daysOfTheMonth';

interface CalendarProps {
  newDate: string;
}

export default ({ newDate }: CalendarProps) => {
  return (
    <Calendar>
      {daysOfTheMonth.map((day: string) => {
        if (newDate && newDate === day) {
          return (
            <NewDate id="new-date">
              {day.length === 1 ? <SingleDate>{day}</SingleDate> : day}
            </NewDate>
          );
        }
        if (day.includes('unavailable')) {
          return (
            <UnavailableDateButton>{day.split('-')[0]}</UnavailableDateButton>
          );
        }
        return <UnavailableDateButton>{day}</UnavailableDateButton>;
      })}
    </Calendar>
  );
};
