import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Details from './Details';

const getWCDetailsQuery = loader('./graphql/Get_WC_Details.graphql');
export interface WCClaimsData {
    getWCDetails?: {
      activitieswheninjured?: string;
      causeofinjury?: string;
      ernotifiedofinjurydate?: string;
      injuryreporteddate?: string;
      losslocationaddressline1?: string;
      losslocationcity?: string;
      losslocationstate?: string;
      losslocationzip?: string;
      natureofinjury?: string;
      occupation?: string;
      partofbody?: string;
      returntoworkdate?: string;
      dateofloss?: string;
      injuredContactDetails?: {
        name?: string;
      };
      employerContactDetails?: {
        name?: string;
      };
    };
  }
export interface DetailsProps {
    accountNumber: string;
    policyNumber: string;
    claimNumber: string | boolean;
    name?:string
  }
  const DetailsScreen: React.FC<DetailsProps> = ({
    accountNumber,
    policyNumber,
    claimNumber,
    name
  }) => {
    const variables = {
      accountNumber,
      policyNumber,
      claimNumber
    };
    const {
      data: getWCDetailsResult,
      loading: wcDetailsResultLoading
    } = useQuery(getWCDetailsQuery, {
      variables
    });

    const getWCDetails: WCClaimsData = getWCDetailsResult?.getWCDetails ?? {};

    return (
      <Details
        getWCDetails={getWCDetails}
        loading={wcDetailsResultLoading || false}
        name={name}
      />
    );
  };
  
  export default DetailsScreen;