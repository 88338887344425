import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import moment from 'moment-timezone';
import { ChangeDueDateModal } from '.';
import { GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items } from './graphql/__generated__/GET_CANCEL_PAYMENT_INVOICES';

const updateInvoiceDue = loader('./graphql/Update_Invoice_Due_Date.graphql');

const checkAvailable = loader(
  './graphql/Check_Update_Bill_Due_Date_Eligibility.graphql'
);

const getCurrentDate = loader('./graphql/Get_Billing_Date.graphql');

const get_cancel_payment = loader(
  './graphql/Get_Cancel_Payment_Invoices.graphql'
);

const get_payment_info = loader(
  './graphql/Change_Due_Date_Get_Filtered_Payment_Info.graphql'
);

const get_user_id = loader('./graphql/Change_Due_Date_Get_User_Id.graphql');

const filterNonCancelablePayments = (
  invoices: (GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items | null)[]
) => {
  const filteredInvoices: GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items[] = invoices.filter(
    (
      invoice: GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items | null
    ): invoice is GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items => {
      if (!invoice?.pmt_draft_date) {
        return false;
      }
      const draft_date = moment.tz(
        invoice.pmt_draft_date,
        'YYYY-MM-DD',
        'America/New_York'
      );
      const currentDate = moment.tz('America/New_York');
      const isBeforeDratDate =
        currentDate.isBefore(draft_date) ||
        (currentDate.isSameOrBefore(draft_date, 'days') &&
          invoice.pmt_status === 'Canceled');
      if (
        isBeforeDratDate &&
        currentDate.isSameOrAfter(draft_date.clone().subtract(10, 'days'))
      ) {
        return true;
      }
      return false;
    }
  );
  return filteredInvoices;
};

const containsDesiredPaymentStatus = (
  invoices: GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items[],
  desiredStatus: string
) => {
  let containsDesiredStatus: boolean = false;
  let firstInvoiceWithStatus: GET_CANCEL_PAYMENT_INVOICES_account_items_invoices_items | null = null;
  for (let i = 0; i < invoices.length; i += 1) {
    if (invoices[i].pmt_status === desiredStatus) {
      containsDesiredStatus = true;
      firstInvoiceWithStatus = invoices[i];
      break;
    }
  }
  return {
    containsDesiredStatus,
    firstInvoiceWithStatus
  };
};
interface changeDueDateProps {
  setShowModal: Function;
}

export default ({ setShowModal }: changeDueDateProps) => {
  const { accountNumber } = useParams();
  const variables = {
    account_number: accountNumber
  };

  const [updateDue, { loading, error, data: updatedDate }] = useMutation(
    updateInvoiceDue
  );

  const [getAvailable, availableResult] = useMutation(checkAvailable);
  const { loading: isAvailable, data: availability } = availableResult;

  const currentDateResult = useQuery(getCurrentDate, { variables });
  const { data: currentDate } = currentDateResult;
  let date = '';
  if (currentDate) {
    date = currentDate.account.items[0].payment.fixed_bill_due_date;
  }

  const userResult = useQuery(get_user_id);
  const { data: userInfo } = userResult;
  let user = '';
  if (userInfo) {
    user = userInfo.me?.user_id;
  }

  const timeToPaymentResult = useQuery(get_cancel_payment, { variables });
  const { data: paymentResult } = timeToPaymentResult;
  let filteredPayments;
  let within10Days = false;
  if (paymentResult) {
    filteredPayments = filterNonCancelablePayments(
      paymentResult?.account?.items[0]?.invoices.items
    );
    within10Days = containsDesiredPaymentStatus(filteredPayments, 'Requested')
      .containsDesiredStatus;
  }

  const getPastDueResult = useQuery(get_payment_info, { variables });
  const { loading: pastDueLoading, data: getPastDue } = getPastDueResult;
  let pastDueAmount = '-';
  if (
    !pastDueLoading &&
    getPastDue &&
    getPastDue.account.items[0].payments.items !== null &&
    getPastDue.account.items[0].payments.items[0].past_amount_due !== null
  ) {
    const [temp, ...rest] = getPastDue.account.items[0].payments.items;
    pastDueAmount = temp.past_amount_due;
  }

  const [hasBeenCalled, setCalled] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [stoppedLoading, setStoppedLoading] = useState<boolean>(false);
  const [noFutureInvoices, setNoFutureInvoices] = useState<boolean>(false);
  useEffect(() => {
    if (accountNumber && !hasBeenCalled) {
      getAvailable({ variables });
      setCalled(true);
    }
  });

  useEffect(() => {
    if (
      !isAvailable &&
      availability?.updateBillDueDate?.reason &&
      ![
        'Account has no planned invoices and cannot change the due date',
        'Billing due dates can only change once every 365 days',
        'Given due date is same as current invoices due date and it cannot be changed'
      ].includes(availability?.updateBillDueDate?.reason)
    ) {
      setAvailable(true);
      setStoppedLoading(true);
    }
    if (!isAvailable && hasBeenCalled) {
      setStoppedLoading(true);
    }
    if (
      availability?.updateBillDueDate?.reason ===
      'Account has no planned invoices and cannot change the due date'
    ) {
      setNoFutureInvoices(true);
    }
  }, [isAvailable, availability?.updateBillDueDate?.reason]);

  return (
    <ChangeDueDateModal
      loading={loading}
      error={error}
      setShowModal={setShowModal}
      updateDue={updateDue}
      updatedDate={updatedDate?.updateInvoiceDueDate}
      current={date}
      available={available}
      accountNumber={accountNumber}
      within10Days={within10Days}
      pastDue={pastDueAmount !== '-'}
      hasBeenCalled={hasBeenCalled}
      stoppedLoading={stoppedLoading}
      user={user}
      noFutureInvoices={noFutureInvoices}
    />
  );
};
