import React from 'react';
import ChevronDown from '../assets/images/chevron-down.svg';
interface ChevronIconProps {
  width?: number;
  height?: number;
}
const ChevronDownUnbolded: React.FC<ChevronIconProps> = ({ width = 14, height = 8 }) => {
  return (
    <div>
      <img src={ChevronDown} width={width} height={height} />
    </div>
  );
};

export default ChevronDownUnbolded;