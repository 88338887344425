import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { formatMoney } from '../../util/formatters';
import { InvoiceSnapshot } from '..';
import { colors } from '../../themes/default.theme';
import { displayableInvoice } from './BillingHistory';
import { ChevronDownUnbolded, ChevronUp } from '../../icons';
import { ContentsTag } from '.';

function renderNumber(value: number) {
  if (value > 0) {
    return <Text>{formatMoney(value)}</Text>;
  }
  if (value === 0) {
    return <Text>-</Text>;
  }
  return <Text color={colors.valueRed1}>{formatMoney(value)}</Text>;
}

interface TableContentProps {
  invoice: displayableInvoice;
  visible: boolean;
  onClick: Function;
}

const DataGroup = styled.section`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
`;

export const Data = styled.li`
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100%;
  }
  color: #041e41;
  display: flex;
  width: 11.6666667%;
  min-width: 81.66px;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  margin: 0;
  padding: 10px;
  word-break: break-all;
  &:nth-child(2) {
    width: 30%;
    min-width: 190px;
  }
  &:last-child {
    justify-content: center;
  }
`;

const OuterBox = styled.div<{ visible?: boolean }>`
  &:hover {
    cursor: pointer;
    border-left: 4px solid #9325b2;
  }
  border-left: ${props =>
    props.visible ? '4px solid #9325b2' : '4px solid #ffffff'};
`;

export default ({ invoice, visible, onClick }: TableContentProps) => {
  return (
    <OuterBox visible={visible}>
      <DataGroup onClick={() => onClick(invoice.invoice_number)}>
        <Data>{invoice.due_date}</Data>
        <Data>
          <Box direction="row" wrap>
            {invoice.contents.map(content => {
              let color = '';
              const lowerCaseContent = content.toLowerCase();
              if (['credit', 'payment reversal'].includes(lowerCaseContent)) {
                color = 'green';
              }
              if (lowerCaseContent === 'cancellation') {
                color = 'red';
              }
              return (
                <ContentsTag color={color} testId={`${content}-contents-tag`}>
                  {content}
                </ContentsTag>
              );
            })}
          </Box>
        </Data>
        <Data>{invoice.docuid}</Data>
        <Data>
          <Text>{invoice.invoice_amount ? renderNumber(invoice.invoice_amount) : ""}</Text>
        </Data>
        <Data>
          <Text>{invoice.paid_amount ? renderNumber(invoice.paid_amount) : ""}</Text>
        </Data>
        <Data>
          <Text>{invoice.amount_due ? renderNumber(invoice.amount_due) : ""}</Text>
        </Data>
        {!visible ? (
          <Data>
            <ChevronDownUnbolded />
          </Data>
        ) : (
          <Data>
            <ChevronUp />
          </Data>
        )}
      </DataGroup>
      {visible && <InvoiceSnapshot invoiceNumber={invoice.invoice_number || ""} />}
    </OuterBox>
  );
};
