import styled from 'styled-components';
import { colors } from '../../themes/default.theme';
import { CancelPolicyAccordionHeadProps } from './cancelPolicy';

interface accordionBodyProps {
  isOpen: boolean;
}

export const CancelPolicyAccordionWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px #01538e33;
  margin-bottom: 20px;
  background-color: #ffffff;
`;

export const CancelPolicyAccordionHead = styled.div<
  CancelPolicyAccordionHeadProps
>`
  cursor: pointer;
  display: flex;
  height: 60px;
  align-items: stretch;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px #01538e33;

  :hover > div:first-child {
    background-color: ${colors.green1};
  }
  pointer-events: ${props => (props?.disabled ? 'none' : 'auto')};
  cursor: ${props => props?.disabled && 'not-allowed'};
  & > div:nth-child(2) > p {
    color: ${props => props?.disabled && '#979797'};
  }
  & > div:first-child {
    background-color: #e0e0e0;
    width: 30px;
  }
  & > * {
    cursor: ${props => props?.disabled && 'not-allowed'};
  }
`;

export const HeaderIconWrap = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
  background-color: #0072c6;
`;

export const HeaderIcon = styled.img`
  display: block;
  width: 36px;
  height: auto;
`;

export const HeaderTitleWrap = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-grow: 1;
`;

export const HeaderTitle = styled.p`
  flex-grow: 1;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #3b3b3b;
`;

export const HeaderChevronWrap = styled.div`
  display: block;
  padding: 0 14px;
`;

export const CancelPolicyAccordionBody = styled.div<accordionBodyProps>`
  ${props => (props.isOpen ? '' : 'display: none;')}
`;

export const DisabledText = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff6e9;
  border: 1px solid #f5a524;
  border-left: 5px solid #f5a524;
  border-radius: 4px;
  color: #772f1d;
  margin: 30px;
  padding: 16px;
  font-size: 14px;
  gap: 16px;
`;
