import React from 'react';
import styled from 'styled-components';
import getConfig, { Env } from '../../config';
import { genericGTMDataLayer } from '../../util/GTMHelpers';

const config = getConfig(process.env.REACT_APP_ENV as Env);

const StartNewQuoteButton = styled.button`
  background-color: #4d840b;
  color: #ffffff;
  border-style: none;
  border-radius: 5px;
  padding: 13px 25px 14px 25px;
  font-weight: 700;
  font-size: 19px;
  cursor: pointer;
  max-width: 252px;
`;

const startNewQuoteClick = () => {
  genericGTMDataLayer({
    event_action: 'Button Click',
    event_label: 'Start A New Quote',
    event_name_snowplow: 'snowplow-quotePageNewQuoteClick'
  });
  window.open(config.saconnect_url_endpoint, '_self');
};

export default () => (
  <StartNewQuoteButton onClick={startNewQuoteClick}>
    START A NEW QUOTE
  </StartNewQuoteButton>
);
