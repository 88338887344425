import React, { useState } from 'react';
import {
  Popup,
  WcClaimsButton,
  Overlay,
  StyledPrimaryButton,
  RowContainer,
  AdvancedFilterContainer,
  AdvancedFilterButton,
  Span,
  Label,
  WarningText,
  Button,
  Message,
  Header,
  Icons,
  Heading,
  Value
} from './AdvancedFilterStyles';
import moment from 'moment';
import { ClaimsData } from '../../pages/ClaimsPage/DataContainer';
import { formatDate } from '../../util/formatters';
import {
  EmptyCheckBox,
  CheckedCheckBox,
  BlueCross,
  RedInfo,
  TrashCan
} from '../../icons';
import LocationDropDown from './LocationDropDown';
import InjuredNameInput from './InjuredNameInput';
import CustomDatePicker from './Calender';


interface AdvancedFilterProps {
  searchBarPosition: boolean;
  claimsList: ClaimsData[] | null;
  setSearchResults: React.Dispatch<React.SetStateAction<ClaimsData[] | null>>;
  setEmptySearchResults: React.Dispatch<React.SetStateAction<boolean>>;
}
export const formatAddress = (
  losslocationaddressline1?: string,
  losslocationcity?: string,
  losslocationstate?: string,
  losslocationzip?: string
) => {
  const sanitizedlosslocationaddressline1 = losslocationaddressline1
    ? losslocationaddressline1 + ', '
    : '';
  const sanitizedlosslocationcity = losslocationcity
    ? losslocationcity + ', '
    : '';
  const sanitizedlosslocationstate = losslocationstate
    ? losslocationstate + ', '
    : '';
  const sanitizedlosslocationzip = losslocationzip ? losslocationzip : '';
  const optionValue = `${sanitizedlosslocationaddressline1} 
  ${sanitizedlosslocationcity} ${sanitizedlosslocationstate}
   ${sanitizedlosslocationzip}`;
  return optionValue;
};
const AdvancedFilter = ({
  searchBarPosition,
  claimsList,
  setSearchResults,
  setEmptySearchResults
}: AdvancedFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [invalid, setInvalid] = useState<boolean>(false);
  //the states
  const [isClaimOpen, setIsClaimOpen] = useState<boolean>(false);
  const [isClaimClose, setIsClaimClose] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [injuredDOB, setInjuredDOB] = useState<Date | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [injuredName, setInjuredName] = useState('');

  const handleClaimOpenCheckBox = () => {
    if (isClaimOpen) {
      setIsClaimOpen(false);
    } else {
      if (isClaimClose) setIsClaimClose(false);
      setIsClaimOpen(true);
    }
  };
  const handleClaimCloseCheckBox = () => {
    if (isClaimClose) {
      setIsClaimClose(false);
    } else {
      if (isClaimOpen) setIsClaimOpen(false);
      setIsClaimClose(true);
    }
  };
  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setInjuredDOB(null);
    setIsClaimClose(false);
    setIsClaimOpen(false);
    setSelectedOptions([]);
    setInjuredName('');
    setInvalid(false);
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const isBaseDateGreaterThanSelectedDate = () => {
    if (startDate && endDate) {
      return moment(startDate).isAfter(endDate);
    }
    return false;
  };
  
  const handleFilter = () => {
    if (!invalid) {
      closePopup();
      setEmptySearchResults(false);
      setFilterCount(0);
      let filteredClaims: ClaimsData[] = claimsList ? [...claimsList] : [];
      if (claimsList) {
        if (isClaimOpen) {
          setFilterCount(prevFilterCount => prevFilterCount + 1);
          filteredClaims = filteredClaims.filter(
            claim => claim.claim_status === 'Open'
          );
        } else if (isClaimClose) {
          setFilterCount(prevFilterCount => prevFilterCount + 1);
          filteredClaims = filteredClaims.filter(
            claim => claim.claim_status === 'Closed'
          );
        }
        if (injuredName) {
          setFilterCount(prevFilterCount => prevFilterCount + 1);
          filteredClaims = filteredClaims.filter(
            claim => claim.name === injuredName
          );
        }
        if (injuredDOB) {
          setFilterCount(prevFilterCount => prevFilterCount + 1);
          const DOB = new Date(injuredDOB);
          filteredClaims = filteredClaims.filter(claim => {
            // @ts-ignore
            const formattedClaimDate = new Date(formatDate(claim.dateofbirth));
            return formattedClaimDate.getTime() === DOB.getTime();

          });
        }

        if (startDate && endDate) {
          const startDateTime = new Date(startDate);
          const endDateTime = new Date(endDate);
          setFilterCount(prevFilterCount => prevFilterCount + 1);
          filteredClaims = filteredClaims.filter(claim => {
            // @ts-ignore
            const formattedClaimDate = new Date(formatDate(claim.dateofloss));
            return (
              formattedClaimDate > startDateTime &&
              formattedClaimDate < endDateTime
            );
          });
        }

        if (selectedOptions.length > 0) {
          setFilterCount(prevFilterCount => prevFilterCount + 1);
          filteredClaims = filteredClaims.filter(claim =>
            selectedOptions.includes(
              formatAddress(
                claim.losslocationaddressline1,
                claim.losslocationcity,
                claim.losslocationstate,
                claim.losslocationzip
              )
            )
          );
        }
        if (filteredClaims.length < 1) {
          setEmptySearchResults(true);
        }
        
        setSearchResults(filteredClaims);
      }
    }
  };

  const resetFilter = () => {
    setFilterCount(0);
    handleClear();
    setSearchResults(claimsList);
    setEmptySearchResults(false);
  };
  return (
    <div>
      {searchBarPosition ? (
        <>
          {filterCount === 0 ? (
            <AdvancedFilterButton onClick={openPopup}>
              Advanced Filter
            </AdvancedFilterButton>
          ) : (
            <RowContainer>
              <AdvancedFilterButton onClick={openPopup}>
                View/Edit Filters({filterCount})
              </AdvancedFilterButton>
              <Span onClick={resetFilter}>
                <TrashCan isBlue={false} />
              </Span>
            </RowContainer>
          )}
        </>
      ) : (
        <>
          {filterCount === 0 ? (
            <RowContainer>
              <WcClaimsButton onClick={openPopup}>
                Advanced Filter
              </WcClaimsButton>
            </RowContainer>
          ) : (
            <RowContainer>
              <WcClaimsButton onClick={openPopup}>
                View/Edit Filters({filterCount})
              </WcClaimsButton>
              <Span onClick={resetFilter}>
                <TrashCan isBlue={true} />
              </Span>
            </RowContainer>
          )}
        </>
      )}

      {isOpen && (
        <Overlay>
          <Popup>
            <AdvancedFilterContainer>
              <Header>
                <Heading>Filter Existing Claims Associated with Policy</Heading>
                <Icons onClick={closePopup}>
                  <BlueCross height="20px" width="20px" />
                </Icons>
              </Header>

              <InjuredNameInput
                claimsList={claimsList}
                injuredName={injuredName}
                setInjuredName={setInjuredName}
              ></InjuredNameInput>
              <Label>Claim Status</Label>

              <RowContainer>
                <span onClick={handleClaimOpenCheckBox}>
                  {isClaimOpen ? (
                    <CheckedCheckBox width={34} height={34} />
                  ) : (
                    <EmptyCheckBox width={35} height={35} />
                  )}
                </span>
                <Value>Open</Value>
                <span onClick={handleClaimCloseCheckBox}>
                  {isClaimClose ? (
                    <CheckedCheckBox width={34} height={34} />
                  ) : (
                    <EmptyCheckBox width={35} height={35} />
                  )}
                </span>
                <Value>Closed</Value>
              </RowContainer>

              <Label>Loss Location</Label>

              <LocationDropDown
                claimsList={claimsList}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
              />

              <Label>Date of Injury</Label>
              <RowContainer>
                <CustomDatePicker
                  selectedDate={startDate}
                  setSelectedDate={setStartDate}
                  inputName="Start Date"
                />
                <Value> &nbsp; -</Value>
                <CustomDatePicker
                  selectedDate={endDate}
                  setSelectedDate={setEndDate}
                  inputName="End Date"
                />
              </RowContainer>
              {isBaseDateGreaterThanSelectedDate() && (
                <Message>
                  <RedInfo />
                  <WarningText>
                    The start date cannot be greater than end date.
                  </WarningText>
                </Message>
              )}
              <RowContainer>
                <CustomDatePicker
                  selectedDate={injuredDOB}
                  setSelectedDate={setInjuredDOB}
                  inputName="Injured Date Of Birth"
                />
              </RowContainer>
              <RowContainer>
                <Button>
                  <WcClaimsButton bool={true} onClick={handleClear}>
                    Clear
                  </WcClaimsButton>
                  <StyledPrimaryButton onClick={handleFilter}>
                    Filter
                  </StyledPrimaryButton>
                </Button>
              </RowContainer>
            </AdvancedFilterContainer>
          </Popup>
        </Overlay>
      )}
    </div>
  );
};

export default AdvancedFilter;
