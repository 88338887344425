import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import {
  useParams,
  RouteComponentProps,
  useLocation,
  navigate
} from '@reach/router';
import { Box } from 'grommet';
import styled from 'styled-components';
import { CommunicationAccordion } from '.';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useDocumentDownload from '../../util/useDocumentDownload';

const get_communication_items = loader(
  './graphql/Get_Communication_Items.graphql'
);

export const StyledModal = styled(Box)`
  padding: 15px;
  width: 825px;
  height: 401px;
  display: flex;
  text-align: center;
`;

interface commAccordionDCProps extends RouteComponentProps {
  page?: string | undefined;
}

export default ({ page }: commAccordionDCProps) => {
  const { accountNumber } = useParams();
  const [items, setItems] = useState([]);

  const variables = {
    account_number: accountNumber
  };

  const location = useLocation();
  const pathname = location.pathname.split('/')[3];

  const { data, error, loading: isLoading } = useQuery(
    get_communication_items,
    {
      variables,
      onCompleted: () => {
        if (data?.account?.items) {
          setItems(data.account.items[0].communications.items);
        }
      }
    }
  );

  let gtmViewAllEvent: string = '';
  let gtmOpenCommunicationEvent: string = '';
  if (page) {
    gtmViewAllEvent = `click-${page}ViewAllCommunications`;
    gtmOpenCommunicationEvent = `click-${page}OpenCommunication`;
  }

  const { onDocumentClick, documentModal } = useDocumentDownload(
    accountNumber,
    'communication',
    gtmOpenCommunicationEvent
  );

  const gtmViewAll = () => {
    simpleGTMDataLayer({
      event: gtmViewAllEvent,
      page: 'Communications'
    });
    navigate(`/account/${accountNumber}/communications`);
  };

  const props = {
    isLoading,
    error,
    onDocumentClick,
    items,
    pathname,
    accountNumber,
    onViewAll: gtmViewAll
  };

  return (
    <Box>
      {documentModal}
      <CommunicationAccordion {...props} />
    </Box>
  );
};
