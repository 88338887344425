import React, { useState } from 'react';
import moment from 'moment-timezone';

import DatePicker from '..';
import { today } from '../DatePickerUtil';

import { ModalWrap, ModalBody } from './DatePickerTest.styles';

export default () => {
  const [date, setDate] = useState('');
  const [showModal, setShowModal] = useState(true);

  return (
    <>
      {showModal && (
        <ModalWrap>
          <ModalBody>
            <DatePicker
              date={date}
              setDate={setDate}
              minDate={moment(today)
                .subtract(10, 'd')
                .format('YYYY-MM-DD')}
              maxDate={moment(today)
                .add(10, 'd')
                .format('YYYY-MM-DD')}
              onClickCancel={() => setShowModal(false)}
              onClickOk={() => setShowModal(false)}
            />
          </ModalBody>
        </ModalWrap>
      )}
    </>
  );
};
