import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { SearchBar } from '.';
import {
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_searchAccount_search_response
} from './graphql/__generated__/GET_SEARCH_RESULTS';
import { generateJSX } from './AutoComplete';
import { formatTitleCase } from '../../util/formatters';
import { NoSearchResults } from '../../icons';
import {
  processPhone,
  formatHighlightTitleCase
} from './helpers/searchDCHelper';
import parseSearchHighlighting from '../../util/parseSearchHighlighting';
import { DisplayableResult } from './SearchDisplayInterfaces';
import { SEARCH_PANEL_GET_QUOTE_SEARCH_SELECTION_BEHAVIOR } from './graphql/__generated__/SEARCH_PANEL_GET_QUOTE_SEARCH_SELECTION_BEHAVIOR';

const get_search_results_query = loader('./graphql/Get_Search_Results.graphql');
const get_quote_search_selection_behavior = loader(
  './graphql/Search_Panel_Get_Quote_Search_Selection_Behavior.graphql'
);

const filterNulls = (value: string | null): value is string => !!value;

interface searchPanelLocation {
  header?: boolean | undefined;
}

const HoverableBox = styled(Box)`
  & {
    font-size: 14px;
    width: 837px;
  }
  padding: 30px;
  aligncontent: start;
  background: #ffffff;
  direction: row;
  margin-left: 8px;
  justify: between;
`;

const TextBox = styled(Box)`
  margin-left: 41px;
`;
const DropdownBox = styled(Box)`
  aligncontent: center;
`;
const LinkHolder = styled.span`
  text-decoration: underline;
  font-size: 16px;
  color: #0173c6;
`;

export const transformData = (data: GET_SEARCH_RESULTS) => {
  const valueArray: DisplayableResult[] = [];

  const content: DisplayableResult[] =
    data?.searchAccount?.search_response
      ?.map(
        (
          hitPossibleNulls: GET_SEARCH_RESULTS_searchAccount_search_response | null
        ) => {
          if (!hitPossibleNulls) {
            return null;
          }
          const hit = {
            account_number: hitPossibleNulls.account_number ?? '',
            cell_phone: hitPossibleNulls.cell_phone ?? '',
            display_name: formatTitleCase(hitPossibleNulls.display_name) ?? '',
            email_address: hitPossibleNulls.email_address ?? '',
            primary_phone: hitPossibleNulls.primary_phone ?? '',
            address:
              formatTitleCase(hitPossibleNulls.address?.replace(/\n/g, ', ')) ??
              '',
            home_phone: hitPossibleNulls.home_phone ?? '',
            work_phone: hitPossibleNulls.work_phone ?? '',
            policy_numbers:
              hitPossibleNulls.policy_numbers?.filter(filterNulls) ?? [],
            quote_number: hitPossibleNulls.quote_number ?? '',
            status: hitPossibleNulls.status ?? '',
            premium: hitPossibleNulls.premium ?? NaN,
            line_of_business: hitPossibleNulls.line_of_business ?? '',
            quote_effective_date: hitPossibleNulls.quote_effective_date ?? '',
            document_type: hitPossibleNulls.document_type ?? '',
            transaction_type: hitPossibleNulls.transaction_type ?? '',
            highlights: {
              account_number:
                hitPossibleNulls.highlights?.account_number?.filter(
                  filterNulls
                ) ?? [],
              cell_phone:
                hitPossibleNulls.highlights?.cell_phone?.filter(filterNulls) ??
                [],
              display_name:
                hitPossibleNulls.highlights?.display_name?.filter(
                  filterNulls
                ) ?? [],
              email_address:
                hitPossibleNulls.highlights?.email_address?.filter(
                  filterNulls
                ) ?? [],
              primary_phone:
                hitPossibleNulls.highlights?.primary_phone?.filter(
                  filterNulls
                ) ?? [],
              address:
                hitPossibleNulls.highlights?.address?.filter(filterNulls) ?? [],
              home_phone:
                hitPossibleNulls.highlights?.home_phone?.filter(filterNulls) ??
                [],
              work_phone:
                hitPossibleNulls.highlights?.work_phone?.filter(filterNulls) ??
                [],
              policy_numbers:
                hitPossibleNulls.highlights?.policy_numbers?.filter(
                  filterNulls
                ) ?? [],
              quote_number:
                hitPossibleNulls.highlights?.quote_number?.filter(
                  filterNulls
                ) ?? []
            }
          };

          const result: DisplayableResult = {
            name: hit.display_name,
            accountNumber: hit.account_number,
            address: hit.address,
            phoneNumber: '',
            email: hit.email_address,
            keyAccountNumber: hit.account_number,
            policyNumbers: hit.policy_numbers,
            quoteNumber: hit.quote_number,
            status: hit.status,
            lineOfBusiness: hit.line_of_business,
            quoteEffectiveDate: hit.quote_effective_date,
            premium: hit.premium,
            documentType: hit.document_type,
            transactionType: hit.transaction_type
          };

          const processedPhone = processPhone({
            primary_phone: hit.primary_phone,
            cell_phone: hit.cell_phone,
            home_phone: hit.home_phone,
            work_phone: hit.work_phone,
            cell_highlights: hit.highlights.cell_phone,
            home_highlights: hit.highlights.home_phone,
            work_highlights: hit.highlights.work_phone
          });

          result.phoneNumber = processedPhone.plain;

          valueArray.push(JSON.parse(JSON.stringify(result)));

          if (processedPhone.highlighted.length) {
            result.displayablePhoneNumber = processedPhone.highlighted;
          }

          const { highlights } = hit;

          if (highlights.display_name.length) {
            result.displayableName = parseSearchHighlighting(
              highlights.display_name[0]
            );
            result.displayableName = formatHighlightTitleCase(
              result.name,
              result.displayableName
            );
          }

          if (highlights.account_number.length) {
            result.displayableAccountNumber = parseSearchHighlighting(
              highlights.account_number[0]
            );
          }

          result.displayablePolicyNumbers = highlights.policy_numbers
            .map(policyHighlight => {
              return parseSearchHighlighting(policyHighlight ?? '');
            })
            .slice(0, 3);

          if (highlights.address.length) {
            result.displayableAddress = parseSearchHighlighting(
              hit.highlights.address[0]
            );
            result.displayableAddress = formatHighlightTitleCase(
              result.address,
              result.displayableAddress
            );
          }

          if (highlights.email_address.length) {
            result.displayableEmail = parseSearchHighlighting(
              hit.highlights.email_address[0]
            );
          }

          if (highlights.quote_number.length) {
            result.displayableQuoteNumber = parseSearchHighlighting(
              hit.highlights.quote_number[0]
            );
          }

          return result;
        }
      )
      .filter((hit: DisplayableResult | null): hit is DisplayableResult => {
        return hit != null;
      }) || [];
  if (content.length === 0) {
    return [
      {
        label: (
          <HoverableBox id="Displayable_Result_HoverableBox_0">
            <DropdownBox direction="row">
              <NoSearchResults />
              <TextBox>
                <Text>
                  This policy is not available in the new system, but we’re
                  continuing to add policies.
                </Text>
                <LinkHolder>
                  Click here to search on State Auto Connect.
                </LinkHolder>
              </TextBox>
            </DropdownBox>
          </HoverableBox>
        ),
        value: 'NoResultsFound'
      }
    ];
  }
  return content.map((label: DisplayableResult, index: number) => {
    return {
      label: generateJSX(label, index),
      value: JSON.stringify(valueArray[index])
    };
  });
};

export default ({ header }: searchPanelLocation) => {
  const [getResults, searchResults] = useLazyQuery(get_search_results_query);
  const [getQuoteSearchSelectionBehavior] = useLazyQuery(
    get_quote_search_selection_behavior,
    {
      onCompleted: (
        data: SEARCH_PANEL_GET_QUOTE_SEARCH_SELECTION_BEHAVIOR | undefined
      ) => {
        if (
          !data?.quoteSearchSelectionBehavior?.location ||
          !data?.quoteSearchSelectionBehavior.type
        ) {
          console.error('Could not determine quote behavior');
          return null;
        }
        const {
          type: behaviorType,
          location: behaviorLocation
        }: {
          type: string | null;
          location: string | null;
        } = data.quoteSearchSelectionBehavior;
        switch (behaviorType) {
          case 'navigate':
            navigate(behaviorLocation!);
            break;
          case 'redirect':
            window.open(behaviorLocation!, '_self');
            break;
          default:
            break;
        }
        return null;
      }
    }
  );
  const { called, error } = searchResults;
  let { data } = searchResults;
  if (error) {
    console.log(error);
  }

  if (data && !error && called) {
    data = transformData(data);
  }

  return (
    <SearchBar
      header={header}
      callSearch={getResults}
      data={data}
      getQuoteSearchSelectionBehavior={getQuoteSearchSelectionBehavior}
    />
  );
};
