import styled from 'styled-components';
import { ReactComponent as Info } from '../../assets/images/info-dark-blue.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 4px;
  background-color: #e7f5fa;
  border: 1px solid #b9e2f0;
  box-sizing: border-box;
  border-left: 5px solid #0173c6;
  gap: 12px;
  padding: 11px 12px;
  min-height: 53px;
`;

export const BannerText = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 21px;
  margin: auto 0;
`;

export const StyledInfo = styled(Info)`
  height: 22px;
  width: 22px;
  margin: auto 0;
`;
