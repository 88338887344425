import React from 'react';
import { Layer } from 'grommet';

interface modalProperties {
  onEsc: () => void;
  onClickOutside: () => void;
  showModal: boolean;
  children: JSX.Element;
}

export default ({
  showModal,
  onEsc,
  onClickOutside,
  children
}: modalProperties) => {
  if (showModal) {
    return (
      <Layer onEsc={onEsc} onClickOutside={onClickOutside}>
        {children}
      </Layer>
    );
  }
  return null;
};
