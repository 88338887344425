import React, { useState, useEffect } from 'react';

interface ringProps {
  percentage: number;
}

export default ({ percentage }: ringProps) => {
  const percentageIndicatorColor = percentage >= 90 ? '#65A100' : '#0060FF';
  const [strokeDasharray, setStrokeDasharray] = useState('0,88');

  useEffect(() => {
    if (percentage > 0) {
      setTimeout(() => {
        setStrokeDasharray(`${80 * (percentage / 100)},88`);
      }, 400);
    }
  }, [percentage]);

  return (
    <svg
      width="34px"
      height="33px"
      viewBox="0 0 34 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Ring" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="RingMask" fill="#D1D7DE" fillRule="nonzero">
          <path
            d="M17,0.5 C26.1067793,0.5 33.5,7.81497796 33.5,16.8518375 C33.5,23.9504592 28.8958227,30.1668806 22.2115601,32.3705714 C20.9002731,32.802881 19.4868087,32.090329 19.0544991,30.779042 C18.6221895,29.467755 19.3347414,28.0542906 20.6460285,27.621981 C25.3019229,26.0870097 28.5,21.7690624 28.5,16.8518375 C28.5,10.5883799 23.3571936,5.5 17,5.5 C10.6428064,5.5 5.5,10.5883799 5.5,16.8518375 C5.5,21.7725691 8.70263668,26.0929043 13.3632622,27.6250396 C14.6749166,28.0562332 15.3886711,29.4690907 14.9574775,30.7807451 C14.5262838,32.0923996 13.1134263,32.8061541 11.8017719,32.3749604 C5.11071138,30.1753394 0.5,23.9555209 0.5,16.8518375 C0.5,7.81497796 7.89322065,0.5 17,0.5 Z"
            id="Path"
          />
        </g>
        <rect
          id="RingBackground"
          // currentColor="#D1D7DE"
          x="-1"
          y="-2"
          width="36"
          height="36"
        />
        <path
          d="M21.4287943,29.9962762 C26.9902554,28.1627545 31,22.9706034 31,16.8518375 C31,9.20167891 24.7319865,3 17,3 C9.2680135,3 3,9.20167891 3,16.8518375 C3,22.9747509 7.0151823,28.1697929 12.582517,30"
          id="RingPercentageIndicator"
          stroke={percentageIndicatorColor}
          strokeWidth="5"
          strokeLinecap="round"
          strokeDasharray={strokeDasharray}
          transform="translate(17.000000, 16.500000) scale(-1, 1) translate(-17.000000, -16.500000) "
          style={{
            transition: 'stroke-dasharray 333ms cubic-bezier(0.4, 0.0, 0.2, 1)'
          }}
        />
      </g>
    </svg>
  );
};
