import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useParams, RouteComponentProps } from '@reach/router';

import CustomerIntent from './CustomerIntent';
import {
  GET_ACCOUNT_PREDICTIONS,
  GET_ACCOUNT_PREDICTIONS_getAccountPrediction_call_reasons
} from './graphql/__generated__/GET_ACCOUNT_PREDICTIONS';
import determineClick from './DetermineClickFunction';
import generateOpenOneInc from '../../util/openOneIncURL/generateOpenOneIncURL';
import getConfig, { Env } from '../../config';
import { genericGTMDataLayer } from '../../util/GTMHelpers';
import { agencyBilledOnly } from '../../util/agencyBilledUtils';

const get_account_predictions_query = loader(
  './graphql/Get_Account_Predictions.graphql'
);

const get_policy_payplan_billing_method = loader(
  './graphql/Customer_Intent_Get_Policy_PayPlan_Billing_Method.graphql'
);

const config = getConfig(process.env.REACT_APP_ENV as Env);

export const filterReason = (call_reason: any) => {
  let shouldKeep = true;
  switch (call_reason.type) {
    case 'Other':
      shouldKeep = false;
      break;
    case 'Reinstatement':
      shouldKeep = false;
      break;
    case 'eSignature':
      shouldKeep = false;
      break;
    case 'IDCardDecPages':
      shouldKeep = false;
      break;
    case 'PremiumDifference':
      shouldKeep = false;
      break;
    case 'DeviceQuestionIssue':
      shouldKeep = false;
      break;
    default:
      shouldKeep = true;
  }
  return shouldKeep;
};

const changeDisplayContent = (call_reason: any, openURL: Function) => {
  let display;
  switch (call_reason.type) {
    case 'MakeOneTimePayment':
      display = 'Make a One Time Payment';
      break;
    case 'Refund':
      display = 'Refund Received';
      break;
    case 'PaymentChange':
      display = 'Modify Payment Info';
      break;
    case 'CompletePolicyChangeForAgent':
      display = 'Make a Policy Change';
      break;
    case 'BillingInquiry':
      display = 'Billing Question';
      break;
    /*  case 'DeviceQuestionIssue':
      display = 'Telematics Question';
      break;  */
    case 'Fees':
      display = 'Billing Fees';
      break;
    case 'PremiumChange':
      display = 'Change in Premium';
      break;
    case 'StopPay':
      display = 'Stop a Payment';
      break;
    case 'LastDayToPay':
      display = 'Payment Due Date';
      break;
    case 'CompleteQuoteForAgent':
      display = 'Complete Quote';
      break;
    case 'EffectiveDateChanges':
      display = 'Effective Date Change';
      break;
    default:
      display = call_reason.display;
  }
  return {
    display,
    score: parseFloat(call_reason.score) * 100,
    onClick: determineClick(call_reason.type, openURL)
  };
};

export const transformData = (
  data: GET_ACCOUNT_PREDICTIONS,
  accountNumber: string,
  openURL: Function
) => {
  let possible_reasons:
    | (
        | GET_ACCOUNT_PREDICTIONS_getAccountPrediction_call_reasons
        | null
        | undefined
      )[]
    | null
    | undefined
    | { display: any; score: number; onClick: Function }[];
  const agentInfo = data?.me;
  possible_reasons = data.getAccountPrediction?.call_reasons;
  if (!possible_reasons) {
    return [];
  }
  possible_reasons = possible_reasons.filter((call_reason: any) =>
    filterReason(call_reason)
  );

  possible_reasons = possible_reasons.map(call_reason =>
    changeDisplayContent(call_reason, openURL)
  );
  possible_reasons = possible_reasons.slice(0, 4);
  return possible_reasons;
};

export const agencyBillClickHandler: Function = () => {
  genericGTMDataLayer({
    event_action: 'Button Click',
    event_label: 'Billing Agency Bill button',
    event_name_snowplow: 'snowplow-billingAgencyBillButtonClick'
  });
  window.open(config.agency_bill_url, '_blank');
};

export interface CustomerIntentProps {
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

export default ({
  readOnlyAccess,
  readOnlyFeatureFlag
}: RouteComponentProps<CustomerIntentProps>) => {
  const { accountNumber } = useParams();

  const variables = {
    account_number: accountNumber
  };

  const queryResult = useQuery(get_account_predictions_query, {
    variables
  });

  const billingMethodQueryResult = useQuery(get_policy_payplan_billing_method, {
    variables
  });

  const { loading, error } = queryResult;
  let { data } = queryResult;
  const [openOneInc] = generateOpenOneInc(accountNumber, data?.me?.user_id);

  const {
    data: billingMethodData,
    loading: billingMethodLoading
  } = billingMethodQueryResult;

  let openURLHandler: Function;
  if (agencyBilledOnly(billingMethodData)) {
    openURLHandler = agencyBillClickHandler;
  } else openURLHandler = openOneInc;

  if (data) {
    data = transformData(data, accountNumber, openURLHandler);
  }

  return (
    <CustomerIntent
      loading={loading || billingMethodLoading}
      error={error}
      call_reasons={data}
      readOnlyAccess={readOnlyAccess}
      readOnlyFeatureFlag={readOnlyFeatureFlag}
    />
  );
};
