import React from 'react';
import {
  ClaimDetailBox,
  AdjusterDetailCol,
  AdjusterDetailHeadingWrapper,
  AdjusterDetailIcon,
  AdjusterDetailText,
  StatusDetailCol,
  StatusDetailHeadingWrapper,
  StatusDetailIcon,
  StatusDetailText,
  WorkerCompDetailCol,
  WorkerCompDetailHeadingWrapper,
  WorkerCompDetailIcon,
  WorkerCompDetailText,
  DetailBox,
  DetailRowBox,
  DetailColumnOneBox,
  DetailColumnTwoBox,
  StatusCapsuleContainer,
  StatusCapsuleLeft,
  StatusCapsuleRightIcon,
  WarningBanner,
  StatusContainer
} from './wcClaimsDetailBoxStyles';
import {
  Adjuster,
  AltInfo,
  ChevronUp,
  ChevronDownUnbolded,
  Clock
} from '../../icons';
import { SelectedClaimsProps } from '../wcClaimsTab/WCClaimsTab';

export interface WCClaimsDetailProps {
  claimDetailResult?: any;
  expandedView: boolean;
  setExpandedView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClaimsObject: SelectedClaimsProps | null;
}

export default ({
  claimDetailResult,
  setExpandedView,
  expandedView,
  selectedClaimsObject
}: WCClaimsDetailProps) => {
  const handleExpand = () => {
    if (expandedView) {
      setExpandedView(false);
    } else {
      setExpandedView(true);
    }
  };
  return (
    <>
      {selectedClaimsObject?.hideClaim ? (
        <WarningBanner>
          Due to the sensitive nature of this claim, please contact your
          Adjuster.
        </WarningBanner>
      ) : (
        <></>
      )}
      <ClaimDetailBox>
        <WorkerCompDetailCol>
          <WorkerCompDetailHeadingWrapper>
            <WorkerCompDetailIcon>
              <AltInfo />
            </WorkerCompDetailIcon>
            <WorkerCompDetailText>Workers Comp</WorkerCompDetailText>
          </WorkerCompDetailHeadingWrapper>
          <DetailBox>
            <DetailRowBox>
              <DetailColumnOneBox>Policy Number:</DetailColumnOneBox>
              <DetailColumnTwoBox>
                {claimDetailResult.policynumber}
              </DetailColumnTwoBox>
            </DetailRowBox>
            <DetailRowBox>
              <DetailColumnOneBox>Injured Name:</DetailColumnOneBox>
              <DetailColumnTwoBox>
                {claimDetailResult.injuredContactDetails.name}
              </DetailColumnTwoBox>
            </DetailRowBox>
          </DetailBox>
        </WorkerCompDetailCol>
        <AdjusterDetailCol>
          <AdjusterDetailHeadingWrapper>
            <AdjusterDetailIcon>
              <Adjuster />
            </AdjusterDetailIcon>
            <AdjusterDetailText>Adjuster</AdjusterDetailText>
          </AdjusterDetailHeadingWrapper>
          <DetailBox>
            <DetailRowBox>
              <DetailColumnOneBox>Name:</DetailColumnOneBox>
              <DetailColumnTwoBox>
                {claimDetailResult.adjusterDetails.username}
              </DetailColumnTwoBox>
            </DetailRowBox>
            <DetailRowBox>
              <DetailColumnOneBox>Email:</DetailColumnOneBox>
              <DetailColumnTwoBox>
                {claimDetailResult.adjusterDetails.email}
              </DetailColumnTwoBox>
            </DetailRowBox>
            <DetailRowBox>
              <DetailColumnOneBox>Phone Number:</DetailColumnOneBox>
              <DetailColumnTwoBox>
                {claimDetailResult.adjusterDetails.phone}
              </DetailColumnTwoBox>
            </DetailRowBox>
          </DetailBox>
        </AdjusterDetailCol>
        <StatusDetailCol>
          <StatusDetailHeadingWrapper>
            <StatusDetailIcon>
              <Clock width={30} height={30} />
            </StatusDetailIcon>
            <StatusDetailText>Status</StatusDetailText>
          </StatusDetailHeadingWrapper>
          {selectedClaimsObject?.hideClaim ? (
            <>
              {setExpandedView(false)}
              <StatusContainer>
                {claimDetailResult.claim_status}
              </StatusContainer>
            </>
          ) : (
            <StatusCapsuleContainer>
              <StatusCapsuleLeft>
                {claimDetailResult.claim_status}
              </StatusCapsuleLeft>

              <StatusCapsuleRightIcon onClick={handleExpand}>
                {expandedView ? (
                  <ChevronUp width={30} height={30} />
                ) : (
                  <ChevronDownUnbolded width={30} height={30} />
                )}
              </StatusCapsuleRightIcon>
            </StatusCapsuleContainer>
          )}
        </StatusDetailCol>
      </ClaimDetailBox>
    </>
  );
};
