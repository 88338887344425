import React, { useState } from 'react';
import {
  formatDate,
  formatMoney,
  formatTitleCase
} from '../../util/formatters';
import { colors } from '../../themes/default.theme';
import usePagination from '../../util/usePagination';
import {
  Container,
  SnapshotWrap,
  SnapshotBackground,
  Column,
  LeftColumnRow,
  LeftColumnLabelText,
  RightColumnRow,
  RightColumnLabelText,
  Text,
  Pagination,
  LinkText,
  WhatChangedLink,
  WhatChangedModal,
  WhatChangedModalBody,
  ModalHeaderWrap,
  ModalHeaderTitle,
  ModalHeaderCloseBtn,
  WhatChangedModalDesc,
  ModalFooterBtnWrap,
  ModalFooterCloseBtn,
  IconDiv
} from './SnapshotsAndQuotesTableStyles';

import SnapshotInvoicesTable from './snapshotInvoicesTable';

import { SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes } from './graphql/__generated__/SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES';
import { genericGTMDataLayer } from '../../util/GTMHelpers';
import CloseSrc from '../../assets/images/CloseSmallIcon.svg';
import ChevronDown from '../../assets/images/chevron-down.svg';
import ChevronUp from '../../assets/images/chevron-up.svg';

export function renderNumber(value: number | null) {
  if (!value) return <Text>-</Text>;
  if (value > 0) return <Text>{formatMoney(value)}</Text>;
  return <Text color={colors.valueRed1}>{formatMoney(value)}</Text>;
}

export function formatText(input: string, field: string) {
  let updatedText: string = '';
  switch (input.toLowerCase()) {
    case 'policychange':
      updatedText = 'Policy Change';
      break;
    case 'inforce':
      updatedText = 'In Force';
      break;
    case 'issuance in-progress':
      updatedText = 'Binding';
      break;
    default:
      if (field === 'Status') {
        updatedText = formatTitleCase(input) as string;
        break;
      } else {
        updatedText =
          input.substring(0, 1).toUpperCase() +
          input.substring(1, input.length).toLowerCase();
        break;
      }
  }
  return updatedText;
}

export interface SnapshotsAndQuotesTableProps {
  snapshotInvoiceFlag: boolean;
  error?: Error;
  snapshotsAndQuotes?:
    | SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes[]
    | undefined;
  showAll?: boolean;
  onDocumentClick?: Function;
  accountNumber: string;
  policyNumber: string;
  redirectOnQuoteClickAction: Function;
}

export const quotesStatuses = {
  Draft: 'Draft',
  Issuance_In_Progress: 'Issuance In-Progress',
  Quoted: 'Quoted',
  Quote_In_Progress: 'Quote In-Progress'
};

export default ({
  snapshotInvoiceFlag,
  error,
  snapshotsAndQuotes,
  onDocumentClick,
  accountNumber,
  policyNumber,
  redirectOnQuoteClickAction
}: SnapshotsAndQuotesTableProps) => {
  const [displayableSnapshots, pageSelector] = usePagination({
    accountNumber,
    policyNumber,
    items: snapshotsAndQuotes ?? [],
    itemsPerPage: 10
  });
  const [showWhatChanged, setShowWhatChanged] = useState(false);
  const [accordionFlags, setAccordionFlags] = useState<{
    [key: string]: boolean;
  }>({});
  if (error) {
    return <p>Error While Loading...</p>;
  }

  const toggleSnapshotAccordion = (id: string) => {
    setAccordionFlags({ ...accordionFlags, [id]: !accordionFlags[id] });
  };

  const handleSnapshotClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => {
    const dataLink: string | null = (e.target as Element)!.getAttribute(
      'data-link'
    );

    if (
      !['quote-redirect', 'what-changed', 'download'].includes(dataLink || '')
    ) {
      toggleSnapshotAccordion(id);
    }
  };

  return (
    <>
      <Container>
        {displayableSnapshots.map(
          (
            snapshot: SNAPSHOPTS_AND_QUOTES_TABLE_GET_SNAPSHOTS_AND_QUOTES_account_items_policy_quotes
          ) => {
            const invoiceAccordionFlag: boolean =
              snapshotInvoiceFlag && !snapshot.quote_number;
            let descriptionText: string | null =
              snapshot.description ??
              (formatTitleCase(snapshot.activity_type) as string) ??
              (formatTitleCase(snapshot.transaction_type) as string);
            if (descriptionText?.length > 40)
              descriptionText = `${descriptionText.slice(0, 40)}...`;
            const date: string | null =
              snapshot.effective_date ?? snapshot.effective_dt;
            return (
              <SnapshotWrap>
                <SnapshotBackground
                  key={snapshot.policy_key}
                  onClick={e => {
                    if (invoiceAccordionFlag)
                      handleSnapshotClick(e, snapshot.policy_key || '');
                  }}
                  className={invoiceAccordionFlag ? 'snapshot-invoice' : ''}
                >
                  <Column
                    className={invoiceAccordionFlag ? 'snapshot-invoice' : ''}
                  >
                    <LeftColumnRow>
                      <LeftColumnLabelText>Description</LeftColumnLabelText>
                      <Text>
                        {descriptionText
                          ? formatText(descriptionText, 'Description')
                          : '-'}
                      </Text>
                    </LeftColumnRow>
                    <LeftColumnRow>
                      <LeftColumnLabelText>Quote #</LeftColumnLabelText>
                      {snapshot.quote_number &&
                      snapshot.status &&
                      [
                        quotesStatuses.Draft,
                        quotesStatuses.Quoted,
                        quotesStatuses.Quote_In_Progress,
                        quotesStatuses.Issuance_In_Progress
                      ].includes(snapshot.status) ? (
                        <LinkText
                          data-link="quote-redirect"
                          onClick={() => {
                            redirectOnQuoteClickAction({
                              variables: {
                                quote_number: snapshot.quote_number
                              }
                            });
                          }}
                        >
                          {snapshot.quote_number}
                        </LinkText>
                      ) : (
                        <Text>{snapshot.quote_number ?? '-'}</Text>
                      )}
                    </LeftColumnRow>
                    <LeftColumnRow>
                      <LeftColumnLabelText>Effective Date</LeftColumnLabelText>
                      <Text>{date ? formatDate(date) : '-'}</Text>
                    </LeftColumnRow>
                  </Column>
                  <Column
                    className={invoiceAccordionFlag ? 'snapshot-invoice' : ''}
                  >
                    <RightColumnRow>
                      <RightColumnLabelText>Status</RightColumnLabelText>
                      <Text>
                        {snapshot.status
                          ? formatText(snapshot.status, 'Status')
                          : '-'}
                      </Text>
                    </RightColumnRow>
                    <RightColumnRow>
                      <RightColumnLabelText>Change</RightColumnLabelText>
                      {renderNumber(snapshot.amount_change)}
                    </RightColumnRow>
                    <RightColumnRow>
                      <RightColumnLabelText>Total</RightColumnLabelText>
                      {renderNumber(snapshot.amount_total)}
                      {snapshot.activity_type === 'RENEWAL' && (
                        <WhatChangedLink
                          data-link="what-changed"
                          onClick={() => {
                            setShowWhatChanged(true);
                            genericGTMDataLayer({
                              event_action: 'Click',
                              event_label: 'What Changed',
                              event_name_snowplow: 'What-Changed-Click'
                            });
                          }}
                        >
                          (What changed?)
                        </WhatChangedLink>
                      )}
                    </RightColumnRow>
                    <RightColumnRow>
                      <RightColumnLabelText>Document</RightColumnLabelText>
                      {snapshot.quote_number ||
                      snapshot.transaction_type ||
                      !snapshot.docuid ? (
                        <Text>-</Text>
                      ) : (
                        <LinkText
                          data-link="download"
                          onClick={() => {
                            if (onDocumentClick) {
                              onDocumentClick({
                                policy_number: policyNumber,
                                snapshot_id: snapshot.policy_key,
                                gtm_data: {
                                  snapshotId: snapshot.policy_key
                                }
                              });
                            }
                          }}
                        >
                          Download
                        </LinkText>
                      )}
                    </RightColumnRow>
                  </Column>
                  <IconDiv>
                  {invoiceAccordionFlag && (
                    <img
                      alt="AccordionChevron"
                      src={
                        accordionFlags[snapshot?.policy_key || '']
                          ? ChevronUp
                          : ChevronDown
                      }
                    />
                  )}
                  </IconDiv>
                </SnapshotBackground>
                {accordionFlags[snapshot?.policy_key || ''] ? (
                  <SnapshotInvoicesTable
                    transactionNumber={snapshot.transaction_number}
                    amountChange={snapshot.amount_change}
                    accountNumber={accountNumber}
                    policyNumber={policyNumber}
                  />
                ) : (
                  <></>
                )}
              </SnapshotWrap>
            );
          }
        )}
        <Pagination>{pageSelector}</Pagination>
      </Container>
      {showWhatChanged && (
        <WhatChangedModal>
          <WhatChangedModalBody>
            <ModalHeaderWrap>
              <ModalHeaderTitle>
                Why did the cost of insurance change at renewal time?
              </ModalHeaderTitle>
              <ModalHeaderCloseBtn
                onClick={() => setShowWhatChanged(false)}
                src={CloseSrc}
              />
            </ModalHeaderWrap>
            <WhatChangedModalDesc>
              There are many factors that go into determining the cost, please
              contact Underwriting for more details.{' '}
            </WhatChangedModalDesc>
            <ModalFooterBtnWrap>
              <ModalFooterCloseBtn onClick={() => setShowWhatChanged(false)}>
                CLOSE
              </ModalFooterCloseBtn>
            </ModalFooterBtnWrap>
          </WhatChangedModalBody>
        </WhatChangedModal>
      )}
    </>
  );
};
