import React from 'react';
import Clock from '../assets/images/Clock.svg';
interface ClockProps {
  width?: number;
  height?: number;
}
 const ClockIcon: React.FC<ClockProps> = ({ width = 15, height = 15 }) => {
  return (
    <div>
      <img src={Clock} width={width} height={height} />
    </div>
  );
};
export default ClockIcon;