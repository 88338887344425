import React, { useState } from 'react';
import { Phone, Email, Adjuster } from '../../icons';
import styled from 'styled-components';

import {
  Label,
  Value,
  RowContainer,
  LabelDisplay,
  Wrapper,
  IconContainer,
  DropdownContent
} from './ExpandedViewStyles';
const Header = styled.div`
  padding: 10px;
`;
interface AdjusterInfoProps {
  name?: string;
  email?: string;
  phone?: string;
}
const AdjusterInfo: React.FC<AdjusterInfoProps> = ({ name, email, phone }) => {
  return (
    <Wrapper>
      <DropdownContent open={true}>
        <Header>
          <h2>Adjuster Info</h2>
        </Header>

        <RowContainer>
          <IconContainer>
            <Adjuster height={20} width={20} />
          </IconContainer>
          <LabelDisplay>
            <Label>Name</Label>
            <Value>{name || 'N/A'}</Value>
          </LabelDisplay>
          <IconContainer>
            <Phone />
          </IconContainer>
          <LabelDisplay>
            <Label>Phone</Label>
            <Value>{phone || 'N/A'}</Value>
          </LabelDisplay>
        </RowContainer>
        <RowContainer>
          <IconContainer>
            <Email />
          </IconContainer>
          <LabelDisplay>
            <Label>Email</Label>
            <Value>{email || 'N/A'}</Value>
          </LabelDisplay>
        </RowContainer>
      </DropdownContent>
    </Wrapper>
  );
};

export default AdjusterInfo;
