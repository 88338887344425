import React, { useEffect, useState } from 'react';
import { Text, Box } from 'grommet';
import { formatTitleCase } from '../../util/formatters';
import { PolicyInfoProps } from './PolicyInfo';
import {
  HomeInfoGroup,
  HomeInfoLabel,
  AttemptingStringAsAddress,
  OtherInsuredLabel,
  ChangePageIconWrapper,
  TotalPagesText,
  IconSpacing,
  BoxWrapper,
  TextWrapper,
  TitleText,
  ContentText,
  InfoContainer,
  ToolTipText,
  InfoPopUp,
  MainWrapper
} from './PolicyInfoStyles';
import getUniqueParties from '../../util/uniqueParties/uniqueParties';
import {
  PaginationPrevious,
  PaginationNext,
  PaginationNextDisabled,
  PaginationPreviousDisabled,
  SmallInfo
} from '../../icons';

// eslint-disable-next-line no-unused-vars
import { GET_POLICY_INFO_account_items_policy_insuredassets_items } from './graphql/__generated__/GET_POLICY_INFO';

/*
Types of Commercial Products
- Umbrella
- Commercial Umbrella
- Farmowners Umbrella
- Commercial Package
*/
export default ({
  information,
  accountNumber,
  policyNumber
}: PolicyInfoProps) => {
  const insuredAssets = information?.insuredassets?.items ?? [];
  const insuredParties = information?.insuredparties?.items ?? [];
  const uniqueParties = getUniqueParties(information?.insuredparties?.items);
  let i = 0;
  const assetList: (string | null)[] = [];
  const uniqueLocations = [];
  const otherInsured = [];
  for (i = 0; i < insuredAssets.length; i += 1) {
    const current = insuredAssets[i];
    if (
      current?.asset &&
      current?.asset_name?.toUpperCase().includes('LOCATION')
    ) {
      if (!assetList.includes(current.asset)) {
        assetList.push(current.asset);
        uniqueLocations.push(current);
      }
    }
  }
  for (i = 0; i < uniqueParties.length; i += 1) {
    const current = uniqueParties[i];
    const data = { asset: null, asset_name: current };
    otherInsured.push(data);
  }
  const [counter, setCounter] = useState(1);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [showPerPage, setShowPerPage] = useState(4);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage
  });
  const onButtonClick = (type: String) => {
    if (type === 'prev') {
      if (counter === 1) {
        setCounter(1);
      } else {
        setCounter(counter - 1);
      }
    } else if (type === 'next') {
      if (Math.ceil(uniqueLocations.length / showPerPage) === counter) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
    }
  };
  useEffect(() => {
    const value = showPerPage * counter;
    setPagination({ start: value - showPerPage, end: value });
    if (counter === Math.ceil(uniqueLocations.length / showPerPage)) {
      setDisableNext(true);
    } else if (counter === 1) {
      setDisablePrev(true);
    } else {
      setDisableNext(false);
      setDisablePrev(false);
    }
  }, [counter]);

  return (
    <MainWrapper>
      <ChangePageIconWrapper>
        <TotalPagesText>
          {counter} of {Math.ceil(uniqueLocations.length / showPerPage)}
        </TotalPagesText>
        <IconSpacing onClick={() => onButtonClick('prev')}>
          {disablePrev ? (
            <PaginationPreviousDisabled />
          ) : (
            <PaginationPrevious />
          )}
        </IconSpacing>
        <IconSpacing onClick={() => onButtonClick('next')}>
          {disableNext ? <PaginationNextDisabled /> : <PaginationNext />}
        </IconSpacing>
      </ChangePageIconWrapper>
      <BoxWrapper>
        {uniqueLocations &&
          uniqueLocations
            .sort(
              (
                assetOne: GET_POLICY_INFO_account_items_policy_insuredassets_items,
                assetTwo: GET_POLICY_INFO_account_items_policy_insuredassets_items
              ) => (assetOne?.asset_key > assetTwo?.asset_key ? 1 : -1)
            )
            .slice(pagination.start, pagination.end)
            .map(item => (
              <div>
                {item.asset !== null &&
                  item.asset_name !== null &&
                  item.asset_name.toUpperCase().includes('LOCATION') && (
                    <TextWrapper>
                      <HomeInfoGroup>
                        <HomeInfoLabel>
                          Address - {item.asset_name.split('-')[1]}
                        </HomeInfoLabel>
                        <AttemptingStringAsAddress>
                          {item.asset}
                        </AttemptingStringAsAddress>
                      </HomeInfoGroup>
                    </TextWrapper>
                  )}
              </div>
            ))}
        {otherInsured.map(item => (
          <div>
            {item.asset == null && item.asset_name !== null && (
              <TextWrapper>
                <HomeInfoGroup>
                  <OtherInsuredLabel>Other Insured</OtherInsuredLabel>
                  <Text wordBreak="keep-all" size="12px">
                    {formatTitleCase(item.asset_name)}
                  </Text>
                </HomeInfoGroup>
              </TextWrapper>
            )}
          </div>
        ))}

        <TextWrapper>
          {information?.agent_record_name && (
            <Box align="start">
              <TitleText>Agent of Record</TitleText>
              <ContentText>{information.agent_record_name}</ContentText>
              <InfoContainer>
                <ToolTipText>({information.agency_code})</ToolTipText>
                <InfoPopUp>
                  <Box>
                    <Box direction="row" margin={{ left: '5px' }}>
                      <SmallInfo />
                      <Text
                        weight="bold"
                        margin={{ left: 'xsmall' }}
                        size="10pt"
                      >
                        Agency Code
                      </Text>
                    </Box>
                    <Text size="10pt" margin={{ left: '25px' }}>
                      The Agency Code for the Agent of Record.
                    </Text>
                  </Box>
                </InfoPopUp>
              </InfoContainer>
            </Box>
          )}
        </TextWrapper>
      </BoxWrapper>
    </MainWrapper>
  );
};
