import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import { AccountInformationEdit } from './AccountInformationEdit';

const get_customer_info_query = loader('./graphql/Get_Customer_Info.graphql');
const update_contact_number_mutation = loader(
  './graphql/Update_Contact_Number.graphql'
);
const update_email_mutation = loader('./graphql/Update_Email_Address.graphql');

interface AccountInfoContainerProps {
  lastDayToPayFlag: boolean;
  edit: boolean;
  setEditable?: Function;
  doSave: number;
  setServiceLoading?: Function;
}

export default ({
  lastDayToPayFlag,
  edit,
  setEditable,
  doSave,
  setServiceLoading
}: AccountInfoContainerProps) => {
  const { accountNumber } = useParams();

  const variables = {
    account_number: accountNumber
  };
  const queryResult = useQuery(get_customer_info_query, {
    variables
  });

  const [
    updateHome,
    { loading: homeLoading, error: homeError, data: homeData }
  ] = useMutation(update_contact_number_mutation);
  const [
    updateCell,
    { loading: cellLoading, error: cellError, data: cellData }
  ] = useMutation(update_contact_number_mutation);
  const [
    updateWork,
    { loading: workLoading, error: workError, data: workData }
  ] = useMutation(update_contact_number_mutation);
  const [
    updatePrimary,
    { loading: primaryLoading, error: primaryError, data: primaryData }
  ] = useMutation(update_contact_number_mutation);

  const { loading, error, data } = queryResult;
  const props = {
    isLoading: loading,
    error,
    edit
  };
  let contactID = '';
  if (data?.account?.items) {
    Object.assign(props, {
      accountInfo: data.account.items[0]
    });
    contactID = data.account.items[0].publicid;
  }

  const [
    sendUpdate,
    { loading: updateLoading, error: updateError, data: update }
  ] = useMutation(update_email_mutation);

  let temp;
  if (!updateLoading && !updateError && update) {
    temp = update.updateEmailAddress;
  }

  const UpdateFinal = (newAccountInfo: any) => {
    Object.assign(props, {
      accountInfo: newAccountInfo
    });
  };

  return (
    <AccountInformationEdit
      {...props}
      lastDayToPayFlag={Boolean(lastDayToPayFlag)}
      accountNumber={accountNumber}
      emailUpdate={sendUpdate}
      ID={contactID}
      emailResponse={temp}
      editMode={edit}
      setEditable={setEditable}
      saveState={doSave}
      updateFinal={UpdateFinal}
      emailLoading={updateLoading}
      homeUpdate={updateHome}
      homeLoading={homeLoading}
      homeError={homeError}
      cellUpdate={updateCell}
      cellLoading={cellLoading}
      cellError={cellError}
      workUpdate={updateWork}
      workLoading={workLoading}
      workError={workError}
      primaryUpdate={updatePrimary}
      primaryLoading={primaryLoading}
      primaryError={primaryError}
      emailError={updateError}
      setServiceLoading={setServiceLoading}
    />
  );
};
