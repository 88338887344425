import styled from 'styled-components';

export const ModalWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalBody = styled.div`
  width: 291px;
`;
