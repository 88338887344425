import React from 'react';
import Error from '../assets/images/ErrorIcon.svg';
interface ErrorIconProps {
  width?: number;
  height?: number;
}
const ErrorIcon: React.FC<ErrorIconProps> = ({ width = 30, height = 30 }) => {
  return <img src={Error} width={width} height={height} alt="" />;
};

export default ErrorIcon;