import React, { ChangeEvent } from 'react';

import moment from 'moment-timezone';

import {
  InputGroup,
  Label,
  ElementWrap,
  DatePicker,
  CalendarIcon,
  CustomDatePickerModal,
  CustomDatePickerModalBackground,
  CustomDatePickerWrap,
  FieldSubText
} from '../cancelPolicyFormStyles';

import { DatePicker as CustomDatePicker } from '../../..';

import CalendarIconSrc from '../../../../assets/images/icon-calendar-green.svg';

interface initialState {
  value: string;
  active: boolean;
  error: boolean;
  msg: string;
  showDatePicker: boolean;
}

interface props {
  state: initialState;
  toggleCalendar: Function;
  onFocus: Function;
  onBlur: Function;
  onChange: Function;
  disabled: boolean;
  maximumDate?: string;
  minimumDate?: string;
}

export const getMomentObj = (date: string) =>
  moment(date, 'YYYY-MM-DD', 'America/New_York');

export const minDate = moment
  .tz('America/New_York')
  .subtract(2, 'd')
  .format('YYYY-MM-DD');

export const initialState: initialState = {
  value: '',
  active: false,
  error: false,
  msg: '',
  showDatePicker: false
};

export const isValid = (value: string) =>
  value !== '' && getMomentObj(value).isValid();

export const isEffectiveDateInRange = (
  value: string,
  minimumDate: string | null,
  maximumDate: string
) => {
  const min = minimumDate || minDate;
  return (
    parseInt(min.replace(/-/g, ''), 10) <=
      parseInt(value.replace(/-/g, ''), 10) &&
    parseInt(value.replace(/-/g, ''), 10) <=
      parseInt(maximumDate.replace(/-/g, ''), 10)
  );
};

const EffectiveDate = ({
  state: { value, active, error, msg, showDatePicker },
  toggleCalendar,
  onFocus,
  onBlur,
  onChange,
  disabled,
  maximumDate,
  minimumDate
}: props) => (
  <InputGroup test-attr="cancel-policy-effective-date">
    <Label active={active} error={error}>
      Effective Date
    </Label>
    <ElementWrap active={active} error={error}>
      <DatePicker
        placeholder="MM/DD/YYYY"
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        min={minimumDate || minDate}
        max={maximumDate}
        disabled={disabled}
      />
      <CalendarIcon onClick={() => toggleCalendar()} src={CalendarIconSrc} />
    </ElementWrap>
    <FieldSubText error={error}>{msg || 'MM/DD/YYYY'}</FieldSubText>
    {showDatePicker && (
      <CustomDatePickerModal>
        <CustomDatePickerModalBackground onClick={() => toggleCalendar()} />
        <CustomDatePickerWrap>
          <CustomDatePicker
            date={value || ''}
            setDate={(date: string) => onChange(date)}
            minDate={minimumDate || minDate}
            onClickCancel={() => toggleCalendar()}
            maxDate={maximumDate}
          />
        </CustomDatePickerWrap>
      </CustomDatePickerModal>
    )}
  </InputGroup>
);

EffectiveDate.defaultProps = {
  maximumDate: '',
  minimumDate: ''
};

export default EffectiveDate;
