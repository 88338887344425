import React, { useState, useEffect } from 'react';

import {
  CancelPolicyAccordionWrap,
  CancelPolicyAccordionHead,
  HeaderIconWrap,
  HeaderIcon,
  HeaderTitleWrap,
  HeaderTitle,
  HeaderChevronWrap,
  CancelPolicyAccordionBody
} from './cancelPolicyStyles';

import CancelPolicyIconSrc from '../../assets/images/CancelPolicy.svg';
import {
  ChevronUp,
  ChevronDownUnbolded,
  CancelPolicyDisabled,
  ChevronDownDisabled
} from '../../icons';

import CancelPolicyForm from './cancelPolicyForm/cancelPolicyForm';
import CancellationRequestSent from './cancellationRequestSent/cancellationRequestSent';
import { CANCEL_POLICY_cancelPolicy } from './graphql/__generated__/CANCEL_POLICY';
import OutageModal from '../outageModal';

export interface formValues {
  cancellationReason: string;
  effectiveDate: string;
  cancellationDocument: File;
  securityType: string;
  reasonCode: string;
  uploadAvailable: boolean;
  carrierMovedTo: string;
  otherCarrier: string;
}

export interface CancelPolicyAccordionHeadProps {
  disabled: boolean;
}

export const formStatusObj = {
  notSubmitted: 'notSubmitted',
  submitting: 'submitting',
  submitted: 'submitted'
};
export interface cancelPolicyProps {
  upload: Function;
  uploadError?: Error;
  policyNumber: string;
  systemCode: string;
  cancel: Function;
  cancelData: CANCEL_POLICY_cancelPolicy;
  cancelError?: Error;
  minDate: string;
  maxDate: string;
  agencyBill: boolean;
  product: string;
  getAcceptance: Function;
  acceptable: boolean;
  openChanges: boolean;
  accountNumber: string;
  acceptanceLoading: boolean;
  pcOutage: boolean;
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
}

export default ({
  upload,
  uploadError,
  policyNumber,
  systemCode,
  cancel,
  cancelData,
  cancelError,
  minDate,
  maxDate,
  agencyBill,
  product,
  getAcceptance,
  acceptable,
  openChanges,
  accountNumber,
  acceptanceLoading,
  pcOutage,
  readOnlyAccess,
  readOnlyFeatureFlag
}: cancelPolicyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formStatus, setFormStatus] = useState(formStatusObj.notSubmitted); // Initialize with formStatusObj.submitted if user has submitted the form already
  const [outageModalOpen, setOutageModalOpen] = useState(false);

  let initialSource = 'Select Requested By';
  if (product !== 'COMMERCIAL PACKAGE' && product !== 'WORKERS COMP') {
    initialSource = agencyBill ? 'Agent' : 'Insured';
  }
  const [cancellationSource, setCancellationSource] = useState<string>(
    initialSource
  );
  useEffect(() => {
    if (cancelError || uploadError) {
      setFormStatus(formStatusObj.submitted);
    }
  }, [cancelError, uploadError]);

  const translateDocument = async (f: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = err => {
        reject(err);
        return null;
      };
    });
  const submitForm = async ({
    cancellationReason,
    effectiveDate,
    cancellationDocument,
    securityType,
    reasonCode,
    uploadAvailable,
    carrierMovedTo,
    otherCarrier
  }: formValues) => {
    if (acceptable) {
      setFormStatus(formStatusObj.submitting);
      await cancel({
        variables: {
          policy_number: policyNumber,
          method: 'createPolicyCancellationTransaction',
          cancellation_reason_code: reasonCode,
          description: cancellationReason,
          effective_date: `${effectiveDate}T00:00:00Z`,
          refund_method: 'prorata',
          source: cancellationSource.toLowerCase(),
          carrier_moved: carrierMovedTo,
          other_carrier: carrierMovedTo === 'Other' ? otherCarrier : '',
          system_code: systemCode
        }
      });
      if (uploadAvailable) {
        const docData = await translateDocument(cancellationDocument);
        await upload({
          variables: {
            policy_number: policyNumber,
            file_name: cancellationDocument.name,
            mime_type: cancellationDocument.type,
            document_type: 'letter_received',
            security_type: securityType,
            source_system_code: systemCode,
            document_data: docData
          }
        });
      }
      if (!cancelError && !uploadError && !cancelData?.uwReferral) {
        await cancel({
          variables: {
            policy_number: policyNumber,
            method: 'confirmPolicyCancellation',
            system_code: systemCode
          }
        });
      }
      setFormStatus(formStatusObj.submitted);
    } else if (!acceptanceLoading) {
      setFormStatus(formStatusObj.submitted);
    }
  };

  return (
    <CancelPolicyAccordionWrap>
      <CancelPolicyAccordionHead
        disabled={readOnlyAccess && readOnlyFeatureFlag}
        onClick={() => {
          if (pcOutage) {
            setOutageModalOpen(!outageModalOpen);
          } else {
            setIsOpen(!isOpen);
          }
        }}
      >
        <HeaderIconWrap>
          <>
            {readOnlyAccess && readOnlyFeatureFlag ? (
              <CancelPolicyDisabled />
            ) : (
              <HeaderIcon src={CancelPolicyIconSrc} />
            )}
          </>
        </HeaderIconWrap>
        <HeaderTitleWrap>
          <HeaderTitle>CANCEL THIS POLICY</HeaderTitle>
          <HeaderChevronWrap>
            {isOpen ? (
              <ChevronUp />
            ) : (
              <>
                {readOnlyAccess && readOnlyFeatureFlag ? (
                  <ChevronDownDisabled />
                ) : (
                  <ChevronDownUnbolded />
                )}
              </>
            )}
          </HeaderChevronWrap>
        </HeaderTitleWrap>
      </CancelPolicyAccordionHead>
      {outageModalOpen && (
        <OutageModal
          title="Cancel Policy is Unavailable"
          onClose={() => setOutageModalOpen(false)}
        >
          Unfortunately, due to server maintenance, canceling your policy is
          currently unavailable. Please check back in 24 to 48 hours.
        </OutageModal>
      )}
      <CancelPolicyAccordionBody isOpen={isOpen}>
        {formStatus !== formStatusObj.submitted ? (
          <CancelPolicyForm
            submit={submitForm}
            formStatus={formStatus}
            systemCode={systemCode}
            maxDate={maxDate}
            minDate={minDate}
            cancellationSource={cancellationSource}
            setCancellationSource={setCancellationSource}
            product={product}
            initialSource={initialSource}
            close={() => setIsOpen(false)}
            openChanges={openChanges}
            getAcceptance={getAcceptance}
            acceptable={acceptable}
            acceptanceLoading={acceptanceLoading}
            accountNumber={accountNumber}
            policyNumber={policyNumber}
          />
        ) : (
          <CancellationRequestSent
            systemCode={systemCode}
            error={cancelError || uploadError}
            acceptable={acceptable}
            underwriting={cancelData?.uwReferral}
          />
        )}
      </CancelPolicyAccordionBody>
    </CancelPolicyAccordionWrap>
  );
};
