import React, { useEffect, useState } from 'react';
import getConfig, { Env } from '../../config';
import { ReactComponent as CpraImg } from '../../assets/images/CPRA_32px.svg';
import {
  Footer,
  PopUpLink,
  Wrapper,
  MainWrapper,
  LeftWrapper,
  RightWrapper,
  SAFooterProps,
  Anchor,
  Span,
} from './FooterStyles';
const config = getConfig(process.env.REACT_APP_ENV as Env);
export const FooterComponent = ({
  removeBranding,
  CARightsRequired,
  ...rest
}: SAFooterProps) => {
  const handleTestClick = () => {
    /* @ts-ignore  */
    if (typeof window.utag != 'undefined') {
      /* @ts-ignore  */
      window.utag.gdpr.showDoNotSellPrompt();
    }
  };

  const branding =
    removeBranding === true
      ? 'Company'
      : 'State Automobile Mutual Insurance Company';
  const today = new Date();
  const year = today.getFullYear().toString();
  const text = `© ${year} Copyright ${branding}. All rights reserved.`;
  const links = !removeBranding ? (
    <MainWrapper>
      <MainWrapper>
        <Anchor href="https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy">
          Privacy Policy
        </Anchor>
        &nbsp;|&nbsp;
        <Anchor href="https://www.stateauto.com/terms">Terms of Use</Anchor>
        &nbsp;|
        <MainWrapper>
          <LeftWrapper>
            <CpraImg />
          </LeftWrapper>
          <RightWrapper>
            &nbsp;
            <Anchor href={config.carights_url}>
              Your California Privacy Choices
            </Anchor>
            &nbsp;|&nbsp;
          </RightWrapper>
        </MainWrapper>
        <PopUpLink role="button" tabIndex={0} onClick={handleTestClick}>
          <Anchor>
            Do Not Sell or Share My Personal Information (CA Residents Only)
          </Anchor>
        </PopUpLink>
      </MainWrapper>
    </MainWrapper>
  ) : (
    ''
  );

  return (
    <Footer {...rest}>
      <Wrapper>
      <Span>{text}</Span> 
        <span>{links}</span>
      </Wrapper>
    </Footer>
  );
};