import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useParams, RouteComponentProps } from '@reach/router';
import {
  GET_CLAIMS_Recent_Claims_account_items_claims_items,
  GET_CLAIMS_Recent_Claims
} from './graphql/__generated__/GET_CLAIMS_RECENT_CLAIMS';
import {
  GET_CLAIM_INCIDENTS,
  GET_CLAIM_INCIDENTS_account_items_claim_incidents,
  GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts
} from './graphql/__generated__/GET_CLAIM_INCIDENTS';
import ClaimsPage from './ClaimsPage';
import {
  formatZipcode,
  formatTitleCase,
  formatMoney,
  formatDate
} from '../../util/formatters';
import {
  VehicleProps,
  PropertyProps,
  InjuryProps,
  MedicalProps,
  ClaimsDetailProps,
  AdjusterProps
} from '../../components/claimsDetail/ClaimsDetail';
import { ClaimsLatestPaymentProps } from '../../components/claimsLatestPayments/ClaimsLatestPayments';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const get_claims_recent_claims = loader(
  './graphql/Get_Claims_Recent_Claims.graphql'
);
export interface ClaimsData {
  claim_status?: string;
  claimnumber?: string;
  dateofbirth?: string;
  dateofloss?: string;
  hideClaimDetails?: boolean;
  injurydescription?: string;
  losslocationaddressline1?: string;
  losslocationcity?: string;
  losslocationstate?: string;
  losslocationzip?: string;
  name?: string;
  policynumber?: string;
}
const get_claim_incidents = loader('./graphql/Get_Claim_Incidents.graphql');

export const alertsTransform = (
  alerts:
    | (GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts | null)[]
    | null
    | undefined
) => {
  if (!alerts) {
    return [];
  }
  const transformedAlerts: GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts[] = alerts
    .filter(
      (
        alert
      ): alert is GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts => {
        return alert != null;
      }
    )
    .map(
      (
        alert: GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts
      ) => {
        let createDate;
        if (alert.createDate) {
          createDate = `${formatDate(alert.createDate)}`;
        } else {
          createDate = 'Not Available';
        }
        return {
          __typename: 'ClaimAlert',
          publicID: alert.publicID || '',
          createDate,
          alertName: alert.alertName || 'Not Available',
          alertDescription: alert.alertDescription || 'Not Available',
          isRead: alert.isRead
        };
      }
    );
  return transformedAlerts;
};

export const paymentsTransform = (
  incident: GET_CLAIM_INCIDENTS_account_items_claim_incidents | null
) => {
  const payments: ClaimsLatestPaymentProps[] = [];
  if (incident?.exposureDetails?.length) {
    incident.exposureDetails.forEach(exposure => {
      if (exposure?.payments?.length) {
        exposure.payments.forEach(exposurePayment => {
          const singlePayment: ClaimsLatestPaymentProps = {
            payees: [],
            paymentMethod:
              formatTitleCase(exposurePayment?.paymentMethod) || 'Payment',
            issueDate: 'Not Available',
            checkNumber: exposurePayment?.checkNumber || 'Not Available',
            amount: 'Not Available'
          };
          if (exposurePayment?.payees?.length) {
            exposurePayment.payees.forEach(payee => {
              if (payee?.name) {
                const titleCaseName = formatTitleCase(payee.name)!;
                singlePayment.payees.push(titleCaseName);
              }
            });
          }
          if (exposurePayment?.issuedDate) {
            singlePayment.issueDate = `${formatDate(
              exposurePayment.issuedDate
            )}`;
          }
          if (exposurePayment?.amount?.amount) {
            const amount = parseFloat(exposurePayment.amount.amount);
            if (amount === 0) {
              singlePayment.amount = '-';
            } else {
              singlePayment.amount = formatMoney(amount)!;
            }
          }
          payments.push(singlePayment);
        });
      }
    });
  }
  return payments;
};

export const adjusterTransform = (
  occurrence: GET_CLAIM_INCIDENTS_account_items_claim_incidents,
  data: any
) => {
  const adjuster = {
    displayName: 'Not Available',
    email: 'Not Available',
    phoneNumber: 'Not Available'
  };
  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.displayName
  ) {
    adjuster.displayName =
      occurrence.exposureDetails[0].adjusterDetails.displayName;
  } else if (
    data?.account?.items &&
    data.account.items[0]?.claim?.adjuster?.displayName
  ) {
    adjuster.displayName = data.account.items[0].claim.adjuster.displayName;
  }
  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.email
  ) {
    adjuster.email = occurrence.exposureDetails[0].adjusterDetails.email.toLowerCase();
  } else if (
    data?.account?.items &&
    data.account.items[0]?.claim?.adjuster?.email
  ) {
    adjuster.email = data.account.items[0].claim.adjuster.email.toLowerCase();
  }
  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.phoneNumber
  ) {
    adjuster.phoneNumber =
      occurrence.exposureDetails[0].adjusterDetails.phoneNumber;
  } else if (
    data?.account?.items &&
    data.account.items[0]?.claim?.adjuster?.phoneNumber
  ) {
    adjuster.phoneNumber = data.account.items[0].claim.adjuster.phoneNumber;
  }
  return adjuster;
};

export const nameTransform = (data: GET_CLAIMS_Recent_Claims) => {
  const name = data?.account?.items?.[0];
  const userDisplayName =
    name?.display_name === null
      ? ''
      : `for ${formatTitleCase(name?.display_name)}`;
  return userDisplayName;
};

export const transformData = (data: GET_CLAIMS_Recent_Claims) => {
  const information = data?.account?.items?.[0]?.claims?.items;
  const transformedClaims =
    information?.map(
      (claim: GET_CLAIMS_Recent_Claims_account_items_claims_items | null) => {
        let lossLocation = 'Not Available';
        if (claim?.lossLocation && claim.lossLocation.state) {
          lossLocation = claim.lossLocation.state;
        }
        return {
          claimNumber: claim?.claimNumber || 'Not Available',
          insuredName: claim?.insuredName || 'Not Available',
          lossLocation,
          dateOfLoss: claim?.lossDate || 'Not Available',
          status: claim?.claimState || 'Not Available'
        };
      }
    ) || [];
  const name = nameTransform(data);
  return {
    transformedClaims,
    name
  };
};

export const consolidatePRIncidents = (
  incidents: (ClaimsDetailProps | undefined)[]
) => {
  const uniqueAddresses: string[] = [];
  const uniqueIncidents: (ClaimsDetailProps | undefined)[] = [];
  const originalAdjuster: (AdjusterProps | undefined)[] = [];
  const incidentStatus: (string | null | undefined)[] = [];
  incidents.forEach(incident => {
    if (incident?.injury || incident?.medical || incident?.vehicle) {
      uniqueIncidents.push(incident);
      originalAdjuster.push(incident.adjuster);
      incidentStatus.push(incident.status);
    } else if (
      incident?.propertyName !== null &&
      incident?.propertyName !== undefined &&
      !uniqueAddresses.includes(incident?.propertyName)
    ) {
      uniqueAddresses.push(incident.propertyName);
      uniqueIncidents.push(incident);
      originalAdjuster.push(incident.adjuster);
      incidentStatus.push(incident.status);
    } else {
      let index = 0;
      if (
        uniqueIncidents.length > 1 &&
        incident?.propertyName !== null &&
        incident?.propertyName !== undefined
      ) {
        index = uniqueAddresses.indexOf(incident?.propertyName);
      }
      const condensedPayments =
        uniqueIncidents[index]?.summary?.payments?.claimsSummaryPaymentsList ??
        [];
      const currentPayments =
        incident?.summary?.payments?.claimsSummaryPaymentsList ?? [];
      const combinedPayments = condensedPayments.concat(currentPayments);
      const condensedAlerts =
        uniqueIncidents[index]?.summary?.alerts?.alerts ?? [];
      const currentAlerts = incident?.summary?.alerts?.alerts ?? [];
      const combinedAlerts = condensedAlerts.concat(currentAlerts);
      if (currentPayments.length > 0) {
        incidentStatus[index] = incident?.status;
      }

      const updatedIncident: ClaimsDetailProps = {
        publicID: uniqueIncidents[index]?.publicID,
        status: incidentStatus[index],
        summary: {
          payments: {
            claimsSummaryPaymentsList: combinedPayments
          },
          alerts: {
            alerts: combinedAlerts
          }
        },
        adjuster:
          incident?.adjuster?.displayName !==
          originalAdjuster[index]?.displayName
            ? incident?.adjuster
            : uniqueIncidents[index]?.adjuster,
        injury: uniqueIncidents[index]?.injury,
        insuredAsset: uniqueIncidents[index]?.insuredAsset,
        medical: uniqueIncidents[index]?.medical,
        propertyName: uniqueIncidents[index]?.propertyName,
        property: uniqueIncidents[index]?.property,
        vehicle: uniqueIncidents[index]?.vehicle
      };
      uniqueIncidents[index] = updatedIncident;
    }
  });
  return uniqueIncidents;
};

export interface adjusterProps {
  displayName: string;
  email: string;
  phoneNumber: string;
}

export interface incidentProps {
  adjuster: adjusterProps;
  insuredAsset: string;
  vehicle: VehicleProps | null;
  property: PropertyProps | null;
  injury: InjuryProps | null;
  medical: MedicalProps | null;
  status: string;
}

export default (_: RouteComponentProps) => {
  const [selected, setSelected] = useState('');
  const [claimStatus, setClaimStatus] = useState('');
  const [gtm, setGTM] = useState(false);
  const [incidentsStatus, setIncidentsStatus] = useState<string>('');
  const [incidents, setIncidents] = useState<(ClaimsDetailProps | undefined)[]>(
    []
  );
  const { accountNumber } = useParams();
  // const { readOnlyAccess, readOnlyFeatureFlag } = useGlobalContext();
  const [
    getIncidentsQuery,
    { data: incidentsData, error: incidentsError }
  ] = useLazyQuery(get_claim_incidents, {
    onCompleted: (data: GET_CLAIM_INCIDENTS) => {
      if (incidentsError) {
        setIncidentsStatus('Error');
        return;
      }
      const information = data?.account?.items?.[0]?.claim?.incidents;
      const incidentsArray: (ClaimsDetailProps | undefined)[] =
        information?.map(
          (
            occurrence: GET_CLAIM_INCIDENTS_account_items_claim_incidents | null
          ) => {
            if (occurrence) {
              const incident: ClaimsDetailProps = {
                publicID: occurrence!.incident?.publicID || '',
                insuredAsset: 'Not Available',
                summary: {
                  payments: {
                    claimsSummaryPaymentsList: paymentsTransform(occurrence)
                  },
                  alerts: {
                    alerts: alertsTransform(occurrence.incident?.alerts)
                  }
                },
                vehicle: null,
                property: null,
                injury: null,
                medical: null,
                status: occurrence?.incident?.status || 'Not Available',
                adjuster: {
                  displayName: 'Not Available',
                  email: 'Not Available',
                  phoneNumber: 'Not Available'
                },
                propertyName: null
              };
              incident.adjuster = adjusterTransform(occurrence, data);
              if (occurrence?.incident?.property) {
                incident.insuredAsset =
                  formatTitleCase(
                    occurrence.incident.property.propertyAddress?.addressLine1
                  ) || 'Not Available';
                incident.property = {
                  propertyLine1:
                    occurrence.incident.property.propertyAddress
                      ?.addressLine1 || '',
                  propertyLine2:
                    occurrence.incident.property.propertyAddress?.city || '',
                  propertyLine3: `, ${occurrence.incident.property
                    .propertyAddress?.state || ''}, ${formatZipcode(
                    occurrence.incident.property.propertyAddress?.postalCode
                  ) || ''}`
                };
                incident.propertyName =
                  occurrence.incident.property.propertyName;
              }
              if (occurrence?.incident?.vehicle) {
                incident.insuredAsset = formatTitleCase(
                  `${occurrence.incident.vehicle.year || ''} ${occurrence
                    .incident.vehicle.make || ''} ${occurrence.incident.vehicle
                    .model || ''}`
                )!;
                incident.vehicle = {
                  vin: occurrence.incident.vehicle.vin || 'Not Available'
                };
              }
              if (occurrence?.incident?.injury) {
                incident.insuredAsset =
                  formatTitleCase(
                    occurrence?.incident?.injury?.injured?.name
                  ) || 'Not Available';
                incident.injury = {
                  name:
                    formatTitleCase(
                      occurrence?.incident?.injury?.injured?.name
                    ) || 'Not Available'
                };
              }
              if (occurrence?.incident?.medical) {
                incident.insuredAsset =
                  formatTitleCase(
                    occurrence?.incident?.medical?.injured?.name
                  ) || 'Not Available';
                incident.medical = {
                  name:
                    formatTitleCase(
                      occurrence?.incident?.medical?.injured?.name
                    ) || 'Not Available'
                };
              }
              return incident;
            }
          }
        ) || [];
      if (incidentsArray) {
        if (data?.account?.items?.[0]?.claim?.lossType === 'PR') {
          const consolidatedIncidents: (
            | ClaimsDetailProps
            | undefined
          )[] = consolidatePRIncidents(incidentsArray);
          setIncidents(consolidatedIncidents);
        } else {
          setIncidents(incidentsArray);
        }
      }
      setIncidentsStatus('');
    }
  });

  useEffect(() => {
    setIncidentsStatus('Loading...');
    getIncidentsQuery({
      variables: {
        account_number: accountNumber,
        claim_number: selected
      }
    });
  }, [selected]);

  const variables = {
    account_number: accountNumber
  };

  const queryResult = useQuery(get_claims_recent_claims, {
    variables
  });

  const { loading, error } = queryResult;
  let { data } = queryResult;
  if (data) {
    data = transformData(data);
  }
  const changeSelection = (selection: string, selectionClaimStatus: string) => {
    setSelected(selection);
    setClaimStatus(selectionClaimStatus);
  };

  useEffect(() => {
    if (data?.transformedClaims?.length && selected === '') {
      changeSelection(
        data.transformedClaims[0].claimNumber,
        data.transformedClaims[0].claimState
      );
    }
  }, [data]);

  if (!gtm) {
    simpleGTMDataLayer({
      event: 'goal-tracking',
      event_action: 'Claims Page',
      event_category: localStorage.getItem('lob')
        ? localStorage.getItem('lob')
        : ''
    });
    simpleGTMDataLayer({
      event: 'goal-tracking-snowplow',
      event_action: 'Claims Page',
      event_category: localStorage.getItem('lob')
        ? `${localStorage.getItem('lob')}/Snowplow`
        : '/Snowplow'
    });

    simpleGTMDataLayer({
      event: 'pageview',
      page: {
        path: '/account/claims',
        title: 'SA360: Claims'
      }
    });
    setGTM(true);
  }

  return (
    <ClaimsPage
      loading={loading}
      error={error}
      recentClaimsList={data?.transformedClaims}
      claimsIncidentsList={incidents}
      setSelected={changeSelection}
      selected={selected}
      loadingIncidentStatus={incidentsStatus}
      UserDisplayName={data?.name}
      /* readOnlyAccess={readOnlyAccess ?? false}
      readOnlyFeatureFlag={readOnlyFeatureFlag ?? false} */
    />
  );
};
