import styled from 'styled-components';

export const ContainerFilter = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
`;

export const DatePickerHeaderFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: #0173c6;
  align-items: flex-start;
`;

const HeaderTextFilter = styled.span`
  cursor: pointer;
  display: inline;
  padding: 2px;
  border-radius: 4px;
  align-self: flex-start;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const YearFilter = styled(HeaderTextFilter)`
  margin: 0 0 1px 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 8px;
  color: #fff;
`;

export const DateFilter = styled(HeaderTextFilter)`
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
`;

export const DatePickerBodyFilter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatePickerFooterFilter = styled.div`
  display: flex;
  align-items: right;
  padding: 0 5px 5px;
  justify-content: end;
`;

export const FooterButtonFilter = styled.button`
  cursor: pointer;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 7px;
  color: #0173c6;
  border: none;
  padding: 2px;
  margin-left: 4px;
  :first-child {
    margin-left: 0;
  }
`;
