import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const WcClaimsButton = styled.div`
  cursor: pointer;
  font: inherit;
  padding: 7px;
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #0173c6;
  color: #0173c6 !important;
  padding-left: 10px;
  padding-right: 10px;
`;

export interface ViewWcButtonProps {
  showwcClaimHandler: Function;
  wcClaim: boolean;
}

export default ({ showwcClaimHandler, wcClaim }: ViewWcButtonProps) => {
  
  return (
    <>
      {wcClaim ? (
        <ButtonWrapper>
          <WcClaimsButton
            onClick={() => {
              showwcClaimHandler();
              console.log("clicked")
            }}
          >
            VIEW WORKERS COMP CLAIMS
          </WcClaimsButton>
        </ButtonWrapper>
      ) : null}
    </>
  );
};
