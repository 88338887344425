import React from 'react';
import { LinkText } from './changePolicyStyles';
import { GET_CHANGE_POLICY_INFO_account_items_policy_valid_policy_change_messages } from './graphql/__generated__/GET_CHANGE_POLICY_INFO';

interface commercialUrlMessageProps {
  returnedMessage?:
    | (GET_CHANGE_POLICY_INFO_account_items_policy_valid_policy_change_messages | null)[]
    | null;
  policyType: string | undefined;
}
export default ({ returnedMessage, policyType }: commercialUrlMessageProps) => {
  if (
    returnedMessage &&
    returnedMessage[0]?.message &&
    returnedMessage[0].message[0] &&
    returnedMessage[0]?.category === 'Critical' &&
    policyType === 'COMMERCIAL UMBRELLA'
  ) {
    let urlStart = returnedMessage[0].message[0].indexOf('(') + 1;
    let urlEnd = returnedMessage[0].message[0].indexOf(')');
    let url = returnedMessage[0].message[0].substring(urlStart, urlEnd);
    return (
      <div test-attr="policy-change-commercial-url-message-text">
        Our team is working on enabling online policy changes for your
        convenience, meanwhile for any changes on your commercial policy, please
        send your requests to the email found in this
        <LinkText
          onClick={() => {
            window.open(url);
          }}
          test-attr="policy-change-commercial-url-message-link-click"
        >
          link.
        </LinkText>
      </div>
    );
  }
  return <></>;
};
