import styled from 'styled-components';

import { ReactComponent as ChevronRightSrc } from '../../../../assets/images/pagination-next.svg';

export const MenuOptionWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100% - 45px);
  height: 46px;
  margin: 5px 15px 5px 0;
  padding-left: 30px;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  transition: all 0.2s;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.indent {
    padding-left: 40px;
    width: calc(100% - 55px);
  }

  &:hover {
    background-color: #0173c6;

    p {
      color: #ffffff;
    }

    g {
      stroke: #ffffff;
    }
  }
`;

export const MenuOptionText = styled.p`
  display: block;
  margin: 0;
  flex-grow: 1;
  font-size: 16px;
  color: #3b3b3b;

  &.light {
    color: #666666;
  }
`;

export const MenuOptionChevron = styled(ChevronRightSrc)`
  display: block;
  width: 16px;
  height: 16px;
  margin: 17px;
  transform: rotate(90deg);
  transition: all 0.25s;

  &.active {
    transform: rotate(-90deg);
  }

  &.chevronRight {
    transform: rotate(0deg);
  }
`;
