import React from 'react';
import {
  StyledSearchResult,
  StyledHit,
  StyledLeftColumn,
  StyledMiddleColumn,
  StyledRightColumn,
  StyledRightColumnRow,
  StyledHeaderText,
  StyledColumn
} from './AutoCompleteStyles';
import { generateDisplayTextJSX } from './helpers/searchHelperfunctions';
import { SearchResultProps } from './SearchDisplayInterfaces';

export default ({
  index,
  result,
  displayOptionsPolicyNumbers = [],
  policyNumbers = []
}: SearchResultProps) => (
  <StyledHit>
    <StyledSearchResult
      id={`Displayable_Result_HoverableBox_${index}`}
      index={index}
    >
      <StyledLeftColumn>
        {generateDisplayTextJSX(false, result.name, result.displayableName)}
      </StyledLeftColumn>
      <StyledMiddleColumn>
        {generateDisplayTextJSX(true, result.name, result.displayableName)}
        {generateDisplayTextJSX(
          true,
          result.address,
          result.displayableAddress
        )}
        {generateDisplayTextJSX(
          true,
          result.phoneNumber,
          result.displayablePhoneNumber
        )}
        {generateDisplayTextJSX(true, result.email, result.displayableEmail)}
      </StyledMiddleColumn>
      <StyledRightColumn>
        <StyledRightColumnRow>
          <StyledHeaderText>Account # </StyledHeaderText>
          <div>
            {generateDisplayTextJSX(
              true,
              result.accountNumber,
              result.displayableAccountNumber
            )}
          </div>
        </StyledRightColumnRow>
        <StyledRightColumnRow>
          <StyledHeaderText>Policy # </StyledHeaderText>
          <StyledColumn>
            <div>
              {displayOptionsPolicyNumbers.map(displayOption =>
                generateDisplayTextJSX(true, '', displayOption)
              )}
              {policyNumbers.map(policyNumber =>
                generateDisplayTextJSX(true, policyNumber)
              )}
            </div>
          </StyledColumn>
        </StyledRightColumnRow>
      </StyledRightColumn>
    </StyledSearchResult>
  </StyledHit>
);
