import React from 'react';
import styled from 'styled-components';
import { CloseSmall } from '../../icons';
import {
  StyledPreviouslyViewedWrapper,
  StyledPreviouslyViewedContainer,
  StyledPreviouslyViewedInnerContent
} from './AutoCompleteStyles';
import { DisplayableResult, DisplayOption } from './SearchDisplayInterfaces';
import AccountSearchResult from './AccountSearchResult';
import QuoteSearchResult from './QuoteSearchResults';

interface textProps {
  weight?: string | undefined;
  size?: string | undefined;
  partial?: boolean;
  color?: string | undefined;
}

const Text = styled.span<textProps>`
  font-weight: ${props => props.weight};
  font-size: ${props => props.size};
  white-space: ${props => (props.partial ? 'pre-wrap' : 'normal')};
  color: ${props => props.color};
  line-height: normal;
`;

export const generateJSX = (result: DisplayableResult, index: number) => {
  const displayOptionsPolicyNumbers: DisplayOption[][] =
    result?.displayablePolicyNumbers?.slice(0, 3) || [];
  const highlightedPolicyNumbers: string[] =
    result?.displayablePolicyNumbers
      ?.map(displayablePolicyNumber => {
        return displayablePolicyNumber.reduce((reducedValue, displayOption) => {
          return reducedValue + displayOption.text;
        }, '');
      })
      .slice(0, 3) || [];
  const policyNumbers: string[] =
    result?.policyNumbers
      ?.filter(policyNumber => {
        return !highlightedPolicyNumbers.includes(policyNumber);
      })
      .slice(0, 3 - highlightedPolicyNumbers.length) || [];

  if (result.documentType === 'QUOTE') {
    return (
      <QuoteSearchResult
        index={index}
        result={result}
        displayOptionsPolicyNumbers={displayOptionsPolicyNumbers}
        policyNumbers={policyNumbers}
      />
    );
  }

  return (
    <AccountSearchResult
      index={index}
      result={result}
      displayOptionsPolicyNumbers={displayOptionsPolicyNumbers}
      policyNumbers={policyNumbers}
    />
  );
};

export const getPreviouslyViewed = () => {
  if (
    typeof Storage !== 'undefined' &&
    localStorage.viewedInsureds !== undefined
  ) {
    const recentlyViewed = [];
    recentlyViewed.push({
      label: (
        <StyledPreviouslyViewedWrapper>
          <StyledPreviouslyViewedContainer>
            <StyledPreviouslyViewedInnerContent>
              <Text>Previously Viewed</Text>
            </StyledPreviouslyViewedInnerContent>
            <StyledPreviouslyViewedInnerContent>
              <CloseSmall />
            </StyledPreviouslyViewedInnerContent>
          </StyledPreviouslyViewedContainer>
        </StyledPreviouslyViewedWrapper>
      ),
      value: 'reset:viewedInsureds'
    });
    const storageContainer = JSON.parse(localStorage.viewedInsureds);
    storageContainer.previouslyViewed.map(
      (result: DisplayableResult, index: number) => {
        recentlyViewed.push({
          label: generateJSX(result, index),
          value: JSON.stringify(result)
        });
        return null;
      }
    );
    return recentlyViewed;
  }

  return [];
};
