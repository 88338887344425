import styled from 'styled-components';

export const ClaimsPageRequestButton = styled.button`
  cursor: pointer;
  font: inherit;
  padding: 7px;
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #0173c6;
  color: #0173c6 !important;
  width: 100%;
`;

export const PolicyPageRequestButtonWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgb(1, 83, 142, 0.2);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not(.disabled):hover {
    img:first-child {
      background-color: #65a100;
    }
  }
`;

export const RequestButtonIcon = styled.img`
  padding: 15px 21px;
  width: 24px;
  height: auto;
  background-color: #0072c6;
`;

export const RequestButtonText = styled.p`
  flex-grow: 1;
  font-weight: 700;
  margin-left: 20px;
  font-size: 18px;
  color: #3b3b3b;
`;

export const RequestButtonLoading = styled.img`
  display: block;
  width: 22px;
  height: auto;
  margin-right: 30px;
`;
