import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes/default.theme';
import formatTitleCase from '../../util/formatters/formatTitleCase';

interface StyledTagProps {
  color: string;
}

interface ContentsTagProps extends StyledTagProps {
  children: string;
  testId: string;
}

const ContentsTag = styled.div<StyledTagProps>`
  border: 1px solid ${props => props.color};
  font-size: 12px;
  font-weight: 700;
  display: inline;
  padding: 5px 10px 5px 10px;
  color: ${props => props.color};
  border-radius: 12px;
  margin: 0px 10px 10px 0px;
`;

export default ({ color, testId = '', children }: ContentsTagProps) => {
  let colorHex;
  switch (color) {
    case 'green':
      colorHex = `${colors.green1}`;
      break;
    case 'red':
      colorHex = `${colors.valueRed1}`;
      break;
    case 'purple':
      colorHex = `${colors.purple}`;
      break;
    default:
      colorHex = `${colors.darkGray1}`;
  }

  return (
    <ContentsTag color={colorHex} data-test-id={testId}>
      {formatTitleCase(children)}
    </ContentsTag>
  );
};
