import React from 'react';
import {
  ErrorModal,
  ChangePolicyContainer,
  Unavailable,
  InfoIconSizing,
  CloseIconSizing
} from './changePolicyStyles';
import { ErrorIcon, ErrorAltCloseIcon } from '../../icons';

interface errorModalProps {
  errorMessage: string | null | undefined;
  setShowModal: Function;
}

export default ({ errorMessage, setShowModal }: errorModalProps) => {
  return (
    <ErrorModal>
      <ChangePolicyContainer>
        <Unavailable>
          <InfoIconSizing>
            <ErrorIcon />
          </InfoIconSizing>
          <div test-attr="error-modal-text">{errorMessage}</div>
          <CloseIconSizing
            onClick={() => {
              setShowModal(false);
            }}
            test-attr="close-modal-button"
          >
            <ErrorAltCloseIcon />
          </CloseIconSizing>
        </Unavailable>
      </ChangePolicyContainer>
    </ErrorModal>
  );
};
