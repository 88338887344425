import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { RouteComponentProps, useParams } from '@reach/router';
import moment from 'moment-timezone';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CancelPaymentModal from './CancelPaymentModal';
import CancelPaymentButton from './CancelPaymentButton';
import { UPDATE_CANCEL_PAYMENTVariables } from './graphql/__generated__/UPDATE_CANCEL_PAYMENT';
import {
  GET_CANCELPAYMENT_INVOICES,
  GET_CANCELPAYMENT_INVOICESVariables,
  GET_CANCELPAYMENT_INVOICES_account_items_invoices_items
} from './graphql/__generated__/GET_CANCELPAYMENT_INVOICES';
import { formatDate } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useFeatureFlag from '../../util/hooks/useFeatureFlag';
import OutageModal from '../outageModal';

const get_cancel_payment = loader(
  './graphql/Get_CancelPayment_Invoices.graphql'
);

const update_cancel_payment = loader('./graphql/Update_Cancel_Payment.graphql');

export interface CancelPaymentBtnProps {
  readOnlyAccess: boolean;
  readOnlyFeatureFlag: boolean;
  setHasStoppedPayment: Function;
}

const filterNonCancelablePayments = (
  invoices: (GET_CANCELPAYMENT_INVOICES_account_items_invoices_items | null)[]
) => {
  const filteredInvoices: GET_CANCELPAYMENT_INVOICES_account_items_invoices_items[] = invoices.filter(
    (
      invoice: GET_CANCELPAYMENT_INVOICES_account_items_invoices_items | null
    ): invoice is GET_CANCELPAYMENT_INVOICES_account_items_invoices_items => {
      if (!invoice?.pmt_draft_date) {
        return false;
      }
      const draft_date = moment.tz(
        invoice.pmt_draft_date,
        'YYYY-MM-DD',
        'America/New_York'
      );
      const currentDate = moment.tz('America/New_York');
      const isBeforeDratDate =
        currentDate.isBefore(draft_date) ||
        (currentDate.isSameOrBefore(draft_date, 'days') &&
          invoice.pmt_status === 'Canceled');
      if (
        isBeforeDratDate &&
        currentDate.isSameOrAfter(draft_date.clone().subtract(10, 'days'))
      ) {
        return true;
      }
      return false;
    }
  );
  return filteredInvoices;
};

const containsDesiredPaymentStatus = (
  invoices: GET_CANCELPAYMENT_INVOICES_account_items_invoices_items[],
  desiredStatus: string
) => {
  let containsDesiredStatus: boolean = false;
  let firstInvoiceWithStatus: GET_CANCELPAYMENT_INVOICES_account_items_invoices_items | null = null;
  for (let i = 0; i < invoices.length; i += 1) {
    if (invoices[i].pmt_status === desiredStatus) {
      containsDesiredStatus = true;
      firstInvoiceWithStatus = invoices[i];
      break;
    }
  }
  return {
    containsDesiredStatus,
    firstInvoiceWithStatus
  };
};

export default ({
  readOnlyAccess,
  readOnlyFeatureFlag,
  setHasStoppedPayment
}: RouteComponentProps<CancelPaymentBtnProps>) => {
  const { accountNumber } = useParams();

  const { flagDetails: billingCenterOutageFlagDetails } = useFeatureFlag(
    'BillingCenter-Outage'
  );

  const variables: GET_CANCELPAYMENT_INVOICESVariables = {
    account_number: accountNumber
  };

  const [updateCancelPayment] = useMutation(update_cancel_payment);
  const [invoices, setInvoices] = useState<
    GET_CANCELPAYMENT_INVOICES_account_items_invoices_items[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const [toolTipText, setToolTipText] = useState('');
  const [errorState, setErrorState] = useState(false);
  const [allowCancelPayment, setAllowCancelPayment] = useState(false);
  const [displayStopPaymentOutage, setDisplayStopPaymentOutage] = useState<
    boolean
  >(false);

  const toggleShowModal = () => {
    if (billingCenterOutageFlagDetails?.enabled) {
      setDisplayStopPaymentOutage(true);
    } else {
      setShowModal(!showModal);
    }
  };

  const { data: queryData } = useQuery(get_cancel_payment, {
    variables,
    fetchPolicy: 'no-cache',
    onCompleted: (data: GET_CANCELPAYMENT_INVOICES) => {
      let invoices =
        data &&
        data?.account?.items &&
        data?.account?.items[0] &&
        data?.account?.items[0].invoices
          ? data?.account.items[0].invoices?.items || []
          : [];
      const accountInvoices: GET_CANCELPAYMENT_INVOICES_account_items_invoices_items[] = filterNonCancelablePayments(
        invoices
      );

      const containsCanceled = containsDesiredPaymentStatus(
        accountInvoices,
        'Canceled'
      );
      if (
        containsDesiredPaymentStatus(accountInvoices, 'Requested')
          .containsDesiredStatus
      ) {
        setAllowCancelPayment(true);
      } else if (
        accountInvoices.length &&
        containsCanceled.containsDesiredStatus
      ) {
        setHasStoppedPayment && setHasStoppedPayment(true);
        setToolTipText(
          `The automatic payment due ${formatDate(
            containsCanceled.firstInvoiceWithStatus?.pmt_draft_date
          )} has been stopped.`
        );
      } else {
        setToolTipText(
          'Payments can only be stopped within 10 days of the due date.'
        );
      }
      setInvoices(accountInvoices);
    }
  });

  let numberOfPolicies: number = 0;
  if (
    queryData &&
    queryData?.account?.items &&
    queryData?.account?.items[0]?.policies?.items
  ) {
    numberOfPolicies =
      queryData.account?.items[0]?.policies &&
      queryData?.account?.items[0]?.policies.items.length;
  }

  let submitCancelPayment = () => {};
  const containsRequested = containsDesiredPaymentStatus(invoices, 'Requested');
  if (containsRequested.containsDesiredStatus && allowCancelPayment) {
    submitCancelPayment = () => {
      const input: UPDATE_CANCEL_PAYMENTVariables = {
        account_number: accountNumber
      };
      updateCancelPayment({ variables: input })
        .then(res => {
          setAllowCancelPayment(false);
          const newToolTipText = `The automatic payment due ${formatDate(
            containsRequested.firstInvoiceWithStatus?.pmt_draft_date
          )} has been stopped.`;
          setToolTipText(newToolTipText);
          toggleShowModal();
        })
        .catch((err: Error) => {
          if (
            err.message ===
            'GraphQL error: Cannot cancel payment unless there is 1 requested payment.'
          ) {
            simpleGTMDataLayer({
              event: 'cancelPaymentErrorState',
              event_action: 'Error State',
              event_category: window.location.pathname,
              event_label: 'Invalid Number of Requested Payments'
            });
          }
          setErrorState(true);
        });
    };
  }

  if (showModal) {
    return (
      <CancelPaymentModal
        submitCancelPayment={submitCancelPayment}
        toggleShowModal={toggleShowModal}
        numberOfPolicies={numberOfPolicies}
        pmtDraftDate={
          containsRequested.firstInvoiceWithStatus?.pmt_draft_date || null
        }
        errorState={errorState}
      />
    );
  }
  return (
    <>
      <CancelPaymentButton
        toolTipText={toolTipText}
        allowCancelPayment={allowCancelPayment}
        toggleShowModal={toggleShowModal}
        readOnlyAccess={readOnlyAccess}
        readOnlyFeatureFlag={readOnlyFeatureFlag}
      />
      {displayStopPaymentOutage && (
        <OutageModal
          title="Stop Upcoming Payment is Unavailable"
          onClose={() => setDisplayStopPaymentOutage(false)}
        >
          Unfortunately, due to server maintenance, stopping your upcoming
          payment is currently unavailable. Please check back in 24 to 48 hours.
        </OutageModal>
      )}
    </>
  );
};
