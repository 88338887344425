import { displayOption } from '.';

export default (highlightString: string) => {
  let tmpHLString = highlightString.replace(/\n/g, ', ');
  const highlightSplit: displayOption[] = [];

  while (tmpHLString.indexOf('<>') !== -1) {
    const startingHighlightPos = tmpHLString.indexOf('<>');
    const endingHighlightPos = tmpHLString.indexOf('</>');
    if (startingHighlightPos !== 0) {
      highlightSplit.push({
        style: 'normal',
        text: tmpHLString.slice(0, startingHighlightPos)
      });
    }
    highlightSplit.push({
      style: 'highlighted',
      text: tmpHLString.slice(startingHighlightPos + 2, endingHighlightPos)
    });
    tmpHLString = tmpHLString.slice(endingHighlightPos + 3);
  }

  if (tmpHLString.length) {
    highlightSplit.push({
      style: 'normal',
      text: tmpHLString
    });
  }

  return highlightSplit;
};
