import React from 'react';
import { useParams } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { QuotePageNavBar } from './QuotePageNavBar';
import {
  Get_Quote_Insured_NameVariables,
  Get_Quote_Insured_Name
} from './graphql/__generated__/GET_QUOTE_INSURED_NAME';

const get_quote_insured_name_query = loader(
  './graphql/Get_Quote_Insured_Name.graphql'
);

export default () => {
  const { quoteNumber } = useParams();
  const { data } = useQuery<
    Get_Quote_Insured_Name,
    Get_Quote_Insured_NameVariables
  >(get_quote_insured_name_query, {
    variables: {
      quote_number: quoteNumber
    }
  });

  const insuredName: string | null =
    data?.quote?.insured ?? null;

  return <QuotePageNavBar insuredName={insuredName} />;
};
