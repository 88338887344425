import styled from 'styled-components';

export const PaymentWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: rgba(1, 83, 142, 0.2) 0px 2px 4px 0px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
`;

export const ItemContainer = styled.div`
  margin: 10px 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 20px 20px;
  border: 1px solid rgb(217, 223, 235);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgba(1, 83, 142, 0.2) 0px 2px 4px 0px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin: 10px 0px;
`;

export const HeadingContainer = styled.div`
  flex-basis: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 600;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  line-height: 30px;
`;

export const DateContainerText = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const IconContainer = styled.div`
  margin-left: 10px;
`;

export const NoPaymentPresent = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  padding: 20px 20px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ececec;
  }

  th {
    background-color: rgb(217, 223, 235);
  }

  tr:hover {
    background-color: rgb(217, 223, 235);
  }
`;

export const SearchContainerWrapper = styled.div`
  padding: 20px 30px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flext-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SearchInputContainer = styled.div`
  width: 60%;
  min-height: 50px;
  flex-grow: 2;
`;

export const ClearFilterButton = styled.div`
  align-items: center;
  background-color: transparent;
  border: 1px solid rgb(77, 132, 11);
  border-radius: 6px;
  box-sizing: border-box;
  color: rgb(77, 132, 11);
  display: flex;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: uppercase;
  width: 125px;
  height: 30px;
  cursor: pointer;
  justify-content: center;
`;

export const ClearFilterButtonWrapper = styled.div`
  padding: 10px;
`;

export const FilterPaymentsButtonWrapper = styled.div`
  padding: 10px;
`;

export const FilterPaymentsButton = styled.div`
  align-items: center;
  background-color: rgb(77, 132, 11);
  border: transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: flex;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: uppercase;
  width: 125px;
  height: 30px;
  cursor: pointer;
`;
export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const InputContainer = styled.form`
  position: relative;
  display: inline-block;
`;
export const CustomInput = styled.div`
  background-color: white;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30vw;
  height: auto;
  min-height: 6vh;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  position: relative;
  border: none;
  &:hover {
    border: 1px solid #0173c6;
  }
  &:active {
    border: 1px solid #0173c6;
  }
`;
export const SelectedItem = styled.div`
  background-color: #ccc;
  font-weight: bold;
  margin: 5px;
  padding: 5px;
  height: 25px;
  display: inline-block;
  font-size: 14px;
`;
export const Icon = styled.span`
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: 10px;
`;
export const BorderLabelLocation = styled.span`
  position: absolute;
  top: -10px;
  left: 5px;
  background: linear-gradient(to bottom, transparent 50%, white 50%);
  padding: 0 4px;
  font-size: 12px;
  transform: translateY(140%);
  color: #0173c6;
`;
export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  &:hover,
  &.selected {
    background-color: #ccc;
  }
  margin: 20px;
  height: 5vh;
`;
export const DropdownOptions = styled.div`
  position: absolute;
  top: calc(100% - 20px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  width: 32vw;
  overflow-y: auto;
  z-index: 999;
  padding: 5px;
`;
export const OptionText = styled.span`
  margin-left: 5px;
`;
export const NavButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
`;
export const SortContainer = styled.div`
  display: flex;
  alignitems: center;
  cursor: pointer;
  margin-left: 12px;
`;
export const NavButtonContainer = styled.div`
  display: inline-flex;
  margin-right:10px;
`;
