import React from 'react';
import ClaimAndLiability from './ClaimAndLiability';
import Reserves from './Reserves';
import { Wrapper } from '../ExpandedViewStyles';
import { Spinner, Loading } from '../ExpandedViewStyles';
import { PrimarySpinner } from '../../../icons';
interface OverviewAndUpdatesProps {
  getWCDetails?: any;
  loading?: boolean;
}
const OverviewAndUpdates: React.FC<OverviewAndUpdatesProps> = ({
  getWCDetails,
  loading
}) => {
  const claimInformation = getWCDetails.claimInformation;
  return (
    <Wrapper>
      {loading ? (
        <Loading>
          <Spinner>
            <PrimarySpinner />
          </Spinner>
          Loading ...
        </Loading>
      ) : (
        <>
          <ClaimAndLiability
            claimStatus={getWCDetails.claim_status}
            liabilityStatus={getWCDetails.liability}
          />
          <Reserves
            rowOne={getWCDetails.claimsReserveDetails}
            rowTwo={getWCDetails.paymentSumDetails}
          />
        </>
      )}
    </Wrapper>
  );
};

export default OverviewAndUpdates;
